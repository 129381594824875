import React, { Component } from 'react';
import Select from 'react-select';
import ReactTable from 'react-table';
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import Contact from './Contact';
import LOATemplate from './LOATemplate';
import { withRouter } from 'react-router';
import * as globals from '../../Globals/Variables';
import * as CustodianModels from './CustodianModels';
import * as ContactModels from './ContactModels';
import * as DropdownModels from './DropdownModels';
import * as LOAModels from './LOAModels';
import * as AccountModels from './AccountModels';
import FileSaver from 'file-saver';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

var debounce = require('lodash/debounce');
var FontAwesome = require('react-fontawesome');

const styleOverrides = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    // bottom: "unset",
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    width: '70%',
    height: '70%',
    // top: '25%',
    // pointerEvents: "none"
  },
};
let AllRequiredFields = ['firstName', 'custType', 'lastName', 'status', 'address', 'country', 'custodianName', 'email', 'phone'];

function getINITIAL_STATE(that) {
  return {
    data: [],
    contactData: [],
    countries: [],
    states: [],
    custodianTypes: [],
    contactTypes: [],
    workAddressTypeId: null,
    pagesize: 10,
    page: 0,
    rowCount: 0,
    loaPagesize: 10,
    loaPage: 0,
    loaRowCount: 0,
    loaOrderby: '&$orderby=FileName',
    loaExpand: '&$expand=LOA_TEMPLATE_TYPE',
    contactPagesize: 10,
    contactPage: 0,
    contactRowCount: 0,
    contactOrderby: '&$orderby=CONTACT/NameAlias',
    showRequired: false,
    contactModalOpen: false,
    standalone: false,
    firmTypes: [
      {
        label: 'Custodian',
        value: 'Custodian',
      },
      {
        label: 'Manager',
        value: 'Manager',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    loading: true,
    loaData: [],
    loaColumns: [
      {
        Header: () => {
          return <div className="select-div">Download</div>;
        },
        accessor: 'Buttons',
        Cell: that.downloadCell,
        sortable: false,
      },
      {
        accessor: 'loaname',
        Header: 'Document Name',
        Cell: (info) => that.textCell(info, 'loaData'),
      },
      {
        accessor: 'loatype',
        Header: 'Type (LOA/Addendum)',
        Cell: (info) => that.textCell(info, 'loaData'),
      },
      {
        accessor: 'comments',
        Header: 'Comments',
        Cell: (info) => that.textCell(info, 'loaData'),
      },
    ],
    contactColumns: [
      // {
      //   Header: () => {
      //     return (
      //       <div className='select-div'>
      //         Select
      //       </div>
      //     )
      //   },
      //   accessor: 'buttons',
      //   Cell: (info) => that.buttonCell(info, 'contactData'),
      // },
      {
        accessor: 'contactname',
        Header: 'Contact',
        Cell: (info) => that.textCell(info, 'contactData'),
      },
      {
        accessor: 'email',
        Header: 'Email',
        Cell: (info) => that.textCell(info, 'contactData'),
        sortable: false,
      },
      {
        accessor: 'phone',
        Header: 'Phone',
        Cell: (info) => that.textCell(info, 'contactData'),
        sortable: false,
      },
      {
        accessor: 'address',
        Header: 'Address',
        Cell: (info) => that.textCell(info, 'contactData'),
        sortable: false,
      },
    ],
  };
}
let helpArray = [];
class Custodian extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
  }

  componentDidMount() {
    //Get tooltips
    this.getTooltips();
    if (this.props.match.url.indexOf('custodian') !== -1) {
      this.setState({ standalone: true });
    }
    this.setState({ type: this.props.type });
    if (this.props.type === 'view') {
      this.getCustodian(this.props.custodianId);
      this.getCustodianLOAs(this.props.custodianId);
      this.setState({ custodianId: this.props.custodianId });
    } else if (this.props.match.params.id) {
      this.setState({ type: 'edit', custodianId: this.props.match.params.id });
      this.getCustodian(this.props.match.params.id);
      this.getCustodianLOAs(this.props.match.params.id);
    } else {
      this.getStates();
      this.getCountries();
      this.getCustodianTypes();
      this.getStatus();
      this.getAddressTypes();
      this.getContactTypes();
      this.getLoaTemplateTypes();
    }
  }

  getTooltips = () => {
    return new Promise((resolve, reject) => {
      let { services, user } = this.props;
      AccountModels.getTooltips({ services, user })
        .then((response) => {
          if (!response.error) {
            let helpInformation = response.body;
            if (helpInformation.value) {
              helpInformation.value.map((item) => {
                helpArray[item.HelpID] = item.Text;
                helpArray[item.HelpID.toUpperCase()] = item.Text;
                return {
                  HelpID: item['HelpID'],
                };
              });
              this.setState(
                {
                  helpInformation: helpArray,
                },
                () => {
                  resolve();
                }
              );
            } else {
              this.setState(
                {
                  helpInformation: null,
                },
                () => {
                  resolve();
                }
              );
            }
          }
        })
        .catch((err) => {});
    });
  };

  refreshGrid = () => {
    if (this.props.type === 'view') {
      this.getCustodian(this.props.custodianId);
      this.getCustodianLOAs(this.props.custodianId);
    } else if (this.props.match.params.id) {
      this.getCustodian(this.props.match.params.id);
      this.getCustodianLOAs(this.props.match.params.id);
    }
  };

  async getCustodian(custodianId) {
    let { user, services } = this.props;
    let response = await CustodianModels.getCustodianById({
      user,
      services,
      custodianId,
    });
    if (!response.error) {
      let body = response.body ? response.body : null;
      if (body) {
        let state = Object.assign({}, this.state);
        state.custodianName = body.Name;
        state.custType = { label: '', value: body.TypeID };
        state.status = { label: '', value: body.StatusID };
        if (body.EsignatureFlag) {
          state.esign = { label: 'Accepted', value: true };
        } else {
          state.esign = { label: 'Not Accepted', value: false };
        }
        if (body.VerifiedFlag) {
          state.verified = { label: 'Verified', value: true };
        } else {
          state.verified = { label: 'Not Verified', value: false };
        }
        this.setState(state, () => {
          this.getStates();
          this.getCountries();
          this.getCustodianTypes();
          this.getStatus();
          this.getAddressTypes();
          this.getContactTypes();
          this.getLoaTemplateTypes();
          this.getCustodianContacts();
          this.setState({ loadSpinner: false });
        });
      }
    }
  }

  async getCustodianContacts() {
    const { user, services, custodianId } = this.props;
    const { contactPagesize, contactPage, contactOrderby } = this.state;
    let { values, count } = await CustodianModels.getCustodianContacts({
      user,
      services,
      custodianId,
      pagesize: contactPagesize,
      page: contactPage,
      orderby: contactOrderby,
    });
    this.setState({ contactData: [] });
    let temp = values.map((cont, index) => {
      //For grid
      return {
        DetailID: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0].DetailID : null,
        contactname: cont.CONTACT.NameAlias,
        address: this.formatAddress(cont),
        phone: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0].Phone : null,
        email: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0].Email : null,
        data: cont,
      };
    });
    this.setState({ contactData: temp, contactLoading: false, contactRowCount: count });
  }

  async getCustodianLOAs(custodianId) {
    let { user, services } = this.props;
    let { loaPagesize, loaPage, loaOrderby, loaExpand } = this.state;
    let { values, count } = await CustodianModels.getCustodianLoas({
      user,
      services,
      pagesize: loaPagesize,
      page: loaPage,
      orderby: loaOrderby,
      expand: loaExpand,
      custodianId,
    });
    let templates = values.map((temp) => {
      return {
        loaname: temp.FileName,
        loatype: temp.LOA_TEMPLATE_TYPE.Type,
        comments: temp.Comment,
        TemplateID: temp.TemplateID,
      };
    });
    this.setState({ loaData: templates, loaLoading: false, loaRowCount: count });
  }

  async getAddressTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getAddressTypes({
      user,
      services,
    });
    let temp = [];
    temp = types.map((type) => {
      if (type.AddressType === 'Work') {
        this.setState({ workAddressTypeId: type.AddressTypeID });
      }
      return {
        label: type.AddressType,
        value: type.AddressTypeID,
      };
    });
    this.setState({ addressTypes: temp });
  }

  async getStates() {
    let { user, services } = this.props;
    let states = await DropdownModels.getStates({
      user,
      services,
    });
    let temp = [];
    temp = states.map((state) => {
      return {
        label: state.Name,
        value: state.StateID,
      };
    });
    this.setState({ states: temp });
  }

  async getCountries() {
    let { user, services } = this.props;
    let countries = await DropdownModels.getCountries({
      user,
      services,
    });
    let temp = [];
    temp = countries.map((country) => {
      return {
        label: country.Name,
        value: country.Country1,
      };
    });
    this.setState({ countries: temp });
  }

  async getCustodianTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getCustodianTypes({
      user,
      services,
    });
    let temp = [];
    temp = types.map((type) => {
      if (this.state.custType && this.state.custType.value === type.TypeID) {
        this.setState({ custType: { label: type.Name, value: type.TypeID } });
      }
      return {
        label: type.Name,
        value: type.TypeID,
      };
    });
    this.setState({ custodianTypes: temp });
  }

  async getContactTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getContactTypes({
      user,
      services,
    });
    let temp = [];
    temp = types.map((type) => {
      if (type.ContactType === 'Individual') {
        this.setState({ individualContactTypeID: type.ContactTypeID });
      } else if (type.ContactType === 'Company') {
        this.setState({ companyContactTypeID: type.ContactTypeID });
      }
      return {
        label: type.ContactType,
        value: type.ContactTypeID,
      };
    });
  }

  async getStatus() {
    let { user, services } = this.props;
    let statuss = await DropdownModels.getCustodianStatus({
      user,
      services,
    });
    let temp = [];
    temp = statuss.map((status) => {
      if (this.state.status && this.state.status.value === status.StatusID) {
        this.setState({ status: { label: status.Status, value: status.StatusID } });
      }
      if (status.Name === 'Active') {
        this.setState({ activeStatusId: status.Status });
      }
      return {
        label: status.Status,
        value: status.StatusID,
      };
    });
    this.setState({ statusList: temp });
  }

  async getLoaTemplateTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getTemplateTypes({
      user,
      services,
    });
    let temp = [];
    temp = types.map((type) => {
      return {
        label: type.Type,
        value: type.Type,
      };
    });
    this.setState({ templateTypes: temp });
  }

  b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  async createCustodianContactLink(custodianId, contactId) {
    let { user, services } = this.props;
    let response = await CustodianModels.createCustodianContactLink({
      user,
      services,
      custodianId,
      contactId,
    });
    if (!response.error) {
      let obj = Object.assign({}, this.props.accountData);
      obj.CustodianID = custodianId;
      obj.CustodianName = this.state.custodianName;
      this.props.setValidCustodian(true);
      this.props.updateAccountData(obj);
      if (!this.state.standalone) {
        this.props.setCurrentScreen('account');
      }
    } else {
      // alert('Failed')
    }
  }

  async onSaveClick() {
    let { user, services } = this.props;
    let isContactDone = false;
    let createdCustodianID, createdContactID;

    let custodianBody = {
      FirmID: this.props.user.FirmId,
      Name: this.state.custodianName,
      ShortName: this.state.custodianName,
      ReferenceType: 'INTERNAL',
      TypeID: this.state.custType.value,
      StatusID: this.state.status.value,
      VerifiedFlag: this.state.verified.value,
      EsignatureFlag: this.state.esign.value,
      AccountLimit: 20,
      RequireLOAFlag: true,
      SignerLimit: 10,
    };

    let response = await CustodianModels.createCustodian({
      user,
      services,
      body: custodianBody,
    });
    if (!response.error) {
      //If successful and contact + contact detail is done, make cust_cont_link
      createdCustodianID = response.body.CustodianID;
      this.createCustodianContactLink(createdCustodianID, createdContactID);
    } else {
      // alert('Failed')
    }
    let ContactBody = {
      FirmID: this.props.user.FirmId,
      ContactTypeID: this.state.companyContactTypeID,
      Status: this.state.activeStatusId,
      NameAlias: this.state.firstName + ' ' + this.state.lastName,
    };
    let contactResponse = await ContactModels.createContact({
      user,
      services,
      body: ContactBody,
    });
    if (!contactResponse.error) {
      createdContactID = contactResponse.body.ContactID;
      let ContactDetailBody = {
        ContactID: contactResponse.body.ContactID,
        Title: this.state.jobTitle,
        Address: this.state.address,
        AddressTypeID: this.state.addressType ? this.state.addressType.value : this.state.workAddressTypeId,
        Country: this.state.country ? this.state.country.value : null,
        Email: this.state.email,
        Fax: this.state.fax,
        FirstName: this.state.firstName,
        LastName: this.state.lastName,
        Mobile: this.state.mobile,
        Phone: this.state.phone,
        Postal: this.state.postal,
        Province: this.state.province,
        StateID: this.state.state ? this.state.state.value : null,
        AutoSignatureFlag: false,
        //Signature: this.state.signature,
      };

      let detailResponse = await ContactModels.createContactDetail({
        user,
        services,
        body: ContactDetailBody,
      });
      if (!detailResponse.error) {
        //If successful and contact + contact detail is done, make cust_cont_link
        this.createCustodianContactLink(createdCustodianID, createdContactID);
      } else {
      }
    }
  }

  async onPatchCustodianClick() {
    let { user, services, custodianId } = this.props;
    let custodianBody = {
      FirmID: this.props.user.FirmId,
      Name: this.state.custodianName,
      TypeID: this.state.custType.value,
      StatusID: this.state.status.value,
      VerifiedFlag: this.state.verified.value,
      EsignatureFlag: this.state.esign.value,
      AccountLimit: 20,
      RequireLOAFlag: true,
      SignerLimit: 10,
    };

    let response = await CustodianModels.patchCustodian({
      user,
      services,
      body: custodianBody,
      custodianId,
    });
    this.props.refreshGrid();
  }

  onCancelClick = () => {
    this.props.setCurrentScreen('account');
  };
  editContactClick = (cellInfo, type) => {
    this.setState({ contactDetailId: cellInfo ? this.state.contactData[cellInfo.index].DetailID : null, contactModalType: type }, () => {
      this.setState({ contactModalOpen: true });
    });
  };

  editTemplateClick = (cellInfo, type) => {
    this.setState(
      {
        templateId: cellInfo ? this.state.loaData[cellInfo.index].TemplateID : null,
        templateModalType: type,
      },
      () => {
        this.setState({
          templateModalOpen: true,
        });
      }
    );
  };

  textCell = (cellInfo, data) => {
    let onClick = () => {};
    switch (data) {
      case 'contactData':
        onClick = this.editContactClick.bind(this, cellInfo, 'view');
        break;
      case 'loaData':
        onClick = this.editTemplateClick.bind(this, cellInfo, 'edit');
        break;
    }
    return (
      <div
        title={this.state[data][cellInfo.index] ? this.state[data][cellInfo.index][cellInfo.column.id] : null}
        className={this.state.hoveredRowCol === data + cellInfo.index ? 'clickable-row' : ''}
        onClick={onClick}
        onMouseEnter={() => {
          this.setState({ hoveredRowCol: data + cellInfo.index });
        }}
        onMouseLeave={() => {
          this.setState({ hoveredRowCol: null });
        }}
      >
        <input
          id={cellInfo.index + cellInfo.column.id}
          className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          key={cellInfo.index + cellInfo.column.id}
          readOnly
          value={this.state[data][cellInfo.index] ? this.state[data][cellInfo.index][cellInfo.column.id] : null}
        />
      </div>
    );
  };

  downloadCell = (cellInfo) => {
    return (
      <div className="buttons-div">
        <FontAwesome
          name="fas fa-trash"
          style={{ cursor: 'pointer' }}
          title="Delete"
          className="fa fas fa-trash fa-pencil-edit-row"
          id="fa-grid-icon"
          onClick={(e) => {
            this.confirmDeleteLOA(cellInfo);
          }}
        />
        <FontAwesome
          name="fas fa-download"
          style={{ cursor: 'pointer' }}
          title="Download"
          className="fa fas fa-download fa-pencil-edit-row"
          id="fa-grid-icon"
          onClick={(e) => {
            this.downloadTemplate(cellInfo);
          }}
        />
      </div>
    );
  };
  confirmDeleteLOA = (cellInfo) => {
    confirmAlert({
      title: <div className="unsaved-changes-header">Warning</div>,
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div>Are you sure you want to delete {this.state.loaData[cellInfo.index].loaname}?</div>
          </div>
        );
      },
      buttons: [
        { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
        {
          label: <div id="continue-confirm-button">Confirm</div>,
          id: 'continue-confirm-button',
          onClick: () => {
            this.deleteTemplate(cellInfo);
          },
        },
      ],
    });
  };
  async deleteTemplate(cellInfo) {
    let { user, services } = this.props;
    this.setState({ loaLoading: true });
    let templateId = this.state.loaData[cellInfo.index].TemplateID;
    let response = await LOAModels.deleteTemplate({
      user,
      services,
      templateId,
    });
    this.getCustodianLOAs(this.props.custodianId);
  }

  async downloadTemplate(cellInfo) {
    let { user, services } = this.props;
    this.setState({ loadSpinner: true });
    let templateId = this.state.loaData[cellInfo.index].TemplateID;
    let response = await LOAModels.getTemplateById({
      user,
      services,
      templateId,
    });
    if (!response.error) {
      let body = response.body;
      if (body) {
        let content = body.FileData;
        var blob = this.b64toBlob(content, 'application/pdf');
        FileSaver.saveAs(blob, `${this.state.loaData[cellInfo.index].loaname}.pdf`);
        this.setState({ loadSpinner: false });
      } else {
        this.setState({ loadSpinner: false });
      }
    }
  }

  buttonCell = (cellInfo, data) => {
    if (this.state.type === 'edit') {
      return (
        <div className="buttons-div">
          <FontAwesome
            name="fa-pencil"
            style={{ cursor: 'pointer' }}
            className="fa fa-pencil fa-pencil-edit-row"
            id="fa-grid-icon"
            onClick={() => {
              this.editContactClick(cellInfo, 'view');
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  onLoaSortedChange = (obj) => {
    let tempBaseOrderby = '';
    let tempExpand = this.state.loaExpand;
    let desc = !obj[0].desc ? ' desc' : '';
    switch (obj[0].id) {
      case 'loaname':
        tempBaseOrderby = `&$orderby=FileName${desc}`;
        break;
      case 'loatype':
        tempBaseOrderby = `&$orderby=LOA_TEMPLATE_TYPE/Type${desc}`;
        break;
      case 'comments':
        tempBaseOrderby = `&$orderby=Comment${desc}`;
        break;
      default:
        tempBaseOrderby = this.state.orderby;
        break;
    }
    this.setState({ loaOrderby: tempBaseOrderby }, () => {
      this.getCustodianLOAs(this.props.custodianId);
    });
  };

  onContactSortedChange = (obj) => {
    let tempBaseOrderby = '';
    let desc = !obj[0].desc ? ' desc' : '';
    switch (obj[0].id) {
      case 'contactname':
        tempBaseOrderby = `&$orderby=CONTACT/NameAlias${desc}`;
        break;
      //Cant order by these below because its one to many relationship
      // case 'email':
      // tempBaseOrderby = `&$orderby=CONTACT/CONTACT_DETAIL/Email${desc}`
      // break;
      // case 'phone':
      // tempBaseOrderby = `&$orderby=CONTACT/Phone${desc}`
      // break;
      // case 'address':
      //   tempBaseOrderby = `&$orderby=CONTACT/Address${desc}`
      //   break;
      default:
        tempBaseOrderby = this.state.contactOrderby;
        break;
    }
    this.setState({ contactOrderby: tempBaseOrderby }, () => {
      this.getCustodianContacts();
    });
  };

  onPageSizeChange = (size, grid) => {
    if (grid === 'loa') {
      this.setState({ loaPagesize: size, loaPage: 0, loaLoading: true }, () => {
        this.getCustodianLOAs(this.props.custodianId);
      });
    } else if (grid === 'contact') {
      this.setState({ contactPagesize: size, contactPage: 0, contactLoading: true }, () => {
        this.getCustodianContacts();
      });
    }
  };

  onPageChange = (index, grid) => {
    if (grid === 'loa') {
      this.setState({ loaPage: index, loaLoading: true }, () => {
        this.getCustodianLOAs(this.props.custodianId);
      });
    } else if (grid === 'contact') {
      this.setState({ contactPage: index, contactLoading: true }, () => {
        this.getCustodianContacts();
      });
    }
  };

  formatAddress = (cont) => {
    let temp = '';
    let detail = cont.CONTACT.CONTACT_DETAIL[0];
    if (detail) {
      temp = detail.Address ? temp + detail.Address : temp + '';
      temp = detail.City ? temp + ' ' + detail.City : temp + '';
      temp = detail.State ? temp + ' ' + detail.State : temp + '';
      temp = detail.Postal ? temp + ' ' + detail.Postal : temp + '';
    }

    return temp;
  };

  handleEditClick = () => {
    let contactTmp = Object.assign([], this.state.contactColumns);
    // contactTmp.unshift({
    //   Header: () => {return (<div className='select-div'>Select</div>)},
    //   accessor: 'buttons',
    //   Cell: (info) => this.buttonCell(info, 'contactData'),
    // })
    this.setState({ contactColumns: contactTmp, type: 'edit' });
    if (this.props.setPageTitle) {
      this.props.setPageTitle('Edit');
    }
  };

  closeModal = () => {
    this.setState({
      templateModalOpen: false,
      templateFileData: null,
      templateName: null,
      templateId: null,
    });
  };

  handlePages = (pages) => {
    return pages === 0 ? 1 : pages; // DEV-876 Dont let pages = 0, default to 1 or issues with jumpto occurs
  };

  render() {
    let { showRequired, type } = this.state;
    let { custType } = this.props;
    let title = '';
    let disabled = this.state.loadSpinner || this.state.type === 'view';
    let disabledClass = disabled ? 'disabled-select' : '';
    if (type === 'view') {
      title = 'View Custodian/Manager';
    } else if (type === 'edit') {
      title = 'Edit Custodian/Manager';
    } else {
      title = 'Add Custodian/Manager';
    }
    return (
      <div className="custodian-modal-container">
        {/*           
        <div className='header-accounts'>
          <div className='account-title'>
            {this.props.isModal ? null:title } 
          </div> 
          {this.state.type === 'add' &&
          <div className='button-div'>
            <div className='generic-button-secondary' id='custodian-cancel-button' onClick={()=>{this.onCancelClick()}}>Cancel</div>
            <div className='generic-button-primary' id='custodian-save-button' onClick={()=>{this.onSaveClick()}}>Save</div>
          </div>
          }
          {this.state.type === 'view' &&
            <div className='button-div'>
              <div className='generic-button-secondary' id='cancel-custodian-button' onClick={()=>{this.props.closeViewModal()}}>Cancel</div>
              <div className='generic-button-primary'  id='edit-custodian-button' onClick={()=>{this.handleEditClick()}}>Edit</div>
            </div>
          }
          {(this.state.type === 'edit' && !this.state.standalone) &&
            <div className='button-div'>
              <div className='generic-button-secondary'  id='cancel-custodian-button' onClick={()=>{this.props.closeViewModal()}}>Cancel</div>
              <div className='generic-button-primary'  id='save-custodian-button' onClick={()=>{this.onPatchCustodianClick()}}>Save</div>
            </div>
          }
          {(this.state.type === 'edit' && this.state.standalone) &&
            <div className='button-div'>
              <div className='generic-button-primary' id='save-custodian-button' onClick={()=>{this.onPatchCustodianClick()}}>Save</div>
            </div>
          } 
        </div>
          */}
        <div className="custodian-wrapper">
          <div className="edit-custodian-info">
            <div className="account-info-item span-all-col ga-1-1">
              <label className="label" for="accountnumber">
                {custType} Name
              </label>
              <input
                disabled={disabled}
                id="custodian-accountnumber"
                className={showRequired ? 'account-input req' : 'account-input'}
                placeholder=""
                value={this.state.custodianName}
                onChange={(e) => {
                  this.setState({ custodianName: e.target.value });
                }}
              />
            </div>

            <div className="account-info-item ga-1-2">
              <label className="label" for="custType">
                {custType} Type
              </label>
              <Select
                isDisabled={disabled}
                className={showRequired ? 'account-select req ' + disabledClass : 'account-select ' + disabledClass}
                id="custodian-custType"
                value={this.state.custType}
                onChange={(e) => {
                  let val = e ? e.value : null;
                  this.setState({ custType: e });
                }}
                options={this.state.custodianTypes}
              />
            </div>

            <div className="account-info-item ga-2-2">
              <label className="label" for="status">
                Status
              </label>
              {/* <Select
                isDisabled={disabled}
                className={showRequired ? 'account-select req' : 'account-select'}
                id='custodian-status'
                value={this.state.status}
                onChange={(e) => {let val = e ? e.value : null; this.setState({status: e})}}
                options={this.state.statusList}
              /> */}
              <input
                disabled={true}
                id="custodian-status"
                className={showRequired ? 'account-input req' : 'account-input'}
                placeholder=""
                value={this.state.status ? this.state.status.label : ''}
              />
            </div>

            <div className="account-info-item ga-1-3">
              <label className="label" for="esign">
                eSignature Accepted
              </label>
              <Select
                isDisabled={disabled}
                className={showRequired ? 'account-select req ' + disabledClass : 'account-select ' + disabledClass}
                id="custodian-esign"
                value={this.state.esign}
                onChange={(e) => {
                  let val = e ? e.value : null;
                  this.setState({ esign: e });
                }}
                options={[
                  { label: 'Accepted', value: true },
                  { label: 'Not Accepted', value: false },
                ]}
              />
            </div>

            <div className="account-info-item ga-2-3">
              <label className="label" for="verified">
                Verified
              </label>
              <Select
                isDisabled={disabled}
                className={showRequired ? 'account-select req ' + disabledClass : 'account-select ' + disabledClass}
                id="custodian-verified"
                value={this.state.verified}
                onChange={(e) => {
                  let val = e ? e.value : null;
                  this.setState({ verified: e });
                }}
                options={[
                  { label: 'Not Verified', value: false },
                  { label: 'Verified', value: true },
                ]}
              />
            </div>
          </div>

          <div className="subheader span-all-col">
            LOA Templates
            <div
              className="generic-button-primary no-margin"
              id="custodian-add-template-button"
              disabled={disabled}
              onClick={() => {
                this.editTemplateClick(null, 'add');
              }}
            >
              Add
            </div>
          </div>
          <div className="modal-subheader"></div>
          <div className="table-container">
            <ReactTable
              data={this.state.loaData}
              columns={this.state.loaColumns}
              page={this.state.loaPage || 0}
              pages={this.handlePages(Math.ceil(this.state.loaRowCount / this.state.loaPagesize))}
              pageSize={this.state.loaPagesize || 25}
              className="-striped -highlight grid"
              onSortedChange={this.onLoaSortedChange} // Called when a sortable column header is cli
              onPageSizeChange={(e) => {
                this.onPageSizeChange(e, 'loa');
              }}
              loading={this.state.loaLoading}
              manual={false}
            />
          </div>
          {this.state.type === 'add' && (
            <div className="add-custodian-info">
              <div className="subheader span-all-col">Primary Contact</div>

              <div className="account-info-item">
                <label className="label" for="firstname">
                  First Name
                </label>
                <input
                  disabled={disabled}
                  id="custodian-firstname"
                  className={showRequired ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.firstName}
                  onChange={(e) => {
                    this.setState({ firstName: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="lastname">
                  Last Name
                </label>
                <input
                  disabled={disabled}
                  id="custodian-lastname"
                  className={showRequired ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.lastName}
                  onChange={(e) => {
                    this.setState({ lastName: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="job">
                  Job Title
                </label>
                <input
                  disabled={disabled}
                  id="custodian-job"
                  className="account-input"
                  placeholder=""
                  value={this.state.jobTitle}
                  onChange={(e) => {
                    this.setState({ jobTitle: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="phone">
                  Phone
                </label>
                <input
                  disabled={disabled}
                  id="custodian-phone"
                  className={showRequired ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.phone}
                  onChange={(e) => {
                    this.setState({ phone: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="mobile">
                  Mobile
                </label>
                <input
                  disabled={disabled}
                  id="custodian-mobile"
                  className="account-input"
                  placeholder=""
                  value={this.state.mobile}
                  onChange={(e) => {
                    this.setState({ mobile: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="fax">
                  Fax
                </label>
                <input
                  disabled={disabled}
                  id="custodian-fax"
                  className="account-input"
                  placeholder=""
                  value={this.state.fax}
                  onChange={(e) => {
                    this.setState({ fax: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="email">
                  Email
                </label>
                <input
                  disabled={disabled}
                  id="custodian-email"
                  className={showRequired ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                />
              </div>

              <div className="subheader span-all-col">Address</div>
              <div className="account-info-item">
                <label className="label" for="address">
                  Street Address
                </label>
                <input
                  disabled={disabled}
                  id="custodian-address"
                  className={showRequired ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.address}
                  onChange={(e) => {
                    this.setState({ address: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="state">
                  State
                </label>
                <Select
                  isDisabled={disabled}
                  className={showRequired ? 'account-select req ' + disabledClass : 'account-select ' + disabledClass}
                  id="custodian-state"
                  value={this.state.state}
                  onChange={(e) => {
                    let val = e ? e.value : null;
                    this.setState({ state: e });
                  }}
                  options={this.state.states}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="province">
                  Province
                </label>
                <input
                  disabled={disabled}
                  id="custodian-province"
                  className="account-input"
                  placeholder=""
                  value={this.state.province}
                  onChange={(e) => {
                    this.setState({ province: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="suite">
                  Suite
                </label>
                <input
                  disabled={disabled}
                  id="custodian-suite"
                  className="account-input"
                  placeholder=""
                  value={this.state.suite}
                  onChange={(e) => {
                    this.setState({ suite: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="postal">
                  Postal
                </label>
                <input
                  disabled={disabled}
                  id="custodian-postal"
                  className="account-input"
                  placeholder=""
                  value={this.state.postal}
                  onChange={(e) => {
                    this.setState({ postal: e.target.value });
                  }}
                />
              </div>

              <div className="account-info-item">
                <label className="label" for="country">
                  Country
                </label>
                <Select
                  className={showRequired ? 'account-select req ' + disabledClass : 'account-select ' + disabledClass}
                  isDisabled={disabled}
                  id="custodian-country"
                  value={this.state.country}
                  onChange={(e) => {
                    let val = e ? e.value : null;
                    this.setState({ country: e });
                  }}
                  options={this.state.countries}
                />
              </div>
            </div>
          )}
          {/* </div> */}

          <div className="subheader span-all-col">
            {custType} Contacts
            <div
              className="generic-button-primary no-margin"
              id="custodian-add-contact-button"
              disabled={disabled}
              onClick={() => {
                this.editContactClick(null, 'add');
              }}
            >
              Add
            </div>
          </div>
          <div className="modal-subheader"></div>
          <div className="table-container">
            <ReactTable
              data={this.state.contactData}
              columns={this.state.contactColumns}
              page={this.state.contactPage || 0}
              pages={this.handlePages(Math.ceil(this.state.contactRowCount / this.state.contactPagesize))}
              pageSize={this.state.contactPagesize || 5}
              className="-striped -highlight grid"
              onSortedChange={this.onContactSortedChange} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              // sorted={this.state.sorted || null}
              // filterable={true}
              // filtered={this.state.filtered}
              onPageSizeChange={(e) => {
                this.onPageSizeChange(e, 'contact');
              }}
              onPageChange={(e) => {
                this.onPageChange(e, 'contact');
              }}
              loading={this.state.contactLoading}
              manual={true}
              // getTrProps={this.getTrProps}
            />
          </div>
        </div>
        <Modal isOpen={this.state.contactModalOpen} style={styleOverrides}>
          <Draggable handle=".handle">
            <div className="">
              <div>
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">{this.state.contactModalType === 'add' ? `Add ${custType} Contact` : `Edit ${custType} Contact`}</div>
                  </div>

                  <div
                    className="sidemodal_addnew_x"
                    onClick={() => {
                      this.setState({ contactModalOpen: false });
                    }}
                  >
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <div className="search-modal-container">
                  <Contact
                    user={this.props.user}
                    services={this.props.services}
                    contactFor={'custodian'}
                    type={this.state.contactModalType}
                    contactModalLabel={`${custType} Contact`}
                    helpInformation={this.state.helpInformation}
                    closeViewModal={() => {
                      this.setState({ contactModalOpen: false });
                    }}
                    closeDetailModal={() => {
                      this.setState({ contactModalOpen: false });
                    }}
                    contactDetailId={this.state.contactDetailId}
                    custodianId={this.props.custodianId}
                    updateAccountData={this.props.updateAccountData}
                    setCurrentScreen={this.props.setCurrentScreen}
                    standalone={this.state.standalone}
                    signerIndex={this.props.signerIndex}
                    setValidSigners={this.props.setValidSigners}
                    validSigners={this.props.validSigners}
                    accountData={this.props.accountData}
                    refreshGrid={this.refreshGrid}
                    setForceUpdate={this.props.setForceUpdate}
                    isModal={true}
                    crmData={this.props.crmData}
                  />
                </div>
                {/* {this.renderAccountDetails()} */}
              </div>
            </div>
          </Draggable>
        </Modal>

        <Modal isOpen={this.state.templateModalOpen} style={styleOverrides}>
          <Draggable handle=".handle">
            <div>
              <div className="fullmodal handle">
                <div className="fullmodal_title">
                  <div className="fullmodal_title_add">{this.state.templateModalType === 'add' ? `Add Template` : `Edit Template`}</div>
                </div>

                <div
                  className="sidemodal_addnew_x"
                  onClick={() => {
                    this.setState({ templateModalOpen: false });
                  }}
                >
                  <FontAwesome name="xbutton" className="fa-times" />
                </div>
              </div>
              <div className="search-modal-container">
                <LOATemplate
                  user={this.props.user}
                  services={this.props.services}
                  custodianId={this.state.custodianId}
                  templateId={this.state.templateId}
                  type={this.state.templateModalType}
                  refreshGrid={this.refreshGrid}
                  closeModal={this.closeModal}
                />
              </div>
            </div>
          </Draggable>
        </Modal>
      </div>
    );
  }
}
export default withRouter(Custodian);
