import * as globals from '../../Globals/Variables';
import * as dataService from '../Utils/DataService';

export async function getInstitution({ user, services, id }) {
  let fetchProperties = {
    BaseUrl: `${services.MasterDB.URL}/ENTITY?$filter=Entity_UID eq ${id}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.MasterDB.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let res = await dataService.FetchData(fetchProperties);
  if (!res.error) {
    if (res.body) {
      if (res.body.value[0]) {
        return res.body.value[0].Institution_UID;
      }
    }
  }
  return 'null';
}
export async function getCounterpartyData({ user, services, id }) {
  let fetchProperties = {
    BaseUrl: `${services.MasterReporting.URL}/Counterparty?$count=true&$filter=Counterparty_UID eq ${id}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.MasterReporting.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let res = await dataService.FetchData(fetchProperties);
  if (!res.error) {
    if (res.body) {
      var count = res.body['@odata.count'];
      return { error: false, count: count, values: res.body.value[0] };
    }
  }
  return { error: true, count: 0, data: {} };
}

export async function getLOATemplate({ user, services, id }) {
  let fetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_TEMPLATE?$count=true&$filter=CustodianID eq ${id}&$select=TemplateID`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let res = await dataService.FetchData(fetchProperties);
  if (!res.error) {
    if (res.body) {
      var count = res.body['@odata.count'];
      if (count > 0) {
        return true;
      }
    }
  }
  return false;
}
