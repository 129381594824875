import * as globals from '../../Globals/Variables';
import * as dataService from '../Utils/DataService';

export async function getCustodianById({ user, services, custodianId }) {
  let custodianFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CUSTODIAN?id=${custodianId}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(custodianFetchProperties);
  if (!response.error && response.body) {
    return response.body;
  } else {
    return {};
  }
}

export async function getCustodianContacts({ user, services, custodianId, firmId }) {
  let custodianContactFetchProperties = {
    BaseUrl: `${this.props.services.Enrollment.URL}/CUSTODIAN_CONTACT_LINK?$filter=CustodianID eq ${custodianId}&$expand=CONTACT($expand=CONTACT_DETAIL)`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': this.props.services.Enrollment.subscription_key,
      DDHFirm: this.props.user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(custodianContactFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return {};
  }
}

export async function createCustodianContactLink({ user, services, custodianId, contactId }) {
  let body = {
    CustodianID: custodianId,
    ContactID: contactId,
  };
  let custodianContactFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/CUSTODIAN_CONTACT_LINK`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(custodianContactFetchProperties);
}

export async function createCustodian({ user, services, body }) {
  let custodianFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/CUSTODIAN`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  return await dataService.FetchData(custodianFetchProperties);
}

export async function createContact({ user, services, body }) {
  let contactFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/CONTACT`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(contactFetchProperties);
}

export async function createContactDetail({ user, services, body }) {
  let detailFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/CONTACT_DETAIL`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(detailFetchProperties);
}

export async function createFund({ user, services, body }) {
  let fundFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/FUND`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(fundFetchProperties);
}

export async function patchCustodian({ user, services, body, custodianId }) {
  let custodianFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/CUSTODIAN?id=${custodianId}`,
    Method: 'PATCH',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(custodianFetchProperties);
}
