import * as globals from '../../Globals/Variables';
import * as dataService from '../Utils/DataService';

export async function getRecordDetail({ user, services, modalID, endPoint }) {
  let recordFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/${endPoint}?$filter=(IdNmb eq ${modalID})`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(recordFetchProperties);
}

export async function createRecord({ user, services, endPoint, body }) {
  let recordFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/${endPoint}`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    CallBody: body,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(recordFetchProperties);
}
export async function updateRecord({ user, services, modalID, endPoint, body }) {
  let recordFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/${endPoint}?id=${modalID}`,
    Method: 'PATCH',
    SuccessMessage: 'Successfully Saved',
    FailureMessage: 'Save Failed',
    SuppressMessageOverride: false,
    CallBody: body,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(recordFetchProperties);
}

export async function getSuperhouse({ user, services, id }) {
  let contactDetailFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT_DETAIL?id=${id}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(contactDetailFetchProperties);
}

export async function createContact({ user, services, body }) {
  let contactFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(contactFetchProperties);
}

export async function createContactDetail({ user, services, body }) {
  let contactFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT_DETAIL`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(contactFetchProperties);
}

export async function getContactDetail({ user, services, contactDetailId }) {
  let contactDetailFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT_DETAIL?id=${contactDetailId}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(contactDetailFetchProperties);
}

export async function getContactsByContactTypeId({ user, services, contactTypeId }) {
  let contactFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT?$filter=(ContactTypeID eq ${contactTypeId})`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(contactFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function patchContact({ user, services, body, contactId }) {
  let contactFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT?id=${contactId}`,
    Method: 'PATCH',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(contactFetchProperties);
}

export async function getContact({ user, services, contactId }) {
  let contactFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT?id=${contactId}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(contactFetchProperties);
}

export async function patchContactDetail({ user, services, body, contactDetailId }) {
  let contactFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT_DETAIL?id=${contactDetailId}`,
    Method: 'PATCH',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(contactFetchProperties);
}
