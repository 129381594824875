import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getCounterpartyData, getLOATemplate } from './CounterpartyIntelligenceModels';

const FontAwesome = require('react-fontawesome');

function getINITIAL_STATE() {
  return {
    counterparty: {},
    counterpartyLoading: true,
    notFound: false,
  };
}

class CounterpartyIntelligence extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this, props);
  }

  async componentDidMount() {
    let { user, services, accountData } = this.props;
    let id = accountData.CustodianID;
    let data = await getCounterpartyData({ user, services, id });
    let addendums = await getLOATemplate({ user, services, id: accountData.CustodianID });
    let counterparty = {};
    let notFound = true;

    if (data && data.count > 0) {
      counterparty['data'] = data.values;
      counterparty['stars'] = this.getStars(data.values);
      counterparty['status'] = this.getCounterpartyStatus(data.values);
      counterparty['addendums'] = addendums; // true | false

      if (accountData && accountData.CustodianName) {
        counterparty['name'] = accountData.CustodianName;
      }

      notFound = false;
    }

    this.setState({ counterparty, notFound, counterpartyLoading: false });
  }

  getStars(data) {
    // New Counterparty (0 Active Accts)
    // Enrollment Complexity: New Counterparty
    // Total Active Accts < 20
    // Enrollment Complexity: In Discovery
    // Total Active Accts >= 20 + LOA_Est_Days > 30
    // Enrollment Complexity: High
    // Total Active Accts >= 20 + LOA_Est_Days = 30
    // Enrollment Complexity: Medium
    // Total Active Accts >= 20 + LOA_Est_Days < 30
    // Enrollment Complexity: Low

    let obj = {};
    obj['complexity'] = '';
    obj['starClass'] = 'stars-gray';
    if (data) {
      switch (data.Rating) {
        case 'New Counterparty':
          obj['complexity'] = data.Rating;
          obj['starClass'] = 'stars-gray';
          break;
        case 'In Discovery':
          obj['complexity'] = data.Rating;
          obj['starClass'] = 'stars-gray';
          break;
        case 'High':
          obj['complexity'] = data.Rating;
          obj['starClass'] = 'stars-red';
          break;
        case 'Medium':
          obj['complexity'] = data.Rating;
          obj['starClass'] = 'stars-yellow';
          break;
        case 'Low':
          obj['complexity'] = data.Rating;
          obj['starClass'] = 'stars-green';
          break;
        default:
          obj['complexity'] = data.Rating;
          obj['starClass'] = 'stars-gray';
          break;
      }
    }

    return obj;
  }

  getCounterpartyStatus(data) {
    // If Counterparty_Status = Approved OR # of Active Accounts > 0, “Active Counterparty” (Hex=#228B22)
    // Else, if Counterparty_Status = “Inactive” AND # of Active Accounts = 0, “Inactive Counterparty” (Hex=#D3D3D3)
    // Else, if Counterparty_Status = “Hold” or “New” AND # of Active Accounts = 0, “New Counterparty” (Hex=#D3D3D3)
    if (data.Counterparty_Status === 'Approved' || data.Active > 0) {
      return 'Active Counterparty';
    }

    if (data.Counterparty_Status === 'Inactive' && data.Active === 0) {
      return 'Inactive Counterparty';
    }

    if ((data.Counterparty_Status === 'Hold' || data.Counterparty_Status === 'New') && data.Active === 0) {
      return 'New Counterparty';
    }

    return 'New Counterparty';
  }

  getStarsIcons() {
    const { stars } = this.state.counterparty;
    if (stars) {
      if (stars.complexity) {
        switch (stars.complexity) {
          case 'New Counterparty':
            // Gray open stars
            return (
              <td className={`stars ${stars.starClass}`}>
                <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-1" name="newcounterparty-star-1" key="newcounterparty-star-1" />
                <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-2" name="newcounterparty-star-2" key="newcounterparty-star-2" />
                <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-3" name="newcounterparty-star-3" key="newcounterparty-star-3" />
              </td>
            );

          case 'In Discovery':
            // Gray filled stars
            return (
              <td className={`stars ${stars.starClass}`}>
                <FontAwesome className="fa fas fa-star" id="newcounterparty-star-1" name="newcounterparty-star-1" key="newcounterparty-star-1" />
                <FontAwesome className="fa fas fa-star" id="newcounterparty-star-2" name="newcounterparty-star-2" key="newcounterparty-star-2" />
                <FontAwesome className="fa fas fa-star" id="newcounterparty-star-3" name="newcounterparty-star-3" key="newcounterparty-star-3" />
              </td>
            );
          case 'High':
            // Red stars
            return (
              <td className={`stars ${stars.starClass}`}>
                <FontAwesome className="fa fas fa-star" id="newcounterparty-star-1" name="newcounterparty-star-1" key="newcounterparty-star-1" />
                <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-2" name="newcounterparty-star-2" key="newcounterparty-star-2" />
                <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-3" name="newcounterparty-star-3" key="newcounterparty-star-3" />
              </td>
            );
          case 'Medium':
            // Yellow stars
            return (
              <td className={`stars ${stars.starClass}`}>
                <FontAwesome className="fa fas fa-star" id="newcounterparty-star-1" name="newcounterparty-star-1" key="newcounterparty-star-1" />
                <FontAwesome className="fa fas fa-star" id="newcounterparty-star-2" name="newcounterparty-star-2" key="newcounterparty-star-2" />
                <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-3" name="newcounterparty-star-3" key="newcounterparty-star-3" />
              </td>
            );
          case 'Low':
            // Green Stars
            return (
              <td className={`stars ${stars.starClass}`}>
                <FontAwesome className="fa fas fa-star" id="newcounterparty-star-1" name="newcounterparty-star-1" key="newcounterparty-star-1" />
                <FontAwesome className="fa fas fa-star" id="newcounterparty-star-2" name="newcounterparty-star-2" key="newcounterparty-star-2" />
                <FontAwesome className="fa fas fa-star" id="newcounterparty-star-3" name="newcounterparty-star-3" key="newcounterparty-star-3" />
              </td>
            );
          default:
            // All open Gray stars
            return (
              <td className={`stars ${stars.starClass}`}>
                <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-1" name="newcounterparty-star-1" key="newcounterparty-star-1" />
                <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-2" name="newcounterparty-star-2" key="newcounterparty-star-2" />
                <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-3" name="newcounterparty-star-3" key="newcounterparty-star-3" />
              </td>
            );
        }
      }
    } else {
      // Place holder until data loaded
      return (
        <td className={`stars stars-gray`}>
          <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-1" name="newcounterparty-star-1" key="newcounterparty-star-1" />
          <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-2" name="newcounterparty-star-2" key="newcounterparty-star-2" />
          <FontAwesome className="fa fas fa-star-o" id="newcounterparty-star-3" name="newcounterparty-star-3" key="newcounterparty-star-3" />
        </td>
      );
    }
  }

  getCounterpartyStatusText() {
    const { status } = this.state.counterparty;

    if (status) {
      switch (status) {
        case 'Active Counterparty':
          // Green
          return <td className="counterparty-status cstatus-active">{status}</td>;
        case 'Inactive Counterparty':
          // Gray
          return <td className="counterparty-status cstatus-inactive">{status}</td>;
        case 'New Counterparty':
          // Gray
          return <td className="counterparty-status cstatus-new">{status}</td>;
        default:
          // Gray
          return <td className="counterparty-status cstatus-new">{status}</td>;
      }
    } else {
      return <td className="counterparty-status cstatus-new"> </td>;
    }
  }

  getActiveAccounts() {
    const { data } = this.state.counterparty;

    if (data) {
      switch (true) {
        case data.Active < 5:
          return 'New Counterparty';
        case data.Active >= 5 && data.Active <= 49:
          return '5+ Accounts';
        case data.Active >= 50 && data.Active <= 99:
          return '50+ Accounts';
        case data.Active >= 100 && data.Active <= 499:
          return '100+ Accounts';
        case data.Active >= 500 && data.Active <= 999:
          return '500+ Accounts';
        case data.Active >= 1000:
          return '1000+ Accounts';
        default:
          return 'New Counterparty';
      }
    }
  }

  getMethod() {
    const { data } = this.state.counterparty;

    if (data && data['Auth_MethodID']) {
      switch (data['Auth_MethodID']) {
        case 'DocuSign':
          return 'Accepts Digital Signature';
        case 'Print':
          return 'Print LOA Only';
        default:
          return 'Print LOA Only';
      }
    }
  }

  getEstimatedDays() {
    const { counterparty } = this.state;
    return counterparty && counterparty['data'] && counterparty.data.Days_Pending !== null && <td className="align-right">Estimated Days to Activation: {counterparty.data.Days_Pending}</td>;
  }

  getField = (name) => {
    const { counterparty } = this.state;

    if (counterparty && counterparty[name]) {
      return counterparty[name];
    }

    return '';
  };

  render() {
    const { counterparty, notFound, counterpartyLoading } = this.state;

    return (
      !counterpartyLoading &&
      !notFound && (
        <div className="account-learn-more">
          <div className="tooltip">
            Learn more about this counterparty
            <span className="tooltiptext">
              <span>
                <table>
                  <tr>
                    {this.getStarsIcons()}
                    <td className="complexity">Enrollment Complexity: {counterparty && counterparty['stars'] && counterparty.stars.complexity}</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td className="title">{this.getField('name')}</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    {this.getCounterpartyStatusText()}
                    <td className="align-right"></td>
                  </tr>
                  <tr>
                    <td>
                      {counterparty && counterparty['data'] && counterparty.data.Counterparty_Type
                        ? counterparty.data.Counterparty_Type === 'FI'
                          ? 'Financial Institution'
                          : 'Asset Manager'
                        : 'Type Unknown'}
                    </td>
                    <td className="align-right">{counterparty && counterparty['addendums'] ? 'Has Special Addendums' : 'No Special Addendums'}</td>
                  </tr>
                  <tr>
                    <td>{this.getActiveAccounts()}</td>
                    {counterparty && counterparty['data'] && counterparty.data.Enroll_MaxAccounts ? (
                      <td className="align-right">Maximum {counterparty.data.Enroll_MaxAccounts} Accounts per LOA</td>
                    ) : (
                      this.getEstimatedDays()
                    )}
                  </tr>
                  <tr>
                    <td> {this.getMethod()}</td>
                    {counterparty && counterparty['data'] && counterparty.data.Enroll_MaxAccounts && this.getEstimatedDays()}
                  </tr>
                </table>
              </span>
            </span>
          </div>
        </div>
      )
    );
  }
}

export default withRouter(CounterpartyIntelligence);
