export const account_templateColumns = ['account_number', 'account_name'];

export function account_columnsMap() {
  let templateColumnsMap = [];
  templateColumnsMap['account_number'] = 'AccountNumber';
  templateColumnsMap['account_name'] = 'AccountName';
  return templateColumnsMap;
}

export function account_systemFields() {
  return ['Account_Name', 'Account'];
}
export function account_dataTypes() {
  // decimal, integer, string (default)
  return [];
}

export const account_alt_templateColumns = ['fund_uid', 'fund_name', 'investor_name'];
export function account_alt_columnsMap() {
  let templateColumnsMap = [];
  templateColumnsMap['fund_uid'] = 'Fund_UID';
  templateColumnsMap['fund_name'] = 'FundName';
  templateColumnsMap['investor_name'] = 'InvestorName';
  return templateColumnsMap;
}
