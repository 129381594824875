import * as globals from '../../Globals/Variables';
import * as dataService from '../Utils/DataService';

export async function getActiveLoas({ user, services, filter, body, tokenFilter = true, page = null, pagesize = null, orderby }) {
  let BaseUrl = `${services.Enrollment.URL}/ACTIVE_LOAS?$count=true${filter}${orderby}`;
  if (page !== null && pagesize !== null) {
    BaseUrl = `${services.Enrollment.URL}/ACTIVE_LOAS?$count=true${filter}&$top=${pagesize}&$skip=${page * pagesize}${orderby}`;
  }

  let fetchProperties = {
    BaseUrl,
    Method: 'GET',
    CallBody: body,
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'x-universal-directive': tokenFilter === false ? 'D' : 'S',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(fetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}

export async function getPendingLoas({ user, services, filter, body, tokenFilter = true, page = null, pagesize = null, orderby }) {
  let BaseUrl = `${services.Enrollment.URL}/PENDING_LOAS?$count=true${filter}${orderby}`;
  if (page !== null && pagesize !== null) {
    BaseUrl = `${services.Enrollment.URL}/PENDING_LOAS?$count=true&$top=${pagesize}&$skip=${page * pagesize}${filter}${orderby}`;
  }
  let fetchProperties = {
    BaseUrl,
    Method: 'GET',
    CallBody: body,
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'x-universal-directive': tokenFilter === false ? 'D' : 'S',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(fetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}

export async function cancelLoa({ user, services, loaIds }) {
  let body = { loaId: loaIds };
  let cancelLoaFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/Enrollment/CancelLOA`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      'Content-Type': 'application/json',
    },
    CallBody: body,
  };

  return await dataService.FetchData(cancelLoaFetchProperties);
}

export async function cancelLoas({ user, services, loaIds }) {
  let body = { loaIds: loaIds };

  let cancelLoasFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/Enrollment/CancelLOAs`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      'Content-Type': 'application/json',
    },
    CallBody: body,
  };
  return await dataService.FetchData(cancelLoasFetchProperties);
}

export async function generateLoas({ user, services, accountIds, mode, showMessage = 'ALWAYS', successMessage = 'Request Successful', failMessage = 'Request Failed' }) {
  let body = { accounts: accountIds };
  let generateLoasFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/Enrollment/GenerateLOAs?approvedOnly=true&mode=${mode}`,
    Method: 'POST',
    SuccessMessage: successMessage,
    FailureMessage: failMessage,
    SuppressMessageOverride: false,
    ShowMessage: globals[showMessage],
    HeaderVals: {
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      'Content-Type': 'application/json',
    },
    CallBody: body,
  };

  return await dataService.FetchData(generateLoasFetchProperties);
}

export async function getLoaDocument({ user, services, loaIds }) {
  let filter = '';
  for (let i in loaIds) {
    let loaId = loaIds[i];
    filter = filter === '' ? `LoaID eq ${loaId}` : `${filter} or LoaID eq ${loaId}`;
  }

  let loaDocumentFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_DOCUMENT?$filter=(${filter})&$expand=LOA`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      'Content-Type': 'application/json',
    },
  };
  return await dataService.FetchData(loaDocumentFetchProperties);
}
export async function getStatusOptions({ user, services, currentStatus }) {
  let BaseUrl = `${services.Enrollment.URL}/ACTIVE_LOAS?$apply=groupby((Status))`;

  let fetchProperties = {
    BaseUrl,
    Method: 'GET',

    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(fetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}
