import React from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router';
import Modal from 'react-modal';
import Spinner from 'react-spinkit';
import { showRequired, hasRequired, getRequiredAsterisk } from '../Utils/RequiredFields';
import str2ab from 'string-to-arraybuffer';
import * as LOAModels from './LOAModels';
import * as DropdownModels from './DropdownModels';

const getINITIAL_STATE = (that) => {
  return {
    loading: false,
  };
};
let AllRequiredFields = ['FileName', 'FileData', 'Type'];
let spinnerModalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1111',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    top: '25%',
    left: '12%',
    height: '75%',
    width: '75%',
    border: 'none',
    background: 'transparent',
  },
};

class LOATemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
  }

  componentDidMount() {
    this.setState({ type: this.props.type });
    if (this.props.type === 'view' || this.props.type === 'edit') {
      this.getTemplate(this.props.templateId);
      this.setState({
        custodianId: this.props.custodianId,
        templateId: this.props.templateId,
      });
    } else if (this.props.match.params.custodianId && this.props.match.params.templateId) {
      this.setState({
        type: 'edit',
        custodianId: this.props.custodianId,
        templateId: this.props.templateId,
      });
      this.getTemplate(this.props.match.params.templateId);
    } else {
      this.getTemplateTypes();
      this.getTemplateVisibilities();
      this.setState({
        type: 'add',
        custodianId: this.props.custodianId,
        templateId: this.props.templateId,
      });
    }
  }

  async getTemplate(templateId) {
    let { user, services } = this.props;
    this.setState({ loading: true });
    let response = await LOAModels.getTemplateById({
      user,
      services,
      templateId,
    });
    if (!response.error) {
      let body = response.body ? response.body : null;
      if (body) {
        let state = Object.assign({}, this.state);
        state.FileName = body.FileName;
        state.FileData = body.FileData;
        state.CustodianID = body.CustodianID;
        state.Type = { label: body.Type, value: body.Type };
        state.Visibility = { value: body.ContactTypeID };
        state.Comment = body.Comment;
        if (body.FileData) {
          this.handleFileChange(body.FileData);
        }
        this.setState(state, () => {
          this.getTemplateTypes();
          this.getTemplateVisibilities();
          this.setState({ loading: false });
        });
      }
    }
  }

  async getTemplateTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getTemplateTypes({
      user,
      services,
    });
    let temp = [];
    if (types) {
      temp = types.map((type) => {
        if (this.state.Type && this.state.Type.value === type.Type) {
          this.setState({ Type: { label: type.Type, value: type.Type } });
        }
        return {
          label: type.Type,
          value: type.Type,
        };
      });
    }
    this.setState({ templateTypes: temp });
  }

  async getTemplateVisibilities() {
    let { user, services } = this.props;
    let vis = await DropdownModels.getVisibilityOptions({
      user,
      services,
    });

    let temp = [];

    if (vis) {
      temp = vis.map((item) => {
        if (this.state.Visibility && this.state.Visibility.value === item.ContactTypeID) {
          this.setState({
            Visibility: {
              label: item.ContactType === 'Individual' ? 'Account Signer(s)' : item.ContactType === 'Company' ? 'Manager' : item.ContactType,
              value: item.ContactTypeID,
            },
          });
        }
        return {
          label: item.ContactType === 'Individual' ? 'Account Signer(s)' : item.ContactType === 'Company' ? 'Manager' : item.ContactType,
          value: item.ContactTypeID,
        };
      });
    }

    this.setState({ templateVisibilities: temp }, () => {
      if (!this.state.Visibility) {
        this.setState({ Visibility: temp[0] });
      }
    });
  }

  async onSaveTemplateClick() {
    if (hasRequired(this.state, AllRequiredFields)) {
      this.setState({ loading: true });
      if (this.props.type === 'add') {
        await this.createTemplate();
      } else {
        await this.patchTemplate();
      }
    } else {
      this.setState({ checkRequired: true });
    }
    this.setState({ loading: false });
  }

  async createTemplate() {
    let { user, services } = this.props;
    let { custodianId, FileData, FileName, Type, templateId, templateModalType, Visibility } = this.state;
    let templateBody = {
      FirmID: this.props.user.FirmId,
      FileData,
      FileName,
      ContactTypeID: Visibility ? Visibility.value : null,
      CustodianID: custodianId,
      Type: Type ? Type.value : null,
      CreateDate: new Date().toISOString().slice(0, 19).concat('Z'),
      ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
      ChangeType: 'I',
    };
    let response = await LOAModels.createLoaTemplate({
      user,
      services,
      body: templateBody,
    });
    if (!response.error) {
      if (this.props.refreshGrid) {
        this.props.refreshGrid();
      }
      if (this.props.closeModal) {
        this.props.closeModal();
      }
    }
    return;
  }

  async patchTemplate() {
    let { user, services } = this.props;
    let { custodianId, FileData, FileName, Type, templateId, templateModalType, Visibility } = this.state;
    let templateBody = {
      FirmID: user.FirmId,
      FileData,
      FileName,
      ContactTypeID: Visibility ? Visibility.value : null,
      Type: Type ? Type.value : null,
      ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
      ChangeType: 'U',
    };

    let response = await LOAModels.patchLoaTemplate({
      user,
      services,
      body: templateBody,
      templateId,
    });
    if (!response.error) {
      if (this.props.refreshGrid) {
        this.props.refreshGrid();
      }
      if (this.props.closeModal) {
        this.props.closeModal();
      }
    }
    return response;
  }

  handleFileChange = (data, FileName) => {
    var base = atob(data);
    var input = str2ab(base);
    var blob = new Blob([input], { type: 'application/pdf' });
    if (blob) {
      var ie = navigator.userAgent.match(/.NET/g);
      var edge = navigator.userAgent.match(/Edge/g);
      var fileURL = window.URL.createObjectURL(blob);
    }
    this.setState({
      FileData: data,
      FileDataPreview: fileURL,
      FileName,
    });
  };

  handleCancel = () => {
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };

  handleNumberBlur = (state, e) => {
    if (e.target.value === '' || e.target.value.indexOf('e') != -1 || e.target.value.indexOf('E') != -1) {
      this.setState({ [state]: null });
    }
  };

  handleNumberChange = (state, e) => {
    if (e) {
      this.setState({ [state]: e.target.value.replace(/[-e]/g, '') });
    }
  };

  render() {
    let { checkRequired } = this.state;
    var ie = navigator.userAgent.match(/.NET/g);
    var edge = navigator.userAgent.match(/Edge/g);
    return (
      <div>
        <div className="header-accounts">
          <div>{/* Intentionally blank */}</div>
          <div className="button-div">
            <div className="generic-button-secondary" id="custodian-cancel-button" onClick={this.handleCancel}>
              Cancel
            </div>
            <div
              className="generic-button-primary "
              id="custodian-save-button"
              onClick={() => {
                this.onSaveTemplateClick();
              }}
            >
              Save
            </div>
          </div>
        </div>
        {/* Legend for required fields  */}

        <div className="required-field-message-wrapper">
          <div className="required-field-message required-field-message-contact" id="required-field-message">
            {getRequiredAsterisk(true, '', '', '')} Indicates required field
          </div>
        </div>

        {this.state.loading ? (
          <Modal isOpen={this.state.loading} style={spinnerModalStyle}>
            <center>
              <Spinner id="view-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
            </center>
          </Modal>
        ) : (
          <div className="add-template-info">
            <div className="account-info-item ga-1-1">
              <label className="label" for="status">
                {' '}
                Name:
              </label>
              <input disabled={true} id="template-name" className={'account-input'} placeholder="Upload a file..." value={this.state.FileName} />
            </div>

            <div className="account-info-item ga-2-1">
              <label className="label" for="verified">
                {getRequiredAsterisk(true, '', '', '')}Type:
              </label>
              <Select
                isDisabled={false}
                className={showRequired(checkRequired, this.state.Type, 'value') ? 'account-select req' : 'account-select'}
                id="template-type"
                value={this.state.Type}
                onChange={(e) => {
                  let val = e ? e.value : null;
                  this.setState({ Type: e });
                }}
                options={this.state.templateTypes}
              />
            </div>

            <div className="account-info-item ga-1-2">
              <label className="label" for="status">
                {getRequiredAsterisk(true, '', '', '')}
                {this.props.type === 'edit' ? 'Choose New File' : 'File'}
              </label>
              {/* {this.props.type !== 'edit' || (this.props.type === 'edit' && this.state.FileName) && */}
              <input
                disabled={this.state.templateModalLoading}
                id={`template-file`}
                className={showRequired(checkRequired, this.state, 'FileData') ? 'account-input req template-file-input' : 'account-input template-file-input'}
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  if (e.target.files.length !== 0) {
                    let files = e.target.files || [];
                    let blob = files[0];
                    let FileName = blob.name;
                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                      let base64data = reader.result;
                      base64data = base64data.replace(`data:image/png;base64,`, '');
                      base64data = base64data.replace(`data:application/pdf;base64,`, '');
                      this.handleFileChange(base64data, FileName);
                    };
                  }
                }}
              />
            </div>

            <div className="account-info-item ga-2-2">
              <label className="label" for="status">
                {' '}
                Visibility{' '}
              </label>
              <Select
                isDisabled={false}
                className={'account-select select-up'}
                id="template-visibility"
                value={this.state.Visibility}
                onChange={(e) => {
                  let val = e ? e.value : null;
                  this.setState({ Visibility: e });
                }}
                options={this.state.templateVisibilities}
              />
            </div>
          </div>
        )}
        {this.state.FileDataPreview && !ie && !edge && (
          <div className="account-info-item template-preview">
            {this.state.FileDataPreview && !ie && !edge ? (
              <object className="template-object" data={this.state.FileDataPreview} type="application/pdf">
                <embed src={this.state.FileDataPreview} type="application/pdf" />
              </object>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(LOATemplate);
