import React, { Component } from 'react';
import { withRouter } from 'react-router';

// Components

import { downloadCSV, toTitleCase } from '../Utils/HelperFunctions';
import { account_templateColumns, account_columnsMap, account_alt_templateColumns, account_alt_columnsMap } from './UploadTemplate';

// 3rd party
import Draggable from 'react-draggable';
import CSVReader from 'react-csv-reader';

// Material UI Imports
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import ButtonModal from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Processing from '@material-ui/icons/Autorenew';
import Check from '@material-ui/icons/Check';
import Refresh from '@material-ui/icons/Replay';

import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import FontAwesome from 'react-fontawesome';

// MUI
const theme = createTheme({
  palette: {
    primary: { main: '#808080' }, // gray
    secondary: { main: '#DCDCDC' }, // lightgray
  },
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    // htmlFontSize: 9,
  },
});

// Parse
const { Parser } = require('json2csv');

const paperPropsNewSmall = { style: { width: '30%', maxWidth: '30%', scroll: 'body', minHeight: '30vh' } };
const paperPropsLarge = { style: { width: '90%', maxWidth: '90%', scroll: 'body', minHeight: '95vh' } };

function PaperComponent(props) {
  return (
    <Draggable bounds="parent" handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

let templateColumnsMapRev = [];
var templateColumnsDownload = [];

export default class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonSuccess: '',
      done: false,
      processing: false,
      review: props.id ? true : false,
      batchId: props.id ? props.id : '',
      account: props.account ? props.account : '',
      searchField: '',
      searchOpen: false,
      searchPowerGridOpen: false,
      searchEntityOpen: false,
      searchPowerGridDefaultFilter: '',
      overrideEmailMask: false,
      overrideAccountMask: false,
      batchType: props.type && props.type !== 'null' ? props.type : 'Account',
      templateColumns: [],
      templateColumnsMap: [],
      rerender: new Date().getTime(),
      managerType: props.managerType,
      isAlt: props.managerType === 'ALTERNATIVE' ? true : false,
      selectedFunds: [],
    };
  }

  componentDidMount() {
    const { isAlt } = this.state;
    const templateColumns = isAlt ? account_alt_templateColumns : account_templateColumns;
    const templateColumnsMap = isAlt ? account_alt_columnsMap() : account_columnsMap();

    //Set up template based on validation columns
    let cols = {};
    for (let i in templateColumns) {
      cols[`${templateColumns[i]}`] = '';
    }
    Object.keys(templateColumnsMap).map((key, i) => {
      templateColumnsMapRev[templateColumnsMap[key]] = key;
      templateColumnsMapRev[templateColumnsMap[key].toLowerCase()] = key;
      return null;
    });
    templateColumnsDownload = [];
    templateColumnsDownload.push(cols);

    this.setState({ templateColumns, templateColumnsMap });
  }

  cancelUploadProcessing = () => {
    this.setState({
      processing: false,
    });
  };
  updateParent = (stateObj) => {
    this.setState({
      ...stateObj,
    });
  };

  async handleChangeFiles(data, fileInfo) {
    const json2csvParser = new Parser({ quote: '"' });
    if (!data || data.length === 0) {
      this.handleResponse('UPLOAD_FAILED');
    } else {
      const csv = json2csvParser.parse(data);
      this.handleValidateTemplate(data).then((valid) => {
        if (valid === true) {
          this.setState({ fileData: data, fileDataCSV: csv, fileName: fileInfo.name, buttonSuccess: 'mui-button-green', processing: true }, () => {
            this.props.handleProcessFile(this.state.fileData, this.state.overrideAccountMask);
            //Close window and populate account table
          });
        } else {
          let fail_message = `File is not a valid account batch file template.  Please download the template in the previous screen and review.`;
          this.setState(
            { buttonSuccess: 'mui-button-red', processing: false, status: 'Failed', statusMessage: 'File Not Processed', done: true, success_message: '', fail_message: fail_message },
            () => {}
          );
        }
      });
    }
  }

  async handleValidateTemplate(data) {
    let valid = true;
    let records = data[0];
    const { templateColumns } = this.state;

    for (let i in Object.keys(records)) {
      let column = Object.keys(records)[i].toLowerCase();

      if (!templateColumns.includes(column)) {
        valid = false;
      }
    }

    return valid;
  }

  handleStartOver = () => {
    if (this.state.done) {
      this.setState(
        {
          buttonSuccess: '',
          processing: false,
          status: '',
          statusMessage: '',
          done: false,
          success_message: '',
          fail_message: '',
        },
        () => {}
      );
    }
  };

  handleDownloadTemplate = () => {
    let { selectedFunds, isAlt, templateColumns } = this.state;
    if (isAlt) {
      templateColumnsDownload = [];
      for (let fund of selectedFunds) {
        let obj = {};
        Object.keys(templateColumns).map((col, i) => {
          switch (templateColumns[col]) {
            case 'fund_uid':
              obj[templateColumns[col]] = fund.value;
              break;
            case 'fund_name':
              obj[templateColumns[col]] = fund.label;
              break;
            default:
              obj[templateColumns[col]] = '';
          }
        });
        templateColumnsDownload.push(obj);
      }
    }

    downloadCSV({
      data: templateColumnsDownload,
      fileName: `Multiple_Account_Template_${this.state.managerType}` + new Date().getTime() + '.csv',
    });
  };

  getButtonMessage = () => {
    let message = this.state.done ? this.state.statusMessage : this.state.processing ? 'Processing' : this.state.buttonSuccess ? 'File Selected' : 'Select File';
    return message;
  };

  handleOptionCheckbox = (name) => {
    const fieldVal = this.state[name];
    this.setState({ [name]: !fieldVal, rerender: new Date().getTime() });
  };

  render() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
    };

    const { done, processing, buttonSuccess, fail_message, status, batchType, statementDate, account, optionsOpen, isAlt, selectedFunds } = this.state;
    const disabled = processing;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Dialog
            open={(!this.state.review && !this.props.id) || optionsOpen}
            PaperProps={paperPropsNewSmall}
            PaperComponent={PaperComponent}
            aria-labelledby="form-dialog-title"
            // style={{ zIndex: 99999999999999999 }}
          >
            <DialogTitle style={{ cursor: 'move' }} className="std-modal-header" id="draggable-dialog-title">
              <Grid container spacing={0} className="std-modal-container">
                <Grid item xs={9} className="std-modal-header-title">
                  {optionsOpen ? 'Options' : 'Upload'}
                </Grid>
                <Grid item xs={3} className="std-modal-header-x">
                  <div className="std-modal-x" id="close-modal" title="Close window" onClick={this.props.handleBatchClose}>
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </Grid>
              </Grid>
            </DialogTitle>
            <div className="powerform-tabs powerform-tabs-standard">
              <div className={`powerform-body publishing-form-add publishing-form-body`}>
                <div className="upload-wrapper">
                  <div className="upload-title">{optionsOpen ? 'Update Batch Options' : 'Upload Batch'}</div>

                  {(!done || optionsOpen) && (
                    <>
                      <Table className="upload-no-bottom">
                        <TableRow>
                          <TableCell>
                            Upload a populated version of the Batch Enrollment Template. The file will be verified and prepared for review. No accounts are created or transmitted during this
                            process.
                          </TableCell>
                        </TableRow>
                      </Table>

                      <div className="upload-sub-title">Options</div>

                      <div>
                        {batchType === 'Account' && !isAlt ? (
                          <TableContainer component={Paper} className="upload-details-fixed-table">
                            <Table className="upload-details-summary" size="small" stickyHeader aria-label="sticky dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell className="upload-details-select mui-table-header">
                                    <Checkbox
                                      id="checkbox-override-account-mask"
                                      onClick={() => {
                                        this.handleOptionCheckbox('overrideAccountMask');
                                      }}
                                      checked={this.state.overrideAccountMask}
                                    />
                                  </TableCell>
                                  <TableCell className="mui-table-header">Override Account Mask</TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                        ) : (
                          <>
                            Select fund(s):
                            <Autocomplete
                              key="funds"
                              id="funds"
                              name="funds"
                              multiple={true}
                              options={this.props.funds}
                              renderOption={(option) => <>{option.label}</>}
                              getOptionLabel={(option) => option.label}
                              disabled={false}
                              size="small"
                              onChange={(e, val) => {
                                this.setState({ selectedFunds: val });
                              }}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}

                  <div className="upload-file-wrapper">
                    {!done && !optionsOpen && (
                      <div className="upload-button">
                        <ButtonModal
                          color="primary"
                          className={`upload-startover mui-upload-button ${isAlt && selectedFunds.length === 0 && 'mui-button-gray'}`}
                          component="label"
                          variant="contained"
                          disabled={isAlt && selectedFunds.length === 0}
                          onClick={() => {
                            this.handleDownloadTemplate();
                          }}
                          startIcon={<CloudDownloadIcon />}
                        >
                          Template
                        </ButtonModal>
                        <ButtonModal
                          color="primary"
                          style={{ color: '#000000' }}
                          className={`mui-upload-button ${this.state.buttonSuccess} ${disabled && 'mui-button-gray'}`}
                          variant="contained"
                          component="label"
                          disabled={processing || disabled}
                          onClick={() => {
                            // eslint-disable-next-line no-unused-expressions
                            processing || disabled ? null : this.handleStartOver();
                          }}
                          startIcon={done ? '' : processing ? <Processing className="fa-spin" /> : buttonSuccess ? <Check /> : <CloudUploadIcon />}
                        >
                          {this.getButtonMessage()}
                          {disabled
                            ? null
                            : this.state.buttonSuccess === '' &&
                              !done && (
                                <CSVReader
                                  cssClass="csv-reader-input hide"
                                  label=""
                                  onFileLoaded={this.handleChangeFiles.bind(this)}
                                  parserOptions={papaparseOptions}
                                  inputId="csv"
                                  inputStyle={{ display: 'none' }}
                                />
                              )}
                        </ButtonModal>
                      </div>
                    )}
                    {!optionsOpen && status === 'Failed' && (
                      <div>
                        <div className="upload-text-failed-red">File Not Processed.</div>
                        <div className="upload-text-failed">{fail_message}</div>

                        <ButtonModal
                          color="primary"
                          className="mui-button-green mui-upload-button"
                          component="label"
                          variant="contained"
                          onClick={() => {
                            this.handleStartOver();
                          }}
                          startIcon={<Refresh />}
                        >
                          Start Over
                        </ButtonModal>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </MuiThemeProvider>
    );
  }
}
