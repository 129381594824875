import * as globals from '../../Globals/Variables';
import * as dataService from '../Utils/DataService';

export async function getTemplateById({ user, services, templateId }) {
  let templateFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_TEMPLATE?id=${templateId}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };
  return await dataService.FetchData(templateFetchProperties);
}

export async function createLoaTemplate({ user, services, body }) {
  let LoaTemplateFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_TEMPLATE`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(LoaTemplateFetchProperties);
}

export async function patchLoaTemplate({ user, services, body, templateId }) {
  let LoaTemplateFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_TEMPLATE?id=${templateId}`,
    Method: 'PATCH',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(LoaTemplateFetchProperties);
}

export async function getLoaById({ user, services, loaId }) {
  let loaFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/enrollment/ViewLOA/${loaId}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
    },
  };
  return await dataService.FetchData(loaFetchProperties);
}

export async function getLoaAccountLinks({ user, services, loaId, showData }) {
  let linkFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_ACCOUNT_LINK?$filter=(LoaID eq ${loaId})&$expand=ACCOUNT`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'x-universal-directive': showData ? 'D' : 'S',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };
  let response = await dataService.FetchData(linkFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getLoaStatusOptions({ user, services, currentStatus }) {
  let statusFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/LOA_STATUS_FLOW?$filter=(CurrentStatus eq '${currentStatus}')`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(statusFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function createLoa({ user, services, body }) {
  let LoaTemplateFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_DOCUMENT`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(LoaTemplateFetchProperties);
}

export async function patchLoa({ user, services, body, documentId }) {
  let LoaFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_DOCUMENT?id=${documentId}`,
    Method: 'Patch',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(LoaFetchProperties);
}

export async function updateLoaStatus({ user, services, fromStatus, toStatus, loaIds }) {
  let statusBody = {
    loaIds,
    fromStatus,
    toStatus,
  };
  let statusFetchProperties = {
    BaseUrl: '',
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    CallBody: statusBody,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  statusFetchProperties.BaseUrl = !globals.REMOTE_INSTANCE ? `${services.Enrollment.URL}/LOAGenerateAndPreview/UpdateLOAStatus` : `${services.Enrollment.URL}/enrollment/UpdateLOAStatus`;

  return await dataService.FetchData(statusFetchProperties);
}

export async function deleteTemplate({ user, services, templateId }) {
  let loaTemplateFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_TEMPLATE?id=(${templateId})`,
    Method: 'DELETE',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  return await dataService.FetchData(loaTemplateFetchProperties);
}

export async function updateVisibility({ user, services, body, templateId }) {
  let loaTemplateFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_TEMPLATE?id=(${templateId})`,
    Method: 'PATCH',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  return await dataService.FetchData(loaTemplateFetchProperties);
}

export async function postCertificate({ user, services, body }) {
  let fetchProps = {
    BaseUrl: `${services.Enrollment.URL}/Enrollment/CertificateOfCompletion`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  
  return await dataService.FetchData(fetchProps);
}
