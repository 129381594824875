import * as globals from '../../Globals/Variables';
import * as dataService from '../Utils/DataService';
import { checkPolicy } from '../Utils/HelperFunctions';

export async function getHierarchyData({ user, services, accountData }) {
  let hierarchyDataFetchProperties = {
    BaseUrl: `${services.MasterDB.URL}/ACCOUNT_CORE_HIERARCHY?$filter=Account_UID eq ${accountData.AccountID}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.MasterDB.subscription_key,
      'x-universal-directive': 'D',
      DDHFirm: user.DDHFirm,
    },
  };

  const resp = await dataService.FetchData(hierarchyDataFetchProperties);
  return !resp.error && resp.body && resp.body.value && resp.body.value.length > 0 ? resp.body.value[0] : null;
}

export async function getHierarchyOptions({ user, services, hierarchyParams }) {
  let result = {};

  for (let i in hierarchyParams) {
    let hierarchyOptionsFetchProperties = {
      BaseUrl: `${services.MasterDB.URL}/ACCOUNT_CORE_HIERARCHY?$apply=groupby((${hierarchyParams[i].field}))`,
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      ShowMessage: globals.ON_FAIL,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': services.MasterDB.subscription_key,
        'x-universal-directive': 'D',
        DDHFirm: user.DDHFirm,
      },
    };

    const resp = await dataService.FetchData(hierarchyOptionsFetchProperties);
    result[hierarchyParams[i].id] =
      !resp.error && resp.body && resp.body.value
        ? resp.body.value.map((val) => {
            return {
              label: val[hierarchyParams[i].field],
              value: val[hierarchyParams[i].field],
            };
          })
        : [];
  }

  return result;
}

export async function patchAccount({
  user,
  services,
  showMessage,
  accountData,
  hierarchyData,
  hierarchyParams,
  dirtyFields,
  validSigners,
  newStatusId,
  otherTypeId,
  removedSigners,
  fundData = [],
  enrollHierarchyShow = false,
}) {
  for (let i in removedSigners) {
    removeAccountSigner({
      user,
      services,
      signerId: removedSigners[i],
    });
  }

  let signerLinkEvents = [];
  let uniques = [];
  let index = 0;
  for (let i in accountData.ACCOUNT_SIGNER_LINK) {
    if (validSigners.includes(parseInt(i)) && uniques.indexOf(accountData.ACCOUNT_SIGNER_LINK[i].ContactID) === -1) {
      uniques.push(accountData.ACCOUNT_SIGNER_LINK[i].ContactID);
      signerLinkEvents.push({
        Index: index,
        CapacityID: accountData.ACCOUNT_SIGNER_LINK[i].CapacityID.value,
        ContactID: accountData.ACCOUNT_SIGNER_LINK[i].ContactID,
        ContactDetailID: accountData.ACCOUNT_SIGNER_LINK[i].ContactDetailID,
        // FirstName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName:'',
        // LastName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName:'',
      });
      index++;
    }
  }

  let accountHierarchy = {};
  hierarchyParams.forEach((param) => {
    accountHierarchy[param.field] = hierarchyData[param.id] ? hierarchyData[param.id].value : null;
  });

  let body = {
    AccountEvent: {
      FirmID: user.FirmId,
      AccountName: !dirtyFields.AccountName ? undefined : accountData.AccountName, //edit and havent changed it, dont pass it
      AccountTitle: !dirtyFields.AccountTitle ? undefined : accountData.AccountTitle, //edit and havent changed it, dont pass it
      AccountTypeID: accountData.AccountTypeID && accountData.AccountTypeID.value ? accountData.AccountTypeID.value : otherTypeId,
      Account_PartnerID: !dirtyFields.Account_PartnerID ? undefined : accountData.Account_PartnerID,
      Account_TeamID: !dirtyFields.Account_TeamID ? undefined : accountData.Account_TeamID,
      Account_ClientID: !dirtyFields.Account_ClientID ? undefined : accountData.Account_ClientID,
      Account_InvestmentID: !dirtyFields.Account_InvestmentID ? undefined : accountData.Account_InvestmentID,
      Account_Custom1: !dirtyFields.Account_Custom1 ? undefined : accountData.Account_Custom1,
      Account_Custom2: !dirtyFields.Account_Custom2 ? undefined : accountData.Account_Custom2,
      Account_Custom3: !dirtyFields.Account_Custom3 ? undefined : accountData.Account_Custom3,
      Account_Custom4: !dirtyFields.Account_Custom4 ? undefined : accountData.Account_Custom4,
      Account_Related_Entity_UID: !dirtyFields.Account_Related_Entity_UID ? undefined : accountData.Account_Related_Entity_UID,
      ContactID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.value : null,
      ContactDetailID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.DetailID : null,
      // Status: accountData.StatusObject ? accountData.StatusObject.value : undefined,
      CustodianID: accountData.CustodianID,
      AdvisorID: !dirtyFields.AdvisorID ? undefined : accountData.AdvisorID,
      RelationshipID: !dirtyFields.RelationshipID ? undefined : accountData.RelationshipID,
      ManagerType: accountData.ManagerType,
      Fund_UID: accountData.Fund ? accountData.Fund.value : undefined,
      InvestorName: !dirtyFields.InvestorName ? undefined : accountData.InvestorName,
      InvestorID: !dirtyFields.InvestorID ? undefined : accountData.InvestorID,
    },
    AccountSignerLinkEvent: signerLinkEvents,
  };

  if (enrollHierarchyShow) {
    body['AccountHierarchy'] = accountHierarchy;
  }

  let patchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CompleteAccount/UpdateAccount?id=${accountData.AccountID}`,
    Method: 'PATCH',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: showMessage,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(patchProperties);
}

export async function checkAccounts({ user, services, accountData, dirtyFields, validSigners, multipleAccountsSet, crmData, newStatusId, otherTypeId, fundData = [] }) {
  let signerLinkEvents = [];
  let index = 0;
  for (let i in accountData.ACCOUNT_SIGNER_LINK) {
    if (validSigners.includes(parseInt(i))) {
      //Existing Signer
      signerLinkEvents.push({
        Index: index,
        CapacityID: accountData.ACCOUNT_SIGNER_LINK[i].CapacityID.value,
        ContactID: accountData.ACCOUNT_SIGNER_LINK[i].ContactID,
        ContactDetailID: accountData.ACCOUNT_SIGNER_LINK[i].ContactDetailID,
        FirstName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName : '',
        LastName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName : '',
        Email: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.Email ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.Email : '',
      });
    } else {
      //New Signer
      signerLinkEvents.push({
        Index: index,
        CapacityID: accountData.ACCOUNT_SIGNER_LINK[i].CapacityID.value,
        FirstName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName : '',
        LastName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName : '',
        Email: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.Email ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.Email : '',
      });
    }
    index++;
  }

  let body = [];
  if (multipleAccountsSet) {
    if (accountData.ManagerType === 'ALTERNATIVE') {
      let investorArray = accountData.InvestorName.split(',');
      let fundArray = accountData.Funds.split(',');
      for (let i in investorArray) {
        let name = investorArray[i].trim();
        let fund = fundArray[i].trim();
        let obj = {
          FirmID: user.FirmId,
          AccountName: name,
          AccountTitle: accountData.AccountTitle,
          AccountTypeID: accountData.AccountTypeID && accountData.AccountTypeID.value ? accountData.AccountTypeID.value : otherTypeId,
          ContactID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.value : null,
          ContactDetailID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.DetailID : null,
          Status: newStatusId,
          CustodianID: accountData.CustodianID,
          AdvisorID: accountData.AdvisorID,
          AdvisorName: crmData ? crmData.advisorName : null,
          RelationshipID: accountData.RelationshipID,
          ClientName: crmData ? crmData.clientName : null,
          ManagerType: accountData.ManagerType,
          Fund_UID: fund ? fund : undefined,
          InvestorName: name,
          InvestorID: accountData.InvestorID,
          AccountSignerLink: signerLinkEvents,
        };
        if (name && name.length > 0) {
          body.push(obj);
        }
      }
    } else {
      let accountsArray = accountData.AccountName.split(',');
      for (let i in accountsArray) {
        let name = accountsArray[i].trim();
        let obj = {
          FirmID: user.FirmId,
          AccountName: name,
          AccountTitle: accountData.AccountTitle,
          AccountTypeID: accountData.AccountTypeID && accountData.AccountTypeID.value ? accountData.AccountTypeID.value : otherTypeId,
          ContactID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.value : null,
          ContactDetailID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.DetailID : null,
          Status: newStatusId,
          CustodianID: accountData.CustodianID,
          AdvisorID: accountData.AdvisorID,
          AdvisorName: crmData ? crmData.advisorName : null,
          RelationshipID: accountData.RelationshipID,
          ClientName: crmData ? crmData.clientName : null,
          ManagerType: accountData.ManagerType,
          Fund_UID: accountData.Fund && accountData.Fund.value ? accountData.Fund.value : undefined,
          InvestorName: accountData.InvestorName,
          InvestorID: accountData.InvestorID,
          AccountSignerLink: signerLinkEvents,
        };
        if (name && name.length > 0) {
          body.push(obj);
        }
      }
    }
  } else {
    body = [
      {
        FirmID: user.FirmId,
        AccountName: accountData.AccountName,
        AccountTitle: accountData.AccountTitle,
        AccountTypeID: accountData.AccountTypeID && accountData.AccountTypeID.value ? accountData.AccountTypeID.value : otherTypeId,
        ContactID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.value : null,
        ContactDetailID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.DetailID : null,
        Status: newStatusId,
        CustodianID: accountData.CustodianID,
        AdvisorID: accountData.AdvisorID,
        AdvisorName: crmData ? crmData.advisorName : null,
        RelationshipID: accountData.RelationshipID,
        ClientName: crmData ? crmData.clientName : null,
        ManagerType: accountData.ManagerType,
        Fund_UID: accountData.Fund ? accountData.Fund.value : undefined,
        InvestorName: accountData.InvestorName,
        InvestorID: accountData.InvestorID,
        AccountSignerLink: signerLinkEvents,
      },
    ];
  }

  // Support for multiple funds
  let tempBody = [];
  let fundObj = {};
  if (fundData.length > 0 && body.length === 1 && !multipleAccountsSet) {
    fundData.map((fund) => {
      if (fund.Fund_UID) {
        fundObj = Object.assign({}, body[0]);
        fundObj.Fund_UID = fund.Fund_UID;
        tempBody.push(fundObj);
      }
    });
    body = tempBody;
  }

  let postProperties = {
    BaseUrl: `${services.Enrollment.URL}/CompleteAccount/CheckExistingToken`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(postProperties);
  if (!response.error) {
    let data = response.body[0].Value;
    data = data[0].length === 0 ? '' : data[0];

    return data;
  } else {
    return '';
  }
}
export async function getUserTeamEmail(user, services) {
  let fetchProps = {
    BaseUrl: `${services.Dashboard.URL}/USER?id=${user.userId}`,
    Method: 'GET',
    SuccessMessage: '',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Dashboard.subscription_key,
    },
  };
  let response = await dataService.FetchData(fetchProps);

  return response;
}

export async function postAccount({
  user,
  services,
  accountData,
  hierarchyData,
  hierarchyParams,
  dirtyFields,
  validSigners,
  multipleAccountsSet,
  multipleAccountsData,
  crmData,
  newStatusId,
  otherTypeId,
  fundData = [],
  enrollHierarchyShow = false,
}) {
  // Inject TeamEmail into policy defined field if present in the User record
  if (user.firmPolicies[globals.UI_ENROLLMENT_ACCOUNT_CREATEEMAIL]) {
    let fieldName = user.firmPolicies[globals.UI_ENROLLMENT_ACCOUNT_CREATEEMAIL].Value;
    if (fieldName) {
      let userTeamRes = await getUserTeamEmail(user, services);
      if (userTeamRes) {
        if (!userTeamRes.error) {
          if (userTeamRes.body) {
            if (userTeamRes.body.TeamEmail) {
              accountData[fieldName] = userTeamRes.body.TeamEmail;
            }
          }
        }
      }
    }
  }

  let signerLinkEvents = [];
  let index = 0;
  for (let i in accountData.ACCOUNT_SIGNER_LINK) {
    if (validSigners.includes(parseInt(i))) {
      //Existing Signer
      signerLinkEvents.push({
        Index: index,
        CapacityID: accountData.ACCOUNT_SIGNER_LINK[i].CapacityID.value,
        ContactID: accountData.ACCOUNT_SIGNER_LINK[i].ContactID,
        ContactDetailID: accountData.ACCOUNT_SIGNER_LINK[i].ContactDetailID,
        FirstName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName : '',
        LastName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName : '',
        Email: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.Email ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.Email : '',
      });
    } else {
      //New Signer
      signerLinkEvents.push({
        Index: index,
        CapacityID: accountData.ACCOUNT_SIGNER_LINK[i].CapacityID.value,
        FirstName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName : '',
        LastName: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName : '',
        Email: accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.Email ? accountData.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.Email : '',
      });
    }
    index++;
  }

  let accountHierarchy = {};
  hierarchyParams.forEach((param) => {
    accountHierarchy[param.field] = hierarchyData[param.id] ? hierarchyData[param.id].value : null;
  });

  let body = [];
  if (multipleAccountsSet) {
    if (accountData.ManagerType === 'ALTERNATIVE') {
      let investorArray = accountData.InvestorName.split(',');
      let fundArray = accountData.Funds.split(',');
      for (let i in investorArray) {
        let name = investorArray[i].trim();
        let fund = fundArray[i].trim();
        let obj = {
          FirmID: user.FirmId,
          AccountName: accountData.AccountName,
          AccountTitle: accountData.AccountTitle,
          AccountTypeID: accountData.AccountTypeID && accountData.AccountTypeID.value ? accountData.AccountTypeID.value : otherTypeId,
          Account_PartnerID: accountData.Account_PartnerID,
          Account_TeamID: accountData.Account_TeamID,
          Account_ClientID: accountData.Account_ClientID,
          Account_InvestmentID: accountData.Account_InvestmentID,
          Account_Custom1: accountData.Account_Custom1,
          Account_Custom2: accountData.Account_Custom2,
          Account_Custom3: accountData.Account_Custom3,
          Account_Custom4: accountData.Account_Custom4,
          Account_Related_Entity_UID: !dirtyFields.Account_Related_Entity_UID ? undefined : accountData.Account_Related_Entity_UID,
          ContactID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.value : null,
          ContactDetailID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.DetailID : null,
          Status: newStatusId,
          CustodianID: accountData.CustodianID,
          AdvisorID: accountData.AdvisorID,
          AdvisorName: crmData ? crmData.advisorName : null,
          RelationshipID: accountData.RelationshipID,
          ClientName: crmData ? crmData.clientName : null,
          ManagerType: accountData.ManagerType,
          Fund_UID: fund,
          InvestorName: name,
          InvestorID: accountData.InvestorID,
          AccountSignerLink: signerLinkEvents,
        };
        if (enrollHierarchyShow) {
          obj['AccountHierarchy'] = accountHierarchy;
        }
        if (name && name.length > 0) {
          body.push(obj);
        }
      }
    } else {
      let accountsArray = accountData.AccountName.split(',');
      let accountNamesArray = accountData.AccountTitle.split(',');
      for (let i in accountsArray) {
        let name = accountsArray[i].trim();
        let title = accountNamesArray[i].trim();
        let obj = {
          FirmID: user.FirmId,
          AccountName: name,
          AccountTitle: title,
          AccountTypeID: accountData.AccountTypeID && accountData.AccountTypeID.value ? accountData.AccountTypeID.value : otherTypeId,
          Account_PartnerID: accountData.Account_PartnerID,
          Account_TeamID: accountData.Account_TeamID,
          Account_ClientID: accountData.Account_ClientID,
          Account_InvestmentID: accountData.Account_InvestmentID,
          Account_Custom1: accountData.Account_Custom1,
          Account_Custom2: accountData.Account_Custom2,
          Account_Custom3: accountData.Account_Custom3,
          Account_Custom4: accountData.Account_Custom4,
          Account_Related_Entity_UID: !dirtyFields.Account_Related_Entity_UID ? undefined : accountData.Account_Related_Entity_UID,
          ContactID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.value : null,
          ContactDetailID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.DetailID : null,
          Status: newStatusId,
          CustodianID: accountData.CustodianID,
          AdvisorID: accountData.AdvisorID,
          AdvisorName: crmData ? crmData.advisorName : null,
          RelationshipID: accountData.RelationshipID,
          ClientName: crmData ? crmData.clientName : null,
          ManagerType: accountData.ManagerType,
          Fund_UID: accountData.Fund && accountData.Fund.value ? accountData.Fund.value : undefined,
          InvestorName: accountData.InvestorName,
          InvestorID: accountData.InvestorID,
          AccountSignerLink: signerLinkEvents,
        };
        if (enrollHierarchyShow) {
          obj['AccountHierarchy'] = accountHierarchy;
        }
        if (name && name.length > 0) {
          body.push(obj);
        }
      }
    }
  } else {
    body = [
      {
        FirmID: user.FirmId,
        AccountName: accountData.AccountName,
        AccountTitle: accountData.AccountTitle,
        AccountTypeID: accountData.AccountTypeID && accountData.AccountTypeID.value ? accountData.AccountTypeID.value : otherTypeId,
        Account_PartnerID: accountData.Account_PartnerID,
        Account_TeamID: accountData.Account_TeamID,
        Account_ClientID: accountData.Account_ClientID,
        Account_InvestmentID: accountData.Account_InvestmentID,
        Account_Custom1: accountData.Account_Custom1,
        Account_Custom2: accountData.Account_Custom2,
        Account_Custom3: accountData.Account_Custom3,
        Account_Custom4: accountData.Account_Custom4,
        Account_Related_Entity_UID: !dirtyFields.Account_Related_Entity_UID ? undefined : accountData.Account_Related_Entity_UID,
        ContactID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.value : null,
        ContactDetailID: accountData.CustodianContact && !checkPolicy(user.firmPolicies[globals.ENROLLMENT_ANONYMIZED]) ? accountData.CustodianContact.DetailID : null,
        Status: newStatusId,
        CustodianID: accountData.CustodianID,
        AdvisorID: accountData.AdvisorID,
        AdvisorName: crmData ? crmData.advisorName : null,
        RelationshipID: accountData.RelationshipID,
        ClientName: crmData ? crmData.clientName : null,
        ManagerType: accountData.ManagerType,
        Fund_UID: accountData.Fund ? accountData.Fund.value : undefined,
        InvestorName: accountData.InvestorName,
        InvestorID: accountData.InvestorID,
        AccountSignerLink: signerLinkEvents,
      },
    ];
    if (enrollHierarchyShow) {
      body[0]['AccountHierarchy'] = accountHierarchy;
    }
  }

  // Support for multiple funds
  let tempBody = [];
  let fundObj = {};
  if (fundData.length > 0 && body.length === 1 && !multipleAccountsSet) {
    fundData.map((fund) => {
      if (fund.Fund_UID) {
        fundObj = Object.assign({}, body[0]);
        fundObj.Fund_UID = fund.Fund_UID;
        tempBody.push(fundObj);
      }
    });
    body = tempBody;
  }

  let postProperties = {
    BaseUrl: `${services.Enrollment.URL}/CompleteAccount/CreateAccount`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(postProperties);
}

export async function changeAccountStatus({ user, services, fromStatus, toStatus, accountIDs }) {
  if (accountIDs && fromStatus && toStatus && fromStatus !== toStatus) {
    let statusBody = {
      accountIDs,
      fromStatus,
      toStatus,
    };
    let statusFetchProperties = {
      BaseUrl: !globals.REMOTE_INSTANCE ? `${services.Enrollment.URL}/LOAGenerateAndPreview/UpdateAccountStatus` : `${services.Enrollment.URL}/enrollment/UpdateAccountStatus`,
      Method: 'POST',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      ShowMessage: globals.NEVER,
      CallBody: statusBody,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
        DDHFirm: user.DDHFirm,
      },
    };
    return await dataService.FetchData(statusFetchProperties);
  }
}

export async function getLoaDocument({ user, services, loaid }) {
  let loaDocumentFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_DOCUMENT?$filter=(LoaID eq ${loaid})&$expand=LOA`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };

  return await dataService.FetchData(loaDocumentFetchProperties);
}

export async function sendLoas({ user, services, loaids }) {
  let body = { loaids };

  let sendLoaFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/Enrollment/SendLOAsForESignature`,
    Method: 'POST',
    SuccessMessage: 'LOA Sent',
    FailureMessage: 'Failed to send LOA',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
    },
  };

  return await dataService.FetchData(sendLoaFetchProperties);
}

export async function generateLoas({ user, services, accountids, mode }) {
  let body = { accounts: accountids };

  let generateLoasFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/Enrollment/GenerateLOAs?mode=${mode}`,
    Method: 'POST',
    SuccessMessage: mode === 'ELECTRONIC' ? 'LOA Sent' : 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: mode === 'ELECTRONIC' ? globals.ALWAYS : globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
    },
  };
  return await dataService.FetchData(generateLoasFetchProperties);
}

export async function cancelLoas({ user, services, loaids }) {
  let body = { loaIds: loaids };
  let cancelLoasFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/Enrollment/CancelLOAs`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
    },
  };
  return await dataService.FetchData(cancelLoasFetchProperties);
}

export async function getLoaActions({ user, services }) {
  let loaTrackingFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_ACTION`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };
  let response = await dataService.FetchData(loaTrackingFetchProperties);
  return response.body.value;
}

export async function postLoaTracking({ user, services, actionId, description, loaId, statusId }) {
  let body = {
    LoaID: loaId,
    Status: statusId,
    ActionID: actionId,
    Description: description,
    UserID: user.userId,
  };
  let loaTrackingFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_TRACKING`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };
  return await dataService.FetchData(loaTrackingFetchProperties);
}

export async function getPII({ user, services, accountId }) {
  let getPiiLoasFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/ACCOUNT?$count=true&$top=10&skip=0&$filter=(AccountID eq ${accountId})`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'x-universal-directive': 'D',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(getPiiLoasFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getAccountGridPII({ user, services, accountId }) {
  let getPiiLoasFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/ACCOUNTS_GRID?$count=true&$top=10&skip=0&$filter=(AccountID eq ${accountId})`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'x-universal-directive': 'D',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(getPiiLoasFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getExistingLoa({ user, services, newLoaId, accountId }) {
  let accountLoaFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_ACCOUNT_LINK?$filter=(AccountID eq ${accountId} and LoaID ne ${newLoaId} and ChangeType ne 'D')`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };
  let response = await dataService.FetchData(accountLoaFetchProperties);
  let loaids = response.body.value.map((loa) => loa.LoaID);
  if (loaids.length > 0) {
    await cancelLoas({
      user,
      services,
      loaids,
    });
  }
  return;
}

export async function getAccountsGrid({ user, services, filter, orderby, page = null, pagesize = null }) {
  let BaseUrl = `${services.Enrollment.URL}/ACCOUNTS_GRID?$count=true${filter}${orderby}`;
  if (page !== null && pagesize !== null) {
    BaseUrl = `${services.Enrollment.URL}/ACCOUNTS_GRID?$count=true&$top=${pagesize}&$skip=${page * pagesize}${filter}${orderby}`;
  }
  let fetchProperties = {
    Method: 'GET',
    BaseUrl,
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'x-universal-directive': 'S',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(fetchProperties);
  var notificationCount = response.body['@odata.count'];
  return { values: response.body.value || [], count: notificationCount };
}

export async function getAccountData({ user, services, accountId, showData }) {
  let accountDataFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/ACCOUNT?$filter=(AccountID eq ${accountId})&$expand=FUND,LOA_ACCOUNT_LINK($expand=LOA),ACCOUNT_LOA_STATUS,CUSTODIAN($expand=CUSTODIAN_STATUS),ACCOUNT_SIGNER_LINK($expand=CONTACT_DETAIL,SIGNER_CAPACITY;$orderby=Index),CONTACT($expand=CONTACT_DETAIL)`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'x-universal-directive': showData ? 'D' : 'S',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(accountDataFetchProperties);
  if (!response.error && response.body && response.body.value) {
    //For accounts that were created without all required fields and those fields
    //were defaulted to empty GUIDs, here we map through the account properties
    //and set those fields to null for the application to treat them as empty.
    let data = response.body.value.map((item) => {
      item.CustodianID = item.CustodianID && item.CustodianID === '00000000-0000-0000-0000-000000000000' ? null : item.CustodianID;
      item.CUSTODIAN = item.CUSTODIAN && item.CUSTODIAN.CustodianID === '00000000-0000-0000-0000-000000000000' ? null : item.CUSTODIAN;
      item.LOA_ACCOUNT_LINK.forEach((link) => {
        if (link.ChangeType !== 'D') {
          item['LOA'] = link.LOA;
        }
      });
      return item;
    });
    return response.body.value;
  } else {
    return [];
  }
}
export async function getAccountsData({ user, services, accountIds = null, showData }) {
  let body = accountIds ? accountIds.split(',') : [];
  let accountDataFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CompleteAccount/GetAccountArray`,
    Method: 'POST',
    CallBody: body,
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'x-universal-directive': showData ? 'D' : 'S',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(accountDataFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getPendingLoas({ user, services, key = null, authorization = null, tokenFilter = false, orderby = 'AuthorizationKey' }) {
  if (key) {
    let BaseUrl = `${services.Enrollment.URL}/PENDING_LOAS?$filter=AuthorizationKey eq '${key}' and Authorization ne '${authorization}'&$select=Authorization,AuthorizationKey,AccountIds,AccountSIDs`;
    let fetchProperties = {
      BaseUrl,
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      ShowMessage: globals.NEVER,
      HeaderVals: {
        'x-universal-directive': tokenFilter === false ? 'D' : 'S',
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
        DDHFirm: user.DDHFirm,
      },
    };
    let response = await dataService.FetchData(fetchProperties);

    let accountIds = '';
    if (response.body) {
      if (response.body.value) {
        let res = response.body.value;
        for (let rowData of res) {
          if (accountIds) {
            accountIds += ',' + rowData.AccountIds.split(',').join(',');
          } else {
            accountIds += rowData.AccountIds.split(',').join(',');
          }
        }
        return accountIds;
      }
    } else {
      return '';
    }
  }

  return '';
}

export async function getAccountStatusOptions({ user, services, currentStatus }) {
  let statusFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/ACCOUNT_STATUS_FLOW?$filter=(CurrentStatus eq '${currentStatus}')`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(statusFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getCancellableStatuses({ user, services, currentStatus }) {
  let statusFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/LOA_STATUS_FLOW?$filter=(NextStatus eq 'CANCELLED')`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(statusFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getAccountToken({ user, services, body }) {
  let getTokenFetchProperties = {
    BaseUrl: `${services.PrivacyAPI.URL}/registry/lookup/global`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(getTokenFetchProperties);
}

export async function removeAccountSigner({ user, services, signerId }) {
  let statusFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/ACCOUNT_SIGNER_LINK?id=${signerId}`,
    Method: 'DELETE',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  await dataService.FetchData(statusFetchProperties);
  return;
}

export async function getTooltips({ user, services }) {
  let loaTrackingFetchProperties = {
    BaseUrl: `${services.Dashboard.URL}/HELP?$filter=(contains(HelpID,'Tooltip.Enrollment.Enroll') or (contains(HelpID,'Enrollment.')))`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Dashboard.subscription_key,
    },
  };
  let response = await dataService.FetchData(loaTrackingFetchProperties);
  return response;
}

export async function getHelpTooltips({ user, services }) {
  // Using Dataservice, get all help associated with Enrollment for use in Tooltips, Question, and Info icons
  let fetchProperties = {
    BaseUrl: `${services.Dashboard.URL}/HELP?$filter=(contains(HelpID,'Enrollment.') and (not(contains(HelpID,'Tooltip.'))) )`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Dashboard.subscription_key,
    },
  };
  let response = await dataService.FetchData(fetchProperties);
  return response;
}

export async function getHelp({ user, services }, subscription, url) {
  let fetchProps = {
    BaseUrl: url,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.NEVER,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': subscription,
    },
  };
  let response = await dataService.FetchData(fetchProps);

  return response;
}

export async function getCustodianRelated({ user, services, id }) {
  let fetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CUSTODIAN?$orderby=Name&$filter=(CustodianID eq ${id} and Enrollment eq true)`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = id && (await dataService.FetchData(fetchProperties));
  if (response && !response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}
