import * as globals from '../../Globals/Variables';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router';
import { showRequired, hasRequired } from '../Utils/RequiredFields';
import { checkPolicy } from '../Utils/HelperFunctions';
import { confirmAlert } from 'react-confirm-alert';
import * as Models from './AddTicketModels';
import Draggable from 'react-draggable';
import Modal from 'react-modal';
import DescriptionNote from './DescriptionNote';
import Contact from '../Enrollment/Contact';
import Select from 'react-select';

const FontAwesome = require('react-fontawesome');
const Spinner = require('react-spinkit');

let AllRequiredFields = ['Message', 'Title'];

let getINITIAL_STATE = (that) => {
  const policies = that.props.user.firmPolicies;
  return {
    NoteOpen: true,
    User: '',
    DefaultGroup: '',
    SupportEmail: '',
    Procedure: '',
    Type: '',
    Message: '',
    checkRequired: false,
    loadSpinner: true,
    accountDeleteProc: globals.TICKET_ENROLLMENT_ACCOUNT_DELETE_PROC,
    procedureDisabled: true,
    Data: {
      WORK_CONTEXT: [{}],
      WORK_TICKET: [],
      WORK_PROCEDURE: {},
    },
  };
};

const styleOverrides = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    bottom: 'unset',
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    width: '35%',
    height: '40%',
    top: '20%',
    left: '35%',
    // pointerEvents: "none"
  },
};

const styleOverridesProcedure = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    // bottom: "unset",

    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    width: '35%',
    height: '50%',
    top: '20%',
    left: '35%',
    // pointerEvents: "none"
  },
};

const DescriptionNoteModal = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    bottom: 'unset',
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    width: '36%',
    height: '40%',
    top: '20%',
    left: '32%',
    // pointerEvents: "none"
  },
};

export class AddTicket extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.dataCalls();
  }

  async dataCalls() {
    let { procedureDisabled, accountDeleteProc } = this.state;
    let user = this.props.user;
    let services = this.props.services;
    let Procedure = this.props.Procedure;
    let Account_UID = this.props.acctid ? this.props.acctid : null;
    if (this._isMounted) {
      let acctid = Account_UID ? await this.accountData(Account_UID, user.FirmId) : null;

      let newDate = this.newDate();
      let Email = null;
      let ContactUs = null;
      let Role = null;
      let status = this.state.AccountStatus;
      let workProcedures = [];
      // if (status === 'CANCELLED' || status === 'REVOKED') {
      procedureDisabled = false;
      workProcedures.push({ label: Procedure, value: Procedure });
      // Only add delete option if account in context
      if (Account_UID) {
        workProcedures.push({ label: accountDeleteProc, value: accountDeleteProc });
      }
      // }

      if (!globals.REMOTE_INSTANCE) {
        Email = await Models.getFirmID({ user, services, id: user.FirmId });

        ContactUs = await Models.getWorkProcedure({ user, services, id: Procedure });

        ContactUs = ContactUs.values.find((el) => el.Procedure == Procedure);

        Role = await Models.isInternal({ user, services });

        if (Role) {
          ContactUs.User = ContactUs.DefaultGroup;
          ContactUs.DefaultGroup = Email.body.FirmEmail;
        } else {
          ContactUs.User = this.props.user.Email;
        }
      }

      let tempEmail = this.props.user.FirmId == 540 ? 'support@gs.com' : this.props.user.FirmId + '@pcrinsights.com';

      this.setState({ types: this.getTypes() });
      let { AccountSID, AccountName } = this.state;
      this.setState(
        {
          Date: newDate,
          DefaultGroup: 'enrollment@pcrinsights.com',
          Procedure: Procedure,
          User: !globals.REMOTE_INSTANCE ? Email.body.FirmEmail : tempEmail,
          Type: 'Request',
          Title: null,
          TitlePrefix: AccountSID ? 'Request for Account ID ' + AccountSID + ' - ' : null,
          Account_UID: Account_UID,
          ContextKey: null,
          ContextValue: null,
          ContextMessage: null,
          ContextEntity: null,
          procedureDisabled,
          workProcedures,
          loadSpinner: false,
        },
        () => {
          this.accountPlaceholder();
        }
      );
    }
  }

  // fileInputRef = React.createRef();

  accountData = async (acctid, firmID) => {
    const { user, services } = this.props;
    let account = await Models.getAccountToken({ user, services, id: acctid });

    this.setState({
      AccountID: account.AccountID,
      AccountName: account.AccountName,
      LastBusinessDate: account.LastBusinessDate,
      AccountSID: account.Account_SID,
      AccountStatus: account.Status,
    });
  };

  checkElement = (e, attr) => {
    if (e) {
      return e[attr];
    } else {
      return null;
    }
  };

  updateData = (name, value, nestedName, index) => {
    return new Promise((resolve, reject) => {
      let data = Object.assign({}, this.state.data);
      if (name === 'wholeObject') {
        let val = {};
        for (let i in value) {
          if (i !== 'label' && i !== 'value') {
            val[i] = value[i];
          }
        }
        data[nestedName] = val;
      } else if (nestedName && index !== null && index !== undefined) {
        if (!data[nestedName][index]) {
          data[nestedName][index] = {};
        }
        data[nestedName][index][name] = value;
      } else if (nestedName) {
        data[nestedName][name] = value;
      } else {
        data[name] = value;
      }
      this.setState({ data }, () => {
        resolve();
      });
    });
  };
  DescriptionUpdate = (e) => {
    let { isInternal, workProcedures } = this.state;
    let Procedure = this.state.Procedure;
    let Description = workProcedures.find((pro) => pro.Procedure === Procedure);

    if (isInternal) {
      this.setState({ User: Description.DefaultGroup });
    } else {
      this.setState({ DefaultGroup: Description.DefaultGroup });
    }

    this.setState({ ProcedureDescription: Description.Description, Procedure: Description.Procedure });
  };

  async getProceduresDD() {
    const { user, services } = this.props;
    let procedures = await Models.getWorkProcedures({ user, services });
    let tempData = procedures.map((obj) => {
      //look for nested worktype when figure that out
      if (this.state.Procedure === obj.Procedure) {
        let tmp = obj;
        tmp.label = obj.Procedure;
        tmp.value = obj.Procedure;
        this.setState({ Procedure: obj.Procedure });
      }
      let wholeObject = obj;
      wholeObject.label = obj.Procedure;
      wholeObject.value = obj.Procedure;
      return wholeObject;
    });
    return tempData;
    // this.setState({ workProcedures: tempData });
  }
  async CreateTickets() {
    this.setState({ loadSpinner: true });
    const { user, services } = this.props;

    let TicketDetail = {
      Tickets: [
        {
          Procedure: this.state.Procedure,
          Title: this.state.TitlePrefix ? this.state.TitlePrefix + this.state.Title : this.state.Title,
          CreateEmail: this.props.user.Email,
          Description: this.state.Message,
          Type: this.state.Type.value ? this.state.Type.value : 'Request',
          ContextKey: null,
          ContextValue: null,
          ContextMessage: null,
          ContextEntity: null,
          AccountId: this.state.AccountID ? this.state.AccountID : null,
          BusinessDate: this.state.LastBusinessDate ? this.state.LastBusinessDate : null,
        },
      ],
    };
    let TicketCreate = null;
    if (!globals.REMOTE_INSTANCE) {
      TicketCreate = await Models.postTicket({
        user,
        services,
        body: TicketDetail,
      });
    } else {
      TicketCreate = await Models.postRemoteTicket({
        user,
        services,
        body: TicketDetail,
      });
    }

    if (!TicketCreate.error) {
      let response = null;
      if (!globals.REMOTE_INSTANCE) {
        response = {
          Email: this.state.DefaultGroup,
          id: TicketCreate.body.Value[0].Value[0].TicketId,
        };
      } else {
        response = {
          Email: this.state.DefaultGroup,
          id: '',
        };
      }

      return response;
    } else {
      return false;
    }
  }

  createTicketConfirm = async () => {
    const { loastatus } = this.props;
    const { Procedure, accountDeleteProc } = this.state;
    let requiredFields = AllRequiredFields;
    if (hasRequired(this.state, requiredFields)) {
      if (Procedure === accountDeleteProc) {
        if (
          Procedure === accountDeleteProc &&
          (loastatus === 'PRINTED' ||
            loastatus === 'SENT' ||
            loastatus === 'ACKNOWLEDGED' ||
            // Add New Statuses
            loastatus === 'SIGNED' ||
            loastatus === 'DELIVERED' ||
            loastatus === 'PENDING' ||
            loastatus === 'PROVISIONING' ||
            loastatus === 'ISSUES')
        ) {
          confirmAlert({
            title: `Warning`,
            message: `PCR will delete the account however once the LOA has been created this will not void or change the LOA`,
            // childrenElement: () => <div>{message}</div>,
            buttons: [
              {
                label: <div id="cancel-confirm-button">Cancel</div>,
                onClick: () => {},
              },
              {
                label: <div id="continue-confirm-button">Confirm</div>,
                id: 'continue-confirm-button',
                onClick: async () => {
                  this.TicketChecker();
                },
              },
            ],
          });
        } else {
          this.TicketChecker();
        }
      } else {
        this.TicketChecker();
      }
    } else {
      this.setState({ checkRequired: true });
    }
  };

  getTypes = () => {
    let types = [];

    types.push({ value: 'Error', label: 'Error' });
    types.push({ value: 'Request', label: 'Request' });
    types.push({ value: 'Task', label: 'Task' });

    return types;
  };

  async TicketChecker() {
    let ticket;
    try {
      ticket = await this.CreateTickets();
      this.incomingEmail(ticket);
      this.props.clearTempContext();
    } catch (error) {}
    this.setState({ loadSpinner: false });
  }

  incomingEmail = (ticket) => {
    var message = ``;
    confirmAlert({
      title: ticket.id ? `Ticket #${ticket.id} Created` : `Ticket Created`,
      message: `A confirmation email was sent to ${ticket.Email}. Your request is being reviewed and you will receive regular updates.`,
      childrenElement: () => <div>{message}</div>,
      buttons: [{ label: <div id="cancel-confirm-button">Confirm</div>, onClick: () => {} }],
    });
  };

  newDate = () => {
    var currentDate = new Date();
    var date = currentDate.getMonth() + 1 + '/' + currentDate.getDate() + '/' + currentDate.getFullYear();
    return date;
  };

  setCategorySearch = (e) => {
    this.setState({ Type: e });
  };

  Exit() {
    if (this.props.closeModal) {
      this.props.closeModal();
    } else if (this.props.clearTempContext) {
      this.props.clearTempContext();
    }
  }

  accountPlaceholder = async () => {
    const { AccountName, LastBusinessDate } = this.state;
    let returnstatement;
    if (AccountName && LastBusinessDate) {
      returnstatement = `Account: ${AccountName} Business Date: ${LastBusinessDate}`;
      this.setState({ AccountInfo: returnstatement });
    } else if (AccountName && !LastBusinessDate) {
      returnstatement = `Account: ${AccountName}`;
      this.setState({ AccountInfo: returnstatement });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.props.wipeState();
  }

  getTitleRemaining = () => {
    let { TitleLength } = this.state;
    return `(${50 - TitleLength}) characters remaining.`;
  };
  render() {
    let { checkRequired, AccountName, AccountInfo, LastBusinessDate, Title, procedureDisabled, Procedure, accountDeleteProc } = this.state;
    return (
      <div>
        {this.state.loadSpinner ? (
          <div className="share-spinner-wrapper">
            <Spinner name="line-scale-pulse-out-rapid" color="#315B7B" fadeIn="none" />
          </div>
        ) : (
          <div className="add-ticket-grid-style">
            <div className="top-div-buttons">
              <div id="grid-container-fullwidth">
                <div className="grid-item">
                  <button
                    id="new-addticket-button"
                    className="generic-button-primary "
                    onClick={() => {
                      this.createTicketConfirm();
                    }}
                  >
                    {' '}
                    Send{' '}
                  </button>
                  {/* <button
                    id='new-addticket-button'
                    className='generic-button-white addticket-margin-right'
                    onClick={() => {
                      this.Exit();
                    }}
                  >
                    {" "}
                    Cancel
                  </button> */}
                </div>

                <div>
                  <div className="grid-item-head grid-item">
                    <label for="To" id="add-to-label" className="add-ticket-label">
                      {' '}
                      To:{' '}
                    </label>
                    <input
                      disabled={true}
                      type="To"
                      className="add-to-input add-ticket-input"
                      id="view-ticket-To"
                      value={this.state.DefaultGroup}
                      name="To"
                      onChange={(e) => {
                        this.setState({ DefaultGroup: e.target.value });
                      }}
                    ></input>
                  </div>

                  <div className="grid-item">
                    <label for="From" id="add-from-label" className="add-ticket-label">
                      {' '}
                      From:{' '}
                    </label>
                    <input
                      disabled={true}
                      type="From"
                      id="add-ticket-From"
                      className="add-ticket-input"
                      value={this.state.User}
                      name="From"
                      onChange={(e) => {
                        this.setState({ User: e.target.value });
                      }}
                    ></input>
                  </div>

                  <div className="grid-item">
                    <label for="Re" id="add-re-label2" className="add-ticket-label">
                      {' '}
                      Re:{' '}
                    </label>
                    <div className="createticket-select-drop">
                      <Select
                        type="Procedure"
                        id="currency-input"
                        isDisabled={procedureDisabled}
                        options={this.state.workProcedures}
                        className="ticket-procedure-select"
                        value={{ value: this.state.Procedure, label: this.state.Procedure }}
                        onChange={(e) => {
                          if (e.value && e.value === accountDeleteProc) {
                            let title = AccountName ? `Request to Delete Account ${AccountName.substring(0, 50)}` : Title;
                            this.setState({ Procedure: e.value, Title: title });
                          } else {
                            this.setState({ Procedure: e.value, Title: '' });
                          }
                        }}
                        clearable={false}
                      >
                        {' '}
                      </Select>
                      <div className="question-wrapper">
                        <FontAwesome
                          id="ticket-help"
                          style={{ cursor: 'pointer' }}
                          className="fa fas fa-question-circle"
                          onClick={(e) => {
                            this.props.disableCurrentModalDraggable();
                            this.setState({ ProcedureDescriptionModal: true });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
                {!this.state.procedureOptions && (
                  <div className='grid-item'>
                    <label for='Re' id='add-re-label' className='add-ticket-label'>
                      {" "}
                      Re:{" "}
                    </label>
                    <input
                      disabled={true}
                      type='Re'
                      id='add-ticket-re'
                      placeholder={this.state.Procedure}
                      name='re'
                      className='add-ticket-input'
                      onChange={e => {
                        this.setState({ Procedure: e.target.value });
                      }}
                    ></input>
                    <FontAwesome
                      id='ticket-help'
                      style={{ cursor: "pointer" }}
                      className='fa fas fa-question-circle'
                      onClick={e => {
                        this.setState({ ProcedureDescriptionModal: true });
                      }}
                    />
                  </div>
                )}

                {this.state.procedureOptions && (
                  <div className='grid-item'>
                    <label for='Re' id='add-re-label' className='add-ticket-label'>
                      {" "}
                      Re:{" "}
                    </label>
                    <select
                      type='Re'
                      id='add-ticket-re'
                      placeholder={this.state.Procedure}
                      name='re'
                      className='add-ticket-input'
                      onChange={e => {
                        this.setState({ Procedure: e.target.value });
                      }}
                    >
                      {this.procedureOptions()}
                    </select>
                    <FontAwesome
                      id='ticket-help'
                      style={{ cursor: "pointer" }}
                      className='fa fas fa-question-circle'
                      onClick={e => {
                        this.setState({ ProcedureDescriptionModal: true });
                      }}
                    />
                  </div>
                )} */}

                {(AccountName || LastBusinessDate) && (
                  <div className="grid-item">
                    <label for="blank" id="add-blank-label">
                      {' '}
                    </label>
                    {/* <input disabled={true} className='add-ticket-input' type='blank' id='add-ticket-blank' value={AccountInfo} placeholder={this.accountPlaceholder()} name='Due'></input> */}
                    <input disabled={true} className="add-ticket-input" type="blank" id="add-ticket-blank" value={AccountInfo} name="Due"></input>
                  </div>
                )}

                <div className="grid-item">
                  <label for="Type" id="add-type-label" className="add-ticket-label">
                    Type:{' '}
                  </label>
                  <div className="createticket-select-drop">
                    <Select
                      isDisabled={true}
                      type="Type"
                      id="add-type-select-r"
                      options={this.state.types}
                      value={{ value: 'Request', label: 'Request' }}
                      className="ticket-procedure-select"
                      onChange={(e) => {
                        this.setCategorySearch(e);
                      }}
                    ></Select>
                  </div>
                </div>

                <div className="grid-item">
                  <label for="title" id="add-re-label" className="add-ticket-label">
                    {' '}
                    Title:
                  </label>
                  <input
                    disabled={Procedure === accountDeleteProc}
                    type="title"
                    id="add-ticket-title"
                    placeholder={this.state.Title}
                    name="title"
                    maxLength={50}
                    autoComplete="off"
                    onChange={(e) => {
                      if (e) {
                        this.setState({ Title: e.target.value, TitleLength: e.target.value.length });
                      } else {
                        this.setState({ Title: '', TitleLength: 0 });
                      }
                    }}
                    value={Title}
                    className={showRequired(checkRequired, this.state, 'Title') ? 'req add-ticket-input add-ticket-title' : 'add-ticket-input add-ticket-title'}
                  ></input>
                  {Procedure !== accountDeleteProc && (
                    <div className="ticket-remaining-char">
                      <label for="title" id="add-re-label" className="add-ticket-label">
                        {Title && '\u00A0'}
                      </label>
                      {Title && this.getTitleRemaining()}
                    </div>
                  )}
                </div>
                <div className="grid-item">
                  <label for="message" id="add-message-label" className="add-ticket-label">
                    Desc:{' '}
                  </label>
                  <textarea
                    type="message"
                    id="view-ticket-message2"
                    placeholder="Please Describe Your Request"
                    name="message"
                    onChange={(e) => {
                      this.setState({ Message: e.target.value });
                    }}
                    className={showRequired(checkRequired, this.state, 'Message') ? 'req add-ticket-input' : 'add-ticket-input'}
                  ></textarea>
                </div>

                <div className="grid-message-box-add-createdby">
                  <label for="message">
                    <b>Created By:</b> {this.props.user.Email}{' '}
                  </label>
                </div>
              </div>
              {this.state.ProcedureDescriptionModal && (
                <Modal isOpen={this.state.ProcedureDescriptionModal} style={styleOverridesProcedure}>
                  <Draggable handle=".handle">
                    <div className="draggable-wrapper">
                      <div className="fullmodal handle">
                        <div className="fullmodal_title">
                          <div className="fullmodal_title_add">{this.state.Procedure}</div>
                        </div>

                        <div
                          className="sidemodal_addnew_x"
                          onClick={() => {
                            {
                              this.props.enableCurrentModalDraggable();
                              this.setState({ ProcedureDescriptionModal: false });
                            }
                          }}
                        >
                          <FontAwesome name="xbutton" className="fa-times" />
                        </div>
                      </div>
                      <div className="search-modal-container question-modal">
                        <DescriptionNote user={this.props.user} services={this.props.services} ProcedureDescription={this.state.ProcedureDescription} Procedure={this.state.Procedure} />
                      </div>
                      {/* {this.renderAccountDetails()} */}
                    </div>
                  </Draggable>
                </Modal>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(AddTicket);
