import * as dataService from '../Utils/DataService';
import React, { Component } from 'react';
import Select from 'react-select';
import ReactTable from 'react-table';
import Modal from 'react-modal';
import Contact from './Contact';
import { withRouter } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactTooltip from 'react-tooltip';

import * as validationManager from '../Utils/ValidationManager';
import * as RequestCustodianModels from './RequestCustodianModels';
import * as DropdownModels from './DropdownModels';
import * as globals from '../../Globals/Variables';
import { showRequired, hasRequired, getRequiredAsterisk } from '../Utils/RequiredFields';
import { validateEmail } from '../Utils/HelperFunctions';

var debounce = require('lodash/debounce');
var FontAwesome = require('react-fontawesome');
const Spinner = require('react-spinkit');
const modalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1111',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    top: '8%',
    left: '15%',
    height: '80%',
    width: '75%',
  },
};
let AllRequiredFields = ['firstName', 'custType', 'lastName', 'status', 'address', 'country', 'custodianName', 'email', 'phone'];

//Check componentDidMount() && setRequiredFields() for additional required field processing
// let requiredFields = ["custodianName", "contactFirstName", "contactLastName", "phone", "email", "address", "city", "state", "country", "postal"];
// const default_requiredFields = ["custodianName", "contactFirstName", "contactLastName", "email", "phone"];

function getINITIAL_STATE(that) {
  return {
    data: [],
    contactData: [],
    countries: [],
    states: [],
    custodianTypes: [],
    contactTypes: [],
    workAddressTypeId: null,
    showRequired: false,
    contactModalOpen: false,
    standalone: false,
    // loading: true,
    loadSpinner: false,
    throwFundToast: false,
    requiredFields: ['custodianName', 'contactFirstName', 'contactLastName', 'email', 'phone'],
  };
}

class RequestCustodian extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
  }

  componentDidMount() {
    // if(this.props.match.url.indexOf('requestcustodian') !== -1) {
    //   this.setState({standalone: true})
    //   //Would get the custodian -> custodian contact now to prefil fields from URL cust id
    // }
    let { requestFor, accountData } = this.props;
    if (requestFor === 'fund') {
      this.setState({
        custodianName: accountData.CustodianData ? accountData.CustodianData.CustodianName : null,
        contactFirstName: accountData.CustodianContact && accountData.CustodianContact.data ? accountData.CustodianContact.data.FirstName : null,
        contactLastName: accountData.CustodianContact && accountData.CustodianContact.data ? accountData.CustodianContact.data.LastName : null,
        phone: accountData.CustodianContact && accountData.CustodianContact.data ? accountData.CustodianContact.data.Phone : null,
        email: accountData.CustodianContact && accountData.CustodianContact.data ? accountData.CustodianContact.data.Email : null,
        address: accountData.CustodianContact && accountData.CustodianContact.data ? accountData.CustodianContact.data.Address : null,
        city: accountData.CustodianContact && accountData.CustodianContact.data ? accountData.CustodianContact.data.City : null,
        postal: accountData.CustodianContact && accountData.CustodianContact.data ? accountData.CustodianContact.data.Postal : null,
        throwFundToast: true,
        fundName: '',
        // city, state, fundType, are set on fetch of data
      });
    }

    this.setState({ type: this.props.type });
    this.getStates();
    this.getCountries();
    this.getCustodianTypes();
    this.getFundTypes();
    this.getStatus();
    this.getAddressTypes();
    this.getContactTypes();
    this.getContactStatus();
    this.setRequiredFields();
  }

  //When coming in fgrom url use this to prepopulate
  async getCustodian(custodianId) {
    let { user, services } = this.props;

    let [custodian, custodiancontacts] = await Promise.all([
      RequestCustodianModels.getCustodianById({
        user,
        services,
        custodianId,
      }),
      RequestCustodianModels.getCustodianContacts({
        user,
        services,
        custodianId,
        firmId: user.FirmId,
      }),
    ]);

    let state = Object.assign({}, this.state);
    state.custodianName = custodian.Name;
    state.custType = { label: '', value: custodian.TypeID };
    state.status = { label: '', value: custodian.StatusID };
    if (custodian.EsignatureFlag) {
      state.esign = { label: 'Accepted', value: true };
    } else {
      state.esign = { label: 'Not Accepted', value: false };
    }
    if (custodian.VerifiedFlag) {
      state.verified = { label: 'Verified', value: true };
    } else {
      state.verified = { label: 'Not Verified', value: false };
    }
    this.setState(state, () => {
      this.getStates();
      this.getCountries();
      this.getCustodianTypes();
      this.getStatus();
      this.getAddressTypes();
      this.getContactTypes();
      this.getContactStatus();
      this.setState({ loadSpinner: false });
    });
    let temp = [];
    temp = custodiancontacts.map((cont, index) => {
      //For grid
      return {
        DetailID: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0].DetailID : null,
        contactname: cont.CONTACT.NameAlias,
        address: this.formatAddress(cont),
        phone: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0].Phone : null,
        email: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0].Email : null,
        data: cont,
      };
    });
    this.setState({ contactData: temp, contactLoading: false });
  }

  async getAddressTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getAddressTypes({
      user,
      services,
    });
    let temp = [];
    if (types) {
      temp = types.map((type) => {
        if (type.AddressType === 'Work') {
          this.setState({ workAddressTypeId: type.AddressTypeID });
        }
        return {
          label: type.AddressType,
          value: type.AddressTypeID,
        };
      });
    }
    this.setState({ addressTypes: temp });
  }

  async getFundTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getFundTypes({
      user,
      services,
    });
    let temp = [];
    temp = types.map((type) => {
      if (this.props.accountData.Fund && type.FundType === this.props.accountData.Fund.value) {
        this.setState({ fund: { label: type.Name, value: type.FundType } });
      }
      return {
        label: type.Name,
        value: type.FundType,
      };
    });
    this.setState({ fundTypes: temp });
  }

  async getStates() {
    let { user, services } = this.props;
    let states = await DropdownModels.getStates({
      user,
      services,
    });
    let temp = [];
    temp = states.map((state) => {
      if (this.props.accountData.CustodianContact && this.props.accountData.CustodianContact.data && state.StateID === this.props.accountData.CustodianContact.data.StateID) {
        this.setState({ state: { label: state.Name, value: state.StateID } });
      }
      return {
        label: state.Name,
        value: state.StateID,
      };
    });
    this.setState({ states: temp });
  }

  async getCountries() {
    let { user, services } = this.props;
    let countries = await DropdownModels.getCountries({
      user,
      services,
    });
    let temp = [];
    temp = countries.map((country) => {
      if (this.props.accountData.CustodianContact && this.props.accountData.CustodianContact.data && country.Country1 === this.props.accountData.CustodianContact.data.Country) {
        this.setState({ country: { label: country.Name, value: country.Country1 } });
      }
      return {
        label: country.Name,
        value: country.Country1,
      };
    });
    this.setState({ countries: temp });
  }

  async getCustodianTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getCustodianTypes({
      user,
      services,
    });
    let temp = [];
    let { requestFor } = this.props;
    temp = types.map((type) => {
      if (this.state.custType && this.state.custType.value === type.TypeID) {
        this.setState({ custType: { label: type.Name, value: type.TypeID } });
      }
      if (requestFor === 'custodian' && type.Name === 'Custodian') {
        this.setState({ custodianTypeId: type.TypeID });
      }
      if (requestFor === 'manager' && type.Name === 'Manager') {
        this.setState({ custodianTypeId: type.TypeID });
      }
      if (requestFor === 'institution' && type.Name === 'Other') {
        this.setState({ custodianTypeId: type.TypeID });
      }
      return {
        label: type.Name,
        value: type.TypeID,
      };
    });
    this.setState({ custodianTypes: temp });
  }
  async getContactTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getContactTypes({
      user,
      services,
    });
    let temp = [];
    temp = types.map((type) => {
      if (type.ContactType === 'Company') {
        this.setState({ companyContactTypeID: type.ContactTypeID });
      }
      return {
        label: type.ContactType,
        value: type.ContactTypeID,
      };
    });
    // this.setState({contactTypes: temp});
  }
  async getStatus() {
    let { user, services } = this.props;
    let statuss = await DropdownModels.getCustodianStatus({
      user,
      services,
    });
    let temp = [];
    temp = statuss.map((status) => {
      if (this.state.status && this.state.status.value === status.StatusID) {
        this.setState({ status: { label: status.Status, value: status.StatusID } });
      }
      if (status.Status === 'Requested') {
        this.setState({ custodianStatusRequestedId: status.StatusID });
      }
      return {
        label: status.Status,
        value: status.StatusID,
      };
    });
    this.setState({ statusList: temp });
  }
  async getContactStatus() {
    let { user, services } = this.props;
    let statuss = await DropdownModels.getContactStatus({
      user,
      services,
    });
    let temp = [];
    temp = statuss.map((status) => {
      if (status.Name === 'Active') {
        this.setState({ activeStatusId: status.Status });
      }
      if (status.Name === 'Requested') {
        this.setState({ contactStatusRequestedId: status.Status });
      }
      return {
        label: status.Name,
        value: status.Status,
      };
    });
  }

  async createCustodianContactLink(custodianId, contactId) {
    let { user, services } = this.props;
    let response = await RequestCustodianModels.createCustodianContactLink({
      user,
      services,
      custodianId,
      contactId,
    });
    if (!response.error) {
      return true;
    } else {
      return false;
    }
  }

  async createCustodian() {
    let { user, services, requestFor } = this.props;
    let { custodianName, custodianTypeId, custodianStatusRequestedId } = this.state;
    let CustodianBody = {
      FirmID: user.FirmId,
      Name: custodianName,
      ShortName: custodianName,
      ReferenceType: 'INTERNAL',
      TypeID: custodianTypeId,
      StatusID: custodianStatusRequestedId,
      VerifiedFlag: false,
      EsignatureFlag: true,
      AccountLimit: 20,
      RequireLOAFlag: true,
      SignerLimit: 10,
      Enrollment: true,
      Approver: 'Custodian',
    };
    let response = await RequestCustodianModels.createCustodian({
      user,
      services,
      body: CustodianBody,
    });
    if (!response.error) {
      return response.body.CustodianID;
    } else {
      this.setState({ loadSpinner: false });
      return false;
    }
  }

  async createContact() {
    let { user, services } = this.props;
    let ContactBody = {
      FirmID: this.props.user.FirmId,
      ContactTypeID: this.state.companyContactTypeID,
      Status: 'ACTIVE',
      NameAlias: this.state.contactFirstName + ' ' + this.state.contactLastName,
    };
    let response = await RequestCustodianModels.createContact({
      user,
      services,
      body: ContactBody,
    });
    let createdContactID;
    if (!response.error) {
      createdContactID = response.body.ContactID;
      let ContactDetailBody = {
        ContactID: response.body.ContactID,
        Address: this.state.address,
        AddressTypeID: this.state.workAddressTypeId,
        Country: this.state.country ? this.state.country.value : null,
        Email: this.state.email,
        FirstName: this.state.contactFirstName,
        LastName: this.state.contactLastName,
        City: this.state.city,
        Postal: this.state.postal,
        Phone: this.state.phone,
        StateID: this.state.state ? this.state.state.value : null,
        AutoSignatureFlag: false,
      };
      let detailResponse = await RequestCustodianModels.createContactDetail({
        user,
        services,
        body: ContactDetailBody,
      });
      if (!detailResponse.error) {
        return { ContactID: createdContactID, DetailID: detailResponse.body.DetailID };
      } else {
        this.setState({ loadSpinner: false });
        return null;
      }
    }
  }

  async createFund(CustodianID) {
    let { user, services } = this.props;
    let { fundName, fundType } = this.state;
    let FundBody = {
      FirmID: user.FirmId,
      ChangeType: 'I',
      ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
      Name: fundName,
      ShortName: fundName,
      IngestTag: fundName,
      Manager_UID: CustodianID,
      FundType: fundType ? fundType.value : null,
      Scope: 'Public',
      ReferenceType: 'INTERNAL',
    };
    let response = await RequestCustodianModels.createFund({
      user,
      services,
      body: FundBody,
    });
    if (!response.error) {
      return response.body.Fund_UID;
    } else {
      this.setState({ loadSpinner: false });
      return null;
    }
  }

  //Save and update data so when go back we're good
  async onSaveClick() {
    let CustodianID, ContactObj, ContactID, DetailID, Fund_UID, CustodianContactLinkSuccess;
    let { requestFor } = this.props;
    let { requiredFields, throwFundToast } = this.state;

    //If an email was provided. Validate format before proceeding
    if (this.state.email) {
      //Trim any leading or trailing whitespace
      if (!validateEmail(this.state.email)) {
        confirmAlert({
          title: <div className="unsaved-changes-header">Error</div>,
          childrenElement: () => {
            return (
              <div className="unsaved-changes-div">
                <div>Please provide a valid email address.</div>
              </div>
            );
          },
          buttons: [{ label: <div id="continue-confirm-button">Continue</div>, onClick: () => {} }],
        });
        return;
      }
    }

    if (hasRequired(this.state, requiredFields, true)) {
      this.setState({ loadSpinner: true });
      switch (requestFor) {
        case 'custodian':
          //Create custodian, contact, contact detail, link all
          try {
            CustodianID = await this.createCustodian();
            if (!CustodianID) {
              return;
            }

            ContactObj = await this.createContact(); //Creates and links contact and contact detail
            if (!ContactObj) {
              return;
            }

            ContactID = ContactObj.ContactID;
            DetailID = ContactObj.DetailID;

            CustodianContactLinkSuccess = await this.createCustodianContactLink(CustodianID, ContactID);
            if (CustodianContactLinkSuccess) {
              let obj = Object.assign({}, this.props.accountData);
              obj.CustodianID = CustodianID;
              obj.CustodianName = this.state.custodianName;
              obj.CustodianContact = {
                label: this.state.contactFirstName + ' ' + this.state.contactLastName,
                value: ContactID,
                DetailID,
              };
              obj.CustodianData = {
                Name: this.state.custodianName,
                CUSTODIAN_STATUS: {
                  Status: 'Requested',
                },
              };
              this.props.setValidCustodian(true);
              this.props.updateAccountData(obj);
              this.props.setForceUpdate(true);
              this.props.closeModal();
              this.props.setCurrentScreen('account');
              this.requestReceivedMsg();
            }
          } catch (error) {
            validationManager.toastMessage(false, 'Error Creating');
            this.setState({ loadSpinner: false });
          }
          break;
        case 'manager':
          //Create custodian, contact, contact detail, fund link all
          try {
            CustodianID = await this.createCustodian();
            if (!CustodianID) {
              return;
            }

            ContactObj = await this.createContact(); //Creates and links contact and contact detail
            if (!ContactObj) {
              return;
            }

            ContactID = ContactObj.ContactID;
            DetailID = ContactObj.DetailID;
            Fund_UID = await this.createFund(CustodianID);
            if (!CustodianID) {
              return;
            }

            CustodianContactLinkSuccess = await this.createCustodianContactLink(CustodianID, ContactID);
            if (CustodianContactLinkSuccess) {
              let obj = Object.assign({}, this.props.accountData);
              obj.CustodianID = CustodianID;
              obj.CustodianName = this.state.custodianName;
              obj.CustodianData = {
                Name: this.state.custodianName,
                CUSTODIAN_STATUS: {
                  Status: 'Requested',
                },
              };
              obj.CustodianContact = {
                label: this.state.contactFirstName + ' ' + this.state.contactLastName,
                value: ContactID,
                DetailID,
              };
              this.props.setValidCustodian(true);
              this.props.updateAccountData(obj);
              this.props.closeModal();
              this.props.setForceUpdate(true);
              this.props.setCurrentScreen('account');
              this.requestReceivedMsg();
            }
          } catch (error) {
            validationManager.toastMessage(false, 'Error Creating');
            this.setState({ loadSpinner: false });
          }
          break;
        case 'institution':
          //Create custodian, contact, contact detail, fund link all
          try {
            CustodianID = await this.createCustodian();
            if (!CustodianID) {
              return;
            }

            ContactObj = await this.createContact(); //Creates and links contact and contact detail
            if (!ContactObj) {
              return;
            }

            ContactID = ContactObj.ContactID;
            DetailID = ContactObj.DetailID;
            if (!CustodianID) {
              return;
            }

            CustodianContactLinkSuccess = await this.createCustodianContactLink(CustodianID, ContactID);
            if (CustodianContactLinkSuccess) {
              let obj = Object.assign({}, this.props.accountData);
              obj.CustodianID = CustodianID;
              obj.CustodianName = this.state.custodianName;
              obj.CustodianData = {
                Name: this.state.custodianName,
                CUSTODIAN_STATUS: {
                  Status: 'Requested',
                },
              };
              obj.CustodianContact = {
                label: this.state.contactFirstName + ' ' + this.state.contactLastName,
                value: ContactID,
                DetailID,
              };
              this.props.setValidCustodian(true);
              this.props.updateAccountData(obj);
              this.props.closeModal();
              this.props.setForceUpdate(true);
              this.props.setCurrentScreen('account');
              this.requestReceivedMsg();
            }
          } catch (error) {
            validationManager.toastMessage(false, 'Error Creating');
            this.setState({ loadSpinner: false });
          }
          break;
        case 'fund':
          //Create fund, link custodian (Manager_UID)
          try {
            CustodianID = this.props.accountData ? this.props.accountData.CustodianID : null;
            Fund_UID = await this.createFund(CustodianID);
            if (Fund_UID) {
              let obj = Object.assign({}, this.props.accountData);
              obj.Fund = {
                label: this.state.fundName,
                value: Fund_UID,
              };
              this.props.updateAccountData(obj);
              this.props.setForceUpdate(true);
              this.props.closeModal();
              this.props.setCurrentScreen('account');
              this.requestReceivedMsg();
            }
            if (throwFundToast) {
              validationManager.toastMessage(true, 'Fund Successfully Created');
            }
          } catch (error) {
            validationManager.toastMessage(false, 'Error Creating');
            this.setState({ loadSpinner: false });
          }
          break;
        default:
          break;
      }
    } else {
      //Doesnt have required fields
      this.setState({ checkRequired: true });
    }
  }

  async onPatchCustodianClick() {
    let { user, services, custodianId } = this.props;

    //If an email was provided. Validate format before proceeding
    if (this.state.email) {
      //Trim any leading or trailing whitespace
      if (!validateEmail(this.state.email)) {
        confirmAlert({
          title: <div className="unsaved-changes-header">Error</div>,
          childrenElement: () => {
            return (
              <div className="unsaved-changes-div">
                <div>Please provide a valid email address.</div>
              </div>
            );
          },
          buttons: [{ label: <div id="continue-confirm-button">Continue</div>, onClick: () => {} }],
        });
        return;
      }
    }

    let custodianBody = {
      FirmID: user.FirmId,
      Name: this.state.custodianName,
      TypeID: this.state.custType.value,
      StatusID: this.state.status.value,
      VerifiedFlag: this.state.verified.value,
      EsignatureFlag: this.state.esign.value,
      AccountLimit: 20,
      RequireLOAFlag: true,
      SignerLimit: 10,
    };
    let custodianResponse = await RequestCustodianModels.patchCustodian({
      user,
      services,
      body: custodianBody,
      custodianId,
    });
    this.props.refreshGrid();
  }

  requestReceivedMsg = () => {
    confirmAlert({
      title: <div className="unsaved-changes-header">Request Received</div>,
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div>Your request has been received. Once approved you will be notified by e-mail and can then proceed with issuing the LOA to print or send via DocuSign.</div>
          </div>
        );
      },
      buttons: [
        {
          label: <div id="continue-confirm-button">OK</div>,
          id: 'continue-confirm-button',
          onClick: () => {},
        },
      ],
    });
  };

  setRequiredFields = () => {
    let { requestFor } = this.props;
    let requiredFields = this.state.requiredFields;

    if (requestFor === 'manager') {
      requiredFields.push('fundName');
      requiredFields.push('fundType');
    } else if (requestFor === 'fund') {
      requiredFields = ['fundName', 'fundType'];
    }
    this.setState({ requiredFields });
  };

  onCancelClick = () => {
    this.props.closeModal();
  };

  getSubtitle = () => {
    let { requestFor } = this.props;
    let subtitle;
    if (requestFor === 'custodian') {
      subtitle = 'If you did not find a custodian, institution, or manager, enter the required information to continue with enrollment.';
    } else if (requestFor === 'manager') {
      subtitle = 'If you did not find a custodian, institution, or manager, enter the required information to continue with enrollment.';
    } else if (requestFor === 'institution') {
      subtitle = 'If you did not find a custodian, institution, or manager, enter the required information to continue with enrollment.';
    } else if (requestFor === 'fund') {
      subtitle =
        'If you were unable to identify the fund, you can request we investigate.   Provide the name of the fund and any other information that may be of help and then continue with your enrollment.';
    }
    return subtitle;
  };

  getTitle = () => {
    let { requestFor } = this.props;
    let title;
    if (requestFor === 'custodian') {
      title = 'Request New Custodian';
    } else if (requestFor === 'manager') {
      title = 'Request New Manager';
    } else if (requestFor === 'institution') {
      title = 'Request New Institution';
    } else if (requestFor === 'fund') {
      title = 'Request New Fund';
    }
    return title;
  };

  handleRequired = (checkRequired, state, name) => {
    if (this.state.requiredFields.includes(name)) {
      return showRequired(checkRequired, state, name);
    } else {
      return false;
    }
  };

  render() {
    let { checkRequired, type } = this.state;
    let { requestFor } = this.props;
    let title = this.getTitle();
    let subtitle = this.getSubtitle();
    let disabled = this.state.loadSpinner || this.state.type === 'view';
    let fundEnabled = requestFor === 'fund';
    let viewOnlyMode = disabled || fundEnabled;
    return (
      <div className="custodian-modal-container">
        <div className="header-accounts">
          <div className="account-title">
            {/* <i title="Back to Enroll" className="fas fa-arrow-left" onClick={this.state.type === 'add' ? this.onCancelClick : this.props.closeViewModal}></i> */}
            {/* {this.props.isModal ? null : title } */}
          </div>
          <div className="button-div">
            <div
              className="generic-button-secondary"
              id="custodian-cancel-button"
              onClick={() => {
                this.onCancelClick();
              }}
            >
              Cancel
            </div>
            <div
              className="generic-button-primary"
              id="custodian-save-button"
              onClick={() => {
                this.onSaveClick();
              }}
            >
              Save
            </div>
          </div>
        </div>
        <div className="request-subtitle">{this.props.isModal ? null : subtitle}</div>
        <div className="required-field-message-wrapper">
          <div className="required-field-message required-field-message-contact" id="required-field-message">
            {getRequiredAsterisk(true, '', '', '')} Indicates required field
          </div>
        </div>
        <div className="body-custodian">
          {this.state.loadSpinner ? (
            <center>
              <Spinner id="view-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
            </center>
          ) : (
            <div className="add-custodian-info">
              <div className="custodian-info-item span-all-col ga-1-1">
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-cust" place="top" type="info" effect="solid" className="tooltip_custom">
                  <p>Enter full name that appears on account statements</p>
                </ReactTooltip>
                <label className="label" for="accountnumber">
                  {getRequiredAsterisk(true, '', '', viewOnlyMode)}
                  {this.props.requestFor === 'manager' ? 'Fund Manager:' : this.props.requestFor === 'fund' ? 'Manager Name' : 'Custodian/Institution Name:'}
                  {(this.props.requestFor === 'institution' || this.props.requestFor === 'custodian') && <i className="fa fas fa-question-circle" data-tip data-for="view-info-cust"></i>}
                </label>
                <input
                  disabled={disabled || fundEnabled}
                  id="custodian-accountnumber"
                  className={this.handleRequired(checkRequired, this.state, 'custodianName') ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.custodianName}
                  onChange={(e) => {
                    this.setState({ custodianName: e.target.value });
                  }}
                />
              </div>

              {requestFor === 'manager' || requestFor === 'fund' ? (
                <div className="custodian-info-item ga-1-2">
                  <label className="label" for="accountnumber">
                    {getRequiredAsterisk(true, '', '', disabled)}Fund Name:
                  </label>
                  <input
                    disabled={disabled}
                    id="fund-name"
                    className={this.handleRequired(checkRequired, this.state, 'fundName') ? 'account-input req' : 'account-input'}
                    placeholder=""
                    value={this.state.fundName}
                    onChange={(e) => {
                      this.setState({ fundName: e.target.value });
                    }}
                  />
                </div>
              ) : null}

              {requestFor === 'manager' || requestFor === 'fund' ? (
                <div className="custodian-info-item ga-2-2">
                  <label className="label" for="fund-type">
                    {getRequiredAsterisk(true, '', '', disabled)}Fund Type
                  </label>
                  <Select
                    isDisabled={disabled}
                    className={this.handleRequired(checkRequired, this.state, 'fundType') ? 'account-select req' : 'account-select'}
                    id="fund-type"
                    value={this.state.fundType}
                    onChange={(e) => {
                      let val = e ? e.value : null;
                      this.setState({ fundType: e });
                    }}
                    options={this.state.fundTypes}
                  />
                </div>
              ) : null}

              <div className="custodian-info-item ga-1-3">
                <label className="label" for="contact-firstname">
                  {getRequiredAsterisk(true, '', '', viewOnlyMode)}Contact First Name:
                </label>
                <input
                  disabled={disabled || fundEnabled}
                  id="contact-firstname"
                  className={this.handleRequired(checkRequired, this.state, 'contactFirstName') ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.contactFirstName}
                  onChange={(e) => {
                    this.setState({ contactFirstName: e.target.value });
                  }}
                />
              </div>

              <div className="custodian-info-item ga-2-3">
                <label className="label" for="contact-lastname">
                  {getRequiredAsterisk(true, '', '', viewOnlyMode)}Contact Last Name:
                </label>
                <input
                  disabled={disabled || fundEnabled}
                  id="contact-lastname"
                  className={this.handleRequired(checkRequired, this.state, 'contactLastName') ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.contactLastName}
                  onChange={(e) => {
                    this.setState({ contactLastName: e.target.value });
                  }}
                />
              </div>

              <div className="custodian-info-item ga-1-4">
                <label className="label" for="custodian-phone">
                  {getRequiredAsterisk(true, '', '', viewOnlyMode)}Phone: {!viewOnlyMode && <span className="required-field-email-phone">(Required for Print LOA)</span>}
                </label>
                <input
                  disabled={disabled || fundEnabled}
                  id="custodian-phone"
                  className={this.handleRequired(checkRequired, this.state, 'phone') && !this.state.email ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.phone}
                  onChange={(e) => {
                    this.setState({ phone: e.target.value });
                  }}
                />
                <div className="required-or">OR</div>
              </div>

              <div className="custodian-info-item ga-2-4">
                <label className="label" for="custodian-email">
                  {getRequiredAsterisk(true, '', '', viewOnlyMode)}Email: {!viewOnlyMode && <span className="required-field-email-phone">(Required for E-signature/Docusign)</span>}
                </label>
                <input
                  disabled={disabled || fundEnabled}
                  id="custodian-email"
                  className={this.handleRequired(checkRequired, this.state, 'email') && !this.state.phone ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({ email: e.target.value.trim() });
                  }}
                />
              </div>

              <div className="custodian-info-item ga-1-5">
                <label className="label" for="address">
                  {/* {getRequiredAsterisk(true, "", "", viewOnlyMode)} */}
                  Address:
                </label>
                <input
                  disabled={disabled || fundEnabled}
                  id="custodian-address"
                  className={this.handleRequired(checkRequired, this.state, 'address') ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.address}
                  onChange={(e) => {
                    this.setState({ address: e.target.value });
                  }}
                />
              </div>

              <div className="custodian-info-item ga-2-5 ">
                <label className="label" for="province">
                  {/* {getRequiredAsterisk(true, "", "", viewOnlyMode)} */}
                  City
                </label>
                <input
                  disabled={disabled || fundEnabled}
                  id="contact-city"
                  className={this.handleRequired(checkRequired, this.state, 'city') ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.city}
                  onChange={(e) => {
                    this.setState({ city: e.target.value });
                  }}
                />
              </div>

              <div className="custodian-info-item ga-1-6">
                <label className="label" for="state">
                  {/* {getRequiredAsterisk(true, "", "", viewOnlyMode)} */}
                  State
                </label>
                <Select
                  isDisabled={disabled || fundEnabled}
                  className={this.handleRequired(checkRequired, this.state, 'state') ? 'account-select req' : 'account-select'}
                  id="custodian-state"
                  value={this.state.state}
                  onChange={(e) => {
                    let val = e ? e.value : null;
                    this.setState({ state: e });
                  }}
                  options={this.state.states}
                />
              </div>

              <div className="custodian-info-item ga-2-6">
                <label className="label" for="country">
                  {/* {getRequiredAsterisk(true, "", "", viewOnlyMode)} */}
                  Country
                </label>
                <Select
                  className={this.handleRequired(checkRequired, this.state, 'country') ? 'account-select req' : 'account-select'}
                  isDisabled={disabled || fundEnabled}
                  id="custodian-country"
                  value={this.state.country}
                  onChange={(e) => {
                    let val = e ? e.value : null;
                    this.setState({ country: e });
                  }}
                  options={this.state.countries}
                />
              </div>

              <div className="custodian-info-item ga-1-7">
                <label className="label" for="postal">
                  {/* {getRequiredAsterisk(true, "", "", viewOnlyMode)} */}
                  Postal
                </label>
                <input
                  disabled={disabled || fundEnabled}
                  id="custodian-postal"
                  className={this.handleRequired(checkRequired, this.state, 'postal') ? 'account-input req' : 'account-input'}
                  placeholder=""
                  value={this.state.postal}
                  onChange={(e) => {
                    this.setState({ postal: e.target.value });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(RequestCustodian);
