import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Account from './Account';
import Custodian from './Custodian';
import Contact from './Contact';
import RequestCustodian from './RequestCustodian';
import * as AccountModels from './AccountModels';
import * as LOAModels from './LOAModels';
import { getCookie, detokenizeField, checkPolicy } from '../Utils/HelperFunctions';
import * as TokenModels from '../Utils/TokenModels';
import Modal from 'react-modal';
import * as globals from '../../Globals/Variables';
const qs = require('query-string');
const Spinner = require('react-spinkit');
const queryString = require('query-string');

let spinnerModalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1111',
    background: 'rgba(255, 255, 255, 0.2)',
  },
  content: {
    top: '40%',
    left: '12%',
    height: '75%',
    width: '75%',
    border: 'none',
    background: 'transparent',
  },
};
function getINITIAL_STATE(that) {
  let policies = that.props.user.firmPolicies;
  return {
    accountData: {
      ACCOUNT_SIGNER_LINK: [],
    },
    currentScreen: 'account',
    // validCustodian: false,
    myAccountsLoaded: false,
    signerIndex: -1,
    validSigners: [],
    validCustodian: true,
    edit: false,
    forceUpdate: false,
    addSigner: true,
    enrollAccountTaxEntityShow: checkPolicy(policies[globals.UI_ENROLLMENT_TAXENTITY_SHOW]),
    enrollRelationshipIDSplit: checkPolicy(policies[globals.UI_ENROLLMENT_RELATIONSHIPID_SPLIT]),
    expand: `&$expand=CONTACT($expand=CONTACT_DETAIL),LOA_ACCOUNT_LINK,ACCOUNT_LOA_STATUS,CUSTODIAN($expand=CUSTODIAN_STATUS),ACCOUNT_SIGNER_LINK($expand=CONTACT_DETAIL,SIGNER_CAPACITY)`,
  };
}

class Enroll extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
  }

  componentDidMount() {
    if (getCookie('path') === 'enroll' || getCookie('path') === 'external') {
      document.cookie = `accounts_tokenFilter=false;path=/;${globals.SAMESITECOOKIE}`;
      this.setState({ tokenFilter: false });
    }

    if (this.props.match.params.accountid) {
      this.fetchAccountData();
    }

    let acceptableTypes = ['BROKERAGE', 'ALTERNATIVE', 'OTHER'];
  }

  async fetchAccountData() {
    let { user, services } = this.props;
    const { enrollAccountTaxEntityShow, enrollRelationshipIDSplit } = this.state;
    let accountID = this.props.match.params.accountid;
    this.setState({ loading: true, edit: true });
    let accounts = await AccountModels.getAccountData({
      user,
      services,
      accountId: accountID,
    });

    if (accounts[0]) {
      if (accounts[0]['RelationshipID-token']) {
        detokenizeField('TaxEntityID', 'TaxEntityName', accounts[0]['RelationshipID-token'], accounts[0], enrollAccountTaxEntityShow, user, services).then((accounts) => {
          this.setAccountData(accounts);
        });
      } else {
        accounts[0]['TaxEntityID'] = accounts[0]['RelationshipID'];
        detokenizeField('TaxEntityID', 'TaxEntityName', '', accounts[0], enrollAccountTaxEntityShow, user, services).then((accounts) => {
          this.setAccountData(accounts);
        });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  async setAccountData(data) {
    let { user, services } = this.props;
    const { enrollRelationshipIDSplit } = this.state;

    let relationshipID = !data.RelationshipID && this.props.crmData && this.props.crmData.firmRelationshipID ? this.props.crmData.firmRelationshipID : data.RelationshipID;
    let accountCustom1 = data.Account_Custom1;

    if (enrollRelationshipIDSplit) {
      if (relationshipID) {
        if (relationshipID.indexOf('_') !== -1) {
          let relationshipIDArray = relationshipID.split('_');
          accountCustom1 = relationshipIDArray[0];
        }
      }
    }

    let custodianStatus = '';
    if (data.CUSTODIAN) {
      if (data.CUSTODIAN.CUSTODIAN_STATUS) {
        if (data.CUSTODIAN.CUSTODIAN_STATUS.Status) {
          custodianStatus = data.CUSTODIAN.CUSTODIAN_STATUS.Status.toUpperCase();
        }
      }
    }

    let fundStatus = '';
    if (data.FUND) {
      if (data.FUND.Status) {
        fundStatus = data.FUND.Status.toUpperCase();
      }
    }

    let temp = {};
    temp.AccountID = data.AccountID;
    temp.AccountName = data.AccountName;
    temp['AccountName-token'] = data['AccountName-token'];
    temp['AccountTitle-token'] = data['AccountTitle-token'];
    temp['AdvisorID-token'] = data['AdvisorID-token'];
    temp['RelationshipID-token'] = data['RelationshipID-token'];
    temp['TeamID-token'] = data['TeamID-token'];
    temp.AccountTitle = data.AccountTitle;
    temp.AccountTypeID = { label: '', value: data.AccountTypeID };
    temp.Account_PartnerID = data.Account_PartnerID;
    temp.Account_ClientID = data.Account_ClientID;
    temp.Account_InvestmentID = data.Account_InvestmentID;
    temp.Account_Custom1 = accountCustom1;
    temp.Account_Custom2 = data.Account_Custom2;
    temp.Account_Custom3 = data.Account_Custom3;
    temp.Account_Custom4 = data.Account_Custom4;
    temp.Account_Related_Entity_UID = data.Account_Related_Entity_UID ? data.Account_Related_Entity_UID : '';
    temp.ChangeDate = data.ChangeDate;
    temp.TaxEntityName = data.TaxEntityName ? data.TaxEntityName : '';
    temp.TaxEntityID = data.TaxEntityID ? data.TaxEntityID : '';
    // If Custodian Status is Inactive, clear custodian fields on edit, forcing user to select a new active custodian /Manager
    temp.CustodianStatus = custodianStatus;
    temp.CustodianName = custodianStatus === 'INACTIVE' ? null : data.CUSTODIAN ? data.CUSTODIAN.Name : '';
    temp.CustodianID = custodianStatus === 'INACTIVE' ? null : data.CustodianID;
    temp.CustodianData = custodianStatus === 'INACTIVE' ? null : data.CUSTODIAN;
    temp.CustodianContact =
      custodianStatus === 'INACTIVE'
        ? null
        : data.CONTACT
        ? {
            label: data.CONTACT.NameAlias,
            value: data.CONTACT.ContactID,
            DetailID: data.CONTACT.CONTACT_DETAIL[0] ? data.CONTACT.CONTACT_DETAIL[0].DetailID : null,
            data: data.CONTACT.CONTACT_DETAIL[0] ? data.CONTACT.CONTACT_DETAIL[0] : null,
          }
        : null;
    temp.ContactID = custodianStatus === 'INACTIVE' ? null : data.ContactID;
    temp.ContactDetailID = custodianStatus === 'INACTIVE' ? null : data.ContactDetailID;

    temp.Currency1 = data.Currency1;
    temp.Fund_UID = fundStatus === 'INACTIVE' ? null : data.Fund_UID;
    temp.Fund = fundStatus === 'INACTIVE' ? { label: '', value: null } : { label: '', value: data.Fund_UID };
    temp.InvestorName = data.InvestorName;
    temp.InvestorID = data.InvestorID;
    temp.ManagerType = data.ManagerType;
    temp.Status = data.Status;
    temp.StatusObject = { label: data.Status, value: data.Status };
    temp.StatusName = data.ACCOUNT_LOA_STATUS ? data.ACCOUNT_LOA_STATUS.Name : null;
    temp.AdvisorID = !data.AdvisorID && this.props.crmData && this.props.crmData.advisorID ? this.props.crmData.advisorID : data.AdvisorID;
    temp.RelationshipID = relationshipID;
    temp.Account_TeamID = !data.Account_TeamID && this.props.crmData && this.props.crmData.teamID ? this.props.crmData.teamID : data.Account_TeamID;
    temp.LOA = data.LOA;

    let signers = [];
    let validSigners = [];
    if (data.LOA) {
      await this.getLoaStatusOptions(data.LOA.Status);
    }

    for (let i in data.ACCOUNT_SIGNER_LINK) {
      if (data.ACCOUNT_SIGNER_LINK[i].ChangeType !== 'D') {
        //Push to Signers
        signers.push(data.ACCOUNT_SIGNER_LINK[i]);
      }
    }
    for (let i in signers) {
      if (signers[i].CONTACT_DETAIL) {
        //Create Full Name
        signers[i].CONTACT_DETAIL.FullName = signers[i].CONTACT_DETAIL.FirstName + ' ' + signers[i].CONTACT_DETAIL.LastName;
      }
      validSigners.push(parseInt(i));
    }

    this.setValidSigners(validSigners);
    temp.ACCOUNT_SIGNER_LINK = signers;

    let custodianRelated = {};
    if (temp.Account_Related_Entity_UID) {
      custodianRelated = await AccountModels.getCustodianRelated({
        user,
        services,
        id: temp.Account_Related_Entity_UID,
      });
      if (custodianRelated[0]) {
        temp.CustodianRelatedName = custodianRelated[0].Name;
      }
    }
    this.setState(
      {
        accountData: temp,
      },
      () => {
        this.setState({ loading: false, forceUpdate: true });
      }
    );
  }

  async getAccountStatusOptions(currentStatus) {
    let { user, services } = this.props;
    let statusArray = await AccountModels.getAccountStatusOptions({
      user,
      services,
      currentStatus,
    });
    let options = statusArray.map((obj) => {
      return { label: obj.NextStatus, value: obj.NextStatus };
    });

    options = options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    this.setState({ statusOptions: options });
    return;
  }

  async getLoaStatusOptions(currentStatus) {
    let { user, services } = this.props;
    let statusArray = await LOAModels.getLoaStatusOptions({
      user,
      services,
      currentStatus,
    });
    let options = statusArray.map((obj) => {
      return { label: obj.NextStatus, value: obj.NextStatus };
    });

    options = options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    this.setState({ statusOptions: options });
    return;
  }

  updateAccountData = (obj) => {
    return new Promise((resolve, reject) => {
      this.setState({ accountData: obj }, () => {
        resolve();
      });
    });
  };

  setCurrentScreen = (currentScreen, mode) => {
    this.setState({ currentScreen });
  };

  setValidCustodian = (bool) => {
    this.setState({ validCustodian: bool });
  };

  setSignerIndex = (index) => {
    this.setState({ signerIndex: index });
  };

  setValidSigners = (arr) => {
    this.setState({ validSigners: arr });
  };

  setIsEdit = (bool) => {
    this.setState({ edit: bool });
  };

  setForceUpdate = (bool) => {
    return new Promise((resolve, reject) => {
      this.setState({ forceUpdate: bool }, () => {
        resolve();
      });
    });
  };
  setRequestFor = (requestFor) => {
    this.setState(
      { requestFor }
      // () => {this.setState({currentScreen: 'requestcustodian'})}
    );
  };

  render() {
    let { currentScreen } = this.state;
    let contactFor = 'signer';
    if (currentScreen === 'signer') {
      contactFor = 'signer';
    }
    if (currentScreen === 'custodian') {
      contactFor = 'custodian';
    }
    return (
      <div className={this.props.permissionsLoaded && 'enroll-wrapper'}>
        {/* Added this Modal to display Spinner while we gather Permissions and other state variables being passed to Account */}
        <Modal isOpen={!this.props.permissionsLoaded} style={spinnerModalStyle}>
          <center>
            <Spinner id="view-spinner" name="line-scale-pulse-out-rapid" color="gray" />
          </center>
        </Modal>
        <div></div>
        {this.props.permissionsLoaded && currentScreen === 'account' && (
          <Account
            user={this.props.user}
            setMyAccountsLoaded={this.props.setMyAccountsLoaded}
            services={this.props.services}
            setIsEdit={this.setIsEdit}
            edit={this.state.edit || this.props.selectedAccountId ? true : false}
            setSelectedAccount={this.props.setSelectedAccount}
            accountData={this.state.accountData}
            updateAccountData={this.updateAccountData}
            setCurrentScreen={this.setCurrentScreen}
            setValidCustodian={this.setValidCustodian}
            validCustodian={this.state.validCustodian}
            setSignerIndex={this.setSignerIndex}
            signerIndex={this.state.signerIndex}
            setValidSigners={this.setValidSigners}
            validSigners={this.state.validSigners}
            forceUpdate={this.state.forceUpdate}
            setForceUpdate={this.setForceUpdate}
            setAccountData={this.setAccountData.bind(this)}
            fetchingData={this.state.loading}
            setRequestFor={this.setRequestFor}
            requestFor={this.state.requestFor}
            statusOptions={this.state.statusOptions}
            fetchAccountData={this.fetchAccountData.bind(this)}
            getLoaStatusOptions={this.getLoaStatusOptions.bind(this)}
            localPermissions={this.props.localPermissions}
            permissionsLoaded={this.props.permissionsLoaded}
            queryParams={this.props.location.search}
            crmData={this.props.crmData}
          />
        )}

        {currentScreen === 'addcustodian' && (
          <Custodian
            user={this.props.user}
            services={this.props.services}
            type="add"
            accountData={this.state.accountData}
            setCurrentScreen={this.setCurrentScreen}
            updateAccountData={this.updateAccountData}
            setValidCustodian={this.setValidCustodian}
            crmData={this.props.crmData}
          />
        )}

        {(currentScreen === 'signer' || currentScreen === 'custodian') && (
          <Contact
            user={this.props.user}
            services={this.props.services}
            addSigner={this.state.addSigner}
            type="add"
            contactFor={currentScreen}
            custodianId={this.state.accountData ? this.state.accountData.CustodianID : null}
            updateAccountData={this.updateAccountData}
            setCurrentScreen={this.setCurrentScreen}
            signerIndex={this.state.signerIndex}
            setValidSigners={this.setValidSigners}
            validSigners={this.state.validSigners}
            accountData={this.state.accountData}
            setForceUpdate={this.setForceUpdate}
            crmData={this.props.crmData}
          />
        )}

        {currentScreen === 'requestcustodian' && (
          <RequestCustodian
            user={this.props.user}
            services={this.props.services}
            accountData={this.state.accountData}
            setCurrentScreen={this.setCurrentScreen}
            updateAccountData={this.updateAccountData}
            requestFor={this.state.requestFor}
            setValidCustodian={this.setValidCustodian}
            setForceUpdate={this.setForceUpdate}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Enroll);
