import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router';
import * as ContactModels from './ContactModels';
import * as DropdownModels from './DropdownModels';
import { showRequired, hasRequired, getRequiredAsterisk } from '../Utils/RequiredFields';
import { confirmAlert } from 'react-confirm-alert';
import { nullOrFalse, setPolicyBoolean, checkPolicy, validateEmail } from '../Utils/HelperFunctions';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as globals from '../../Globals/Variables';
import * as validationManager from '../Utils/ValidationManager';
const FontAwesome = require('react-fontawesome');

const Spinner = require('react-spinkit');

function getINITIAL_STATE(that) {
  let policies = that.props.user.firmPolicies;
  return {
    countries: [],
    states: [],
    loadSpinner: false,
    showAdditionalInformation: true,
    enrollSignerIDShow: checkPolicy(policies[globals.UI_ENROLLMENT_SIGNERID_SHOW]),
    enrollSignerIDEdit: checkPolicy(policies[globals.UI_ENROLLMENT_SIGNERID_EDIT]),
    enrollSignerIDLabel: checkPolicy(policies[globals.UI_ENROLLMENT_SIGNERID_LABEL]),
    enrollSignerIDRequired: checkPolicy(policies[globals.UI_ENROLLMENT_SIGNERID_REQUIRED]),
    type: 'add',
  };
}
let EmailEditPolicy = true;

let requiredFields = ['firstName', 'lastName', 'email', 'phone'];

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
  }
  componentWillMount() {
    if (this.props.contactFor === 'custodian' || this.props.type === 'add') {
      this.setState({ showAdditionalInformation: false });
    }
  }
  async componentDidMount() {
    let { contactFor, user, setCreateCustodianName } = this.props;
    let { enrollSignerIDRequired } = this.state;
    requiredFields = ['firstName', 'lastName', 'email', 'phone'];
    if (enrollSignerIDRequired && contactFor === 'signer') {
      requiredFields.push('clientID');
    }
    let company = setCreateCustodianName ? (this.props.accountData.CustodianData ? this.props.accountData.CustodianData.CustodianName : '') : '';

    EmailEditPolicy = await this.checkEmailEdit(contactFor, user.firmPolicies[globals.UI_ENROLLMENT_SIGNER_EMAIL_EDIT]);

    this.setState({
      type: this.props.type,
      standalone: this.props.standalone || this.props.match.params.id,
      contactFor: this.props.contactFor || this.props.match.params.contactFor,
      company,
    });
    if (this.props.type === 'view' || this.props.type === 'edit') {
      this.getContactDetail(this.props.contactDetailId);
    } else if (this.props.match.params.id) {
      this.setState({ type: 'edit' });
      this.getContactDetail(this.props.match.params.id);
    } else {
      this.setState({ type: 'add' });
      this.getStates();
      this.getCountries();
      this.getTypes();
      this.getContactTypes();
      this.getContactStatus();
    }
  }

  async getContactDetail(contactDetailId) {
    let { user, services } = this.props;
    let response = await ContactModels.getContactDetail({
      user,
      services,
      contactDetailId,
    });

    if (!response.error) {
      let body = response.body ? response.body : null;
      if (body) {
        let state = Object.assign({}, this.state);
        state.address = body.Address;
        state.addressTwo = body.Address2;
        state.addressType = { label: '', value: body.AddressTypeID };
        state.city = body.City;
        state.company = body.Company;
        state.contactID = body.ContactID;
        state.country = { label: '', value: body.Country };
        state.detailID = body.DetailID;
        state.email = body.Email;
        state.fax = body.Fax;
        state.firstName = body.FirstName;
        state.jobTitle = body.Title;
        state.lastName = body.LastName;
        state.mobile = body.Mobile;
        state.phone = body.Phone;
        state.postal = body.Postal;
        state.province = body.Province;
        state.signature = body.Signature ? `data:image/jpeg;base64,${body.Signature}` : null;
        state.state = { label: '', value: body.StateID };
        state.useAutoSign = body.AutoSignatureFlag;

        let payload = await ContactModels.getContact({ user, services, contactId: body.ContactID });
        if (!payload.error) {
          state.clientID = payload.body.ClientID;
          payload = nullOrFalse(payload.body.External);
          this.setState({ External: payload });
        }

        this.setState(state, () => {
          this.getStates();
          this.getCountries();
          this.getTypes();
          this.getContactTypes();
          this.getContactStatus();
          this.setState({ loadSpinner: false });
        });
      }
    }
  }

  async getStates() {
    let { user, services } = this.props;
    let states = await DropdownModels.getStates({
      user,
      services,
    });
    let temp = states.map((state) => {
      if (this.state.state && state.StateID === this.state.state.value) {
        this.setState({ state: { label: state.Name, value: state.StateID } });
      }
      return {
        label: state.Name,
        value: state.StateID,
      };
    });
    this.setState({ states: temp });
  }

  async getCountries() {
    let { user, services } = this.props;
    let countries = await DropdownModels.getCountries({
      user,
      services,
    });
    let temp = countries.map((country) => {
      if (this.state.country && country.Country1 === this.state.country.value) {
        this.setState({ country: { label: country.Name, value: country.Country1 } });
      }
      return {
        label: country.Name,
        value: country.Country1,
      };
    });
    this.setState({ countries: temp });
  }

  async getTypes() {
    let { user, services } = this.props;
    let types = await DropdownModels.getAddressTypes({
      user,
      services,
    });
    let temp = types.map((type) => {
      if (this.state.addressType && type.AddressTypeID === this.state.addressType.value) {
        this.setState({ addressType: { label: type.AddressType, value: type.AddressTypeID } });
      }
      return {
        label: type.AddressType,
        value: type.AddressTypeID,
      };
    });
    this.setState({ types: temp });
  }
  async getContactTypes() {
    let { user, services } = this.props;
    let companyType = '';
    let types = await DropdownModels.getContactTypes({
      user,
      services,
    });
    let temp = types.map((type) => {
      if (type.ContactType === 'Individual') {
        this.setState({ individualContactTypeID: type.ContactTypeID });
      }
      if (type.ContactType === 'Company') {
        companyType = type.ContactTypeID;
        this.setState({ companyContactTypeID: type.ContactTypeID });
      }
      return {
        label: type.ContactType,
        value: type.ContactTypeID,
      };
    });
    let contacts = await ContactModels.getContactsByContactTypeId({
      user,
      services,
      contactTypeId: companyType,
    });
    let companies = contacts.map((contact) => {
      return {
        label: contact.NameAlias,
        value: contact.ContactID,
      };
    });
    this.setState({ companies });
  }

  async getContactStatus() {
    let { user, services } = this.props;
    let statuss = await DropdownModels.getContactStatus({
      user,
      services,
    });
    let temp = statuss.map((status) => {
      if (status.Name === 'Active') {
        this.setState({ activeStatusId: status.Status });
      }
      return {
        label: status.Name,
        value: status.Status,
      };
    });
  }

  populateSignerForm = (contactid, detailid, add) => {
    let { accountData } = this.props;
    let obj = Object.assign({}, this.props.accountData);
    let index = parseInt(this.props.signerIndex);
    let inExisting = false;
    for (let i in accountData.ACCOUNT_SIGNER_LINK) {
      if (accountData.ACCOUNT_SIGNER_LINK[i].ContactID === contactid) {
        index = parseInt(i);
        inExisting = true;
      }
    }

    if (add || inExisting) {
      if (!obj.ACCOUNT_SIGNER_LINK || obj.ACCOUNT_SIGNER_LINK.length === 0) {
        if (!obj.ACCOUNT_SIGNER_LINK) {
          obj.ACCOUNT_SIGNER_LINK = [];
        }
        obj.ACCOUNT_SIGNER_LINK.push({
          ContactID: contactid,
          ContactDetailID: detailid,
          CONTACT_DETAIL: {
            FullName: this.state.firstName + ' ' + this.state.lastName,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
          },
          SIGNER_CAPACITY: {},
        });
        obj.ContactID = contactid;
        obj.ContactDetailID = detailid;
      } else {
        if (!obj.ACCOUNT_SIGNER_LINK[index]) {
          obj.ACCOUNT_SIGNER_LINK[index] = {
            ContactID: contactid,
            ContactDetailID: detailid,
            CONTACT_DETAIL: {
              FullName: this.state.firstName + ' ' + this.state.lastName,
              LastName: this.state.lastName,
            },
            SIGNER_CAPACITY: {},
          };
        }
        obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL = {};
        obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.FullName = this.state.firstName + ' ' + this.state.lastName;
        obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.FirstName = this.state.firstName;
        obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.LastName = this.state.lastName;
        obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.Company = this.state.company;
        obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.Title = this.state.jobTitle;
        obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.Email = this.state.email;
        obj.ContactID = contactid;
        obj.ContactDetailID = detailid;
      }

      if (!this.props.validSigners.includes(index)) {
        let tmpValid = Object.assign([], this.props.validSigners);
        tmpValid.push(index);
        this.props.setValidSigners(tmpValid);
      }
      if (this.props.updateAccountData) {
        this.props.updateAccountData(obj);
      }
    }
  };

  onSaveClick = () => {
    let { contactFor } = this.props;

    //If an email was provided. Validate format before proceeding
    if (this.state.email) {
      //Trim any leading or trailing whitespace
      if (!validateEmail(this.state.email)) {
        confirmAlert({
          title: <div className="unsaved-changes-header">Error</div>,
          childrenElement: () => {
            return (
              <div className="unsaved-changes-div">
                <div>Please provide a valid email address.</div>
              </div>
            );
          },
          buttons: [{ label: <div id="continue-confirm-button">Continue</div>, onClick: () => {} }],
        });
        return;
      }
    }

    //Email OR phone is required
    // handled in requiredFields with new optionalEmailPhone(boolean) parameter

    // if (this.state['email']){
    //   requiredFields.push('email');
    // }
    // else{
    //   requiredFields.push('phone');
    // }
    if (this.state.useAutoSign) {
      requiredFields.push('signature');
    }
    if (hasRequired(this.state, requiredFields, true)) {
      if (contactFor === 'signer') {
        this.saveSignerContact();
      } else if (contactFor === 'custodian') {
        this.saveCustodianContact();
      }
    } else {
      this.setState({ checkRequired: true });
    }
  };

  async saveCustodianContact() {
    let { user, services, custodianData } = this.props;
    let { contactFor } = this.state;
    if (contactFor === 'custodian' && custodianData && custodianData.EmailMask && this.state.email) {
      let validFormats = custodianData.EmailMask.split(',');
      let valid = false;
      for (let i in validFormats) {
        if (this.state.email.includes(validFormats[i])) {
          valid = true;
        }
      }
      if (!valid) {
        confirmAlert({
          title: <div className="unsaved-changes-header">Error</div>,
          childrenElement: () => {
            return (
              <div className="unsaved-changes-div">
                <div>Email must be one of the following: {custodianData.EmailMask}</div>
              </div>
            );
          },
          buttons: [{ label: <div id="continue-confirm-button">Continue</div>, onClick: () => {} }],
        });
        return;
      }
    }
    let custodianId = this.props.custodianId || this.props.match.params.linkid;
    let ContactBody = {
      FirmID: this.props.user.FirmId,
      ContactTypeID: this.state.companyContactTypeID,
      ClientID: this.state.clientID,
      Status: this.state.activeStatusId,
      NameAlias: this.state.firstName + ' ' + this.state.lastName,
      ChangeType: 'I',
      ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
      CUSTODIAN_CONTACT_LINK: [
        {
          CustodianID: custodianId,
          ChangeType: 'I',
          ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
        },
      ],
      CONTACT_DETAIL: [
        {
          // ContactID: response.body.ContactID,
          Title: this.state.jobTitle,
          Company: this.state.company,
          Address: this.state.address,
          Address2: this.state.addressTwo,
          AddressTypeID: this.state.addressType ? this.state.addressType.value : this.state.workAddressTypeId,
          Country: this.state.country ? this.state.country.value : null,
          City: this.state.city,
          Email: this.state.email || '',
          Fax: this.state.fax,
          FirstName: this.state.firstName,
          LastName: this.state.lastName,
          Mobile: this.state.mobile,
          Phone: this.state.phone || '',
          Postal: this.state.postal,
          Province: this.state.province,
          StateID: this.state.state ? this.state.state.value : null,
          AutoSignatureFlag: this.state.useAutoSign,
          Signature: this.state.signature,
          ChangeType: 'I',
          ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
        },
      ],
    };
    let response = await ContactModels.createContact({
      user,
      services,
      body: ContactBody,
    });
    if (!response.error) {
      if (this.props.updateAccountData) {
        this.props
          .updateAccountData('CustodianContact', {
            DetailID: ContactBody.CONTACT_DETAIL[0].DetailID,
            data: ContactBody.CONTACT_DETAIL[0],
            label: this.state.firstName + ' ' + this.state.lastName,
            value: response.body.ContactID,
          })
          .then(() => {
            if (this.props.setForceUpdate) {
              this.props.setForceUpdate(true);
            }
          });
      }
      if (this.props.refreshGrid) {
        this.props.refreshGrid();
      }
      if (!this.state.standalone) {
        this.props.setCurrentScreen('account');
      }
      if (this.props.closeViewModal) {
        this.props.closeViewModal();
      }
    } else {
    }
  }

  async saveSignerContact() {
    let { user, services } = this.props;
    let { clientID } = this.state;
    if (this.state.signature) {
      var formattedSignature = this.state.signature.replace(`data:image/png;base64,`, '');
      formattedSignature = formattedSignature.replace(`data:image/jpeg;base64,`, '');
    }

    let ContactBody = {
      CONTACT: {
        FirmID: this.props.user.FirmId,
        ContactTypeID: this.state.individualContactTypeID,
        ClientID: clientID,
        Status: this.state.activeStatusId,
        NameAlias: this.state.firstName + ' ' + this.state.lastName,
        ChangeType: 'I',
        ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
        ReferenceID: this.props.crmData && this.props.crmData.advisorID ? this.props.crmData.advisorID : null,
        External: false,
      },
      Title: this.state.jobTitle,
      Company: this.state.company,
      Address: this.state.address,
      Address2: this.state.addressTwo,
      AddressTypeID: this.state.addressType ? this.state.addressType.value : this.state.workAddressTypeId,
      Country: this.state.country ? this.state.country.value : null,
      City: this.state.city,
      Email: this.state.email || '',
      Fax: this.state.fax,
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      Mobile: this.state.mobile,
      Phone: this.state.phone || '',
      Postal: this.state.postal,
      Province: this.state.province,
      StateID: this.state.state ? this.state.state.value : null,
      AutoSignatureFlag: this.state.useAutoSign,
      Signature: this.state.signature ? formattedSignature : null,
      ChangeType: 'I',
      ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
    };
    let response = await ContactModels.createContactDetail({
      user,
      services,
      body: ContactBody,
    });
    if (!response.error) {
      if (this.props.accountData) {
        this.populateSignerForm(response.body.ContactID, response.body.DetailID, true);
      }
      if (this.props.handleContactSelect) {
        this.props.handleContactSelect(response.body.ContactID, response.body);
      }
      if (this.props.refreshGrid) {
        this.props.refreshGrid();
      }
      if (this.props.closeSearchModal) {
        this.props.closeSearchModal();
      }
      if (this.props.closeViewModal) {
        this.props.closeViewModal();
      }
      if (!this.state.standalone) {
        this.props.setCurrentScreen('account');
      }
    } else {
    }
  }

  async onPatchContactClick() {
    let { user, services, contactFor } = this.props;

    //If an email was provided. Validate format before proceeding
    if (this.state.email) {
      //Trim any leading or trailing whitespace
      if (!validateEmail(this.state.email)) {
        confirmAlert({
          title: <div className="unsaved-changes-header">Error</div>,
          childrenElement: () => {
            return (
              <div className="unsaved-changes-div">
                <div>Please provide a valid email address.</div>
              </div>
            );
          },
          buttons: [{ label: <div id="continue-confirm-button">Continue</div>, onClick: () => {} }],
        });
        return;
      }
    }

    //Email OR phone is required
    // handled in requiredFields with new hasRequired parameter optionalEmailPhone(boolean)

    // if (this.state['email']){
    //   requiredFields.push('email');
    // }
    // else{
    //   requiredFields.push('phone');
    // }
    if (this.state.useAutoSign && contactFor !== 'custodian') {
      requiredFields.push('signature');
    }

    if (hasRequired(this.state, requiredFields, true)) {
      this.setState({ loadSpinner: true });
      if (this.state.signature) {
        var formattedSignature = this.state.signature.replace(`data:image/png;base64,`, '');
        formattedSignature = formattedSignature.replace(`data:image/jpeg;base64,`, '');
      }
      let ContactBody = {
        FirmID: this.props.user.FirmId,
        ContactTypeID: contactFor !== 'custodian' ? this.state.individualContactTypeID : this.state.companyContactTypeID,
        ClientID: this.state.clientID,
        Status: this.state.activeStatusId,
        NameAlias: this.state.firstName + ' ' + this.state.lastName,
      };
      let response = await ContactModels.patchContact({
        user,
        services,
        body: ContactBody,
        contactId: this.state.contactID,
      });
      let ContactDetailBody = {
        ContactID: this.state.contactID,
        Title: this.state.jobTitle,
        Company: this.state.company,
        Address: this.state.address,
        Address2: this.state.addressTwo,
        AddressTypeID: this.state.addressType ? this.state.addressType.value : this.state.workAddressTypeId,
        Country: this.state.country ? this.state.country.value : null,
        City: this.state.city,
        Email: this.state.email || '',
        Fax: this.state.fax,
        FirstName: this.state.firstName,
        LastName: this.state.lastName,
        Mobile: this.state.mobile,
        Phone: this.state.phone || '',
        Postal: this.state.postal,
        Province: this.state.province,
        StateID: this.state.state ? this.state.state.value : null,
        AutoSignatureFlag: this.state.useAutoSign,
        Signature: this.state.signature ? formattedSignature : null,
      };
      let detailResponse = await ContactModels.patchContactDetail({
        user,
        services,
        body: ContactDetailBody,
        contactDetailId: this.state.detailID,
      });
      if (!response.error && !detailResponse.error) {
        if (contactFor === 'signer' && this.props.accountData) {
          this.populateSignerForm(this.state.contactID, this.state.detailID, false);
        }
        validationManager.CustomAlert(false, 'Success', 'Successfully Saved Contact');
      } else {
        validationManager.CustomAlert(true, 'Error', 'Error Saving Contact');
      }

      this.setState({ loadSpinner: false });
      if (!detailResponse.error) {
        this.setState({ type: 'view' });
        if (this.props.refreshGrid) {
          this.props.refreshGrid();
        }
        if (this.props.setForceUpdate) {
          this.props.setForceUpdate(true);
        }
      } else {
      }
    } else {
      this.setState({ checkRequired: true });
    }
  }

  onCancelClick = () => {
    if (this.props.closeViewModal) {
      this.props.closeViewModal();
    } else {
      this.props.setCurrentScreen('account');
    }
  };

  onCopyClick = () => {};

  setEdit = () => {
    this.setState({ type: 'edit' });
    if (this.props.setPageTitle) {
      this.props.setPageTitle('Edit');
    }
  };

  checkEmailEdit = async (contact, policy) => {
    if (contact.toLowerCase() !== 'signer') {
      return true;
    } else {
      return setPolicyBoolean(policy);
    }
  };
  getTooltip = (helpId, defaultText = '') => {
    const { helpInformation } = this.props;
    if (helpInformation[helpId]) {
      return helpInformation[helpId];
    } else {
      return defaultText;
    }
  };
  render() {
    var ie = navigator.userAgent.match(/.NET/g);
    var edge = navigator.userAgent.match(/Edge/g);

    let { type, contactFor, checkRequired, showAdditionalInformation, enrollSignerIDShow, enrollSignerIDEdit, enrollSignerIDLabel, enrollSignerIDRequired } = this.state;
    let { custodianData, hideSignerAddEdit } = this.props;
    let title = '';
    let disabled = this.state.loadSpinner || this.state.type === 'view';
    let disabledClass = disabled ? 'disabled-select' : '';
    let External = this.state.External;
    if (contactFor === 'signer' && type === 'view') {
      title = 'View Signer';
    } else if (contactFor === 'signer' && type === 'edit') {
      title = 'Edit Signer';
    } else if (contactFor === 'signer' && type === 'add') {
      title = 'Add Signer';
    } else if (contactFor === 'custodian' && type === 'edit') {
      title = 'Edit Custodian Contact';
    } else if (contactFor === 'custodian' && type === 'add') {
      title = 'Add Custodian Contact';
    }
    let contactMessageDefault = `Add the name and contact information for the individual responsible for approving LOA's at the specified company.`;
    let AutoSignaturePolicy = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_AUTOSIGNATURE_ENABLED]);
    return (
      <div>
        {this.state.loadSpinner ? (
          <center style={{ height: '500px' }}>
            <Spinner id="view-spinner" className="signer-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
          </center>
        ) : (
          <div className="contact-wrapper">
            <div className="header-accounts">
              {contactFor === 'custodian' && custodianData && custodianData.ContactExplain ? (
                <div className="contact-explain">
                  {/* {custodianData.ContactExplain} */}
                  {this.props.isModal ? null : title}
                </div>
              ) : (
                <div className="account-title">{this.props.isModal ? null : title}</div>
              )}
              {this.state.type === 'add' && (
                <div className="button-div">
                  <div
                    className="generic-button-secondary"
                    id={`contact-cancel-button`}
                    onClick={() => {
                      this.props.closeDetailModal ? this.props.closeDetailModal() : window.history.back();
                    }}
                  >
                    Cancel
                  </div>
                  {/* <div className='generic-button-primary'  id={`contact-copy-button`} onClick={()=>{this.onCopyClick()}}>Copy</div> */}
                  <div
                    className="generic-button-primary"
                    id={`contact-save-button`}
                    onClick={() => {
                      this.onSaveClick();
                    }}
                  >
                    Save
                  </div>
                </div>
              )}
              {this.state.type === 'view' && (
                <div className="button-div">
                  <div
                    className="generic-button-secondary"
                    id={`contact-cancel-button`}
                    onClick={() => {
                      this.props.closeDetailModal ? this.props.closeDetailModal() : window.history.back();
                    }}
                  >
                    Cancel
                  </div>
                  {!hideSignerAddEdit && (
                    <div
                      className="generic-button-primary"
                      id={`contact-edit-button`}
                      onClick={() => {
                        this.setEdit();
                      }}
                    >
                      Edit
                    </div>
                  )}
                </div>
              )}
              {this.state.type === 'edit' && (
                <div className="button-div">
                  <div
                    className="generic-button-secondary"
                    id={`contact-cancel-button`}
                    onClick={() => {
                      this.props.closeDetailModal ? this.props.closeDetailModal() : window.history.back();
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    className="generic-button-primary"
                    id={`contact-save-button`}
                    onClick={() => {
                      this.onPatchContactClick();
                    }}
                  >
                    Save
                  </div>
                </div>
              )}
            </div>
            {/* Legend for required fields  */}
            {!disabled && (
              <div className="required-field-message-wrapper">
                {this.props.type === 'add' && (
                  <div className="contact-message-wrapper">
                    <div className="contact-message-icon" />
                    <div className="contact-message-text">
                      {this.props.contactModalLabel === 'Manager Contact' && this.props.type === 'add' && this.getTooltip('Enrollment.Manager.Contact', contactMessageDefault)}
                      {this.props.contactModalLabel === 'Custodian Contact' && this.props.type === 'add' && this.getTooltip('Enrollment.Custodian.Contact', contactMessageDefault)}
                    </div>
                  </div>
                )}
                <div className="required-field-message required-field-message-contact no-wrap" id="required-field-message">
                  {getRequiredAsterisk(true, '', '', disabled)} Indicates required field
                </div>
              </div>
            )}
            {/* <div className='body-accounts body-account-full'> */}
            <div className={'body-contacts'}>
              <div className="add-signer-info">
                <div className="account-info-item ga-1-1">
                  <label className="company" for="addressType">
                    Company
                  </label>
                  <input
                    disabled={disabled}
                    id={`contact-company`}
                    className="account-input"
                    placeholder=""
                    value={this.state.company}
                    onChange={(e) => {
                      this.setState({ company: e.target.value });
                    }}
                  />
                </div>
                <div className="account-info-item ga-2-1">
                  <label className="label" for="job">
                    Title
                  </label>
                  <input
                    disabled={disabled}
                    id={`contact-job`}
                    className="account-input"
                    placeholder=""
                    value={this.state.jobTitle}
                    onChange={(e) => {
                      this.setState({ jobTitle: e.target.value });
                    }}
                  />
                </div>
                <div className="account-info-item ga-1-2">
                  <label className="label" for="firstname">
                    {getRequiredAsterisk(true, '', '', disabled)}First Name
                  </label>
                  <input
                    disabled={disabled || External}
                    id={`contact-firstname`}
                    className={showRequired(checkRequired, this.state, 'firstName') ? 'account-input req' : 'account-input'}
                    placeholder=""
                    value={this.state.firstName}
                    onChange={(e) => {
                      this.setState({ firstName: e.target.value });
                    }}
                  />
                </div>
                <div className="account-info-item ga-2-2">
                  <label className="label" for="lastname">
                    {getRequiredAsterisk(true, '', '', disabled)}Last Name
                  </label>
                  <input
                    disabled={disabled || External}
                    id={`contact-lastname`}
                    className={showRequired(checkRequired, this.state, 'lastName') ? 'account-input req' : 'account-input'}
                    placeholder=""
                    value={this.state.lastName}
                    onChange={(e) => {
                      this.setState({ lastName: e.target.value });
                    }}
                  />
                </div>
                <div className="account-info-item ga-1-3">
                  <label className="label" for="phone">
                    {getRequiredAsterisk(true, '', '', disabled)}Phone <span className="required-field-email-phone">(Required for Print LOA)</span>
                  </label>
                  <input
                    disabled={disabled || External}
                    id={`contact-phone`}
                    className={showRequired(checkRequired, this.state, 'phone') && !this.state.email ? 'account-input req' : 'account-input'}
                    placeholder=""
                    value={this.state.phone}
                    onChange={(e) => {
                      this.setState({ phone: e.target.value });
                    }}
                    maxLength={'50'}
                  />
                  {EmailEditPolicy && <div className="required-or">OR</div>}
                </div>
                <div className="account-info-item ga-2-3">
                  <label className="label" for="email">
                    {getRequiredAsterisk(true, '', '', disabled || !EmailEditPolicy)}Email
                    {EmailEditPolicy && <span className="required-field-email-phone">(Required for E-signature/Docusign)</span>}
                  </label>
                  <input
                    disabled={disabled || External || !EmailEditPolicy}
                    id={`contact-email`}
                    className={showRequired(checkRequired, this.state, 'email') && !this.state.phone && EmailEditPolicy ? 'account-input req' : 'account-input'}
                    placeholder=""
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value.trim() });
                    }}
                  />
                </div>{' '}
                {showAdditionalInformation && enrollSignerIDShow && contactFor === 'signer' && (
                  <div className={`account-info-item ga-1-4`}>
                    <label className="label" for="mobile">
                      {getRequiredAsterisk(enrollSignerIDRequired, requiredFields, 'clientID', disabled || (type === 'edit' && !enrollSignerIDEdit))}
                      {enrollSignerIDLabel ? enrollSignerIDLabel : 'Signer ID'}
                    </label>
                    <input
                      disabled={disabled || (type === 'edit' && !enrollSignerIDEdit)}
                      id={`contact-signerid`}
                      className={requiredFields.indexOf('clientID') !== -1 && showRequired(checkRequired, this.state, 'clientID') ? 'account-input req' : 'account-input'}
                      placeholder=""
                      value={this.state.clientID}
                      onChange={(e) => {
                        this.setState({ clientID: e.target.value });
                      }}
                    />
                  </div>
                )}
                {showAdditionalInformation && (
                  <div className={`account-info-item ${enrollSignerIDShow && contactFor === 'signer' ? 'ga-2-4' : 'ga-1-4'}`}>
                    <label className="label" for="mobile">
                      Mobile
                    </label>
                    <input
                      disabled={disabled}
                      id={`contact-mobile`}
                      className="account-input"
                      placeholder=""
                      value={this.state.mobile}
                      onChange={(e) => {
                        this.setState({ mobile: e.target.value });
                      }}
                    />
                  </div>
                )}
                {showAdditionalInformation && (
                  <div className={`account-info-item ${enrollSignerIDShow && contactFor === 'signer' ? 'ga-1-5' : 'ga-2-4'}`}>
                    <label className="label" for="fax">
                      Fax
                    </label>
                    <input
                      disabled={disabled}
                      id={`contact-fax`}
                      className="account-input"
                      placeholder=""
                      value={this.state.fax}
                      onChange={(e) => {
                        this.setState({ fax: e.target.value });
                      }}
                    />
                  </div>
                )}
                {showAdditionalInformation && <div className="subheader span-all-col ga-subheader-1">Address</div>}
                {showAdditionalInformation && (
                  <div className="account-info-item span-all-col ga-1-6">
                    <label className="label" for="addressType">
                      Address Type
                    </label>
                    <Select
                      isClearable={true}
                      isDisabled={disabled}
                      className={'account-select ' + disabledClass}
                      id={`contact-addressType`}
                      value={this.state.addressType}
                      onChange={(e) => {
                        let val = e ? e.value : null;
                        this.setState({ addressType: e });
                      }}
                      options={this.state.types}
                    />
                  </div>
                )}
                {showAdditionalInformation && (
                  <div className="account-info-item ga-1-7">
                    <label className="label" for="address">
                      Address 1
                    </label>
                    <input
                      disabled={disabled}
                      id={`contact-address`}
                      className={'account-input'}
                      placeholder=""
                      value={this.state.address}
                      onChange={(e) => {
                        this.setState({ address: e.target.value });
                      }}
                    />
                  </div>
                )}
                {showAdditionalInformation && (
                  <div className="account-info-item ga-2-7">
                    <label className="label" for="addressTwo">
                      Address 2
                    </label>
                    <input
                      disabled={disabled}
                      id={`contact-addressTwo`}
                      className="account-input"
                      placeholder=""
                      value={this.state.addressTwo}
                      onChange={(e) => {
                        this.setState({ addressTwo: e.target.value });
                      }}
                    />
                  </div>
                )}
                {showAdditionalInformation && (
                  <div className="three-col-div span-all-col three-col-grid-area">
                    <div className="account-info-item ga-1-1">
                      <label className="label" for="city">
                        City
                      </label>
                      <input
                        disabled={disabled}
                        id={`contact-city`}
                        className={'account-input'}
                        placeholder=""
                        value={this.state.city}
                        onChange={(e) => {
                          this.setState({ city: e.target.value });
                        }}
                      />
                    </div>
                    <div className="account-info-item ga-1_5-1">
                      <label className="label" for="state">
                        State
                      </label>
                      <Select
                        isClearable={true}
                        isDisabled={disabled}
                        className={'account-select ' + disabledClass}
                        id={`contact-state`}
                        value={this.state.state}
                        onChange={(e) => {
                          let val = e ? e.value : null;
                          this.setState({ state: e });
                        }}
                        options={this.state.states}
                      />
                    </div>

                    <div className="account-info-item ga-2-1">
                      <label className="label" for="postal">
                        Postal
                      </label>
                      <input
                        disabled={disabled}
                        id={`contact-postal`}
                        className="account-input"
                        placeholder=""
                        value={this.state.postal}
                        onChange={(e) => {
                          this.setState({ postal: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                )}
                {showAdditionalInformation && (
                  <div className="account-info-item ga-1-8">
                    <label className="label" for="province">
                      Province
                    </label>
                    <input
                      disabled={disabled}
                      id={`contact-province`}
                      className="account-input"
                      placeholder=""
                      value={this.state.province}
                      onChange={(e) => {
                        this.setState({ province: e.target.value });
                      }}
                    />
                  </div>
                )}
                {showAdditionalInformation && (
                  <div className="account-info-item ga-2-8">
                    <label className="label" for="country">
                      Country
                    </label>
                    <Select
                      isClearable={true}
                      isDisabled={disabled}
                      className={'account-select ' + disabledClass}
                      id={`contact-country`}
                      value={this.state.country}
                      onChange={(e) => {
                        let val = e ? e.value : null;
                        this.setState({ country: e });
                      }}
                      options={this.state.countries}
                    />
                  </div>
                )}
                {AutoSignaturePolicy && showAdditionalInformation && contactFor !== 'custodian' && <div className="subheader span-all-col ga-subheader-2">Signature</div>}
                {AutoSignaturePolicy && showAdditionalInformation && contactFor !== 'custodian' && (
                  <div className="account-info-item account-info-item-signature ga-1-9">
                    <label className="label" for="postal">
                      Use Auto Signature
                    </label>
                    <div className="signature-input-wrapper">
                      <input
                        disabled={disabled}
                        id={`contact-use-autosign`}
                        type="checkbox"
                        className="account-input account-signature-checkbox"
                        placeholder=""
                        checked={this.state.useAutoSign}
                        onClick={() => {
                          this.setState({ useAutoSign: !this.state.useAutoSign });
                        }}
                      />
                    </div>
                  </div>
                )}
                {AutoSignaturePolicy && showAdditionalInformation && contactFor !== 'custodian' && (
                  <div className="account-info-item account-info-item-signature ga-2-9">
                    <label className="label" for="postal">
                      Select Signature
                    </label>
                    <div className={this.state.useAutoSign && showRequired(checkRequired, this.state, 'signature') ? 'signature-input-wrapper req' : 'signature-input-wrapper'}>
                      <input
                        disabled={disabled}
                        id={`contact-upload-signature`}
                        className={this.state.signatureName && !disabled ? 'account-input signature-file-input' : 'account-input signature-file-input hide-name'}
                        type="file"
                        placeholder=""
                        // value={this.state.signatureName}
                        onChange={(e) => {
                          this.setState({ signatureName: e.target.value });
                          let files = e.target.files || [];
                          let blob = files[0];
                          if (blob) {
                            var reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = () => {
                              let base64data = reader.result;
                              // base64data = base64data.replace(`data:image/png;base64,`, '');
                              // base64data = base64data.replace(`data:image/jpeg;base64,`, '');
                              this.setState({
                                signature: base64data,
                              });
                            };
                          } else {
                            this.setState({
                              signature: null,
                            });
                          }
                        }}
                      />
                      {this.state.signature && (
                        <FontAwesome
                          name="xbutton"
                          className={disabled ? 'fa-times clear-file-input disabled-clear' : 'fa-times clear-file-input'}
                          onClick={() => {
                            if (!disabled) {
                              this.setState({ signature: null, signatureName: '' });
                            }
                          }}
                        />
                      )}
                    </div>
                    {this.state.signature && (
                      <div className="signature-preview">
                        <object className="template-object" data={this.state.signature} type="image/png">
                          <embed className="signature-embed" src={this.state.signature} type="image/png" />
                        </object>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* { (contactFor === 'custodian') && */}
              <div
                className="col-2 show-additional-text"
                id={showAdditionalInformation ? 'hide-additional' : 'show-additional'}
                onClick={() => {
                  this.setState({ showAdditionalInformation: !this.state.showAdditionalInformation });
                }}
              >
                {showAdditionalInformation ? 'Hide' : 'Show'} Additional Information
              </div>
              {/* } */}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(Contact);
