import * as globals from '../../Globals/Variables';
import * as dataService from '../Utils/DataService';

export async function getOperation({ user, services }) {
  let batchJobOperationFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/BATCH_OPERATION`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };
  let response = await dataService.FetchData(batchJobOperationFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getEntity({ user, services }) {
  let batchJobEntityFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/BATCH_ENTITY`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };
  let response = await dataService.FetchData(batchJobEntityFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getTemplate({ user, services, entity }) {
  let batchJobTemplateFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/BATCH_TEMPLATE?$filter=(Entity eq  '${entity}')`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };
  let response = await dataService.FetchData(batchJobTemplateFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getBatchJobStatus({ user, services }) {
  let batchJobStatusFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/BATCH_STATUS`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
    },
  };
  let response = await dataService.FetchData(batchJobStatusFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getTypes({ user, services, filter, orderby }) {
  let accountTypeFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/ACCOUNT_TYPE?$orderby=${orderby}&$filter=${filter}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(accountTypeFetchProperties);

  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getContactsByCustodian({ user, services, custodian }) {
  let contactCustodianLinkFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CUSTODIAN_CONTACT_LINK?$filter=CustodianID eq ${custodian}&$expand=CONTACT($expand=CONTACT_DETAIL)&$orderBy=CONTACT/NameAlias asc`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(contactCustodianLinkFetchProperties);

  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getCapacities({ user, services }) {
  let signerCapacityFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/SIGNER_CAPACITY?$orderBy=Name asc`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(signerCapacityFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getStatus({ user, services }) {
  let accountLoaStatusFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/ACCOUNT_LOA_STATUS`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(accountLoaStatusFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getFunds({ user, services, filter }) {
  let accountLoaStatusFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/FUND?$filter=(${filter})&$orderBy=Name ASC`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(accountLoaStatusFetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getCustodians({ user, services, search = '' }) {
  search = search.replace(',', '%2C');
  search = search.replace("'", '%27');
  search = search.replace('&', '%26');
  let fetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CUSTODIAN?$orderby=Name&$filter=(contains(Name, '${search}') and Enrollment eq true)`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(fetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getSigners({ user, services, search = '', crmData }) {
  search = search.replace(',', '%2C');
  search = search.replace("'", '%27');
  search = search.replace('&', '%26');
  let filter = null;
  //When user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER] is set and - filter on just the signers tied to the policy value
  if (user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER] && user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER].Value) {
    //Add ReferenceID to filter
    filter = `$filter=(ReferenceID eq '${
      crmData[Object.keys(crmData).find((key) => key.toLowerCase() === user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER].Value.toLowerCase())]
    }' and CONTACT_TYPE/ContactType eq 'Individual' And contains(NameAlias, '${search}'))`;
  } else {
    filter = `$filter=(CONTACT_TYPE/ContactType eq 'Individual' And contains(NameAlias, '${search}'))`;
  }

  let fetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/Contact?$select=NameAlias&$orderby=NameAlias&${filter}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(fetchProperties);
  if (!response.error && response.body && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getCustodiansGrid({ user, services, filter, orderby, expand, page, pagesize }) {
  let custodianFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CUSTODIAN?$count=true&$top=${pagesize}&$skip=${page * pagesize}${orderby}${filter}${expand}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(custodianFetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}

export async function getSearch({ user, services, filter, orderby, expand, page, pagesize, searchCustodian = '', searchFund = '', type = '' }) {
  let custodianFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/search?$count=true&$top=${pagesize}&$skip=${page * pagesize}${orderby}${filter}${searchCustodian ? `&searchCustodian=${searchCustodian}` : ''}${
      searchFund ? `&searchFund=${searchFund}` : ''
    }`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(custodianFetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}

export async function getManagersGrid({ user, services, filter, orderby, expand, page, pagesize }) {
  // filter = '';
  // expand = '';
  // orderby = '';
  let custodianFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/MANAGERS?$count=true&$top=${pagesize}&$skip=${page * pagesize}${orderby}${filter}${expand}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(custodianFetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}
export async function getContactsGrid({ user, services, filter = '', orderby = '', expand = '', page = 1, pagesize = 20 }) {
  let contactFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT_DETAIL?$count=true&$top=${pagesize}&$skip=${page * pagesize}${orderby}${filter}${expand}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(contactFetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}
export async function getTaxEntityGrid({ user, services, filter = '', orderby = '', expand = '', page = 1, pagesize = 20 }) {
  let taxEntityFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/EnrollmentCoreHierarchy?$count=true&$top=${pagesize}&$skip=${page * pagesize}${orderby}${filter}${expand}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(taxEntityFetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}
export async function getAddressTypes({ user, services }) {
  let addressTypeFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/ADDRESS_TYPE?$orderby=AddressType`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(addressTypeFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getFundTypes({ user, services }) {
  let fundTypeFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/FUND_TYPE?$orderby=Name`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(fundTypeFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getStates({ user, services }) {
  let stateFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/STATE?$orderby=Name`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(stateFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getCountries({ user, services }) {
  let countryFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/COUNTRY?$orderby=Name`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(countryFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getCustodianTypes({ user, services }) {
  let typeFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CUSTODIAN_TYPE?$orderby=Name`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(typeFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getContactTypes({ user, services }) {
  let typeFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CONTACT_TYPE?$orderby=ContactType`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(typeFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getCustodianStatus({ user, services }) {
  let statusFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CUSTODIAN_STATUS`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(statusFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getContactStatus({ user, services }) {
  let statusFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/STATUS`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(statusFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getTemplateTypes({ user, services }) {
  let statusFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_TEMPLATE_TYPE`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(statusFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}

export async function getVisibilityOptions({ user, services }) {
  let statusFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/CONTACT_TYPE?$orderby=ContactType`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(statusFetchProperties);
  if (response.body && response.body.value && response.body.value) {
    let data = response.body.value;

    data.sort((a, b) => {
      return a.ContactType.localeCompare(b.ContactType);
    });

    data.unshift({ ContactType: 'All', ContactTypeID: null });
    return data;
  } else {
    return [];
  }
}
