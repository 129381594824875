import * as linker from '../Utils/UniversalLinker';
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { withRouter } from 'react-router';
import * as globals from '../../Globals/Variables';
import './Header.css';

const defaultLogo = require('../../Images/logos/default_logo_highres3.png');
var FontAwesome = require('react-fontawesome');

function getINITIAL_STATE(that, props) {
  return {
    disableHeader: false,
    advisorNamePolicy: that.props.user.firmPolicies[globals.UI_ENROLLMENT_CRM_ADVISORNAME_LABEL].Value,
  };
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this, props);
  }

  onLogoClick = () => {
    window.location.href = '/';
  };

  SignOutCheck = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h1>Sign Out</h1>
            <p>Are you sure you want to sign out?</p>
            <div className="confirm-alert-custom">
              <div>
                <button id="cancel-confirm-button" onClick={onClose}>
                  Cancel
                </button>
              </div>
              <div>
                <button
                  id="continue-confirm-button"
                  onClick={() => {
                    this.props.logout();
                    onClose();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  render() {
    let { advisorNamePolicy } = this.state;
    return this.props.user && this.props.showHeader === 'true' ? (
      <div className="condensed-header-wrapper">
        {!this.state.disableHeader ? (
          <div id="condensed-header" className="condensed-header">
            <div id="clientInfo" className="header-client-info">
              {this.props.crmData && this.props.crmData.advisorName && (
                <div className="header-client-content">
                  <p>{advisorNamePolicy}:</p> <span>{this.props.crmData.advisorName}</span>
                </div>
              )}
              {this.props.crmData && this.props.crmData.teamID && (
                <div className="header-client-content">
                  <p>Top Account:</p> <span>{this.props.crmData.teamID}</span>
                </div>
              )}
              {this.props.crmData && this.props.crmData.clientName && (
                <div className="header-client-content">
                  <p>Client:</p> <span>{this.props.crmData.clientName}</span>
                </div>
              )}
            </div>
            <div id="signout" className="sign-out-wrapper">
              <FontAwesome
                id="contact-us"
                name="contact-us-icon"
                title="Contact PCR"
                className="fa fa-envelope contact-us-icon"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  linker.UniversalAddTicket('Client.ContactUs', 'popup', null, null, null);
                }}
              />
              <FontAwesome
                id="sign-out"
                name="sign-out-icon"
                title="Sign Out"
                className="fa-sign-out sign-out-icon"
                onClick={() => {
                  this.SignOutCheck();
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    ) : null;
  }
}

export default withRouter(Header);
