import * as globals from '../../Globals/Variables';
import * as dataService from './DataService';

export async function getWorkProcedures({ user, services }) {
  const service = services.Workflow;

  let fetchProperties = {
    BaseUrl: `${service.URL}/WORK_PROCEDURE`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': service.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(fetchProperties);
  if (response.body && response.body.value && response.body.value) {
    return response.body.value;
  } else {
    return [];
  }
}
export async function getTokens({ user, services, body, firmID }) {
  const service = services.PrivacyAPI;
  let fetchProperties = {
    BaseUrl: `${service.URL}/registry/lookup/global`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': service.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  if (firmID) {
    fetchProperties = {
      ...fetchProperties,
      HeaderVals: {
        ...fetchProperties.HeaderVals,
        'x-universal-firm': firmID.value,
      },
    };
  }
  let response = await dataService.FetchData(fetchProperties);
  let tokens = [];
  if (response.body.Registry && response.body.Registry[0].Token) {
    let registries = response.body.Registry;
    for (let i in registries) {
      tokens.push(registries[i].Token);
    }
  }
  return tokens;
}

export async function getFirmID({ user, services, id }) {
  const service = services.Workflow;

  let fetchProperties = {
    BaseUrl: `${service.URL}/FIRM?id=${id}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': service.subscription_key,
    },
  };
  return await dataService.FetchData(fetchProperties);
}

export async function getWorkProcedure({ user, services, id }) {
  const service = services.Workflow;

  let fetchProperties = {
    BaseUrl: `${service.URL}/WORK_PROCEDURE`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': service.subscription_key,
    },
  };
  let response = await dataService.FetchData(fetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}

export async function postTicket({ user, services, body }) {
  const service = services.WorkflowAPI;

  let fetchProperties = {
    BaseUrl: `${service.URL}/ticket/create`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': service.subscription_key,
    },
  };
  return await dataService.FetchData(fetchProperties);
}

export async function postRemoteTicket({ user, services, body }) {
  const service = services.Enrollment;

  let fetchProperties = {
    BaseUrl: `${service.URL}/Ticket/CreateTicket`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': service.subscription_key,
    },
  };
  return await dataService.FetchData(fetchProperties);
}

export async function getAccountToken({ user, services, id }) {
  const service = services.Enrollment;

  let fetchProperties = {
    BaseUrl: `${service.URL}/ACCOUNT?$filter=(AccountID eq ${id})`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    IgnoreDirective: true,
    HeaderVals: {
      'x-universal-directive': 'S',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': service.subscription_key,
    },
  };

  let response = await dataService.FetchData(fetchProperties);

  if (response.body && response.body.value && response.body.value[0] && !response.error) {
    return response.body.value[0];
  } else {
    return false;
  }
}

export async function getWorkProcedureById({ user, services, id }) {
  const service = services.Workflow;
  let fetchProperties = {
    BaseUrl: `${service.URL}/WORK_PROCEDURE?id='${id}'`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': service.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  let response = await dataService.FetchData(fetchProperties);
  if (!response.error) {
    return response.body;
  } else {
    return [];
  }
}

export async function isInternal({ user, services }) {
  let fetchProperties = {
    BaseUrl: `${services.Dashboard.URL}/USER?id=${user.userId}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Dashboard.subscription_key,
    },
  };
  let response = await dataService.FetchData(fetchProperties);
  let value = false;

  if (!response.error) {
    let data = response.body;
    value = data.Type === 'Internal' ? true : false;
    return value;
  } else {
    return false;
  }
}
