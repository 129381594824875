import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, Link, withRouter } from 'react-router-dom';
import iziToast from 'izitoast';
// import 'iziToast/iziToast.min.css';
import 'react-table/react-table.css';
import MyEnrollments from './MyEnrollments';
// import BatchJobs from './BatchJobs';
import Authorizations from './Authorizations';
import Enroll from './Enroll';
import Custodian from './Custodian';
import Contact from './Contact';
import Draggable from 'react-draggable';
import RequestCustodian from './RequestCustodian';
import * as globals from '../../Globals/Variables';
import * as PermissionManager from '../Utils/PermissionManager';
import PermissionDenied from '../Utils/PermissionDenied';
import * as validationManager from '../Utils/ValidationManager';
import Modal from 'react-modal';
import Help from '../Utils/Help';
import NotFound from '../Utils/NotFound';
import AddTicket from '../Utils/AddTicket';
import * as AppModels from '../../AppModels';

const queryString = require('query-string');
const FontAwesome = require('react-fontawesome');
const Spinner = require('react-spinkit');

const renderModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    zIndex: 9,
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    // bottom: "unset",
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    width: '70%',
    height: '75%',
    left: '15%',
    // top: '25%',
    // pointerEvents: "none"
  },
};

const renderAddTicketStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
    zIndex: 9999999,
  },
  content: {
    position: 'absolute',
    top: '60px',
    left: '32%',
    right: '40%',
    bottom: '100px',
    border: 'none',
    background: 'none',
    overflow: 'visible',
    borderRadius: '0px',
    outline: 'none',
    padding: '0px',
    width: '36%',
    height: '70%',
  },
};

let spinnerModalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1111',
    background: 'rgba(255, 255, 255, 0.2)',
  },
  content: {
    top: '40%',
    left: '12%',
    height: '75%',
    width: '75%',
    border: 'none',
    background: 'transparent',
  },
};

let INITIAL_STATE = {
  selectedAccount: null,
  myAccountsLoaded: false,
  helpCount: 0,
  permissionsLoaded: false,
  disableDrag: true,
  localPermissions: {
    [globals.UI_ENROLLMENT_BATCH]: false,
  },
};

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this._isMounted = true;
    let resources = [];
    Object.keys(this.state.localPermissions).forEach(function (key) {
      resources.push({ assetType: globals.AT_FUNCTIONAL, assetId: key });
    });
    PermissionManager.getPermission(this.props.services, resources).then((permissions) => {
      var localPermissions = Object.assign({}, this.state.localPermissions);
      permissions.forEach((item) => {
        localPermissions[item.ProcessId] = item.view;
      });
      this.setState({
        localPermissions,
        permissionsLoaded: true,
      });
    });
    if (queryString.parse(decodeURIComponent(this.props.location.search)).help) {
      this._isMounted && this.renderHelp(queryString.parse(decodeURIComponent(this.props.location.search)).help);
    }

    if (queryString.parse(decodeURIComponent(this.props.location.search)).addticket) {
      this._isMounted && this.renderAddTicketModal(queryString.parse(decodeURIComponent(this.props.location.search)).addticket);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let target = '';
    if (queryString.parse(this.props.location.search).context && this.props.location.search !== prevProps.location.search) {
      for (let i in queryString.parse(this.props.location.search).context.split(',')) {
        let context = queryString.parse(this.props.location.search).context.split(',')[i];
        if (context.split('=')[0] === 'target') {
          target = context.split('=')[1] ? context.split('=')[1] : '';
          if (target === 'popup') {
            this.setState({
              renderModalOpen: queryString.parse(this.props.location.search).api ? false : true,
            });
          } else {
            this.setState(
              {
                renderModalOpen: false,
              },
              () => {
                this.props.setTarget(target);
              }
            );
          }
        }
        if (queryString.parse(decodeURIComponent(this.props.location.search)).universalcontext) {
          document.cookie = context.split('=')[0] + '=' + context.substring(context.indexOf(context.split('=')[1]));
        }
        this.setContextFromURL(context.split('=')[0], context.substring(context.indexOf(context.split('=')[1]), context.length));
      }
    }

    if (
      (queryString.parse(decodeURIComponent(this.props.location.search)).help &&
        queryString.parse(decodeURIComponent(this.props.location.search)).help !== queryString.parse(decodeURIComponent(prevProps.location.search)).help) ||
      (queryString.parse(decodeURIComponent(this.props.location.search)).help &&
        queryString.parse(decodeURIComponent(this.props.location.search)).help === queryString.parse(decodeURIComponent(prevProps.location.search)).help &&
        queryString.parse(this.props.location.search).context &&
        queryString.parse(this.props.location.search).context !== queryString.parse(prevProps.location.search).context)
    ) {
      this.renderHelp(queryString.parse(decodeURIComponent(this.props.location.search)).help, target);
    }

    if (
      (queryString.parse(decodeURIComponent(this.props.location.search)).addticket &&
        queryString.parse(decodeURIComponent(this.props.location.search)).addticket !== queryString.parse(decodeURIComponent(prevProps.location.search)).addticket) ||
      (queryString.parse(decodeURIComponent(this.props.location.search)).addticket &&
        queryString.parse(decodeURIComponent(this.props.location.search)).addticket === queryString.parse(decodeURIComponent(prevProps.location.search)).addticket &&
        queryString.parse(this.props.location.search).context &&
        queryString.parse(this.props.location.search).context !== queryString.parse(prevProps.location.search).context)
    ) {
      // this.setDynamicRender("addticket");
      // this.setModalStyle("addticket");
      this.renderAddTicketModal(
        queryString.parse(decodeURIComponent(this.props.location.search)).addticket,
        queryString.parse(decodeURIComponent(this.props.location.search)).acctid,
        queryString.parse(decodeURIComponent(this.props.location.search)).loastatus
      );
    }
  }

  setMyAccountsLoaded = (bool) => {
    this.setState({ myAccountsLoaded: bool }, () => {
      if (!bool) {
        this.forceUpdate();
      }
    });
  };

  setSelectedAccount = (pk, val) => {
    return new Promise((resolve, reject) => {
      this.setState({ selectedAccountId: pk, selectedAccountData: val }, () => {
        resolve();
      });
    });
  };

  onCancelClick = () => {
    this.setMyAccountsLoaded(false);
    this.setState({ toMyEnrollments: true });
  };

  renderAddTicketModal = async (target, acctid, loastatus) => {
    let { services } = this.props;
    let value = null;

    this.setState({
      dynamicRenderer: 'addticket',
      helpInformation: target,
      addTicketAcctId: acctid,
      loastatus,
    });

    return;
  };

  wipeState = () => {
    this.setState({ addTicketAcctId: undefined });
  };

  renderHelp = (HelpId) => {
    this.setState({ fetchingData: true });
    return new Promise((resolve, reject) => {
      let value = null;

      if (HelpId.charAt(0) === 'C') {
        for (let i in this.props.context) {
          if (this.props.context[i].Parameter === 'help_value') {
            value = this.props.context[i].Value;
            break;
          }
        }
        this.props.setContextParameter('help_value', null);
      }
      let { services, user } = this.props;
      // fetch(`${this.props.services.DashboardAPI.URL}/help/get?id=${HelpId}&documentation=true${value ? "&value=" + value : ""}`, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     "Ocp-Apim-Subscription-Key": this.props.services.DashboardAPI.subscription_key,
      //   },
      //   method: "GET",
      //   credentials: "include",
      // })
      AppModels.getHelp(
        { services, user },
        this.props.services.DashboardAPI.subscription_key,
        `${this.props.services.DashboardAPI.URL}/help/get?id=${HelpId}&documentation=true${value ? '&value=' + value : ''}`
      )
        // .then((res) => {
        //   return validationManager.ResolveHTTPResponse(res, "Request Successful", "Request Failed", res.ok ? true : false);
        // })
        .then((response) => {
          if (!response.error) {
            let helpInformation = response.body;

            if (helpInformation.Help[0]) {
              helpInformation.Help[0]['value'] = value;
              resolve(
                this.setState({
                  helpCount: this.state.helpCount + 1,
                  dynamicRenderer: 'help',
                  helpInformation: helpInformation.Help[0],
                  fetchingData: false,
                })
              );
            } else {
              resolve(
                this.setState({
                  dynamicRenderer: 'notfound',
                  helpInformation: null,
                  fetchingData: false,
                })
              );
            }
          }
        })
        .catch((err) => {
          validationManager.CustomAlert(true, 'UNKNOWN ERROR', 'Exception Caught');
        });
    });
  };

  setFetchingData = (fetchingData) => {
    this.setState({ fetchingData });
  };

  clearTempContext = (closePopup) => {
    if (queryString.parse(this.props.location.search).context) {
      for (let i in queryString.parse(this.props.location.search).context.split(',')) {
        let context = queryString.parse(this.props.location.search).context.split(',')[i];
        if (
          context.split('=')[0] === '{form_add}' ||
          context.split('=')[0] === '{form_id}' ||
          context.split('=')[0] === '{form_filter}' ||
          context.split('=')[0] === '{can_add}' ||
          context.split('=')[0] === '{grid_selectable}' ||
          context.split('=')[0] === '{document_location}' ||
          context.split('=')[0] === '{document_database}' ||
          context.split('=')[0] === '{document_table}' ||
          context.split('=')[0] === '{document_column}' ||
          context.split('=')[0] === '{form_editable}'
        ) {
          this.setContextFromURL(context.split('=')[0], null);
        }
      }
    }
    this.setState({
      formValues: null,
      forceGridUpdate: true,
      isNew: false,
      gridSelectable: false,
      canAdd: true,
      backgroundGridData: {
        ...this.state.backgroundGridData,
        canAdd: true,
      },
    });

    if (this.state.dynamicRenderer === 'help' && this.state.renderModalOpen === true) {
      var currentURL = document.URL;
      var part = currentURL.split('?')[0];
      window.history.pushState({}, document.title, part);
      this.closeModal();
    } else if (this.state.dynamicRenderer === 'addticket' && this.state.renderModalOpen === true) {
      var currentURL = document.URL;
      var part = currentURL.split('?')[0];
      window.history.pushState({}, document.title, part);
      this.closeAddTicket();
    } else {
      if (closePopup) {
        window.history.go(this.state.previousFormBody ? this.state.previousFormBody.count * -1 - 1 : null);
      } else {
        window.history.back();
      }
    }
  };

  getDynamicRender = () => {
    switch (this.state.dynamicRenderer) {
      case 'help':
        return <Help user={this.props.user} services={this.props.services} helpInformation={this.state.helpInformation} setFetchingData={this.setFetchingData} />;
        break;
      case 'addticket':
        return (
          <AddTicket
            user={this.props.user}
            services={this.props.services}
            Procedure={this.state.helpInformation}
            clearTempContext={this.clearTempContext}
            closeAddTicket={this.closeAddTicket}
            setFetchingData={this.setFetchingData}
            acctid={this.state.addTicketAcctId}
            loastatus={this.state.loastatus}
            wipeState={this.wipeState}
            enableCurrentModalDraggable={this.enableCurrentModalDraggable}
            disableCurrentModalDraggable={this.disableCurrentModalDraggable}
          />
        );
        break;
      case 'notfound':
        return <NotFound displayHeader={false} />;
        break;
      case 'empty':
        break;
      default:
        return (
          <center>
            <Spinner id="view-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
          </center>
        );
        break;
    }
  };

  setContextFromURL = (context, value) => {
    context = context.replace('}', '').replace('{', '');
    let found = false;
    let tempContextList = this.props.context;

    for (var i = 0; i < tempContextList.length; i++) {
      if (tempContextList[i].Parameter === context) {
        tempContextList[i].Value = value;
        found = true;
        break;
      }
    }

    if (!found) {
      tempContextList.push({
        Choices: null,
        ContextId: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }),
        ContextType: 'SYSTEM',
        DataType: 'Text',
        Ordinal: 0,
        Parameter: context,
        ParameterLabel: context,
        Value: value,
      });
    }
    this.props.setContext(tempContextList);
  };

  closeHelp = () => {
    this.setState(
      {
        renderModalOpen: false,
      },
      () => {
        this.props.history.push(this.props.location.pathname);
      }
    );
  };

  closeAddTicket = () => {
    this.setState(
      {
        renderModalOpen: false,
      },
      () => {
        this.props.history.push(this.props.location.pathname);
      }
    );
  };

  disableCurrentModalDraggable = () => {
    this.setState({ disableDrag: true });
  };

  enableCurrentModalDraggable = () => {
    this.setState({ disableDrag: false });
  };

  render() {
    //Keep/pass user data/services
    return (
      <div>
        {this.state.toMyEnrollments ? !globals.REMOTE_INSTANCE ? <Redirect push to="/myenrollments" /> : <Redirect push to="/authorize" /> : null}
        {this.state.dynamicRenderer == 'addticket' && (
          <Modal isOpen={this.state.renderModalOpen} style={renderAddTicketStyle}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                <div className="padded_modal_top">
                  <div className="help-modal handle">
                    <div className="help_modal_title">
                      {/* <div className="help_modal_title_add"> */}
                      Create Ticket
                      {/* </div> */}
                    </div>
                    <div
                      title="Close"
                      className="help_modal_addnew_x"
                      onClick={() => {
                        this.closeAddTicket();
                      }}
                    >
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>
                  <div className="dynamic-modal-ticket-window modal-edit-row">{this.getDynamicRender(null, true)}</div>
                </div>
              </div>
            </Draggable>
          </Modal>
        )}
        {this.state.dynamicRenderer == 'help' && this.state.renderModalOpen && (
          <Modal isOpen={this.state.renderModalOpen} style={renderModalStyle}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                <div className="padded_modal_top">
                  <div className="help-modal handle">
                    <div className="help_modal_title">
                      {/* <div className="help_modal_title_add"> */}
                      Help
                      {/* </div> */}
                    </div>
                    <div
                      title="Close"
                      className="help_modal_addnew_x"
                      onClick={() => {
                        this.closeHelp();
                      }}
                    >
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>
                  <div className="dynamic-modal-add-window modal-edit-row">
                    {this.state.fetchingData ? (
                      <center>
                        <Spinner id="view-spinner" className="modal-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
                      </center>
                    ) : (
                      this.getDynamicRender(null, true)
                    )}
                  </div>
                </div>
              </div>
            </Draggable>
          </Modal>
        )}

        <Router>
          <Switch>
            <Route
              path="/authorize"
              render={(routeProps) => (
                <MyEnrollments
                  user={this.props.user}
                  services={this.props.services}
                  setSelectedAccount={this.setSelectedAccount}
                  setMyAccountsLoaded={this.setMyAccountsLoaded}
                  myAccountsLoaded={this.state.myAccountsLoaded}
                  crmData={this.props.crmData}
                />
              )}
            />
            <Route
              path="/myenrollments"
              render={(routeProps) => (
                <MyEnrollments
                  user={this.props.user}
                  services={this.props.services}
                  setSelectedAccount={this.setSelectedAccount}
                  setMyAccountsLoaded={this.setMyAccountsLoaded}
                  myAccountsLoaded={this.state.myAccountsLoaded}
                  crmData={this.props.crmData}
                  // TEST CASE FOR SSO LOGINS-- Please keep
                  // crmData={{
                  //   firmRelationshipIDToken: ['token1', 'token2'],
                  // }}
                />
              )}
            />
            <Route
              path="/authorizations"
              render={(routeProps) => (
                <Authorizations routeProps={routeProps} user={this.props.user} services={this.props.services} setSelectedAccount={this.setSelectedAccount} crmData={this.props.crmData} />
              )}
            />
            {/* {this.state.permissionsLoaded ? (
              <Route
                path="/batchjobs"
                render={(routeProps) =>
                  this.state.localPermissions[globals.UI_ENROLLMENT_BATCH] ? (
                    <BatchJobs
                      user={this.props.user}
                      services={this.props.services}
                      setSelectedAccount={this.setSelectedAccount}
                      setMyAccountsLoaded={this.setMyAccountsLoaded}
                      myAccountsLoaded={this.state.myAccountsLoaded}
                    />
                  ) : (
                    <PermissionDenied />
                  )
                }
              />
            ) : null} */}
            <Route
              path="/enroll/:accountid?"
              query={this.props.location.search}
              render={(routeProps) => (
                <Enroll
                  user={this.props.user}
                  services={this.props.services}
                  selectedAccountId={this.state.selectedAccountId}
                  selectedAccountData={this.state.selectedAccountData}
                  setMyAccountsLoaded={this.setMyAccountsLoaded}
                  setSelectedAccount={this.setSelectedAccount}
                  permissionsLoaded={this.state.permissionsLoaded}
                  localPermissions={this.state.localPermissions}
                  crmData={this.props.crmData}
                  // TEST CASE FOR SSO LOGINS-- Please keep
                  // crmData={{
                  //   firmRelationshipID: '111111_2222222',
                  //   advisorID: '3333333',
                  //   teamID: 'team1',
                  // }}
                />
              )}
            />
            <Route path="/custodian/:id?" render={(routeProps) => <Custodian user={this.props.user} services={this.props.services} />} />

            <Route path="/requestcustodian/:id?" render={(routeProps) => <RequestCustodian user={this.props.user} services={this.props.services} />} />

            <Route path="/contact/:contactFor?/:id?/:linkid?" render={(routeProps) => <Contact user={this.props.user} services={this.props.services} crmData={this.props.crmData} />} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default withRouter(Container);
