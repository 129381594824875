import React, { Component } from 'react';
import { isNullOrEmpty } from '../Utils/HelperFunctions';
import ReactTable from 'react-table';
import Upload from './Upload';
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { confirmAlert } from 'react-confirm-alert';

const FontAwesome = require('react-fontawesome');
const multipleAccountsModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    height: '70%',
    width: '60%',
  },
};

const newRow = {
  Status: '',
  OverrideMask: '',
  Action: '',
  AccountNumber: '',
  AccountName: '',
  AccountNumberValid: true,
  AccountNumberMessage: '',
  AccountNameValid: true,
  AccountNameMessage: '',
  Fund: null,
  InvestorName: '',
};

const header = (header, required, align = 'center') => {
  return (
    <div className={`select-div-${align}`}>
      {required && <i className="fa fas fa-asterisk required-field-header"></i>}
      {header}
    </div>
  );
};

function getINITIAL_STATE(that, props) {
  let title = 'Custodian';
  if (props.managerType === 'ALTERNATIVE' && !props.searchCustodianRelated) {
    title = 'Manager';
  }
  if (props.managerType === 'OTHER') {
    title = 'Institution';
  }

  let retObj = {
    multipleAccountsError: false,
    multipleAccountsString: '',
    data: props.multipleAccountsData ? props.multipleAccountsData : [{ ...newRow }],
    key: new Date().getTime(),
    direction: 'ascending',
    funds: props.funds,
    columns: [
      {
        accessor: 'Status',
        Header: header('Status'),
        Cell: that.statusCell,
        width: 75,
        maxWidth: 100,
        // sortable: false,
      },
      {
        accessor: 'OverrideMask',
        Header: header('Override Mask'),
        Cell: that.checkboxCell,
        width: 125,
        maxWidth: 125,
        // sortable: false,
      },
      {
        accessor: 'Action',
        Header: header('Action'),
        Cell: that.actionCell,
        width: 75,
        maxWidth: 100,
        sortable: false,
      },
    ],
  };
  if (props.accountData.ManagerType === 'ALTERNATIVE') {
    retObj['columns'] = retObj.columns.filter((x) => x.accessor !== 'OverrideMask');
    retObj.columns.push(
      {
        accessor: 'Fund',
        Header: header('Fund Name', true, 'left'),
        width: 300,
        maxWidth: 350,
        Cell: that.selectCell,
      },
      {
        accessor: 'InvestorName',
        Header: header('Investor Name', true, 'left'),
        // width: 300,
        // maxWidth: 350,
        Cell: that.textCell,
      }
    );
  } else {
    retObj.columns.push(
      {
        accessor: 'AccountNumber',
        Header: header('Account Number', true, 'left'),
        width: 250,
        maxWidth: 350,
        Cell: that.textCell,
      },
      {
        accessor: 'AccountName',
        Header: header('Account Name', true, 'left'),
        // maxWidth: 350,
        Cell: that.textCell,
      }
    );
  }

  return retObj;
}

export default class AccountMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this, props);
  }

  componentDidMount() {}

  onSortedChange = (obj) => {
    let data = [...this.state.data];
    let id = obj[0].id;
    let direction = obj[0].desc ? 'descending' : 'ascending';
    if (id && direction) {
      if (direction === 'ascending') {
        data = [...data].sort((a, b) => (a[id] < b[id] ? -1 : 1));
      } else {
        data = [...data].sort((a, b) => (a[id] > b[id] ? -1 : 1));
      }
    }

    this.setState({ data, direction, sort: id });
  };

  handleInputChange = () => {};

  textCell = (cellInfo) => {
    let data = this.state.data;
    let value = this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null;

    let isValid = true;
    let message = '';
    if (cellInfo.column.id === 'AccountNumber') {
      if (!data[cellInfo.index]['AccountNumberValid']) {
        isValid = false;
      }
      if (data[cellInfo.index]['AccountNumberMessage'] !== '') {
        message = data[cellInfo.index]['AccountNumberMessage'];
      }
    }

    if (cellInfo.column.id === 'AccountName') {
      if (!data[cellInfo.index]['AccountNameValid']) {
        isValid = false;
      }
      if (data[cellInfo.index]['AccountNameMessage'] !== '') {
        message = data[cellInfo.index]['AccountNameMessage'];
      }
    }
    return (
      <div
        title={message ? message : value}
        className={`${cellInfo.column.id === 'AccountNumber' ? 'padding-right-10 ' : ''}`}
        onMouseEnter={() => {
          this.setState({ hoveredRowCol: data + cellInfo.index });
        }}
        onMouseLeave={() => {
          this.setState({ hoveredRowCol: null });
        }}
      >
        <input
          id={cellInfo.index + cellInfo.column.id}
          // className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          className={`add-multiple-text multiple-accounts-input${!isValid ? ' field-warning' : ''}`}
          key={cellInfo.index + cellInfo.column.id}
          // disabled={true}
          onChange={(e) => {
            let data = this.state.data;
            data[cellInfo.index][cellInfo.column.id] = e.target.value;
            this.setState({ data });
            this.validateRows(data, cellInfo.index);
          }}
          value={this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
        />
      </div>
    );
  };
  selectCell = (cellInfo) => {
    let data = this.state.data;
    let value = this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null;
    let funds = this.props.funds;
    let isValid = true;
    let message = '';
    if (cellInfo.column.id === 'AccountNumber') {
      if (!data[cellInfo.index]['AccountNumberValid']) {
        isValid = false;
      }
      if (data[cellInfo.index]['AccountNumberMessage'] !== '') {
        message = data[cellInfo.index]['AccountNumberMessage'];
      }
    }

    if (cellInfo.column.id === 'AccountName') {
      if (!data[cellInfo.index]['AccountNameValid']) {
        isValid = false;
      }
      if (data[cellInfo.index]['AccountNameMessage'] !== '') {
        message = data[cellInfo.index]['AccountNameMessage'];
      }
    }
    let field = 'Fund';
    return (
      <div title={message ? message : value} className={this.state.hoveredRowCol === data + cellInfo.index ? '' : ''}>
        <Autocomplete
          key={cellInfo.index + cellInfo.column.id}
          id={cellInfo.index + cellInfo.column.id}
          name={cellInfo.index + cellInfo.column.id}
          className="fund-input-select"
          options={this.props.funds}
          renderOption={(option) => <>{option.label}</>}
          getOptionLabel={(option) => option.label}
          disabled={false}
          size="small"
          onChange={(e, val) => {
            let data = this.state.data;
            if (val === null) {
              data[cellInfo.index][cellInfo.column.id] = null;
              data[cellInfo.index]['FundName'] = null;
            } else {
              data[cellInfo.index][cellInfo.column.id] = val.value;
              data[cellInfo.index]['FundName'] = val.label;
            }
            this.setState({ data });
            this.validateRows(data, cellInfo.index);
          }}
          defaultValue={
            data && funds
              ? funds.find((opt) => {
                  let val = opt.value;
                  let fieldVal = this.state.data[cellInfo.index][cellInfo.column.id];
                  if (val == fieldVal) {
                    return opt;
                  }
                })
              : null
          }
          value={
            data && funds
              ? funds.find((opt) => {
                  let val = opt.value;
                  let fieldVal = this.state.data[cellInfo.index][cellInfo.column.id];
                  if (val == fieldVal) {
                    return opt;
                  }
                })
              : null
          }
          renderInput={(params) => (
            <TextField // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant="outlined"
              placeholder={'Select Fund...'}
            />
          )}
        />
      </div>
    );
  };
  statusCell = (cellInfo) => {
    let status = this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null;
    return (
      <div className="grid-radio" value={status} id={status} key={cellInfo.index + cellInfo.column.id + status}>
        {status === 'OK' ? (
          <FontAwesome className="fa fas fa-check-circle validate-account-icon-ok-grid" id="search-custodian-button" name="fas fa-check-circle" />
        ) : status === 'INVALID' ? (
          <FontAwesome className="fa fas fa-exclamation-circle validate-account-icon-warning-grid" id="search-custodian-button" name="fas fa-exclamation-circle" />
        ) : null}
      </div>
    );
  };

  actionCell = (cellInfo) => {
    let data = this.state.data;
    return (
      <div className="grid-action">
        <FontAwesome
          name="fas fa-trash"
          style={{ cursor: 'pointer' }}
          title="Delete row"
          className="fa fas fa-trash fa-pencil-edit-row"
          id="fa-grid-icon"
          onClick={(e) => {
            data = data.filter((element, index) => index !== cellInfo.index);
            this.setState({ data });
          }}
        />
      </div>
    );
  };

  checkboxCell = (cellInfo) => {
    let data = this.state.data;
    return (
      <div className="grid-checkbox grid-radio clickable-row">
        <input
          id={cellInfo.index + cellInfo.column.id}
          type="checkbox"
          className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          key={cellInfo.index + cellInfo.column.id}
          // disabled={true}
          checked={data[cellInfo.index] ? data[cellInfo.index][cellInfo.column.id] : null}
          value={data[cellInfo.index] ? data[cellInfo.index][cellInfo.column.id] : null}
          onChange={(e) => {
            if (data[cellInfo.index]) {
              data[cellInfo.index][cellInfo.column.id] = !data[cellInfo.index][cellInfo.column.id];
            }
            this.setState({ data }, () => {
              this.validateRows(data, cellInfo.index, true);
            });
          }}
        />
      </div>
    );
  };

  handleCustodianSelect = (pk, val) => {
    let { searchCustodianRelated } = this.props;
    if (searchCustodianRelated) {
      // Handle a related custodian differently
      this.props.updateAccountData('Account_Related_Entity_UID', pk, 'CustodianRelatedName', val.CustodianName);
    } else if (this.state.searchType === 'Manager') {
      this.props.updateAccountData('CustodianID', pk, 'CustodianName', val.CustodianName).then(() => {
        this.props.updateAccountData('Fund', { label: val.FundShortName, value: val.Fund_UID });
      });
    } else {
      this.props.updateAccountData('CustodianID', pk, 'CustodianName', val.CustodianName);
    }

    if (searchCustodianRelated) {
      // Handle a related custodian differently
      this.setState({ selectedCustodianRelatedId: pk }, () => {
        this.props.closeModal();
      });
    } else {
      this.setState({ selectedCustodianId: pk }, () => {
        this.props.updateAccountData('CustodianData', val).then(() => {
          this.props.setValidCustodian(true);
          this.props.closeModal();
        });
      });
    }
  };

  onPageSizeChange = (size) => {
    this.setState({ pagesize: size, page: 0, loading: true }, () => {
      this.searchCustodian(this.state.custodianSearch);
    });
  };

  onPageChange = (index) => {
    this.setState({ page: index, loading: true }, () => {
      this.searchCustodian(this.state.custodianSearch);
    });
  };
  onViewClick = (custodianId) => {
    this.setState({ custodianId }, () => {
      this.setState({ viewCustodianModalOpen: true });
    });
  };

  refreshGrid = () => {
    this.searchCustodian(this.state.custodianSearch);
  };

  setPageTitle = (title) => {
    this.setState({ pageTitle: title });
  };

  handlePages = (pages) => {
    return pages === 0 ? 1 : pages; // DEV-876 Dont let pages = 0, default to 1 or issues with jumpto occurs
  };

  addRow = () => {
    let data = this.state.data;

    data.push({ ...newRow });
    this.setState({ data, key: new Date().getTime() });
  };

  accountValidator(name, overrideMask, name2) {
    // multipleAccountsError
    let { accountData } = this.props;
    // let accountStringReplace = multipleAccountsString.trim().replace(/\s+/g, '\\n\n') + '\\n';
    // let accountsArray = accountStringReplace.split(/\r?\n/);
    let erroredAccountNames = [];
    let uniqueAccountNames = [];
    // let regex = RegExp('[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9][0-9]-[0-9]'); //Set to custodian regex
    let regex = accountData.CustodianData.AccountMask ? (!overrideMask ? RegExp(`^${accountData.CustodianData.AccountMask}$`) : { test: () => true }) : { test: () => true };

    name = name.trim().replace(/\\n/g, '');

    //has length, non-unique or doesntmatch regex
    if (accountData.ManagerType !== 'ALTERNATIVE') {
      if (accountData.CustodianData.AccountMask && !regex.test(name)) {
        erroredAccountNames.push(name);
      } else if (uniqueAccountNames.includes(name)) {
        erroredAccountNames.push(name);
      }
    } else {
      if (isNullOrEmpty(name2) || isNullOrEmpty(name)) {
        erroredAccountNames.push(name);
      }
    }
    uniqueAccountNames.push(name);
    if (uniqueAccountNames.length >= accountData.CustodianData.AccountLimit) {
      erroredAccountNames.push(`Account Limit of ${accountData.CustodianData.AccountLimit} exceeded.`);
    }
    if (erroredAccountNames.length === 0) {
      return true;
    }
    return false;
  }

  validateRows = (data = [], index = 0) => {
    let { accountData } = this.props;
    let { direction, sort } = this.state;
    direction = direction === 'descending' ? true : false;
    if (data.length > 0) {
      let row = data[index];
      let uniqueLabel = accountData.ManagerType === 'ALTERNATIVE' ? 'Investor Name' : 'Account Number';
      let uniqueIdentifier = accountData.ManagerType === 'ALTERNATIVE' ? row.InvestorName : row.AccountNumber;
      let uniqueIdentifier2 = accountData.ManagerType === 'ALTERNATIVE' ? row.Fund : row.AccountName;
      let valid = this.accountValidator(uniqueIdentifier, row.OverrideMask, uniqueIdentifier2);
      if (accountData.ManagerType === 'ALTERNATIVE') {
        data[index]['Status'] = isNullOrEmpty(uniqueIdentifier) || isNullOrEmpty(uniqueIdentifier2) ? 'INVALID' : valid ? 'OK' : 'INVALID';
      } else {
        data[index]['Status'] = isNullOrEmpty(uniqueIdentifier) ? '' : valid ? 'OK' : 'INVALID';
      }

      data[index]['AccountNumberValid'] = isNullOrEmpty(uniqueIdentifier) ? true : valid ? true : false;
      data[index]['AccountNumberMessage'] = isNullOrEmpty(uniqueIdentifier)
        ? ''
        : valid
        ? ''
        : `${uniqueLabel} must be in the following format: ${accountData.CustodianData.AccountMaskExplain}`;
      this.onSortedChange([{ id: sort, desc: direction }]);
      // this.setState({ data });
    }
  };

  isLimitExceeded = () => {
    const { accountData } = this.props;
    const { data } = this.state;
    let accountLimitExceeded = false;
    if (accountData) {
      if (accountData.CustodianData) {
        if (accountData.CustodianData.AccountLimit !== '') {
          if (data.length >= accountData.CustodianData.AccountLimit) {
            accountLimitExceeded = true;
          }
        }
      }
    }
    return accountLimitExceeded;
  };
  isSaveDisabled = () => {
    let data = this.state.data;
    let { accountData } = this.props;
    let valid = 0;
    let invalid = 0;
    if (data && data.length > 0) {
      for (let index in data) {
        let row = data[index];
        let uniqueIdentifier = accountData.ManagerType === 'ALTERNATIVE' ? row.InvestorName : row.AccountNumber;
        let uniqueIdentifier2 = accountData.ManagerType === 'ALTERNATIVE' ? row.Fund : row.AccountName;
        if (!isNullOrEmpty(uniqueIdentifier) && row.AccountNumberValid && !isNullOrEmpty(uniqueIdentifier2)) {
          valid++;
        } else {
          invalid++;
        }
      }
      if (invalid === 0) {
        return false;
      }
    }
    return true;
  };

  isAccountsUnique = (data) => {
    const { accountData } = this.props;
    let type = accountData.ManagerType;
    // if (accountData.ManagerType === 'ALTERNATIVE') {
    //   return true;
    // }
    let uniqueAccounts = [];
    let uniqueErrorMessage = '';
    let messageText = 'Account numbers must be unique.';
    let messageTextAlt = 'Fund / Investor combinations must be unique.';
    if (data && data.length > 0) {
      for (let row of data) {
        let key = type === 'ALTERNATIVE' ? `${row.Fund}_${row.InvestorName}` : row.AccountNumber;
        if (uniqueAccounts.includes(key)) {
          row.AccountNumberValid = false;
          row.AccountNumberMessage = type === 'ALTERNATIVE' ? messageTextAlt : messageText;
          uniqueErrorMessage = type === 'ALTERNATIVE' ? messageTextAlt : messageText;
        } else {
          uniqueAccounts.push(key);
          row.AccountNumberValid = true;
          row.AccountNumberMessage = '';
        }
      }
    }

    this.setState({ data, key: new Date().getTime(), uniqueErrorMessage });
    if (uniqueErrorMessage) {
      confirmAlert({
        title: <div className="unsaved-changes-header">Warning</div>,
        childrenElement: () => {
          return (
            <div className="unsaved-changes-div">
              <div>{uniqueErrorMessage}</div>
            </div>
          );
        },
        buttons: [{ label: <div id="cancel-confirm-button">OK</div>, onClick: () => {} }],
      });
      return false;
    }

    return true;
  };

  handleSave = () => {
    let { data } = this.state;
    const { accountData } = this.props;
    let unique = this.isAccountsUnique(data);
    let count = data ? data.length : 0;
    if (unique) {
      confirmAlert({
        // title: <div className="unsaved-changes-header">Warning</div>,
        childrenElement: () => {
          return (
            <div className="unsaved-changes-div">
              <div>
                You're about to initiate enrollment for <b>{count}</b> account(s).
              </div>
              <div>Are you sure you want to proceed?</div>
            </div>
          );
        },
        buttons: [
          { label: <div id="cancel-confirm-button">No</div>, onClick: () => {} },
          {
            label: <div id="continue-confirm-button">Yes</div>,
            id: 'continue-confirm-button',
            onClick: () => {
              if (accountData.ManagerType === 'ALTERNATIVE') {
                let funds = '';
                let fundNames = '';
                let fundNameArray = [];
                let investorNames = '';
                for (let row of data) {
                  if (!fundNameArray.includes(row.Fund)) {
                    if (!isNullOrEmpty(row.FundName) && fundNames !== '') {
                      fundNames += ', ' + row.FundName;
                    } else {
                      fundNames = row.FundName;
                    }
                    fundNameArray.push(row.Fund);
                  }
                  if (!isNullOrEmpty(row.Fund) && funds !== '') {
                    funds += ', ' + row.Fund;
                  } else {
                    funds = row.Fund;
                  }
                  if (!isNullOrEmpty(row.InvestorName) && investorNames !== '') {
                    investorNames += ', ' + row.InvestorName;
                  } else {
                    investorNames = row.InvestorName;
                  }
                }
                data['FundNames'] = fundNames;
                this.props.onMultipleAccountsAltSaveClick(funds, investorNames, data);
              } else {
                let accountNumbers = '';
                let accountNames = '';
                for (let row of data) {
                  if (!isNullOrEmpty(row.AccountNumber) && accountNumbers !== '') {
                    accountNumbers += ', ' + row.AccountNumber;
                  } else {
                    accountNumbers = row.AccountNumber;
                  }
                  if (!isNullOrEmpty(row.AccountName) && accountNames !== '') {
                    accountNames += ', ' + row.AccountName;
                  } else {
                    accountNames = row.AccountName;
                  }
                }
                this.props.onMultipleAccountsSaveClick(accountNumbers, accountNames, data);
              }
            },
          },
        ],
      });
    }
  };

  handleBatchUpload = () => {
    this.setState({ batchUploadModalOpen: true });
  };
  handleBatchClose = () => {
    this.setState({ batchUploadModalOpen: false });
  };
  handleProcessFile = (json, overrideMask) => {
    this.setState({ batchUploadModalOpen: false, batchJSON: json, batchOverrideMask: overrideMask }, () => {
      this.processBatch(this.state.batchJSON, this.state.batchOverrideMask);
    });
  };
  processBatch = (json, overrideMask) => {
    let { data: temp } = this.state;
    const { accountData, funds } = this.props;
    let limit = accountData ? accountData.CustodianData.AccountLimit : '';
    let exceeded = false;
    let exceededBy = 0;

    if (accountData.ManagerType === 'ALTERNATIVE') {
      // Add new rows for accounts that do not exist in grid yet
      if (json) {
        temp = temp.filter((x) => !isNullOrEmpty(x.Fund) && !isNullOrEmpty(x.InvestorName));
        for (let fileRow of json) {
          let acctName = temp.filter((x) => x.InvestorName === fileRow.investor_name && x.Fund === fileRow.fund_uid);
          if (acctName.length === 0) {
            // Create a new row
            let newRecord = { ...newRow };
            newRecord.InvestorName = fileRow.investor_name;
            // Only add funds that belong to this manager
            if (funds.find((x) => x.value === fileRow.fund_uid)) {
              newRecord.Fund = fileRow.fund_uid;
              newRecord.FundName = fileRow.fund_name;
            }
            temp.push({ ...newRecord });
          }
        }
      }
    } else {
      // In Data, populate account names if the account number exists in grid
      if (temp && json) {
        for (let row of temp) {
          let acctName = json.filter((x) => x.account_number === row.AccountNumber);
          if (acctName.length > 0 && row.AccountName === '') {
            row.AccountName = acctName[0].account_name;
          }
          if (acctName.length > 0) {
            row.OverrideMask = overrideMask;
          }
        }
      }
      // Add new rows for accounts that do not exist in grid yet
      if (json) {
        for (let fileRow of json) {
          let acctName = temp.filter((x) => x.AccountNumber === fileRow.account_number);
          if (acctName.length === 0) {
            // Create a new row
            let newRecord = { ...newRow };
            newRecord.AccountNumber = fileRow.account_number;
            newRecord.AccountName = fileRow.account_name;
            if (overrideMask) {
              newRecord.OverrideMask = overrideMask;
            }
            temp.push({ ...newRecord });
          }
        }
      }
    }

    if (temp.length > 0) {
      temp = temp.filter((x) => !(x.AccountNumber === '' && x.AccountName === '' && x.Fund_UID === '' && x.InvestorName === ''));
      if (temp.length > limit) {
        exceeded = true;
        exceededBy = temp.length - limit;
        temp = temp.filter((x, index) => index < limit);
      }
      for (let index in temp) {
        this.validateRows(temp, index);
      }
    }
    this.setState({ data: temp, key: new Date().getTime() }, () => {
      if (exceeded) {
        let message = `Account limit of (${limit}) exceeded.  (${exceededBy}) accounts were not added to the list.`;
        confirmAlert({
          title: <div className="unsaved-changes-header">Account Limit Exceeded</div>,
          childrenElement: () => {
            return (
              <div className="unsaved-changes-div">
                <div>{message}</div>
              </div>
            );
          },
          buttons: [
            {
              label: <div id="cancel-confirm-button">OK</div>,
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  getInfo = () => {
    const { accountData } = this.props;

    let sampleAccount = accountData.CustodianData.AccountMaskExplain;
    let custodianName = accountData.CustodianName;
    let custodianContact = accountData.CustodianContact && accountData.CustodianContact.label ? accountData.CustodianContact.label : '';
    let signers = '';
    let signerArray = accountData.ACCOUNT_SIGNER_LINK;
    for (let signer of signerArray) {
      if (signers !== '') {
        signers += ', ' + signer.CONTACT_DETAIL.FullName;
      } else {
        signers = signer.CONTACT_DETAIL.FullName;
      }
    }
    return (
      <>
        {custodianName && (
          <div>
            Counterparty: <b>{custodianName}</b>
          </div>
        )}
        {sampleAccount && accountData.ManagerType !== 'ALTERNATIVE' && (
          <div>
            Sample Account Number Format: <b>{sampleAccount}</b>
          </div>
        )}
        {custodianContact && (
          <div>
            Custodian Contact: <b>{custodianContact}</b>
          </div>
        )}
        {signers && (
          <div>
            Signer(s): <b>{signers}</b>
          </div>
        )}
      </>
    );
  };

  render() {
    const disabled = this.state.loading;
    const { accountLimitExceeded, batchUploadModalOpen } = this.state;
    const { accountData, funds } = this.props;
    const isSaveDisabled = this.isSaveDisabled();
    const isLimitExceeded = this.isLimitExceeded();
    return (
      <>
        {batchUploadModalOpen && <Upload handleProcessFile={this.handleProcessFile} handleBatchClose={this.handleBatchClose} managerType={accountData.ManagerType} funds={funds} />}
        <Modal isOpen={true} style={multipleAccountsModalStyle}>
          <Draggable handle=".handle">
            <div className="draggable-wrapper">
              <div>
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">Add Multiple Accounts</div>
                  </div>

                  <div
                    className="sidemodal_addnew_x"
                    onClick={() => {
                      this.props.closeModal();
                    }}
                  >
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <div className="search-modal-container">
                  <div className="multiple-accounts-header">
                    <div className="multiple-accounts-info-text">{this.getInfo()}</div>
                    <div className="button-div">
                      <div
                        id="upload-batch-accounts-button"
                        className={'generic-button-secondary'}
                        onClick={() => {
                          this.handleBatchUpload();
                        }}
                      >
                        Upload Batch
                      </div>
                      <div
                        id="save-accounts-button"
                        className={!isSaveDisabled ? 'generic-button-primary no-margin' : 'generic-button-primary no-margin generic-button-disabled'}
                        onClick={() => {
                          if (!isSaveDisabled) {
                            this.handleSave();
                          }
                        }}
                      >
                        Save
                      </div>
                    </div>
                  </div>

                  <div className="multiple-accounts-body">
                    <div className="multiple-accounts-grid-container padding-top-30">
                      <div className="multiple-accounts-item1">
                        {' '}
                        <button
                          title={isLimitExceeded ? `Account Limit of ${accountData ? accountData.CustodianData.AccountLimit : ''} exceeded.` : 'Add Account'}
                          className={'multiple-accounts-add'}
                          onClick={() => !isLimitExceeded && this.addRow()}
                        >
                          <FontAwesome className={isLimitExceeded ? 'fa fas fa-plus disabled-icon' : 'fa fas fa-plus'} id={'multiple-accounts-add-row'} name="fa-plus" />
                        </button>
                      </div>
                      <div className="multiple-accounts-item2">Remember that these accounts must belong to the same signer(s), or the LOA will be rejected.</div>
                    </div>
                    <div className="table-container table-container-multiple">
                      <ReactTable
                        key={this.state.key}
                        data={this.state.data}
                        columns={this.state.columns}
                        // page={this.state.page || 0}
                        // pages={this.handlePages(Math.ceil(this.state.rowCount / this.state.pagesize))}
                        showPagination={false}
                        pageSize={this.state.data.length > 15 ? this.state.data.length : 15}
                        className="-striped -highlight grid"
                        onSortedChange={this.onSortedChange} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
                        // onPageSizeChange={this.onPageSizeChange}
                        // onPageChange={this.onPageChange}
                        loading={this.state.loading}
                        manual={true}
                        // getTrProps={this.getTrProps}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
        </Modal>
      </>
    );
  }
}
