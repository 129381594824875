import * as globals from '../../Globals/Variables';
import * as dataService from '../Utils/DataService';

export async function getCustodianById({ user, services, custodianId }) {
  let custodianFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CUSTODIAN?id=${custodianId}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
    },
  };
  return await dataService.FetchData(custodianFetchProperties);
}

export async function getCustodianContacts({ user, services, orderby, page, pagesize, custodianId }) {
  let custodianContactsFetchProperties = {
    BaseUrl: `${services.Enrollment.URL}/CUSTODIAN_CONTACT_LINK?$count=true&$top=${pagesize}&$skip=${
      page * pagesize
    }&$filter=CustodianID eq ${custodianId}&$expand=CONTACT($expand=CONTACT_DETAIL)${orderby}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Enrollment.subscription_key,
    },
  };
  let response = await dataService.FetchData(custodianContactsFetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}

export async function getCustodianLoas({ user, services, orderby, page, pagesize, custodianId, expand }) {
  let loaTemplateFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/LOA_TEMPLATE?$count=true&$top=${pagesize}&$skip=${
      page * pagesize
    }&$filter=(CustodianID eq ${custodianId})&$select=FileName,Comment,TemplateID${orderby}${expand}`,
    Method: 'GET',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  let response = await dataService.FetchData(loaTemplateFetchProperties);
  return { values: response.body.value, count: response.body['@odata.count'] };
}

export async function createCustodianContactLink({ user, services, custodianId, contactId }) {
  let body = {
    CustodianID: custodianId,
    ContactID: contactId,
  };
  let custodianContactFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/CUSTODIAN_CONTACT_LINK`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ON_FAIL,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  return await dataService.FetchData(custodianContactFetchProperties);
}

export async function createCustodian({ user, services, body }) {
  let custodianFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/CUSTODIAN`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };
  return await dataService.FetchData(custodianFetchProperties);
}

export async function patchCustodian({ user, services, body, custodianId }) {
  let custodianFetchProperties = {
    BaseUrl: `${services.Counterparty.URL}/CUSTODIAN?id=${custodianId}`,
    Method: 'PATCH',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    CallBody: body,
    SuppressMessageOverride: false,
    ShowMessage: globals.ALWAYS,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': services.Counterparty.subscription_key,
      DDHFirm: user.DDHFirm,
    },
  };

  return await dataService.FetchData(custodianFetchProperties);
}
