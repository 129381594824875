import React, { Component } from 'react';
import { toTitleCase } from '../Utils/HelperFunctions';
import ReactTable from 'react-table';
import Custodian from './Custodian';
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import * as DropdownModels from './DropdownModels';

var debounce = require('lodash/debounce');
const FontAwesome = require('react-fontawesome');

const styleOverrides = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    bottom: 'unset',
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    width: '65%',
  },
};
const header = (header) => {
  return <div className="select-div">{header}</div>;
};

function getINITIAL_STATE(that, props) {
  let title = 'Custodian';
  if (props.managerType === 'ALTERNATIVE' && !props.searchCustodianRelated) {
    title = 'Manager';
  }
  if (props.managerType === 'OTHER') {
    title = 'Institution';
  }
  return {
    searchType: title, // Capture type of search so we could use different search endpoing based on search type
    custodianSearch: '',
    fundSearch: '',
    selectedCustodianId: null,
    selectedCustodianData: null,
    data: [],
    pagesize: 10,
    pageTitle: 'View',
    page: 0,
    rowCount: 0,
    loading: true,
    key: new Date().getTime(),
    orderby: '&$orderBy=CustodianName asc, FundShortName asc',
    orderbySort: '',
    expand: '&$expand=CUSTODIAN_STATUS,CUSTODIAN_TYPE,CUSTODIAN_CONTACT_LINK',
    columns: [
      {
        accessor: 'Checkboxes',
        Header: header('Select'),
        Cell: that.checkboxCell,
        maxWidth: 55,
        sortable: false,
      },
      {
        accessor: 'CustodianName',
        Header: title,
        minWidth: 285,
        Cell: that.textCell,
      },
      {
        accessor: 'status',
        Header: 'Manager Status',
        minWidth: 75,
        Cell: that.textCell,
      },
      {
        accessor: 'FundName',
        Header: 'Fund',
        minWidth: 275,
        Cell: that.textCell,
      },
      {
        accessor: 'FundStatus',
        Header: 'Fund Status',
        minWidth: 75,
        Cell: that.textCell,
      },
      {
        accessor: 'ActiveFeed',
        Header: header('Supported on Feed'),
        minWidth: 120,
        Cell: that.boolCell,
      },
      {
        accessor: 'esign',
        Header: header('E-Signature'),
        maxWidth: 90,
        Cell: that.boolCell,
      },
      {
        accessor: 'verified',
        Header: header('Verified'),
        minWidth: 60,
        Cell: that.boolCell,
      },
      {
        accessor: 'RequireLOAFlag',
        Header: header('Require LOA'),
        maxWidth: 95,
        Cell: that.boolCell,
      },
      {
        accessor: 'AccountMaskExplain',
        Header: 'Sample Number',
        Cell: that.textCell,
      },
    ],
  };
}

export default class SearchCustodian extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this, props);
  }

  componentDidMount() {
    let { searchCustodianRelated } = this.props;
    let { columns, searchType } = this.state;
    let search = '';
    if (searchCustodianRelated) {
      columns = this.state.columns.filter((el) => el.accessor !== 'type' && el.accessor !== 'FundName' && el.accessor !== 'FundStatus');
    } else if (searchType === 'Manager') {
      columns = this.state.columns.filter((el) => el.accessor !== 'type' && el.accessor !== 'ActiveFeed');
    } else {
      //remove the fundname column for everything but Manager
      columns = this.state.columns.filter((el) => el.accessor !== 'FundName' && el.accessor !== 'FundStatus');
    }

    columns.map((el) => {
      if (el.accessor === 'status') {
        el.Header = `${searchType} Status`;
      }
    });

    if (searchCustodianRelated) {
      // Related Custodian (Only on Alternative Enrollments)
      search = this.props.accountData ? (this.props.accountData.CustodianRelatedName ? this.props.accountData.CustodianRelatedName : '') : '';
    } else {
      search = this.props.accountData.CustodianName ? this.props.accountData.CustodianName : '';
    }
    let type = this.getCustodianTypeFilter(this.props.accountData.ManagerType);
    this.setState({ custodianSearch: search, columns });
    this.searchCustodian(search);
  }

  getCustodianTypeFilter = (managerType) => {
    let { searchCustodianRelated } = this.props;
    let custodianType = null;
    managerType = searchCustodianRelated ? 'BROKERAGE' : managerType; // We repurpose searchCustodian for Alternative Custodian Search
    switch (managerType) {
      case 'BROKERAGE':
        custodianType = 'Custodian';
        break;
      case 'ALTERNATIVE':
        custodianType = 'Manager';
        break;
      case 'OTHER':
        custodianType = 'Other';
        break;
      default:
        break;
    }

    return custodianType;
  };

  searchCustodian = debounce(async (searchCustodianText = '', searchFundText = '') => {
    let { user, services, accountData } = this.props;
    let { orderby, orderbySort, expand, page, pagesize, custodianSearch, fundSearch } = this.state;
    this.setState({ loading: true });
    let filter = ``;
    let type = this.getCustodianTypeFilter(accountData.ManagerType);
    // If we pass in a searchCustodian or searchFund, we want to use that instead of state
    if (searchCustodianText != '') {
      custodianSearch = searchCustodianText;
    }
    if (searchFundText != '') {
      fundSearch = searchFundText;
    }

    // Encode search text so we don't run into issues when passing special characters through URL
    searchCustodianText = custodianSearch !== '' ? encodeURIComponent(custodianSearch) : custodianSearch;
    searchFundText = fundSearch !== '' ? encodeURIComponent(fundSearch) : fundSearch;

    // Default type to Custodian
    if (!type) {
      type = 'Custodian';
    }

    if (orderbySort !== '') {
      orderby = `&$orderBy=${orderbySort}`;
    } else {
      if (searchCustodianText != '' || searchFundText != '') {
        orderby = '&$orderBy=Ranking asc, CustodianName asc, FundShortName asc';
      } else {
        orderby = '&$orderBy=CustodianName asc, FundShortName asc';
      }
    }

    // Create filter that will be passed to counterparty/search
    filter = `&$filter=(TypeName eq '${type}' and StatusName ne 'Inactive' and Enrollment eq true)`;

    let { values, count } = await DropdownModels.getSearch({
      user,
      services,
      filter,
      orderby,
      expand,
      page,
      pagesize,
      searchCustodian: searchCustodianText, // custodianSearch will only search custodians if populated
      searchFund: searchFundText, // fundSearch will only search funds if populated
      type,
    });

    let tempCust = [];
    if (values) {
      tempCust = values.map((cust) => {
        return {
          CustodianID: cust.CustodianID,
          CustodianName: cust.CustodianName,
          FundName: cust.FundShortName ? cust.FundShortName : '',
          FundStatus: cust.FundStatus ? toTitleCase(cust.FundStatus) : null,
          type: cust.TypeName,
          status: cust.StatusName,
          esign: cust.EsignatureFlag,
          verified: cust.VerifiedFlag,
          RequireLOAFlag: cust.RequireLOAFlag,
          ActiveFeed: cust.ActiveFeed,
          AccountMaskExplain: cust.AccountMaskExplain,
          data: cust,
        };
      });
      this.setState({ data: tempCust, loading: false, rowCount: count });
    }
  }, 1000);

  onSortedChange = (obj) => {
    let { searchType, orderby } = this.state;
    let temp = this.state.expand;
    let tempBaseOrderby = '';
    let desc = !obj[0].desc ? ' desc' : '';

    switch (obj[0].id) {
      case 'CustodianName':
        tempBaseOrderby = `CustodianName${desc}`;
        break;
      case 'FundName':
        tempBaseOrderby = `FundShortName${desc}`;
        break;
      case 'esign':
        tempBaseOrderby = `EsignatureFlag${desc}`;
        break;
      case 'verified':
        tempBaseOrderby = `VerifiedFlag${desc}`;
        break;
      case 'RequireLOAFlag':
        tempBaseOrderby = `RequireLOAFlag${desc}`;
        break;
      case 'status':
        tempBaseOrderby = `StatusName${desc}`;
        break;
      case 'FundStatus':
        tempBaseOrderby = `FundStatus${desc}`;
        break;
      case 'AccountMaskExplain':
        tempBaseOrderby = `AccountMaskExplain${desc}`;
        break;
      default:
        temp = this.state.expand;
        tempBaseOrderby = this.state.orderbySort;
        break;
    }
    this.setState({ expand: temp, orderbySort: tempBaseOrderby }, () => {
      this.searchCustodian(this.state.custodianSearch);
    });
  };

  textCell = (cellInfo) => {
    const { searchCustodianRelated } = this.props;
    return (
      <div id={cellInfo.index + cellInfo.column.id} className={cellInfo.viewIndex % 2 === 0 ? '' : 'clickable-row'} key={cellInfo.index + cellInfo.column.id} disabled={true}>
        <button
          title={this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
          className={this.state.hoveredRow === cellInfo.index ? `custom-grid-input even-row moz-grid${!searchCustodianRelated && ' under-click'}` : 'custom-grid-input even-row moz-grid'}
          onMouseEnter={() => {
            !searchCustodianRelated && this.setState({ hoveredRow: cellInfo.index });
          }}
          onMouseLeave={() => {
            !searchCustodianRelated && this.setState({ hoveredRow: null });
          }}
          onClick={() => {
            !searchCustodianRelated && this.onViewClick(this.state.data[cellInfo.index].CustodianID, this.state.data[cellInfo.index].contactId);
          }}
        >
          {this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
        </button>
      </div>
    );
  };

  checkboxCell = (cellInfo) => {
    return (
      <div className="grid-radio" title="Click to Select">
        <input
          id={cellInfo.index + cellInfo.column.id}
          type="radio"
          name="datasource"
          className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          key={cellInfo.index + cellInfo.column.id}
          disabled={false}
          onChange={(e) => {
            this.handleCustodianSelect(this.state.data[cellInfo.index].CustodianID, this.state.data[cellInfo.index].data);
          }}
        />
      </div>
    );
  };

  boolCell = (cellInfo) => {
    return (
      <div className="grid-checkbox grid-radio clickable-row">
        <input
          id={cellInfo.index + cellInfo.column.id}
          type="checkbox"
          className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          key={cellInfo.index + cellInfo.column.id}
          disabled={true}
          checked={this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
        />
      </div>
    );
  };

  handleCustodianSelect = (pk, val) => {
    let { searchCustodianRelated } = this.props;
    if (searchCustodianRelated) {
      // Handle a related custodian differently
      this.props.updateAccountData('Account_Related_Entity_UID', pk, 'CustodianRelatedName', val.CustodianName);
    } else if (this.state.searchType === 'Manager') {
      this.props.updateAccountData('CustodianID', pk, 'CustodianName', val.CustodianName).then(() => {
        if (val.FundStatus !== 'INACTIVE') {
          this.props.updateAccountData('Fund_UID', val.Fund_UID, 'Fund', { label: val.FundShortName, value: val.Fund_UID });
          this.props.handleFundSelect({ value: { Name: val.FundShortName, Fund_UID: val.Fund_UID } }, 0);
        }
      });
    } else {
      this.props.updateAccountData('CustodianID', pk, 'CustodianName', val.CustodianName);
    }

    if (searchCustodianRelated) {
      // Handle a related custodian differently
      this.setState({ selectedCustodianRelatedId: pk }, () => {
        this.props.closeModal();
      });
    } else {
      this.setState({ selectedCustodianId: pk }, () => {
        this.props.updateAccountData('CustodianData', val).then(() => {
          this.props.setValidCustodian(true);
          this.props.closeModal();
        });
      });
    }
  };

  onCustodianSearchChange = (e) => {
    this.setState({ custodianSearch: e.target.value.trimLeft(), selectedCustodianId: null, selectedCustodianData: null, page: 0 }, () => {
      this.searchCustodian(this.state.custodianSearch, this.state.fundSearch);
    });
  };
  onFundSearchChange = (e) => {
    this.setState({ fundSearch: e.target.value.trimLeft(), selectedFundId: null, selectedFundData: null, page: 0 }, () => {
      this.searchCustodian(this.state.custodianSearch, this.state.fundSearch);
    });
  };
  onNewClick = () => {
    let requestFor = 'custodian';
    if (this.props.managerType === 'BROKERAGE') {
      requestFor = 'custodian';
    }
    if (this.props.managerType === 'ALTERNATIVE') {
      requestFor = 'manager';
    }
    if (this.props.managerType === 'OTHER') {
      requestFor = 'institution';
    }
    this.props.setRequestFor(requestFor, 'requestcustodian');
    this.props.setRequestModalOpen(true);
  };

  onPageSizeChange = (size) => {
    this.setState({ pagesize: size, page: 0, loading: true }, () => {
      this.searchCustodian(this.state.custodianSearch);
    });
  };

  onPageChange = (index) => {
    this.setState({ page: index, loading: true }, () => {
      this.searchCustodian(this.state.custodianSearch);
    });
  };
  onViewClick = (custodianId) => {
    this.setState({ custodianId }, () => {
      this.setState({ viewCustodianModalOpen: true });
    });
  };

  refreshGrid = () => {
    this.searchCustodian(this.state.custodianSearch);
  };

  handleClearClick = (type = 'Custodian') => {
    if (type === 'Custodian') {
      this.setState({ custodianSearch: '', orderbySort: '', key: new Date().getTime() }, () => this.searchCustodian(''));
    } else {
      this.setState({ fundSearch: '', orderbySort: '', key: new Date().getTime() }, () => this.searchCustodian(this.state.custodianSearch, ''));
    }
  };

  setPageTitle = (title) => {
    this.setState({ pageTitle: title });
  };

  isAnyModalOpen = () => {
    const { viewCustodianModalOpen } = this.state;
    return viewCustodianModalOpen;
  };

  handlePages = (pages) => {
    return pages === 0 ? 1 : pages; // DEV-876 Dont let pages = 0, default to 1 or issues with jumpto occurs
  };

  render() {
    let { searchCustodianRelated } = this.props;
    let custType = 'Custodian';
    if (this.props.managerType === 'ALTERNATIVE' && !searchCustodianRelated) {
      custType = 'Manager';
    }
    if (this.props.managerType === 'OTHER') {
      custType = 'Institution';
    }

    let buttonText = 'Unable to Identify Custodian?';
    if (this.props.managerType === 'ALTERNATIVE') {
      buttonText = 'Unable to Identify Fund Manager?';
    }
    if (this.props.managerType === 'OTHER') {
      buttonText = 'Unable to Identify Institution?';
    }
    const disabled = this.isAnyModalOpen() || this.state.loading;
    return (
      <div>
        <div className="fullmodal handle">
          <div className="fullmodal_title">
            <div className="fullmodal_title_add">Search {searchCustodianRelated ? 'Custodian' : custType === 'Manager' ? 'Manager / Fund' : custType}</div>
          </div>

          <div className="sidemodal_addnew_x" onClick={this.props.closeModal}>
            <FontAwesome name="xbutton" className="fa-times" />
          </div>
        </div>

        <div className="search-modal-container">
          <div className="header-myenrollments">
            <div className="custodian-modal-search-wrapper">
              <div className="custodian-modal-search">
                <input
                  disabled={disabled}
                  className="custodian-search"
                  id="custodian-search-input"
                  placeholder={`Search by ${custType}...`}
                  onChange={(e) => {
                    this.onCustodianSearchChange(e);
                  }}
                  value={this.state.custodianSearch}
                />
                <FontAwesome
                  name="xbutton"
                  className={`fa-times fa-times-clear clear-search-popup ${this.state.loading && 'disabled-icon'}`}
                  onClick={() => {
                    !this.state.loading && this.handleClearClick('Custodian');
                  }}
                />
              </div>
              {custType === 'Manager' && (
                <div className="custodian-modal-search">
                  <input
                    disabled={disabled}
                    className="custodian-search"
                    id="custodian-search-input"
                    placeholder="Search by Fund..."
                    onChange={(e) => {
                      this.onFundSearchChange(e);
                    }}
                    value={this.state.fundSearch}
                  />
                  <FontAwesome
                    name="xbutton"
                    className={`fa-times fa-times-clear clear-search-popup ${this.state.loading && 'disabled-icon'}`}
                    onClick={() => {
                      !this.state.loading && this.handleClearClick('Fund');
                    }}
                  />
                </div>
              )}
            </div>
            {!searchCustodianRelated && (
              <div
                className="generic-button-secondary myenrollments-button no-margin unable-to-identify"
                id="new-custodian-button"
                onClick={() => {
                  this.onNewClick();
                }}
              >
                {buttonText}
              </div>
            )}
          </div>
          <div className="table-container">
            <ReactTable
              key={this.state.key}
              data={this.state.data}
              columns={this.state.columns}
              page={this.state.page || 0}
              pages={this.handlePages(Math.ceil(this.state.rowCount / this.state.pagesize))}
              pageSize={this.state.pagesize}
              className="-striped -highlight grid"
              onSortedChange={this.onSortedChange}
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
              loading={this.state.loading}
              manual={true}
            />
          </div>
          <Modal isOpen={this.state.viewCustodianModalOpen} style={styleOverrides}>
            <Draggable handle=".handle">
              <div className="custodian-modal-wrapper">
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">
                      {this.state.pageTitle} {custType}
                    </div>
                  </div>

                  <div
                    className="sidemodal_addnew_x"
                    onClick={() => {
                      this.setState({ viewCustodianModalOpen: false });
                    }}
                  >
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <Custodian
                  user={this.props.user}
                  services={this.props.services}
                  type="view"
                  closeViewModal={() => {
                    this.setState({ viewCustodianModalOpen: false });
                  }}
                  custodianId={this.state.custodianId}
                  updateAccountData={this.props.updateAccountData}
                  setCurrentScreen={this.props.setCurrentScreen}
                  signerIndex={this.props.signerIndex}
                  setValidSigners={this.props.setValidSigners}
                  validSigners={this.props.validSigners}
                  accountData={this.props.accountData}
                  refreshGrid={this.refreshGrid}
                  setForceUpdate={this.props.setForceUpdate}
                  setPageTitle={this.setPageTitle}
                  custType={custType}
                  isModal={true}
                  crmData={this.props.crmData}
                />
              </div>
            </Draggable>
          </Modal>
        </div>
      </div>
    );
  }
}
