import { TOKEN_PREFIX } from '../../Globals/Variables';
import * as TokenModels from '../Utils/TokenModels';
import converter from 'json-2-csv';
import FileSaver from 'file-saver';
import str2ab from 'string-to-arraybuffer';
import moment from 'moment';
import { sanitizeUrl } from '@braintree/sanitize-url';

export function getBaseURL() {
  return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

export function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function isToken(string = '') {
  return string.startsWith(TOKEN_PREFIX);
}

export function setPolicyBoolean(policy) {
  return policy && policy.Value.toLowerCase() === 'true' ? true : false;
}

export function nullOrFalse(setting) {
  return setting === undefined || setting === null || setting == false ? false : true;
}

// ********************* Used to load JSON content from a local JSON file                            *********************
// ********************* fileName    = Path of file to read                                          *********************
export async function loadJSON(fileName) {
  return new Promise((resolve, reject) => {
    var xobj = new XMLHttpRequest();
    xobj.overrideMimeType('application/json');
    xobj.open('GET', fileName, true); // Replace 'my_data' with the path to your file
    xobj.onreadystatechange = function () {
      if (xobj.readyState == 4 && xobj.status == '200') {
        resolve(xobj.responseText);
      }
    };
    xobj.send(null);
  });
}
export function formatAMPM(date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear().toString().slice(-2);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  month = month <= 9 ? `0${month}` : month;
  day = day <= 9 ? `0${day}` : day;
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  let strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
  let strDate = month + '/' + day + '/' + year;
  return strDate + ' ' + strTime;
}
export function getCurrentTimeString(date) {
  try {
    // We are converting the time on the backend at a firm level and shouldn't be converting to local time zone
    // Format date as it is returned from API
    return formatAMPM(date);
  } catch (e) {
    return null;
  }
}

export function checkPolicy(policy) {
  if (policy) {
    switch (policy.Value) {
      case 'true':
        return true;
      case 'false':
        return false;
      case 'On':
        return true;
      case 'Off':
        return false;
      default:
        return policy.Value;
    }
  }
  return '';
}

//Compare URL to what is set in the policy as the home screen UI.Enrollment.Enroll.Home.Redirect.Section
export function checkIfHome(homeSection) {
  let pathName = window.location.pathname.toLowerCase();
  let isHome = false;
  if (pathName.indexOf('/') !== -1 && homeSection) {
    homeSection = homeSection.toLowerCase();
    let section = window.location.pathname.toLowerCase().split('/')[1];
    section = section.toLowerCase() == 'myenrollments' ? 'accounts' : section.toLowerCase();
    isHome = homeSection == section ? true : false;
  }

  return isHome;
}

//Different button styles when policy is set to enhanced Navigation
export function handleEnhancedNav(test, enhancedNavigation) {
  if (enhancedNavigation) {
    return test === true ? `quickfilter-button-secondary` : `quickfilter-button-secondary quickfilter-button-secondary-clicked`;
  } else {
    return test === true ? `quickfilter-button` : `quickfilter-button quickfilter-button-clicked`;
  }
}

export async function detokenizeField(idField, nameField, token, accounts, enrollAccountTaxEntityShow = false, user, services) {
  let body = {
    Registry: [{ Token: token }],
  };

  //If a token is passed in, detokenize it
  if (token) {
    let detokenize = await TokenModels.getDetokenizeField({
      user,
      services,
      body,
    });
    if (!detokenize.error) {
      if (detokenize.body) {
        if (detokenize.body.Registry[0]) {
          accounts[idField] = detokenize.body.Registry[0].Value;
        }
      }
    }
  }

  if (enrollAccountTaxEntityShow) {
    //For Tax Entity Field, we need to get the Tax Entity that matches the RelationshipID to get the name
    if (accounts[idField]) {
      let filter = `&$filter=(TaxIdNmb eq ${parseInt(accounts[idField])})`;
      let { values, count } = await TokenModels.getTaxEntity({
        user,
        services,
        filter,
      });
      if (values) {
        if (values[0]) {
          accounts[nameField] = values[0].HierarchyPath;
        }
      }
    }
  }

  return accounts;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function toTitleCase(str) {
  if (str === null || str === '') {
    return false;
  } else {
    str = str.toString();
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export function isNullOrEmpty(str) {
  if (str === null || str === '' || (str === undefined && str === 'undefined')) {
    return true;
  }
  return false;
}

export function downloadCSV({ data, fileName }) {
  var options = { DELIMITER: { WRAP: '"' }, emptyFieldValue: '' };
  converter.json2csv(
    data,
    (err, CSV) => {
      CSV = str2ab(CSV);
      var blob = new Blob([CSV], { type: 'text/csv' });
      if (blob) {
        FileSaver.saveAs(blob, fileName);
      }
    },
    options
  );
}
export function getRealProperty(details, property) {
  const properties = Object.keys(details).filter((pro) => pro.toLowerCase() === property.toLowerCase());
  return properties.length > 0 ? properties[0] : null;
}

export function downloadCSVFormatted({ data, fileName, exportPreferences = null, visibleColumns = null, customColumns = null, preferences = null }) {
  let fields = [];
  if (exportPreferences) {
    if (exportPreferences.exportColumns === 'page') {
      //Use visibleColumns with user configured headers
      if (exportPreferences.exportColumnNames === 'page') {
        for (let col of visibleColumns) {
          fields.push({
            label: col.Header,
            value: col.accessor,
          });
        }
      } else if (exportPreferences.exportColumnNames === 'actual') {
        for (let col of visibleColumns) {
          fields.push({
            label: col.accessor,
            value: col.accessor,
          });
        }
      }
    } else if (exportPreferences.exportColumns === 'all') {
      if (exportPreferences.exportColumnNames === 'page') {
        for (let col of customColumns) {
          fields.push({
            label: col.Header,
            value: col.accessor,
          });
        }
      } else if (exportPreferences.exportColumnNames === 'actual') {
        for (let col of customColumns) {
          fields.push({
            label: col.accessor,
            value: col.accessor,
          });
        }
      }
    }
  }
  const { Parser } = require('json2csv');
  const jsonToCsv = new Parser({ fields: fields, unwindBlank: true });

  let csvFormatted = jsonToCsv.parse(data);
  csvFormatted = str2ab(csvFormatted);

  var blob = new Blob([csvFormatted], { type: 'text/csv' });
  if (blob) {
    FileSaver.saveAs(blob, fileName);
  }
}
export function formatFromUTCtoLocal(UTCDate, dateFormat = 'MM/DD/YYYY hh:mm A') {
  let UTC = moment.utc(UTCDate);
  UTC = moment(UTC).local();
  return UTC.isValid() ? UTC.format(dateFormat) : '';
}

export function numberWithCommas(x) {
  let y = x.indexOf('.') !== -1 ? `.${x.substring(x.indexOf('.') + 1, x.length)}` : '';
  x = x.indexOf('.') !== -1 ? x.substring(0, x.indexOf('.')) : x;
  x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  x = `${x}${y}`;
  return x;
}

export function formatValue(value, datatype = 'string', format = null) {
  let orig = value;
  if (datatype === 'dateTime') {
    datatype = 'date';
  }
  switch (datatype) {
    case 'integer':
      if (format !== 'noformat') {
        value = parseInt(value);
        value = new Intl.NumberFormat('en-US').format(value);
      }
      value = value === 'NaN' ? orig : value;
      return value;
    case 'decimal':
      value = Number.parseFloat(value).toFixed(format);
      value = numberWithCommas(value);
      value = value === 'NaN' ? orig : value;
      return value;
    case 'date':
      if (value && format) {
        // All DB and View date/time fields are in UTC.  Views have formatted date/times 11/20/2020 02:48 PM and DB has ISO 8601 (2020-05-14T17:45:59.383Z)
        // If we have a formatted date/time, then append UTC on it.  This will allow us to use it in moment and convert it properly to firm / or local timezone
        if (value.indexOf('AM') !== -1 || value.indexOf('PM') !== -1) {
          value = value + ' UTC';
        }
        let dateConvert = new Date(value);
        dateConvert = formatFromUTCtoLocal(value, format);
        value = dateConvert;
        return value;
      } else {
        return value;
      }
    case 'currency':
      value = new Intl.NumberFormat('en-US', { style: 'currency', currency: format ? format : 'USD' }).format(value);
      return value;
    default:
      //return value as is
      return value;
  }
}

export function sanitizeURL(url) {
  let sanitizedUrl = sanitizeUrl(url);
  sanitizedUrl = sanitizedUrl.replace(
    /(?:java\s*script\s*:\s*|<script\b[^>]*>([\s\S]*?)<\/script>|on(?:abort|blur|click|contextmenu|dblclick|error|focus|input|keydown|keypress|keyup|load|mousedown|mouseenter|mouseleave|mousemove|mouseout|mouseover|mouseup|reset|resize|scroll|select|submit|unload|beforeunload|prompt|message|open)\s*=\s*["']?[^"'>]+["']?)/g,
    ''
  );

  return sanitizedUrl;
}
