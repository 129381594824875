import React, { Component } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import ReactTable from 'react-table';
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import SearchCustodian from './SearchCustodian';
import SearchContact from './SearchContact';
import SearchTaxEntity from './SearchTaxEntity';
import RequestCustodian from './RequestCustodian';
import CounterpartyIntelligence from './CounterpartyIntelligence';
import AccountMultiple from './AccountMultiple';
import { Redirect, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import FileSaver from 'file-saver';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import str2ab from 'string-to-arraybuffer';
import ServerSideSelect from '../Utils/ServerSideSelect';
import Contact from './Contact';
import * as linker from '../Utils/UniversalLinker';
import * as validationManager from '../Utils/ValidationManager';
import * as globals from '../../Globals/Variables';
import * as DropdownModels from './DropdownModels';
import * as AccountModels from './AccountModels';
import * as LOAModels from './LOAModels';
import debounce from 'lodash/debounce';
import { getCookie, setPolicyBoolean, checkPolicy } from '../Utils/HelperFunctions';
import { getRequiredAsterisk } from '../Utils/RequiredFields';
const FontAwesome = require('react-fontawesome');
const Spinner = require('react-spinkit');

let BROKERAGERequiredFields = [
  'AccountName',
  'AccountTitle',
  // 'AccountTypeID',
  'CustodianID',
  'CustodianName',
  'CustodianContact',
  'TaxEntityID',
];
let BROKERAGERequiredFields_Anonymized = ['AccountName', 'AccountTitle', 'CustodianID', 'CustodianName'];

let ALTERNATIVERequiredFields = [
  // 'AccountName',
  // 'AccountTypeID',
  'CustodianID',
  'CustodianName',
  'InvestorName',
  // 'Fund',
  'CustodianContact',
  'TaxEntityID',
];
let ALTERNATIVERequiredFields_Anonymized = ['CustodianID', 'CustodianName', 'InvestorID', 'InvestorName', 'TaxEntityID'];

let OTHERRequiredFields = ['AccountName', 'AccountTitle', 'CustodianID', 'CustodianName', 'CustodianContact', 'TaxEntityID'];

const styleOverrides = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    // bottom: "unset",
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    width: '70%',
    height: '75%',
    zIndex: 99999,
    // top: '25%',
    // pointerEvents: "none"
  },
};

const styleOverridesWide = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    // bottom: "unset",
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    width: '80%',
    height: '75%',
    zIndex: 99999,
    // top: '25%',
    // pointerEvents: "none"
  },
};

const styleOverridesWideLOA = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    bottom: 'unset',
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    width: '95%',
    height: '95%',
    zIndex: 99999,
    // top: '25%',
    // pointerEvents: "none"
  },
};

const smallModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    // bottom: "unset",
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    width: '40%',
    height: '80%',
    // top: '25%',
    // pointerEvents: "none"
  },
};

const hierarchyModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    width: '30%',
  },
};

let spinnerModalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1111',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    top: '8%',
    left: '12%',
    height: '80%',
    width: '75%',
    border: 'none',
    background: 'transparent',
  },
};

const multipleAccountsModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    height: '70%',
    width: '60%',
  },
};

const modalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '135%',
    zIndex: '1111',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    top: '8%',
    left: '12%',
    height: '60%',
    width: '75%',
    overflow: 'hidden',
  },
};

function getINITIAL_STATE(that, props) {
  let policies = that.props.user.firmPolicies;

  return {
    contactFilter: '',
    accountDataLoaded: false,
    enableButton: false,
    tokenFilter: true,
    accountTypeOptions: [],
    searchCustodianModalOpen: false,
    searchCustodianRelated: false,
    searchSignerModalOpen: false,
    addHierarchyOptionModalOpen: false,
    hierarchyOption: '',
    hierarchyItem: '',
    hierarchyData: {},
    hierarchySelectOptions: {},
    toMyEnrollments: false,
    toAuthorizations: false,
    loadedSigners: false,
    statusList: [],
    loadSpinner: false,
    signerEdited: [],
    signerAdded: [],
    showRequired: false,
    previewModalOpen: false,
    nullFields: [],
    isFormDirty: false,
    dirtyFields: {},
    multipleAccountsError: false,
    multipleAccountsString: '',
    multipleAccountsData: null,
    overrideMask: false,
    isTokenized: true,
    loaPreviewData: false,
    removedSigners: [],
    accountTypePrompt: that.props.edit ? false : true,
    helpResponse: '',
    allRequiredFields: [],
    fundData: [],
    fieldArray: [],
    fieldArrayLists: [],
    SourceFetchProperties: {
      BaseUrl: `${that.props.services.Enrollment.URL}/CUSTODIAN`,
      Mode: 'cors',
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': that.props.services.Enrollment.subscription_key,
        'x-universal-firm': that.props.user.FirmId,
      },
    },
    ContactFetchProperties: {
      BaseUrl: `${that.props.services.Enrollment.URL}/CONTACT_DETAIL`,
      Mode: 'cors',
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': that.props.services.Enrollment.subscription_key,
        'x-universal-firm': that.props.user.FirmId,
      },
    },
    FundFetchProperties: {
      BaseUrl: `${that.props.services.Enrollment.URL}/FUND`,
      Mode: 'cors',
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': that.props.services.Enrollment.subscription_key,
        'x-universal-firm': that.props.user.FirmId,
      },
    },
    TaxEntityFetchProperties: {
      BaseUrl: `${that.props.services.Enrollment.URL}/EnrollmentCoreHierarchy`,
      Mode: 'cors',
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': that.props.services.Enrollment.subscription_key,
        'x-universal-firm': that.props.user.FirmId,
      },
    },
    enrollLabel: that.props.user.firmPolicies[globals.UI_ENROLLMENT_ENROLLLABEL].Value,
    enrollPrefixLabel: that.props.user.firmPolicies[globals.UI_ENROLLMENT_ENROLLPREFIXLABEL].Value,
    // Enhanced Navigation and styles
    enhancedNavigation: checkPolicy(policies[globals.UI_ENROLLMENT_ENHANCED_NAVIGATION]),
    // Account Types Screen
    enrollTitle: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_TITLE]),
    // Account Type Panel Buttons
    enrollBrokeragePanel: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_BROKERAGE_PANEL_BUTTON_LABEL]),
    enrollAlternativePanel: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_ALTERNATIVE_PANEL_BUTTON_LABEL]),
    enrollOtherPanel: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_OTHER_PANEL_BUTTON_LABEL]),
    // Enrollment Titles
    enrollBrokerageTitle: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_BROKERAGE_TITLE]),
    enrollAlternativeTitle: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_ALTERNATIVE_TITLE]),
    enrollOtherTitle: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_OTHER_TITLE]),
    // Enrollment Buttons
    enrollHomeSection: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_HOME_REDIRECT_SECTION]),
    enrollNextButtonLabel: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_NEXT_BUTTON_LABEL]),
    //
    // Additional Account Fields
    enrollPartnerIDShow: checkPolicy(policies[globals.UI_ENROLLMENT_PARTNERID_SHOW]),
    enrollPartnerIDEdit: checkPolicy(policies[globals.UI_ENROLLMENT_PARTNERID_EDIT]),
    enrollPartnerIDLabel: checkPolicy(policies[globals.UI_ENROLLMENT_PARTNERID_LABEL]),

    enrollTeamIDShow: checkPolicy(policies[globals.UI_ENROLLMENT_TEAMID_SHOW]),
    enrollTeamIDEdit: checkPolicy(policies[globals.UI_ENROLLMENT_TEAMID_EDIT]),
    enrollTeamIDLabel: checkPolicy(policies[globals.UI_ENROLLMENT_TEAMID_LABEL]),

    enrollClientIDShow: checkPolicy(policies[globals.UI_ENROLLMENT_CLIENTID_SHOW]),
    enrollClientIDEdit: checkPolicy(policies[globals.UI_ENROLLMENT_CLIENTID_EDIT]),
    enrollClientIDLabel: checkPolicy(policies[globals.UI_ENROLLMENT_CLIENTID_LABEL]),

    enrollInvestmentIDShow: checkPolicy(policies[globals.UI_ENROLLMENT_INVESTMENTID_SHOW]),
    enrollInvestmentIDEdit: checkPolicy(policies[globals.UI_ENROLLMENT_INVESTMENTID_EDIT]),
    enrollInvestmentIDLabel: checkPolicy(policies[globals.UI_ENROLLMENT_INVESTMENTID_LABEL]),

    enrollAccountCustom1Show: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM1_SHOW]),
    enrollAccountCustom1Edit: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM1_EDIT]),
    enrollAccountCustom1Label: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM1_LABEL]),

    enrollAccountCustom2Show: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM2_SHOW]),
    enrollAccountCustom2Edit: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM2_EDIT]),
    enrollAccountCustom2Label: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM2_LABEL]),

    enrollAccountCustom3Show: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM3_SHOW]),
    enrollAccountCustom3Edit: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM3_EDIT]),
    enrollAccountCustom3Label: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM3_LABEL]),

    enrollAccountCustom4Show: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM4_SHOW]),
    enrollAccountCustom4Edit: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM4_EDIT]),
    enrollAccountCustom4Label: checkPolicy(policies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM4_LABEL]),

    enrollAccountTaxEntityShow: checkPolicy(policies[globals.UI_ENROLLMENT_TAXENTITY_SHOW]),
    enrollAccountTaxEntityEdit: checkPolicy(policies[globals.UI_ENROLLMENT_TAXENTITY_EDIT]),
    enrollAccountTaxEntityLabel: checkPolicy(policies[globals.UI_ENROLLMENT_TAXENTITY_LABEL]),

    enrollAdvisorIDShow: checkPolicy(policies[globals.UI_ENROLLMENT_ADVISORID_SHOW]),
    enrollRelationshipIDShow: checkPolicy(policies[globals.UI_ENROLLMENT_RELATIONSHIPID_SHOW]),
    enrollRelationshipIDSplit: checkPolicy(policies[globals.UI_ENROLLMENT_RELATIONSHIPID_SPLIT]),

    enrollAlternativeCustodianShow: checkPolicy(policies[globals.UI_ENROLLMENT_ALTERNATIVE_CUSTODIAN_SHOW]),
    enrollAlternativeCustodianEdit: checkPolicy(policies[globals.UI_ENROLLMENT_ALTERNATIVE_CUSTODIAN_EDIT]),
    enrollAlternativeCustodianLabel: checkPolicy(policies[globals.UI_ENROLLMENT_ALTERNATIVE_CUSTODIAN_LABEL]),

    enrollHierarchyShow: checkPolicy(policies[globals.UI_ENROLLMENT_HIERARCHY]),
    enrollCounterpartyIntelligence: checkPolicy(policies[globals.UI_ENROLLMENT_COUNTERPARTY_INTELLIGENCE]),
    enrollment_anonymized: checkPolicy(policies[globals.ENROLLMENT_ANONYMIZED]) ? checkPolicy(policies[globals.ENROLLMENT_ANONYMIZED]) : false,
    //
    helpInformation: [],
    loaAlertBody: that.props.user.firmPolicies[globals.UI_ENROLLMENT_LOA_ALERT].Value,
    comparedAccountInfo: {},
    setCreateCustodianName: false,
  };
}

let signerCount = 0;
let fundCount = 0;
let helpArray = [];
let enrollCopyPolicy = false;
let enrollAdvisorIdPolicy = false;
let enrollRelationshipIdPolicy = false;
let enrollBrokeragePolicy = false;
let enrollAlternativePolicy = false;
let enrollOtherPolicy = false;
let enrollDisclaimerPolicy = false;
let enrollTileCount = 0;
let enrollType = '';
let enrollPrompt = false;
let printLOABody = '';
let printLOAShowWarning = true;
let preventLOASendMSG = '';
let accountsButtonLabel = '';
let authorizationsButtonLabel = '';
let copyButtonLabel = '';
let newButtonLabel = '';
let previewButtonLabel = '';
let cancelButtonLabel = '';
let cancelAccountButtonLabel = '';
let saveButtonLabel = '';
let investorIDLabel = 'Investor ID';

const hierarchyParams = [
  {
    id: 'branch',
    label: 'Branch',
    field: 'Branch',
  },
  {
    id: 'advisor',
    label: 'Advisor',
    field: 'Advisor',
  },
  {
    id: 'super-house',
    label: 'Super House',
    field: 'SuperHouse',
  },
  {
    id: 'house',
    label: 'House',
    field: 'House',
  },
  {
    id: 'tax-entity',
    label: 'Tax Entity',
    field: 'TaxEntity',
  },
];

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this, props);
    this.toggle = this.toggle.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement('body');
    //Get tooltips
    this.getTooltips();

    let { user, services, accountData, viewOnlyMode } = this.props;
    const { enrollHierarchyShow, enrollment_anonymized } = this.state;
    if (enrollment_anonymized) {
      BROKERAGERequiredFields = BROKERAGERequiredFields_Anonymized;
      ALTERNATIVERequiredFields = ALTERNATIVERequiredFields_Anonymized;
    }
    if (viewOnlyMode && accountData.AccountID && enrollHierarchyShow) {
      AccountModels.getHierarchyData({ user, services, accountData }).then((hierarchyData) => {
        if (hierarchyData) {
          this.setState((prevState) => {
            let temp = { ...prevState.hierarchyData };
            hierarchyParams.forEach((param) => {
              if (hierarchyData[param.field]) {
                temp[param.id] = {
                  label: hierarchyData[param.field],
                  value: hierarchyData[param.field],
                };
              }
            });

            return { hierarchyData: temp };
          });
        }
      });
    }

    //If user wants to see account information supress eyeball
    let tokenFilter = this.state.tokenFilter;
    if (getCookie('accounts_tokenFilter') !== '') {
      tokenFilter = JSON.parse(getCookie('accounts_tokenFilter'));
    }
    if (!this.props.match.params.accountid) {
      this.setState({ accountTypePrompt: true });
      enrollPrompt = true;
    }
    this.setState({ tokenFilter });

    this.getCapacities().then(() => {
      if (this.state.queryMode) {
        this.setDefaults();
      }
      this.getLoaActions();
      this.getStatus();
      this.getTypes();
      if (enrollHierarchyShow) {
        this.getHierarchyOptions();
      }

      if (this.props.viewOnlyMode) {
        this.getFunds();
        this.getCustodianContacts();
      }
    });
    //Generate contact filter for signer drop downs
    this.handleContactFilter();

    // POLICY DRIVEN REQUIRED FIELDS
    this.setAllRequiredFields();

    // POLICY DRIVEN TYPE FIELDS LIST / TEXT
    this.getFieldTypes();

    // POLICY CHECKING SHOULD BE DONE ONLY ONCE, IN STATE OR IN DIDMOUNT, NOT IN RENDER
    enrollCopyPolicy = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_COPY]);
    enrollAdvisorIdPolicy = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_EDIT]);
    enrollRelationshipIdPolicy = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_EDIT]);
    enrollBrokeragePolicy = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ENROLL_BROKERAGE]);
    enrollAlternativePolicy = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ENROLL_ALTERNATIVE]);
    enrollOtherPolicy = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ENROLL_OTHER]);
    enrollDisclaimerPolicy = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ENROLL_DISCLAIMER]);
    enrollTileCount = this.getTileWidth(enrollBrokeragePolicy, enrollAlternativePolicy, enrollOtherPolicy, enrollDisclaimerPolicy);
    enrollType = this.getEnrollType(enrollTileCount, enrollBrokeragePolicy, enrollAlternativePolicy, enrollOtherPolicy, enrollDisclaimerPolicy);
    printLOABody = this.props.user.firmPolicies[globals.UI_ENROLLMENT_PRINTLOA_BODY].Value;
    printLOAShowWarning = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_PRINTLOA_SHOW_WARNING]);
    preventLOASendMSG = this.props.user.firmPolicies[globals.UI_ENROLLMENT_PREVENTLOASEND_MSG].Value;
    accountsButtonLabel = this.props.user.firmPolicies[globals.UI_ENROLLMENT_ACCOUNTS_BTN_LBL].Value;
    authorizationsButtonLabel = this.props.user.firmPolicies[globals.UI_ENROLLMENT_AUTHORIZATIONS_BTN_LBL].Value;
    copyButtonLabel = this.props.user.firmPolicies[globals.UI_ENROLLMENT_COPY_BTN_LBL].Value;
    newButtonLabel = this.props.user.firmPolicies[globals.UI_ENROLLMENT_NEW_BTN_LBL].Value;
    previewButtonLabel = this.props.user.firmPolicies[globals.UI_ENROLLMENT_PREVIEW_BTN_LBL].Value;
    cancelButtonLabel = this.props.user.firmPolicies[globals.UI_ENROLLMENT_CANCEL_BTN_LBL].Value;
    cancelAccountButtonLabel = this.props.user.firmPolicies[globals.UI_ENROLLMENT_CANCEL_ACCOUNT_BTN_LBL].Value;
    saveButtonLabel = this.props.user.firmPolicies[globals.UI_ENROLLMENT_SAVE_BTN_LBL].Value;
    investorIDLabel = this.props.user.firmPolicies[globals.UI_ENROLLMENT_ALTS_INVESTORID_LBL].Value;
  }

  getFieldTypes = (name) => {
    let fields = ['RelationshipID', 'AdvisorID', 'PartnerID', 'TeamID', 'ClientID', 'InvestmentID', 'Account_Custom1', 'Account_Custom2', 'Account_Custom3', 'Account_Custom4'];
    let { user } = this.props;
    let fieldArray = [];
    let fieldArrayLists = [];
    let values = [];
    for (let field of fields) {
      let policy = checkPolicy(user.firmPolicies[`UI.Enrollment.${field}.Type`]);
      if (policy === 'LIST') {
        let policyValues = checkPolicy(user.firmPolicies[`UI.Enrollment.${field}.Values`]);
        if (policyValues) {
          policyValues = policyValues.split(',');
          values = [];
          for (let p of policyValues) {
            values.push({ value: p, label: p });
          }
          fieldArray[field] = { field: field, type: 'LIST', choices: values };
          fieldArrayLists.push(field);
        }
      }
    }
    this.setState({ fieldArray, fieldArrayLists });
  };

  setAllRequiredFields = () => {
    // Policy Driven Required Fields
    // export const UI_ENROLLMENT_RELATIONSHIPID_REQUIRED = 'UI.Enrollment.RelationshipID.Required';
    // export const UI_ENROLLMENT_ADVISORID_REQUIRED = 'UI.Enrollment.AdvisorID.Required';
    // export const UI_ENROLLMENT_PARTNERID_REQUIRED = 'UI.Enrollment.PartnerID.Required';
    // export const UI_ENROLLMENT_TEAMID_REQUIRED = 'UI.Enrollment.TeamID.Required';
    // export const UI_ENROLLMENT_CLIENTID_REQUIRED = 'UI.Enrollment.ClientID.Required';
    // export const UI_ENROLLMENT_INVESTMENTID_REQUIRED = 'UI.Enrollment.InvestmentID.Required';
    // export const UI_ENROLLMENT_ACCOUNTCUSTOM1_REQUIRED = 'UI.Enrollment.Account_Custom1.Required';
    // export const UI_ENROLLMENT_ACCOUNTCUSTOM2_REQUIRED = 'UI.Enrollment.Account_Custom2.Required';
    // export const UI_ENROLLMENT_ACCOUNTCUSTOM3_REQUIRED = 'UI.Enrollment.Account_Custom3.Required';
    // export const UI_ENROLLMENT_ACCOUNTCUSTOM4_REQUIRED = 'UI.Enrollment.Account_Custom4.Required';
    let allRequiredFields = [];
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_REQUIRED]) && allRequiredFields.push('RelationshipID');
    }
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_REQUIRED]) && allRequiredFields.push('AdvisorID');
    }
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_PARTNERID_REQUIRED]) && allRequiredFields.push('Account_PartnerID');
    }
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_TEAMID_REQUIRED]) && allRequiredFields.push('Account_TeamID');
    }
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_CLIENTID_REQUIRED]) && allRequiredFields.push('Account_ClientID');
    }
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_INVESTMENTID_REQUIRED]) && allRequiredFields.push('Account_InvestmentID');
    }
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM1_REQUIRED]) && allRequiredFields.push('Account_Custom1');
    }
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM2_REQUIRED]) && allRequiredFields.push('Account_Custom2');
    }
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM3_REQUIRED]) && allRequiredFields.push('Account_Custom3');
    }
    {
      checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ACCOUNTCUSTOM4_REQUIRED]) && allRequiredFields.push('Account_Custom4');
    }
    this.setState({ allRequiredFields });
  };

  getTooltips = () => {
    return new Promise((resolve, reject) => {
      let { services, user } = this.props;
      // fetch(`${this.props.services.Dashboard.URL}/HELP?$filter=contains(HelpID,'Tooltip.Enrollment.Enroll')`, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     "Ocp-Apim-Subscription-Key": this.props.services.Dashboard.subscription_key,
      //   },
      //   method: "GET",
      //   credentials: "include",
      // })
      AccountModels.getTooltips({ services, user })

        .then((response) => {
          if (!response.error) {
            let helpInformation = response.body;
            if (helpInformation.value) {
              helpInformation.value.map((item) => {
                helpArray[item.HelpID] = item.Text;
                helpArray[item.HelpID.toUpperCase()] = item.Text;
                return {
                  HelpID: item['HelpID'],
                };
              });
              this.setState(
                {
                  helpInformation: helpArray,
                },
                () => {
                  resolve();
                }
              );
            } else {
              this.setState(
                {
                  helpInformation: null,
                },
                () => {
                  resolve();
                }
              );
            }
          }
        })
        .catch((err) => {
          // validationManager.CustomAlert(true, "UNKNOWN ERROR", "Exception Caught");
        });
    });
  };

  setDefaults = () => {
    let { capacities } = this.state;

    // If query strings are included in URL query params, set them
    let queries = null;
    // queries = Object.assign({}, qs.parse(this.props.location.search, { ignoreQueryPrefix: true }));

    // Or If ExternalData is present in cookies list use it instead
    if (getCookie('ExternalData') !== '' && JSON.parse(getCookie('ExternalData'))) {
      queries = JSON.parse(getCookie('ExternalData'));
      document.cookie = `ExternalData=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;${globals.SAMESITECOOKIE}`;
    }
    // Handle Numbered Signer and Capacity Defaults
    let fieldMap = {
      firstname: 'FirstName',
      lastname: 'LastName',
      email: 'Email',
      capacity: 'Capacity',
    };

    let objects = [];
    let i = 1;
    while (i < 5) {
      let x = i.toString();
      let temp = Object.keys(queries)
        .filter((key) => key.includes(x))
        .map((key) => {
          let keyLabel = fieldMap[key.replace(i, '').toLowerCase()];
          return {
            index: i,
            source: key,
            name: keyLabel,
            value: queries[key],
          };
        });
      objects.push(temp);
      i++;
    }

    Object.keys(objects)
      .filter((key) => objects[key].length !== 0)
      .map((key, index) => {
        let firstname = '',
          lastname = '',
          email = '',
          capacity = '',
          capacityDefault = [];
        Object.keys(objects[key]).map((key2) => {
          switch (objects[key][key2].name) {
            case 'FirstName':
              firstname = objects[key][key2].value;
              break;
            case 'LastName':
              lastname = objects[key][key2].value;
              break;
            case 'Email':
              email = objects[key][key2].value;
              break;
            case 'Capacity':
              capacity = objects[key][key2].value;
              break;
            default:
              break;
          }
        });
        Object.keys(capacities)
          .filter((key) => capacities[key].label.toLowerCase().replace(/\s/g, '') === capacity.toLowerCase().replace(/\s/g, ''))
          .map((key) => {
            capacityDefault[capacities[key].label.toLowerCase().replace(/\s/g, '')] = capacities[key].value.toLowerCase().replace(/\s/g, '');
          });

        this.onSignerLoad(firstname, lastname, email, capacity, capacityDefault, index);
      });

    // Handle all free formed text fields
    let fieldMapOther = {
      firmadvisorid: 'AdvisorID',
      firmrelationshipid: 'RelationshipID',
      accountname: 'AccountTitle',
      accountnumber: 'AccountName',
      institution: 'CustodianName',
      custodianname: 'CustodianName',
      custodian: 'CustodianName',
      investorid: 'InvestorID',
      investorname: 'InvestorName',
    };

    Object.keys(queries)
      .filter((key) => fieldMapOther[key.toLowerCase()])
      .map((key) => {
        let keyName = fieldMapOther[key.toLowerCase()];
        this.updateAccountData(keyName, queries[key]);
      });

    // Handle drop down defaults
    let fieldMapDropDowns = {
      accounttype: 'default_AccountType',
    };
    Object.keys(queries)
      .filter((key) => fieldMapDropDowns[key.toLowerCase()])
      .map((key) => {
        let keyName = fieldMapDropDowns[key.toLowerCase()];
        this.setState({ [keyName]: queries[key] });
      });
  };

  onSignerLoad = (firstname, lastname, email, capacity, capacityDefault, index) => {
    let obj = Object.assign({}, this.props.accountData);
    // Handle dropdown Capacity 1 - x Default
    if (capacityDefault[capacity.toLowerCase().replace(/\s/g, '')]) {
      obj.ACCOUNT_SIGNER_LINK.push({
        CONTACT_DETAIL: {
          FullName: firstname + ' ' + lastname,
          FirstName: firstname,
          LastName: lastname,
          Email: email,
        },
        SIGNER_CAPACITY: {
          Name: capacity ? capacity : null,
          CapacityID: capacityDefault[capacity] ? capacityDefault[capacity] : null,
        },
        CapacityID: this.state.capacities[0] ? this.state.capacities[0].value : null,
        SignerCapacity0: {
          label: this.state.capacities[0] ? this.state.capacities[0].label : null,
          value: this.state.capacities[0] ? this.state.capacities[0].value : null,
        },
      });
      this.onCapacityChange({ label: capacity, value: capacityDefault[capacity.toLowerCase().replace(/\s/g, '')] }, false, index, true);
    } else {
      obj.ACCOUNT_SIGNER_LINK.push({
        CONTACT_DETAIL: {
          FullName: firstname + ' ' + lastname,
          FirstName: firstname,
          LastName: lastname,
          Email: email,
        },
        SIGNER_CAPACITY: {
          Name: this.state.capacities[0] ? this.state.capacities[0].label : null,
          CapacityID: this.state.capacities[0] ? this.state.capacities[0].value : null,
        },
        CapacityID: this.state.capacities[0] ? this.state.capacities[0].value : null,
        SignerCapacity0: {
          label: this.state.capacities[0] ? this.state.capacities[0].label : null,
          value: this.state.capacities[0] ? this.state.capacities[0].value : null,
        },
      });
      this.onCapacityChange(
        { label: this.state.capacities[0] ? this.state.capacities[0].label : null, value: this.state.capacities[0] ? this.state.capacities[0].value : null },
        false,
        index,
        true
      );
    }

    this.props.updateAccountData(obj).then(() => {
      //For Maintaining focus and moving cursor to end
      this[`signer${index}`].focus();
      let tmp = this[`signer${index}`].value;
      this[`signer${index}`].value = '';
      this[`signer${index}`].value = tmp;
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.toEnroll) {
      this.setState({ toEnroll: false });
    }

    ReactTooltip.rebuild();
    let { user, services, accountData } = this.props;
    const { enrollHierarchyShow } = this.state;
    if (prevProps.accountData.CustodianID !== accountData.CustodianID && accountData.CustodianID) {
      if (accountData.AccountID && enrollHierarchyShow) {
        const hierarchyData = await AccountModels.getHierarchyData({ user, services, accountData });

        if (hierarchyData) {
          this.setState((prevState) => {
            let temp = { ...prevState.hierarchyData };
            hierarchyParams.forEach((param) => {
              if (hierarchyData[param.field]) {
                temp[param.id] = {
                  label: hierarchyData[param.field],
                  value: hierarchyData[param.field],
                };
              }
            });
            return { hierarchyData: temp };
          });
        }
      }

      // BMILLER 10/18/2022 Commenting this out - I am not sure why we are clearing these values everytime the component is updated.
      // await this.updateAccountData('DatasourceID', { label: '', value: null }, null, null, null, null, null, null, true);
      // await this.updateAccountData('Fund', { label: '', value: null }, null, null, null, null, null, null, true);

      this.getCustodianContacts();
      this.getFunds();
      this.buildComparedAccount(accountData);
    }

    if (!prevProps.accountData.AccountTypeID && accountData.AccountTypeID) {
      this.getTypes();
    }
    if (!prevProps.accountData.Fund_UID && accountData.Fund_UID) {
      this.getFunds();
    }

    if (this.props.forceUpdate) {
      this.props.setForceUpdate(false);
      await Promise.all([this.getCustodianContacts(), this.getFunds(), this.getCapacities(), this.getTypes()]);
      this.setState({ loadSpinner: false });
    }
  }

  buildComparedAccount = (acct) => {
    let Name, ID, comparedAccountInfo;
    switch (acct.ManagerType) {
      case 'ALTERNATIVE':
        Name = acct.InvestorName;
        ID = acct.Fund_UID;
        comparedAccountInfo = { Name, ID };
        this.setState({ comparedAccountInfo });
        break;
      case 'BROKERAGE':
        Name = acct.AccountName;
        ID = acct.CustodianID;
        comparedAccountInfo = { Name, ID };
        this.setState({ comparedAccountInfo });
        break;
      default:
        this.setState({ comparedAccountInfo: {} });
        break;
    }
  };

  async getLoaActions() {
    let { user, services } = this.props;
    let loaActions = {};
    let result = await AccountModels.getLoaActions({
      user,
      services,
    });
    result.map((action) => {
      loaActions[action.Name] = action.ActionID;
    });
    this.setState({ loaActions });
  }

  async getTypes() {
    let { user, services, accountData } = this.props;
    let { AccountTypeID } = accountData;
    let { default_AccountType } = this.state;
    let otherTypeId;
    let types = await DropdownModels.getTypes({
      user,
      services,
      filter: `Name ne 'Brokerage' and Name ne 'Alternative'`,
      orderby: `Name`,
    });
    let defaultAccountType = default_AccountType ? default_AccountType.toLowerCase().replace(/\s/g, '') : '';
    let tempTypes = types.map((type) => {
      if ((AccountTypeID && type.TypeID === AccountTypeID.value) || defaultAccountType === type.Name.toLowerCase().replace(/\s/g, '')) {
        this.updateAccountData(
          'AccountTypeID',
          {
            label: type.Name,
            value: type.TypeID,
          },
          null,
          null,
          null,
          null,
          null,
          null,
          true
        );
      }
      if (type.Name === 'Other') {
        otherTypeId = type.TypeID;
      }
      return {
        label: type.Name,
        value: type.TypeID,
      };
    });
    this.setState({ accountTypeOptions: tempTypes, otherTypeId });
  }

  async getHierarchyOptions() {
    const { user, services } = this.props;
    const hierarchySelectOptions = await AccountModels.getHierarchyOptions({
      user,
      services,
      hierarchyParams,
    });
    this.setState({ hierarchySelectOptions });
  }

  async getCustodianContacts() {
    let { user, services, accountData } = this.props;
    let { CustodianID, CustodianContact } = accountData;
    if (CustodianID) {
      let [custcontacts] = await Promise.all([
        DropdownModels.getContactsByCustodian({
          user,
          services,
          custodian: CustodianID,
        }),
      ]);

      let temp = custcontacts.map((cont, index) => {
        if (index === 0 && !CustodianContact) {
          this.updateAccountData(
            'CustodianContact',
            {
              label: cont.CONTACT.NameAlias,
              value: cont.CONTACT.ContactID,
              DetailID: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0].DetailID : null,
              data: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0] : null,
              status: cont.CONTACT.Status,
            },
            null,
            null,
            null,
            null,
            null,
            null,
            true
          );
        }
        if (CustodianContact && cont.CONTACT && CustodianContact.value === cont.CONTACT.ContactID) {
          this.updateAccountData(
            'CustodianContact',
            {
              label: cont.CONTACT.NameAlias,
              value: cont.CONTACT.ContactID,
              DetailID: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0].DetailID : null,
              data: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0] : null,
              status: cont.CONTACT.Status,
            },
            null,
            null,
            null,
            null,
            null,
            null,
            true
          );
        }

        return {
          label: cont.CONTACT.NameAlias,
          value: cont.CONTACT.ContactID,
          DetailID: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0].DetailID : null,
          data: cont.CONTACT.CONTACT_DETAIL[0] ? cont.CONTACT.CONTACT_DETAIL[0] : null,
          status: cont.CONTACT.Status,
        };
      });

      temp = temp.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

      this.setState(
        {
          custodiancontacts: temp,
        },
        () => {
          return;
        }
      );
    }
  }

  async getCapacities() {
    let { user, services, accountData } = this.props;
    let { ACCOUNT_SIGNER_LINK } = accountData;
    let tempCaps = [];
    let caps = await DropdownModels.getCapacities({
      user,
      services,
    });
    tempCaps = caps.map((cap) => {
      for (let i in ACCOUNT_SIGNER_LINK) {
        if (ACCOUNT_SIGNER_LINK[i] && ACCOUNT_SIGNER_LINK[i].SIGNER_CAPACITY && cap.CapacityID === ACCOUNT_SIGNER_LINK[i].SIGNER_CAPACITY.CapacityID) {
          this.updateAccountData(
            null,
            null,
            null,
            null,
            'ACCOUNT_SIGNER_LINK',
            i,
            'CapacityID',
            {
              label: cap.Name,
              value: cap.CapacityID,
            },
            true,
            true
          );
          this.onCapacityChange({ label: cap.Name, value: cap.CapacityID }, false, i, true);
        }
      }
      return {
        label: cap.Name,
        value: cap.CapacityID,
      };
    });

    tempCaps = tempCaps.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    this.setState({ capacities: tempCaps });
  }

  async getStatus() {
    let { user, services, accountData } = this.props;
    let { Status } = accountData;

    let statuss = await DropdownModels.getStatus({
      user,
      services,
    });
    let temp = statuss.map((status) => {
      if (status.Name === 'New') {
        if (!Status) {
          this.updateAccountData('Status', status.Status, 'StatusName', status.Name, null, null, null, null, true, true);
        }
        this.setState({ newStatus: status.Status });
      }
      if (status.Name === 'Enrolling') {
        this.setState({ enrollingLoaStatus: status.Status });
      }

      return {
        label: status.Name,
        value: status.Status,
      };
    });
    this.setState({ statusList: temp });
  }

  async getFunds() {
    let { user, services, accountData } = this.props;
    let { CustodianID } = accountData;

    if (accountData.CustodianID) {
      let funds = await DropdownModels.getFunds({
        user,
        services,
        filter: `Manager_UID eq ${CustodianID} and Status ne 'INACTIVE'`,
      });
      funds = funds.sort();
      let temp = funds.map((fund) => {
        if (this.props.accountData.Fund_UID === fund.Fund_UID) {
          this.updateAccountData('Fund', { label: fund.Name, value: fund.Fund_UID, status: fund.Status }, null, null, null, null, null, null, true);
        }
        return {
          label: fund.Name,
          value: fund.Fund_UID,
          status: fund.Status,
        };
      });
      if (temp.length === 1) {
        this.updateAccountData(
          'Fund',
          {
            label: temp[0].label,
            value: temp[0].value,
            status: temp[0].status,
          },
          null,
          null,
          null,
          null,
          null,
          null,
          true
        );
      }
      this.setState({ funds: temp });
    }
  }

  resetAccountPage = (home, toAccounts) => {
    this.setState(
      {
        multipleAccountsSet: false,
        multipleAccountsString: '',
        hierarchyData: {},
      },
      () => {
        this.props.setMyAccountsLoaded(false);
        this.props.setIsEdit(false);
        this.props.setSelectedAccount(null, null);
        this.props.updateAccountData({ ACCOUNT_SIGNER_LINK: [] });
        this.handleManagerTypeChange(null);
        if (home) {
          this.setState({ toAuthorizations: true });
        } else if (toAccounts) {
          this.setState({ toMyEnrollments: true });
        } else {
          this.setState({ toEnroll: true });
        }
      }
    );
  };

  onCancelClick = ({ home, toAccounts, toEnroll }) => {
    let { edit } = this.props;
    let { enrollment_anonymized } = this.state;
    ReactTooltip.rebuild();
    // There was a hardcoded redirect to Authorizations.. Added a policy driven option to determine what Home is
    // UI.Enrollment.Enroll.Home.Redirect.Section
    // Otherwise, use the previous logic if it doesn't exist or is set to Authorizations
    if ((this.state.enrollHomeSection === 'Accounts' && home !== false) || enrollment_anonymized) {
      toAccounts = true;
      home = '';
    }

    if (toEnroll || home === false) {
      toAccounts = false;
      home = '';
    }
    if (!this.state.isFormDirty) {
      this.setState({ accountTypePrompt: true, showRequired: false }, () => {
        this.resetAccountPage(home, toAccounts);
      });
    } else {
      //warning
      confirmAlert({
        title: <div className="unsaved-changes-header">Warning</div>,
        childrenElement: () => {
          return (
            <div className="unsaved-changes-div">
              <div>All unsaved changes will be lost!</div>
            </div>
          );
        },
        buttons: [
          { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
          {
            label: <div id="continue-confirm-button">Continue without Saving</div>,
            id: 'continue-confirm-button',
            onClick: () => {
              this.setState({ accountTypePrompt: true, isFormDirty: false, showRequired: false, multipleAccountsData: null }, () => {
                this.resetAccountPage(home);
              });
            },
          },
        ],
      });
    }
  };

  isAccountMaskCorrect = (from, message = true) => {
    let { accountData, viewOnlyMode } = this.props;
    const { dirtyFields } = this.state;
    //Below is the disabled logic we use in render()
    //If we are in disabled mode, we do not want to check the account mask as there is no way to change the value
    let disabled = viewOnlyMode || !globals.REMOTE_INSTANCE ? !accountData.ManagerType : null;
    disabled = this.state.edit && this.state.multipleAccountsSet ? true : disabled;
    disabled = viewOnlyMode ? true : disabled;
    disabled = this.isAnyModalOpen() ? true : disabled;
    if (!disabled) {
      disabled = !disabled && accountData.LOA ? true : false;
    }

    //If not editing or we are editing the account name
    //If we dont have multiple accounts set
    //If we dont have the overridemask flag set
    //If our custodian has a mask
    //If generating a preview from existing account dont verify account mask
    if (
      (!this.props.edit || (this.props.edit && dirtyFields.AccountName)) &&
      !this.state.multipleAccountsSet &&
      !this.state.overrideMask &&
      accountData &&
      accountData.CustodianData &&
      accountData.CustodianData.AccountMask &&
      from !== 'preview' &&
      !disabled
    ) {
      let regex = new RegExp('^' + accountData.CustodianData.AccountMask + '$');
      let valid = false;
      if (accountData.AccountName && !regex.test(accountData.AccountName)) {
        if (message) {
          confirmAlert({
            title: <div className="unsaved-changes-header">Error</div>,
            childrenElement: () => {
              return (
                <div className="unsaved-changes-div">
                  <div>Account Number must be in the following format: {accountData.CustodianData.AccountMaskExplain}</div>
                </div>
              );
            },
            buttons: [{ label: <div id="continue-confirm-button">Continue</div>, onClick: () => {} }],
          });
        }
        return false;
      }
    }
    return true;
  };

  checkAccountMask = debounce(async () => {
    // This is to use the same isAccountMaskCorrect functionality but display realtime validation of the Account number being entered
    if (this.isAccountMaskCorrect('', false)) {
      this.setState({ validAccountMask: true });
    } else {
      this.setState({ validAccountMask: false });
    }
  }, 500);

  checkHasRequired = debounce(async () => {
    // This is to use the same hasRequired functionality but disable and enable buttons based on its response.
    if (this.hasRequiredFields(false)) {
      this.setState({ enableButton: true });
    } else {
      this.setState({ enableButton: false });
    }
  }, 500);

  getToday = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  };

  onConfirmCancelAccount = () => {
    confirmAlert({
      title: `Warning`,
      childrenElement: () => {
        return (
          <div className="delete-error-div">
            <div>Are you sure you would like to cancel this account?</div>
          </div>
        );
      },
      buttons: [
        {
          label: <div id="cancel-confirm-button">Cancel</div>,
          onClick: () => {},
        },
        {
          label: <div id="continue-confirm-button">Yes</div>,
          onClick: () => {
            // DEV-3702 Repurpose the Cancel Button in the Enrollment Screen
            // Use existing logic to update an account status, just force the values for this condition
            let obj = Object.assign({}, this.props.accountData);
            obj['Status'] = 'CANCELLED';
            obj['StatusObject'] = { label: 'Cancelled', value: 'CANCELLED' };
            this.props.updateAccountData(obj).then(() => {
              this.setState({ fromStatus: { label: 'New', value: 'NEW' } }, () => {
                this.saveAccount('', 'CANCEL_ACCOUNT');
              });
            });
          },
        },
      ],
    });
  };

  async saveAccount(from, action) {
    let { hierarchyData, dirtyFields, fromStatus, multipleAccountsSet, multipleAccountsData, otherTypeId, removedSigners, enableButton, fundData, enrollHierarchyShow } = this.state;
    const { user, services, accountData, edit, validSigners, crmData } = this.props;
    if (!this.hasRequiredFields() || !this.isAccountMaskCorrect(from)) {
      return;
    }
    // Reset enabled buttons when clicking save and new
    if (action === 'SAVE_AND_NEW') {
      enableButton = false;
    }

    this.setState({ loadSpinner: true, showRequired: false });
    this.setState({ accountTypePrompt: false });
    try {
      if (fromStatus && !from) {
        //Status Changed, we need to hit custom endpoint
        let toStatus = accountData.StatusObject ? accountData.StatusObject.value : null;
        if (fromStatus) {
          let statusChangeResponse = await AccountModels.changeAccountStatus({
            user,
            services,
            accountIDs: accountData.AccountID,
            fromStatus: fromStatus.value,
            toStatus: toStatus,
          });
          if (!statusChangeResponse.error && statusChangeResponse.body.Success) {
            this.setState({ fromStatus: toStatus.value });
          }
        }
      }

      let response;
      if (edit) {
        response = await AccountModels.patchAccount({
          user,
          services,
          accountData,
          hierarchyData,
          hierarchyParams,
          dirtyFields,
          otherTypeId,
          validSigners,
          removedSigners,
          showMessage: from || action === 'NEXT' ? globals.ON_FAIL : globals.ALWAYS,
          fundData,
          enrollHierarchyShow,
        });
      } else {
        response = await AccountModels.postAccount({
          user,
          services,
          accountData,
          hierarchyData,
          hierarchyParams,
          dirtyFields,
          otherTypeId,
          validSigners,
          multipleAccountsSet,
          multipleAccountsData,
          crmData,
          fundData,
          enrollHierarchyShow,
        });
      }

      if (!response.error && (response.body === null || response.body.Value.length === 1)) {
        this.buildComparedAccount(accountData);
      }

      this.setState({
        isFormDirty: false,
        dirtyFields: {},
        signerAdded: [],
        signerEdited: [],
        removedSigners: [],
        enableButton,
      });
      if (!edit || from !== 'preview') {
        this.setState({ isTokenized: true });
      }
      // Here we grab all the information from the newly enrolled account and go to the edit screen
      // We want to change this behavior for Enhanced Navigation.  So we are going to call setRedirect to route to different functions
      if (!response.error) {
        if (action) {
          if (action === 'SAVE_AND_NEW') {
            this.setState({ hierarchyData: { branch: null, advisor: null, 'super-house': null, house: null, 'tax-entity': null } });
          }

          this.setState({ loadSpinner: true });
          // Enhanced Navigation
          let accIds = [];
          let accId = '';
          if (response.body && response.body.Value && !multipleAccountsSet) {
            accId = `${response.body.Value[0].Value[0].AccountID}`;
          } else if (response.body && response.body.Value && multipleAccountsSet) {
            for (let i in response.body.Value) {
              accIds.push(response.body.Value[i].Value[0].AccountID);
            }
          }
          this.setState({ multipleAccountsIds: accIds, loaAcctId: accId }, () => {
            this.setRedirect(action, response, from);
          });
        } else {
          // Legacy

          //Filter down the fundData to only funds that have a Fund_UID
          if (fundData.length > 0) {
            fundData = fundData.filter(function (el) {
              return el.Fund_UID !== '';
            });
          }

          this.props.setIsEdit(true);
          if (response.body && response.body.Value && !multipleAccountsSet && fundData.length < 2) {
            this.props.history.push(`enroll/${response.body.Value[0].Value[0].AccountID}`);
          } else if (response.body && response.body.Value && (multipleAccountsSet || fundData.length > 1)) {
            let accIds = [];
            for (let i in response.body.Value) {
              accIds.push(response.body.Value[i].Value[0].AccountID);
            }
            this.setState({ edit: true });
          }
          if (multipleAccountsSet || fundData.length > 1) {
            // Set pending authorizations filter
            let today = this.getToday();
            let searchString = `${accountData.CustodianName}_${today}`;
            document.cookie = `Pending_filter=${JSON.stringify(searchString)};path=/;${globals.SAMESITECOOKIE}`;
            this.setState({ loadSpinner: false, toAuthorizations: true, pendingGrid: true });
          }
          this.props.setIsEdit(true);
          if (!multipleAccountsSet && fundData.length < 2 && from !== 'preview') {
            this.props.fetchAccountData();
          }
        }
      } else {
        this.setState({ loadSpinner: false, enableButton });
      }
    } catch {
      this.setState({ loadSpinner: false, enableButton });
    }
  }

  setRedirect = async (action, response, from) => {
    let { accountData } = this.props;
    let { multipleAccountsSet, fundData } = this.state;
    //SAVE_AND_EXIT
    //SAVE_AND_NEW
    //NEXT
    switch (action) {
      case 'CANCEL_ACCOUNT':
        this.setState({ loadSpinner: false, toMyEnrollments: true });
        break;
      case 'SAVE_AND_EXIT':
        this.setState({ loadSpinner: false, toMyEnrollments: true });
        break;
      case 'SAVE_AND_NEW':
        this.onNewClick();
        break;
      case 'NEXT':
        !multipleAccountsSet && fundData.length < 2 && (await this.generateLOA('PREVIEW'));

        this.setState({ loadSpinner: false });
        // Legacy
        this.props.setIsEdit(true);
        if (response.body && response.body.Value && !multipleAccountsSet && fundData.length < 2) {
          this.props.history.push(`enroll/${response.body.Value[0].Value[0].AccountID}`);
        } else if (response.body && response.body.Value && multipleAccountsSet) {
          let accIds = [];
          for (let i in response.body.Value) {
            accIds.push(response.body.Value[i].Value[0].AccountID);
          }
          this.setState({ edit: true });
        }
        if (multipleAccountsSet || fundData.length > 1) {
          // Set pending authorizations filter
          let today = this.getToday();
          let searchString = `${accountData.CustodianName}_${today}`;
          document.cookie = `Pending_filter=${JSON.stringify(searchString)};path=/;${globals.SAMESITECOOKIE}`;
          this.setState({ loadSpinner: false, toAuthorizations: true, pendingGrid: true });
        }
        this.props.setIsEdit(true);
        if (!multipleAccountsSet && fundData.length < 2 && from !== 'preview') {
          this.props.fetchAccountData();
        }
        break;
      default:
        break;
    }
  };

  async generateLOA(mode) {
    let { multipleAccountsIds, multipleAccountsSet, loaAcctId } = this.state;
    let { user, services, accountData, edit } = this.props;
    this.setState({ loadSpinner: true });

    let accountids = accountData.AccountID;
    if (!accountids && loaAcctId) {
      accountids = loaAcctId;
    }

    if (multipleAccountsSet) {
      accountids = [];
      for (let i in multipleAccountsIds) {
        accountids.push(multipleAccountsIds[i]);
      }
      accountids = accountids.join(',');
    }

    let result = await AccountModels.generateLoas({
      user,
      services,
      accountids,
      mode,
    });
    result = JSON.parse(result.body);
    if (result && !result.error && result[0] && result[0].content) {
      if (mode === 'PREVIEW') {
        this.setState({ loaPreviewData: result[0] });
        let content = result[0].content;
        let id = result[0].LOAID;
        var base = atob(content);
        var input = str2ab(base);
        var blob = new Blob([input], { type: 'application/pdf' });
        if (blob) {
          var ie = navigator.userAgent.match(/.NET/g);
          var edge = navigator.userAgent.match(/Edge/g);
          var fileURL = window.URL.createObjectURL(blob);

          this.setState(
            {
              loaFileData: fileURL,
              loaFileContent: content,
              loaFileBlob: blob,
              temporaryLOAID: id,
              generatedLOAID: id,
              loaStatus: result[0].LoaStatus,
            },
            () => {
              this.setState({ previewModalOpen: true, loadSpinner: false }, () => {
                this.setState({ loadSpinner: false });
                return;
              });
            }
          );
        } else {
          this.setState({ loaFileData: 'Error Previewing LOA', loadSpinner: false }, () => {
            return;
          });
          validationManager.toastMessage(false, 'Failed to Load!');
          this.setState({ loadSpinner: false });
        }
      } else if (mode === 'PRINT') {
        let content = result[0].content;
        let id = result[0].LOAID;
        var blob = this.b64toBlob(content, 'application/pdf');
        FileSaver.saveAs(blob, `${this.props.accountData.AccountName}.pdf`);

        await AccountModels.postLoaTracking({
          user,
          services,
          description: 'Print LOA document from Hosted-Enrollment',
          statusId: this.state.enrollingLoaStatus,
          actionId: this.state.loaActions.Print,
          loaId: id,
        });

        if (!multipleAccountsSet) {
          this.props.fetchAccountData();
        }
      } else if (mode === 'ELECTRONIC') {
        this.setState({ loadSpinner: false }, () => {
          this.setState({ isFormDirty: false, dirtyFields: {} });
          if (!this.state.multipleAccountsSet) {
            this.props.fetchAccountData();
            this.setState({ isTokenized: true });
          } else {
            this.updateAccountData('StatusObject', { label: 'Enrolling', value: 'ENROLLING' }, null, null, null, null, null, null, true); //To Hide Preview button
          }
        });
      }
    } else {
      this.setState({ loaFileData: 'Error Previewing LOA', loadSpinner: false }, () => {
        return;
      });
      this.setState({ loadSpinner: false });
    }
  }

  openLOAInNewTab = () => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(this.state.loaFileBlob, 'LOAPreview');
    }
  };

  handleViewPDF = (url) => {
    if (url) {
      window.open(url);
    }
  };

  renderPreviewLOA = () => {
    let { enhancedNavigation, helpInformation, loaFileData } = this.state;
    let { accountData } = this.props;
    var ie = navigator.userAgent.match(/.NET/g);
    var edge = navigator.userAgent.match(/Edge/g);
    let previewData = this.state.loaPreviewData;
    let preventSendMessage = '';
    //Flag to prevent automated LOA from going out in case of signers or custodian contact not having email address.
    let preventLOASend = false;
    //Flag to prevent manual and automated LOA from going out in case of "REVIEWING" operational data

    let preventLOAPrint = false;

    if (this.props.accountData.CustodianContact.status && this.props.accountData.CustodianData.CUSTODIAN_STATUS) {
      preventLOAPrint = this.props.accountData.CustodianData.CUSTODIAN_STATUS.Status.toUpperCase() !== 'ACTIVE' || this.props.accountData.CustodianContact.status.toUpperCase() !== 'ACTIVE'; // 1) Prevent if Custodian is not approved // 2) Prevent if Custodian Contact is not approved
    }

    if (accountData.Fund) {
      if (accountData.Fund.status) {
        if (accountData.Fund.status.toUpperCase() !== 'ACTIVE') {
          preventLOAPrint = true;
          preventSendMessage = 'Fund';
        }
      }
    }
    //Build the description as to why the user is no able to send or print the loa
    if (preventLOAPrint) {
      preventLOASend = true; //By default if LOA cannot be printed it cannot be sent either
      if (!preventSendMessage) {
        preventSendMessage = this.props.accountData.CustodianData.CUSTODIAN_STATUS.Status.toUpperCase() !== 'ACTIVE' ? 'Custodian' : '';
        if (this.props.accountData.CustodianContact.status.toUpperCase() !== 'ACTIVE') {
          preventSendMessage = preventSendMessage ? preventSendMessage + ' and Custodian Contact' : 'Custodian Contact';
        }
      }
      preventSendMessage = 'Note: Send and Print features are unavailable because the ' + preventSendMessage + ' is still under review.';
    }

    if (!preventLOASend) {
      //Check if the custodian contact does not have an email address
      if (!this.props.accountData.CustodianContact.data.Email) {
        preventLOASend = true;
      } else {
        this.props.accountData.ACCOUNT_SIGNER_LINK.forEach((link) => {
          //Check if at least one of the signers do not have an email address.
          if (!link.CONTACT_DETAIL.Email) {
            preventLOASend = true;
          }
        });
      }
      //Set the message if user is not able to send automated LOA
      if (preventLOASend) {
        preventSendMessage = preventLOASendMSG;
      }
    }

    //EsignatureFlag = false
    if (!preventLOASend) {
      //Check if the custodian contact does not have an email address
      if (this.props.accountData.CustodianData.EsignatureFlag === false) {
        preventLOASend = true;
      }
      //Set the message if user is not able to send automated LOA
      if (preventLOASend) {
        preventSendMessage = `Note: ${this.props.accountData.CustodianData.Name} does not accept e-signature.`;
      }
    }

    let accName = previewData.AccountNumbers ? previewData.AccountNumbers.join(', ') : '';
    let custName = previewData.CustodianName;
    let title = previewData.Title;
    let signers;
    if (previewData.AccountSigners) {
      signers = previewData.AccountSigners.map((name, index) => {
        return <li>{name}</li>;
      });
    }

    let custodianPreferences = [];
    if (this.props.accountData.CustodianData && this.props.accountData.CustodianData.RequireLOAFlag) {
      custodianPreferences.push(<li className="cust-preferences-li">Signed Document as PDF</li>);
    }
    if (this.props.accountData.CustodianData && this.props.accountData.CustodianData.EsignatureFlag) {
      custodianPreferences.push(<li className="cust-preferences-li">E-Signature</li>);
    }
    return (
      <div className="loa-preview-outer-div">
        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`loa-button-send`} place="top" type="info" effect="solid" className="tooltip_custom">
          {this.getTooltip('Tooltip.Enrollment.Enroll.Preview.LOA.Send')}
        </ReactTooltip>
        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`loa-button-print`} place="top" type="info" effect="solid" className="tooltip_custom">
          {this.getTooltip('Tooltip.Enrollment.Enroll.Preview.LOA.Print')}
        </ReactTooltip>
        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="loa-newwindow-info" place="left" type="info" effect="solid" className="tooltip_custom">
          Open PDF in new browser tab
        </ReactTooltip>
        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="loa-help-info" place="left" type="info" effect="solid" className="tooltip_custom">
          Open LOA help window
        </ReactTooltip>
        <div className="account-title"></div>
        {this.state.loaPreviewData ? (
          <div className="preview-wrapper">
            {this.state.loaFileData && !ie && !edge && (
              <object className="loa-preview-object" data={`${this.state.loaFileData}#toolbar=1&navpanes=0&scrollbar=0&view=fitH,100`} type="application/pdf">
                <embed src={this.state.loaFileData} type="application/pdf" />
              </object>
            )}
            {!this.state.loaFileData && !ie && !edge && <div className="faux-loa"></div>}
            <div className={enhancedNavigation ? 'loa-preview-summary-wrapper-enhanced' : 'loa-preview-summary-wrapper'}>
              <div className={enhancedNavigation ? '' : 'loa-preview-footer'}>
                <div
                  id="send-loa-button"
                  className={preventLOASend ? 'generic-button-primary generic-button-disabled generic-button-small' : 'generic-button-primary generic-button-small'}
                  data-tip
                  data-for={`loa-button-send`}
                  onClick={() => {
                    if (!preventLOASend) {
                      this.onConfirmSendClick();
                    }
                  }}
                >
                  Send
                </div>
                <div
                  id="print-loa-button"
                  className={
                    preventLOAPrint
                      ? enhancedNavigation
                        ? 'generic-button-secondary generic-button-enhanced-secondary-disabled generic-button-small'
                        : 'generic-button-primary generic-button-disabled generic-button-small'
                      : enhancedNavigation
                      ? 'generic-button-secondary generic-button-small'
                      : 'generic-button-primary generic-button-small'
                  }
                  data-tip
                  data-for={`loa-button-print`}
                  onClick={() => {
                    if (!preventLOAPrint) {
                      this.onConfirmPrintClick();
                    }
                  }}
                >
                  Print
                </div>
                {enhancedNavigation && (
                  <div
                    id="help-loa-button"
                    className={'generic-button-secondary generic-button-small'}
                    data-tip
                    data-for={`loa-help-info`}
                    onClick={() => {
                      linker.UniversalHelp('Enrollment.LOA.Preparation', 'popup');
                    }}
                  >
                    Help
                  </div>
                )}
                <div
                  data-tip
                  data-for="loa-newwindow-info"
                  className={'fa-external-link-loa-wrapper margin-top margin-left'}
                  id="view-newwindow-button"
                  onClick={() => {
                    this.handleViewPDF(loaFileData);
                  }}
                >
                  <FontAwesome name="fas fa-external-link" className="fa fas fa-external-link fa-external-link-loa" id="fa-external-link-icon" />
                </div>
              </div>
              <div>
                {preventLOASend && !enhancedNavigation && preventSendMessage && <div className="pending-status-note">{preventSendMessage}</div>}
                {/* Enhanced Navigation contains a help button - so this message cant live next to buttons */}
                {preventLOASend && enhancedNavigation && preventSendMessage && (
                  <div className="preview-loa-note-enhanced">{preventLOASend && enhancedNavigation && <div className="pending-status-note">{preventSendMessage}</div>}</div>
                )}
              </div>
              {/* <div className="preview-loa-note">{preventLOASend && enhancedNavigation && <div className="pending-status-note">{preventSendMessage}</div>}</div> */}
              <div className="loa-summary-details">
                <div>
                  <div className="loa-preview-summary-headers">Account</div>
                  <ul className="loa-preview-ul">
                    <li>{accName}</li>
                    <li>{custName}</li>
                    {/* <li>{title}</li>*/}
                  </ul>
                </div>
                <div>
                  <div className="loa-preview-summary-headers">Signers</div>
                  <ul className="loa-preview-ul">{signers}</ul>
                </div>
                <div>
                  {ie || edge ? (
                    <div>
                      <div className="loa-preview-summary-headers">LOA</div>
                      <ul className="view-loa-link loa-preview-ul" title="Open LOA Document in a New Tab" onClick={this.openLOAInNewTab}>
                        <li>Click to View Current LOA Document</li>
                      </ul>
                    </div>
                  ) : null}
                  <div className="loa-preview-summary-headers">Delivery</div>
                  <div>
                    <div className="loa-preview-delivery">
                      A Letter of Authorization for this account has not been registered as delivered. The Custodian/Manager has indicated they will accept the following:
                    </div>
                    <ul className="loa-preview-ul with-bullets">{custodianPreferences}</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="preview-wrapper preview-error">Error Previewing LOA</div>
        )}
      </div>
    );
  };

  renderExistingLOA = () => {
    let data = this.props.accountData;
    let accName = this.props.accountData.AccountName;
    let custName = this.props.accountData.CustodianName;
    let title = this.props.accountData.Title;
    let signers;
    if (data.ACCOUNT_SIGNER_LINK) {
      signers = data.ACCOUNT_SIGNER_LINK.map((signer, index) => {
        let name = signer.CONTACT_DETAIL.FirstName + ' ' + signer.CONTACT_DETAIL.LastName;
        return <li>{name}</li>;
      });
    }
    return (
      <div className="preview">
        {/* <div className='account-title'>View LOA</div> */}
        <div className="preview-wrapper">
          {this.state.existingLoaFileData ? (
            <object className="loa-preview-object" data={`${this.state.existingLoaFileData}#toolbar=1&navpanes=0&scrollbar=0&view=fitH,100`} type="application/pdf">
              <embed src={this.state.existingLoaFileData} type="application/pdf" />
            </object>
          ) : (
            <div className="faux-loa"></div>
          )}
          <div>
            <div>
              <div className="loa-preview-summary-headers">Account</div>
              <ul className="loa-preview-ul">
                <li>{accName}</li>
                <li>{custName}</li>
                {/* <li>{title}</li>*/}
              </ul>
            </div>
            <div>
              <div className="loa-preview-summary-headers">Signers</div>
              <ul className="loa-preview-ul">{signers}</ul>
            </div>
            <div>
              <div className="loa-preview-summary-headers">{/* Status */}</div>
              <div>{/* loa tracking table? */}</div>
            </div>
            <div className="loa-preview-footer">
              <div
                className="generic-button-primary"
                onClick={() => {
                  this.printLOA();
                }}
              >
                Print
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  async printLOA() {
    let { newStatus, enrollingLoaStatus } = this.state;
    let { user, services, accountData } = this.props;
    await AccountModels.getExistingLoa({
      user,
      services,
      newLoaId: this.state.generatedLOAID,
      accountId: accountData.AccountID,
    });
    await LOAModels.updateLoaStatus({
      user,
      services,
      fromStatus: 'NEW',
      toStatus: 'PRINTED',
      loaIds: this.state.generatedLOAID,
    });
    let content = this.state.existingFileContent || this.state.loaFileContent;
    var blob = this.b64toBlob(content, 'application/pdf');
    FileSaver.saveAs(blob, `${this.props.accountData.AccountName}.pdf`);
    this.setState({ previewModalOpen: false });

    await AccountModels.postLoaTracking({
      user,
      services,
      description: 'Print LOA document from Hosted-Enrollment',
      statusId: this.state.enrollingLoaStatus,
      actionId: this.state.loaActions.Print,
      loaId: this.props.accountData.LOAID || this.state.generatedLOAID,
    });

    if (!this.state.multipleAccountsSet) {
      this.props.fetchAccountData();
    }
  }

  onConfirmPrintClick = () => {
    const { enhancedNavigation } = this.state;
    var message = `${printLOABody}`;
    var warning = `WARNING: WHEN AVAILABLE E-SIGNATURE PROVIDES A MORE SECURE AND STREAMLINED SIGNER EXPERIENCE`;
    confirmAlert({
      title: `Print LOA`,
      childrenElement: () => {
        return (
          <div className="delete-error-div">
            <div>{message}</div>
            <br />
            {printLOAShowWarning && <div className="loa-print-warning">{warning}</div>}
          </div>
        );
      },
      buttons: [
        {
          label: <div id="cancel-confirm-button">Cancel</div>,
          onClick: () => {},
        },
        {
          label: <div id="continue-confirm-button">Print</div>,
          onClick: () => {
            this.setState({ previewModalOpen: false });
            this.generateLOA('PRINT').then(() => {
              enhancedNavigation && this.setRedirect('SAVE_AND_EXIT');
            });
          },
        },
      ],
    });
  };

  onConfirmSendClick = () => {
    let { loaAlertBody, enhancedNavigation } = this.state;
    var message = loaAlertBody;
    let policyWarning = this.props.user.firmPolicies[globals.UI_ENROLLMENT_LOA_SEND_WARNING];
    var warning = `WARNING: UPLOADING A PRINTED LOA AFTER THIS STEP WILL INVALIDATE ALL E-SIGNATURES, REQUIRING ALL PARTIES TO SIGN AGAIN MANUALLY.`;
    if (policyWarning) {
      warning = policyWarning.Value;
    }
    confirmAlert({
      title: `Send for E-Signatures`,
      childrenElement: () => {
        return (
          <div className="delete-error-div">
            <div>{message}</div>
            <br />
            <div className="loa-print-warning">{warning}</div>
          </div>
        );
      },
      buttons: [
        {
          label: <div id="cancel-confirm-button">Cancel</div>,
          onClick: () => {},
        },
        {
          label: <div id="continue-confirm-button">Send</div>,
          onClick: () => {
            this.setState({ previewModalOpen: false });
            this.generateLOA('ELECTRONIC').then(() => {
              enhancedNavigation && this.setRedirect('SAVE_AND_EXIT');
            });
          },
        },
      ],
    });
  };

  onBatchJobsClick = (batchPermission) => {
    if (batchPermission) {
      this.setState({ toBatchJobs: true });
    }
  };

  renderAccountDetails = () => {
    let { accountData } = this.props;
    return (
      <div>
        {/* <div className='account-title'>Account Details</div> */}

        <div className="data-table-wrapper">
          <table className="data-table">
            <thead></thead>
            <tbody>
              <tr>
                <td>Account Number</td> <td className="table-bold-col">{accountData.detokenizedName || accountData.AccountName}</td>
              </tr>
              <tr>
                <td>Account Name</td> <td className="table-bold-col">{accountData.detokenizedTitle || accountData.AccountTitle}</td>
              </tr>
              <tr>
                <td>Account Status</td> <td className="table-bold-col">{accountData.StatusName}</td>
              </tr>
              <tr>
                <td>Fund</td> <td className="table-bold-col">{accountData.Fund ? accountData.Fund.label : ''}</td>
              </tr>
              <tr>
                <td>Custodian</td> <td className="table-bold-col">{accountData.CustodianName}</td>
              </tr>
              <tr>
                <td>Advisor ID</td> <td className="table-bold-col">{accountData.AdvisorID}</td>
              </tr>
              <tr>
                <td>Relationship ID</td> <td className="table-bold-col">{accountData.RelationshipID}</td>
              </tr>
              <tr>
                <td>Last Updated</td> <td className="table-bold-col">{accountData.ChangeDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  async getPII() {
    let { user, services, accountData } = this.props;
    this.setState({ loadSpinner: true });
    let accounts = await AccountModels.getPII({
      user,
      services,
      accountId: accountData['AccountID'],
    });
    if (accounts && accounts[0]) {
      this.updateAccountData('AccountName', accounts[0].AccountName).then(() => {
        this.updateAccountData('AccountTitle', accounts[0].AccountTitle).then(() => {
          this.updateAccountData('InvestorID', accounts[0].InvestorID).then(() => {
            this.updateAccountData('InvestorName', accounts[0].InvestorName).then(() => {
              this.updateAccountData('AdvisorID', accounts[0].AdvisorID).then(() => {
                this.updateAccountData('Account_PartnerID', accounts[0].Account_PartnerID).then(() => {
                  this.updateAccountData('Account_TeamID', accounts[0].Account_TeamID).then(() => {
                    this.updateAccountData('Account_ClientID', accounts[0].Account_ClientID).then(() => {
                      this.updateAccountData('Account_InvestmentID', accounts[0].Account_InvestmentID).then(() => {
                        this.updateAccountData('Account_Custom1', accounts[0].Account_Custom1).then(() => {
                          this.updateAccountData('Account_Custom2', accounts[0].Account_Custom2).then(() => {
                            this.updateAccountData('Account_Custom3', accounts[0].Account_Custom3).then(() => {
                              this.updateAccountData('Account_Custom4', accounts[0].Account_Custom4).then(() => {
                                this.updateAccountData('RelationshipID', accounts[0].RelationshipID).then(() => {
                                  this.updateAccountData('TaxEntityID', accounts[0].RelationshipID).then(() => {
                                    this.setState(
                                      {
                                        loadSpinner: false,
                                        isTokenized: false,
                                        isFormDirty: false,
                                      },
                                      () => {
                                        this.buildComparedAccount(accounts[0]);
                                      }
                                    );
                                  });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    }
  }

  b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  hasRequiredFields = (message = true) => {
    let { accountData, edit, viewOnlyMode } = this.props;
    let { fundData, multipleAccountsSet, enrollment_anonymized } = this.state;

    let AllRequiredFields = BROKERAGERequiredFields;
    switch (accountData.ManagerType) {
      case 'BROKERAGE':
        AllRequiredFields = BROKERAGERequiredFields;
        break;
      case 'ALTERNATIVE':
        AllRequiredFields = ALTERNATIVERequiredFields;
        // Policy Based Required fields for Alternatives need to be set when we know what managerType is selected.
        if (checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_ALTERNATIVE_CUSTODIAN_REQUIRED])) {
          AllRequiredFields.push('Account_Related_Entity_UID');
        }
        break;
      case 'OTHER':
        AllRequiredFields = OTHERRequiredFields;
    }

    // Add Policy Required fields to list
    AllRequiredFields.push(...this.state.allRequiredFields);

    if (!this.state.enrollAccountTaxEntityShow || !this.state.enrollAccountTaxEntityEdit) {
      const idx = AllRequiredFields.indexOf('TaxEntityID');

      if (idx >= 0) {
        AllRequiredFields.splice(idx, 1);
      }
    }

    let nullFields = [];

    // Support for multiple funds
    if (accountData.ManagerType === 'ALTERNATIVE' && !edit && !viewOnlyMode) {
      if (fundData[0]) {
        if (fundData[0].Fund_UID === '' && !multipleAccountsSet) {
          nullFields.push('Fund0'); // Only first fund is required
        }
      }
      if (!fundData || (!fundData[0] && !multipleAccountsSet)) {
        nullFields.push('Fund'); // If there is no fundData at all
      }
    }
    if (!enrollment_anonymized) {
      // Logic for determining if Signers have input and emails
      if (accountData.ACCOUNT_SIGNER_LINK[0]) {
        if (accountData.ACCOUNT_SIGNER_LINK[0].CONTACT_DETAIL) {
          if (accountData.ACCOUNT_SIGNER_LINK[0].CONTACT_DETAIL.FullName === '') {
            nullFields.push('Signer0'); // Only first signer is required
          }
        }
      }

      if (!accountData.ACCOUNT_SIGNER_LINK || !accountData.ACCOUNT_SIGNER_LINK[0]) {
        nullFields.push('Signer');
      }
      if (accountData.ACCOUNT_SIGNER_LINK && accountData.ACCOUNT_SIGNER_LINK[0] && !accountData.ACCOUNT_SIGNER_LINK[0].CapacityID) {
        nullFields.push('CapacityID');
      }
    }

    for (let i in AllRequiredFields) {
      if (accountData[AllRequiredFields[i]] === null || accountData[AllRequiredFields[i]] === undefined || accountData[AllRequiredFields[i]] === '') {
        nullFields.push(AllRequiredFields[i]);
      }
      if (
        (AllRequiredFields[i] === 'AccountTypeID' || AllRequiredFields[i] === 'CustodianContact' || AllRequiredFields[i] === 'Fund') &&
        accountData[AllRequiredFields[i]] &&
        !accountData[AllRequiredFields[i]].value
      ) {
        nullFields.push(AllRequiredFields[i]);
      }
    }
    if (!this.props.validCustodian) {
      nullFields.push('CustodianName');
    }

    if (nullFields.length > 0) {
      if (message) {
        this.setState({ nullFields, showRequired: true });
        validationManager.CustomAlert(true, 'Please Provide All Required Fields!', 'Error');
        return false;
      } else {
        this.setState({ nullFields });
        return false;
      }
    } else {
      this.setState({ nullFields }); // Need to set nullFields even when its length = 0
    }
    return true;
  };

  confirmLOAStatusChange = (e) => {
    let message = 'Are you sure you want to update the status of this authorization?';
    let button = 'Proceed';

    confirmAlert({
      title: <div className="unsaved-changes-header">Warning</div>,
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div>{message}</div>
          </div>
        );
      },
      buttons: [
        { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
        {
          label: <div id="continue-confirm-button">{button}</div>,
          id: 'continue-confirm-button',
          onClick: () => {
            this.handleLOAStatusChange(e);
          },
        },
      ],
    });
  };

  async handleLOAStatusChange(e) {
    let { user, services, accountData } = this.props;

    let response = await LOAModels.updateLoaStatus({
      user,
      services,
      fromStatus: accountData.LOA.Status,
      toStatus: e.value,
      loaIds: accountData.LOA.LoaID,
    });
    if (!response.error) {
      this.props.getLoaStatusOptions(e.value);
      this.updateAccountData('LOAStatus', e.value).then(() => {
        this.props.fetchAccountData();
        this.setState({ isTokenized: true });
      });
    }

    return;
  }

  updateAccountData = (name, value, name2, value2, nestedObj, index, nestedName, nestedValue, fromLoad) => {
    this.checkHasRequired();
    this.checkAccountMask();
    return new Promise((resolve, reject) => {
      if (this.state.showRequired) {
        this.setState({ showRequired: false, nullFields: [] });
      }
      if (!fromLoad) {
        this.setState({ isFormDirty: true });
        this.setState({
          dirtyFields: {
            ...this.state.dirtyFields,
            [name]: true,
            [name2]: true,
            [nestedName]: true,
            [nestedValue]: true,
          },
        });
      }

      if (nestedObj) {
        let obj = Object.assign({}, this.props.accountData);
        obj[nestedObj][index][nestedName] = nestedValue;
        this.props.updateAccountData(obj).then(() => {
          resolve();
        });
        return;
      }

      if (name === 'LOAStatus') {
        let obj = Object.assign({}, this.props.accountData);
        obj['LOA']['Status'] = value;
        this.props.updateAccountData(obj).then(() => {
          resolve();
        });
        return;
      }

      let obj = Object.assign({}, this.props.accountData);
      obj[name] = value;
      if (name2) {
        obj[name2] = value2;
      }
      // if(name==='CustodianName') {
      //   this.props.setValidCustodian(false);
      // }
      if ((name === 'CustodianName' && value !== this.props.accountData.CustodianName) || (name === 'CustodianID' && value !== this.props.accountData.CustodianID)) {
        obj.CustodianContact = null;
        obj.AccountName = null; // Different Custodians have different account name rules.  Clear Account Name when a custodian is changed.
        this.setState({ custodiancontacts: [], overrideMask: false, multipleAccountsString: '', multipleAccountsSet: false, fundData: [] });
      }
      this.props.updateAccountData(obj).then(() => {
        if (name === 'CustodianName') {
          obj.CustodianID = null;
          this.props.updateAccountData(obj);
          this.props.setValidCustodian(false);
        }

        if (name2 === 'CustodianName' && (value2 !== null || value2 !== undefined)) {
          this.setState({ searchName: value2 });
        }
        if (this.props.accountData['AccountID'] && this.state.accountDataLoaded === false) {
          this.handleContactFilter();
          this.setState({ accountDataLoaded: true });
        }

        if (name === checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER])) {
          this.handleContactFilter();
        }

        resolve();
      });
    });
  };

  onSignerChange = (e, isNew, index) => {
    let obj = Object.assign({}, this.props.accountData);

    //Figure out firstname last name if user edits when in query mode
    let val = e.target.value.toString();
    let fullName = val;
    let firstName = val.split(' ').slice(0, -1).join(' ');
    let lastName = val.split(' ').slice(-1).join(' ');

    if (isNew) {
      obj.ACCOUNT_SIGNER_LINK.push({
        CONTACT_DETAIL: {
          FullName: fullName,
          FirstName: firstName,
          LastName: lastName,
        },
        SIGNER_CAPACITY: {
          Name: this.state.capacities[0] ? this.state.capacities[0].label : null,
          CapacityID: this.state.capacities[0] ? this.state.capacities[0].value : null,
        },
        // CapacityID: this.state.capacities[0] ? this.state.capacities[0].value : null,
        SignerCapacity0: {
          label: this.state.capacities[0] ? this.state.capacities[0].label : null,
          value: this.state.capacities[0] ? this.state.capacities[0].value : null,
        },
      });
    } else {
      obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.FullName = fullName;
      obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.FirstName = firstName;
      obj.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.LastName = lastName;
    }

    let tmpValid = Object.assign([], this.props.validSigners);
    if (tmpValid.includes(index)) {
      tmpValid.splice(tmpValid.indexOf(index), 1);
    }
    this.props.setValidSigners(tmpValid);
    this.props.updateAccountData(obj).then(() => {
      //For Maintaining focus and moving cursor to end
      this[`signer${index}`].focus();
      let tmp = this[`signer${index}`].value;
      this[`signer${index}`].value = '';
      this[`signer${index}`].value = tmp;

      if (this.state.capacities && !obj.ACCOUNT_SIGNER_LINK[index].CapacityID && !this.props.edit) {
        var def = { label: this.state.capacities[0].label, value: this.state.capacities[0].value };
        this.onCapacityChange(def, false, index);
      }
    });
    this.checkHasRequired();
  };

  onCapacityChange = (e, isNew, index, fromLoad) => {
    let obj = Object.assign({}, this.props.accountData);
    obj.ACCOUNT_SIGNER_LINK[index].CapacityID = e;
    this.props.updateAccountData(obj);
  };

  setValidSigner = (i) => {
    let tmpValid = Object.assign([], this.props.validSigners);
    if (!tmpValid.includes(i)) {
      tmpValid.push(i);
    }
    this.props.setValidSigners(tmpValid);
  };

  confirmRemoveSigner = (index) => {
    confirmAlert({
      title: <div className="unsaved-changes-header">Warning</div>,
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div>Are you sure you want to remove this signer from the account?</div>
          </div>
        );
      },
      buttons: [
        { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
        {
          label: <div id="continue-confirm-button">Proceed</div>,
          id: 'continue-confirm-button',
          onClick: () => {
            this.removeSigner(index);
          },
        },
      ],
    });
  };

  confirmRemoveFund = (index) => {
    confirmAlert({
      title: <div className="unsaved-changes-header">Warning</div>,
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div>Are you sure you want to remove this fund from the account?</div>
          </div>
        );
      },
      buttons: [
        { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
        {
          label: <div id="continue-confirm-button">Proceed</div>,
          id: 'continue-confirm-button',
          onClick: () => {
            this.removeFund(index);
          },
        },
      ],
    });
  };

  async confirmCancelLOA(status) {
    let message = 'Are you sure you want to delete this authorization from the account?';
    let message2 = '';
    let button = 'Proceed';
    if (status === 'NEW') {
      message2 = 'This action will void the existing DocuSign LOA and start a new LOA with the same Custodian, Contact, Signers, and Accounts.';
      button = 'Submit';
    }
    confirmAlert({
      title: <div className="unsaved-changes-header">Warning</div>,
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div>{message}</div>
            <div>
              <br />
            </div>
            <div>{message2}</div>
          </div>
        );
      },
      buttons: [
        { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
        {
          label: <div id="continue-confirm-button">{button}</div>,
          id: 'continue-confirm-button',
          onClick: () => {
            this.setState({ temporaryLOAID: this.props.accountData.LOA.LoaID }, () => {
              this.cancelLOA(status);
            });
          },
        },
      ],
    });
  }

  clearSignerFromDOM = (index) => {
    // Used to clear the last signers text box.  Bug when removing the element where the value still exists in the DOM.
    if (document.getElementById('signer' + index)) {
      document.getElementById('signer' + index).value = '';
    }
    if (index === -1) {
      document.getElementById('signer0').value = '';
    }
  };

  clearFundFromDOM = (index) => {
    // Used to clear the last fund text box.  Bug when removing the element where the value still exists in the DOM.
    if (document.getElementById('fund' + index)) {
      document.getElementById('fund' + index).value = '';
    }
    if (index === -1) {
      document.getElementById('fund0').value = '';
    }
  };

  removeSigner = async (index) => {
    let { accountData } = this.props;
    let delSignerIndex = accountData.ACCOUNT_SIGNER_LINK.length - 1;

    // Used to clear the last signers text box.  Bug when removing the element where the value still exists in the DOM.
    this.clearSignerFromDOM(delSignerIndex);

    let tmpValid = Object.assign([], this.props.validSigners);
    if (tmpValid.includes(index)) {
      if (accountData.ACCOUNT_SIGNER_LINK[index] && accountData.ACCOUNT_SIGNER_LINK[index].SignerID) {
        this.setState({
          removedSigners: [...this.state.removedSigners, accountData.ACCOUNT_SIGNER_LINK[index].SignerID],
          isFormDirty: true,
        });
      }
      tmpValid.splice(tmpValid.indexOf(index), 1);
      for (let i in tmpValid) {
        if (parseInt(i) >= index) {
          tmpValid[i]--;
        }
      }
    }

    let tempData = Object.assign({}, this.props.accountData);
    let tempSigners = tempData.ACCOUNT_SIGNER_LINK.filter((signer, i) => {
      return i !== index;
    });
    tempData.ACCOUNT_SIGNER_LINK = tempSigners;
    this.props.updateAccountData(tempData);
    this.props.setValidSigners(tmpValid);
  };

  removeFund = async (index) => {
    let { fundData: tempData, fundData } = this.state;
    let delFundIndex = fundData.length - 1;

    // Used to clear the last funds text box.  Bug when removing the element where the value still exists in the DOM.
    this.clearFundFromDOM(delFundIndex);

    let tempFunds = tempData.filter((fund, i) => {
      return i !== index;
    });
    tempData = tempFunds;
    this.setState({ fundData: tempData });
  };

  toTitleCase = (str) => {
    if (str === null || str === '') {
      return false;
    } else {
      str = str.toString();
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  toolTipSignerText = (index) => {
    let { helpInformation } = this.state;
    let managerType = this.toTitleCase(this.props.accountData.ManagerType);
    if (this.props.accountData.ManagerType === 'ALTERNATIVE') {
      if (index === 0) {
        if (helpInformation['Tooltip.Enrollment.Enroll.Alternative.SignerPrimary']) {
          return helpInformation['Tooltip.Enrollment.Enroll.Alternative.SignerPrimary'];
        } else {
          return <span>Click on Magnifying Glass to select an existing signer and / or add New. The Signer(s) are the Authorized person(s) on the account.</span>;
        }
      } else {
        if (helpInformation['Tooltip.Enrollment.Enroll.Alternative.Signer']) {
          return helpInformation['Tooltip.Enrollment.Enroll.Alternative.Signer'];
        } else {
          return <span> To add up to 4 signers, repeat the instructions for Signer 1. </span>;
        }
      }
    } else {
      //for other help types check if we have help text before returning Signer Field Help
      if (index === 0) {
        if (helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.SignerPrimary`]) {
          return helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.SignerPrimary`];
        } else {
          return <span> Signer Field Help</span>;
        }
      } else {
        if (helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.Signer`]) {
          return helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.Signer`];
        } else {
          return <span> Signer Field Help</span>;
        }
      }
      // return <span> Signer Field Help</span>;
    }
  };

  toolTipFundText = (index) => {
    let { helpInformation } = this.state;
    let managerType = this.toTitleCase(this.props.accountData.ManagerType);

    if (helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.Fund`]) {
      return helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.Fund`];
    } else {
      return <span>Select from existing funds using the telesense field or click [+] to begin adding a new Fund. Select up to 5 funds.</span>;
    }
  };

  toolTipCapacityText = (index) => {
    let { helpInformation } = this.state;
    let managerType = this.toTitleCase(this.props.accountData.ManagerType);
    if (this.props.accountData.ManagerType === 'ALTERNATIVE') {
      if (index === 0) {
        if (helpInformation['Tooltip.Enrollment.Enroll.Alternative.CapacityPrimary']) {
          return helpInformation['Tooltip.Enrollment.Enroll.Alternative.CapacityPrimary'];
        } else {
          return <span>The role of the authorized person(s) on the account. If Capacity choice is not found, please use Authorized Signatory as the default option.</span>;
        }
      } else {
        if (helpInformation['Tooltip.Enrollment.Enroll.Alternative.Capacity']) {
          return helpInformation['Tooltip.Enrollment.Enroll.Alternative.Capacity'];
        } else {
          return <span> To add up to 4 capacity roles for each signer, repeat the instructions for Capacity 1. </span>;
        }
      }
    } else {
      // for other help types check if we have help text before returning Capacity Field Help
      if (index === 0) {
        if (helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.CapacityPrimary`]) {
          return helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.CapacityPrimary`];
        } else {
          return <span>Capacity Field Help</span>;
        }
      } else {
        if (helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.Capacity`]) {
          return helpInformation[`Tooltip.Enrollment.Enroll.${managerType}.Capacity`];
        } else {
          return <span>Capacity Field Help</span>;
        }
      }
      // return <span>Capacity Field Help</span>;
    }
  };

  toolTipAuthStatusText = (index) => {
    return (
      <p className="align-left">
        NEW: LOA saved but not sent OR printed.
        <br />
        SENT: LOA sent for e-signature through DocuSign.
        <br />
        PRINTED: LOA is downloaded OR printed.
        <br />
        DECLINED: LOA declined by one OR more signer(s).
        <br />
        PARTIALLY SIGNED: LOA signed by one of multiple signers available.
        <br />
        DELIVERED: LOA signed by all parties and PCR is waiting for data.
        <br />
        CANCELLED: LOA cancelled due to error OR change.
        <br />
        ACKNOWLEDGED: LOA accepted by Custodian OR Manager.
        <br />
        REJECTED: LOA declined by Custodian OR Manager.
        <br />
        EXPIRED: LOA voided after no activity for 6 months.
        <br />
        ACTIVE: PCR is receiving data for account(s) covered in LOA.
        <br />
        REVOKED: LOA is revoked after being Active (all accounts).
      </p>
    );
  };

  getTooltip = (helpId) => {
    const { helpInformation } = this.state;
    if (helpInformation[helpId]) {
      return <span>{helpInformation[helpId]}</span>;
    } else {
      return <span></span>;
    }
  };

  handleContactFilter = async () => {
    //Generate Contact filter for contact search in ServerSide select (Mimic SearchContact.js)
    let { user, services, accountData } = this.props;

    let selectedSigners = accountData.ACCOUNT_SIGNER_LINK;

    let filter = '';

    //When user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER] is set and - filter on just the signers tied to the policy value

    if (user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER] && user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER].Value) {
      //Get the PII value if sanatized or tokenized
      if (accountData[user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER].Value + '-token']) {
        let accounts = await AccountModels.getPII({
          user,
          services,
          accountId: accountData['AccountID'],
        });
        accountData = accounts[0];
      }
      //Add ReferenceID to filter
      filter = filter ? ` and ${filter}` : '';
      filter = `(CONTACT/ReferenceID eq '${accountData[user.firmPolicies[globals.UI_ENROLLMENT_SIGNERFILTER].Value]}')${filter}`;
    }

    if (filter) {
      filter = filter ? `${filter} and ` : '';
      filter = filter + "(CONTACT/CONTACT_TYPE/ContactType ne 'Company')";
    } else {
      filter = "(CONTACT/CONTACT_TYPE/ContactType ne 'Company')";
    }

    this.setState({ contactFilter: filter });
  };

  handleContactSelect = (e, signerIndex) => {
    let { accountData } = this.props;

    if (e) {
      let { capacities } = this.state;
      let val = e.value;
      let obj = Object.assign({}, accountData);
      if (!obj.ACCOUNT_SIGNER_LINK || obj.ACCOUNT_SIGNER_LINK.length === 0) {
        obj.ACCOUNT_SIGNER_LINK = [{}];
      } else if (obj.ACCOUNT_SIGNER_LINK.length === signerIndex) {
        obj.ACCOUNT_SIGNER_LINK.push({});
      }

      val.FullName = val.FirstName + ' ' + val.LastName;
      obj.ACCOUNT_SIGNER_LINK[signerIndex].CONTACT_DETAIL = val;
      obj.ACCOUNT_SIGNER_LINK[signerIndex].ContactID = val.ContactID;
      obj.ACCOUNT_SIGNER_LINK[signerIndex].ContactDetailID = val.DetailID;
      if (capacities) {
        let defaultVal = capacities.find((Element) => Element.label === 'Authorized Signatory');
        obj.ACCOUNT_SIGNER_LINK[signerIndex].CapacityID = defaultVal;
      }

      this.props.updateAccountData(obj).then(() => {
        this.checkHasRequired();
      });
      this.setValidSigner(signerIndex);
    } else {
      let obj = Object.assign({}, accountData);
      obj.ACCOUNT_SIGNER_LINK[signerIndex].CONTACT_DETAIL['FullName'] = '';
      this.props.updateAccountData(obj).then(() => {
        this.checkHasRequired();
      });
    }
  };

  handleFundSelect = (e, index) => {
    let { fundData: obj } = this.state;

    if (e) {
      let val = e.value;

      if (!obj || obj.length === 0) {
        obj = [{}];
      } else if (obj.length === index) {
        obj.push({});
      }

      if (obj[index]) {
        obj[index].Fund_UID = val.Fund_UID;
        obj[index].Name = val.Name;
      }
    } else {
      obj[index].Fund_UID = '';
      obj[index].Name = '';
    }

    this.setState(
      {
        fundData: obj,
      },
      () => {
        this.checkHasRequired();
      }
    );
  };

  renderSignerInfo = (viewOnlyMode, disabled) => {
    let { showRequired, nullFields, contactFilter } = this.state;
    let { accountData, validSigners } = this.props;
    let managerType = this.toTitleCase(accountData.ManagerType);

    // Below excludes the selected ContactID from the other Signer drop downs to prevent from selecting the same signers.
    let excludeList = [];
    if (accountData) {
      if (accountData.ACCOUNT_SIGNER_LINK) {
        if (accountData.ACCOUNT_SIGNER_LINK.length > 0) {
          for (let contact of accountData.ACCOUNT_SIGNER_LINK) {
            contact.CONTACT_DETAIL.FullName !== '' && contact.ContactID && excludeList.push(`'${contact.ContactID}'`);
          }
          excludeList.join();
        }
        if (excludeList.length > 0 && contactFilter) {
          contactFilter = `${contactFilter} and (cast(ContactID , 'Edm.String') in (${excludeList}) eq false)`;
        } else if (excludeList.length > 0 && !contactFilter) {
          contactFilter = `(cast(ContactID , 'Edm.String') in (${excludeList}) eq false)`;
        }
      }
    }

    if (contactFilter) {
      contactFilter = `and ${contactFilter}`;
    }

    let signers = accountData && accountData.ACCOUNT_SIGNER_LINK ? accountData.ACCOUNT_SIGNER_LINK : [];
    let tmp = [];

    signers.map((signer, index) => {
      let className = !this.props.viewOnlyMode && validSigners.indexOf(index) === -1 ? 'account-input search-input italic' : 'account-input search-input';
      className = showRequired && nullFields.indexOf('Signer' + index) !== -1 ? className + ' req' : className;

      //Existing signers
      tmp.push(
        <div className="signer-info-item">
          <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`view-info-signer-${index}`} place="top" type="info" effect="solid" className="tooltip_custom">
            {this.toolTipSignerText(index)}
          </ReactTooltip>
          <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`view-info-signer-light-${index}`} place="top" type="info" effect="solid" className="tooltip_custom">
            <ul className="contact-detail-ul">
              {signer.CONTACT_DETAIL.Company ? <li>Company: {signer.CONTACT_DETAIL.Company}</li> : null}
              {signer.CONTACT_DETAIL.Title ? <li>Title: {signer.CONTACT_DETAIL.Title}</li> : null}
              {signer.CONTACT_DETAIL.Email ? <li>Email: {signer.CONTACT_DETAIL.Email}</li> : null}
            </ul>
          </ReactTooltip>

          <label className={disabled ? 'disabled-label' : 'label'} for={`signer${index}`}>
            {index === 0 && getRequiredAsterisk(true, '', '', viewOnlyMode)}Signer {index + 1} Name
            <i
              className="fa fas fa-question-circle"
              data-tip
              data-for={`view-info-signer-${index}`}
              onClick={() => {
                linker.UniversalHelp(index === 0 ? `Tooltip.Enrollment.Enroll.${managerType}.SignerPrimary` : `Tooltip.Enrollment.Enroll.${managerType}.Signer`, 'popup', null);
              }}
            ></i>
            <i className="fa fas fa-address-card" data-tip data-for={`view-info-signer-light-${index}`}></i>
          </label>
          <div className="input-container">
            <ServerSideSelect
              key={contactFilter}
              disabled={disabled}
              id={`signer${index}`}
              labelField="FirstName"
              labelField2="LastName"
              labelDelimiter=" "
              additionalFields={contactFilter}
              wrapperClassName={`search-input`}
              fetchProperties={this.state.ContactFetchProperties}
              selectClassName={index === 0 && showRequired && (nullFields.indexOf('Signer0') !== -1 || nullFields.indexOf('Signer') !== -1) ? 'account-select req' : 'account-select'} // specifically check Signer0 for required field check
              placeholder={null}
              isClearable={true}
              onChange={(e) => {
                this.handleContactSelect(e, index);
              }}
              ref={(input) => {
                this[`signer${index}`] = input;
              }}
              value={
                accountData.ACCOUNT_SIGNER_LINK && accountData.ACCOUNT_SIGNER_LINK[index]
                  ? accountData.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.FullName && {
                      label: accountData.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL.FullName,
                      value: accountData.ACCOUNT_SIGNER_LINK[index].CONTACT_DETAIL,
                    }
                  : null
              }
            />

            {!viewOnlyMode && (
              <button
                title="Search Signer"
                disabled={disabled}
                className={disabled ? 'disabled-label search-button' : 'search-button'}
                onClick={() => {
                  this.props.setSignerIndex(index);
                  this.setState({ isNewSigner: false, searchSignerModalOpen: true });
                }}
              >
                <FontAwesome className="fa fas fa-search" id={`search-signer-button-${index}`} name="fa-search" />
              </button>
            )}
            {!viewOnlyMode && signers.length > 1 && (
              <button
                title="Remove Signer"
                disabled={disabled}
                className={disabled ? 'disabled-label search-button remove-signer' : 'search-button remove-signer'}
                onClick={() => {
                  this.confirmRemoveSigner(index);
                }}
              >
                <FontAwesome className="fa fas fa-trash" id={`search-signer-button-${index}`} name="xbutton" />
              </button>
            )}
          </div>
        </div>
      );
      let capClassName = index === 0 && showRequired && nullFields.indexOf('CapacityID') !== -1 ? 'account-select req' : 'account-select';
      let id = index === signers.length - 1 ? 'capacityLast' : 'capacity' + index;
      tmp.push(
        <div className="capacity-info-item">
          <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`view-info-capacity-${index}`} place="top" type="info" effect="solid" className="tooltip_custom">
            {this.toolTipCapacityText(index)}
          </ReactTooltip>
          <label className={disabled ? 'disabled-label' : 'label'} for="capacity">
            Capacity {index + 1}
            <i
              className="fa fas fa-question-circle"
              data-tip
              data-for={`view-info-capacity-${index}`}
              onClick={() => {
                linker.UniversalHelp(index === 0 ? `Tooltip.Enrollment.Enroll.${managerType}.CapacityPrimary` : `Tooltip.Enrollment.Enroll.${managerType}.Capacity`, 'popup', null);
              }}
            ></i>
            {/* <i className='fa fas fa-question-circle' data-tip data-for={`view-info-capacity-${index}`}></i> */}
          </label>
          <Select
            className={
              disabled || !this.props.accountData.ACCOUNT_SIGNER_LINK || !this.props.accountData.ACCOUNT_SIGNER_LINK[index] ? `${capClassName} disabled-dropdown` : `${capClassName}`
            }
            id={id}
            filterOption={this.filterOption}
            isDisabled={disabled}
            value={accountData.ACCOUNT_SIGNER_LINK[index].CapacityID}
            onChange={(e) => {
              let val = e ? e.value : null;
              this.onCapacityChange(e, false, index);
            }}
            options={this.state.capacities}
          />
        </div>
      );
    });
    //Field in place to add a signer if they want

    if ((accountData && !this.props.edit) || (validSigners.length === signers.length && this.props.edit)) {
      let lastIndex = accountData.ACCOUNT_SIGNER_LINK ? accountData.ACCOUNT_SIGNER_LINK.length : 0;
      signerCount = lastIndex + 1;
      let lastClass = !this.props.viewOnlyMode && validSigners.indexOf(lastIndex) === -1 ? 'account-input search-input italic' : 'account-input search-input';
      lastClass = lastIndex === 0 && showRequired && nullFields.indexOf('Signer') !== -1 ? lastClass + ' req' : lastClass; //Null or not valid
      if (signerCount <= 4) {
        tmp.push(
          <div className="signer-info-item">
            <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-signer-last" place="top" type="info" effect="solid" className="tooltip_custom">
              {this.toolTipSignerText(lastIndex)}
            </ReactTooltip>
            <ReactTooltip>
              {
                // placeholder for contact tooltip
              }
            </ReactTooltip>
            <label className={disabled ? 'disabled-label' : 'label'} for="signer">
              {lastIndex === 0 && getRequiredAsterisk(true, '', '', viewOnlyMode)}Signer {lastIndex + 1} Name
              <i
                className="fa fas fa-question-circle"
                data-tip
                data-for={`view-info-signer-last`}
                onClick={() => {
                  linker.UniversalHelp(lastIndex === 0 ? `Tooltip.Enrollment.Enroll.${managerType}.SignerPrimary` : `Tooltip.Enrollment.Enroll.${managerType}.Signer`, 'popup', null);
                }}
              ></i>
              {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-signer-last'></i> */}
            </label>
            <div className="input-container">
              <ServerSideSelect
                key={contactFilter}
                disabled={disabled}
                id={`signer${lastIndex}`}
                labelField="FirstName"
                labelField2="LastName"
                labelDelimiter=" "
                additionalFields={contactFilter}
                wrapperClassName={`search-input`}
                fetchProperties={this.state.ContactFetchProperties}
                selectClassName={
                  lastIndex === 0 && showRequired && (nullFields.indexOf('Signer0') !== -1 || nullFields.indexOf('Signer') !== -1) !== -1 ? 'account-select req' : 'account-select'
                } // specifically check Signer0 for required field check
                placeholder=""
                isClearable={true}
                onChange={(e) => {
                  this.handleContactSelect(e, lastIndex);
                }}
                ref={(input) => {
                  this[`signer${lastIndex}`] = input;
                }}
                value={
                  accountData.ACCOUNT_SIGNER_LINK && accountData.ACCOUNT_SIGNER_LINK[lastIndex]
                    ? accountData.ACCOUNT_SIGNER_LINK[lastIndex].CONTACT_DETAIL.FullName && {
                        label: accountData.ACCOUNT_SIGNER_LINK[lastIndex].CONTACT_DETAIL.FullName,
                        value: accountData.ACCOUNT_SIGNER_LINK[lastIndex].CONTACT_DETAIL,
                      }
                    : null
                }
              />
              {viewOnlyMode ? null : (
                <button
                  disabled={disabled}
                  className={disabled ? 'disabled-label search-button' : 'search-button'}
                  onClick={() => {
                    this.props.setSignerIndex(lastIndex);
                    this.setState({ isNewSigner: true, searchSignerModalOpen: true });
                  }}
                >
                  <FontAwesome className="fa fas fa-search" id="search-signer-button-new" name="fa-search" />
                </button>
              )}
            </div>
          </div>
        );

        let capacityClassName = lastIndex === 0 && showRequired && nullFields.indexOf('CapacityID') !== -1 ? 'account-select req' : 'account-select';

        tmp.push(
          <div className="capacity-info-item">
            <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-capacity2" place="top" type="info" effect="solid" className="tooltip_custom">
              {this.toolTipCapacityText(lastIndex)}
            </ReactTooltip>
            <label className={disabled || !this.props.accountData.ACCOUNT_SIGNER_LINK || !this.props.accountData.ACCOUNT_SIGNER_LINK[lastIndex] ? 'disabled-label' : 'label'} for="capacity">
              Capacity {lastIndex + 1}
              <i
                className="fa fas fa-question-circle"
                data-tip
                data-for={`view-info-capacity2`}
                onClick={() => {
                  linker.UniversalHelp(lastIndex === 0 ? `Tooltip.Enrollment.Enroll.${managerType}.CapacityPrimary` : `Tooltip.Enrollment.Enroll.${managerType}.Capacity`, 'popup', null);
                }}
              ></i>
              {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-capacity2'></i> */}
            </label>
            <Select
              className={
                disabled || !this.props.accountData.ACCOUNT_SIGNER_LINK || !this.props.accountData.ACCOUNT_SIGNER_LINK[lastIndex]
                  ? `${capacityClassName} disabled-dropdown`
                  : `${capacityClassName}`
              }
              id="capacity"
              filterOption={this.filterOption}
              value={
                this.props.accountData.ACCOUNT_SIGNER_LINK && this.props.accountData.ACCOUNT_SIGNER_LINK[lastIndex] ? this.props.accountData.ACCOUNT_SIGNER_LINK[lastIndex].CapacityID : null
              }
              onChange={(e) => {
                let val = e ? e.value : null;
                this.onCapacityChange(e, true, lastIndex);
              }}
              options={this.state.capacities}
              isDisabled={disabled || !this.props.accountData.ACCOUNT_SIGNER_LINK || !this.props.accountData.ACCOUNT_SIGNER_LINK[lastIndex]}
            />
          </div>
        );
      }
    }
    return <div className="ga-signers">{tmp}</div>;
  };

  renderHierarchy = (viewOnlyMode) => {
    const tmp = [];
    hierarchyParams.forEach((param) => {
      tmp.push(
        <div className="hierarchy-info-item">
          <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`view-info-hierarchy-${param.id}`} place="top" type="info" effect="solid" className="tooltip_custom">
            Hierarchy {param.label} Item
          </ReactTooltip>
          <label className={viewOnlyMode ? 'disabled-label' : 'label'} for={`${param.id}`}>
            {param.label}
            <i
              className="fa fas fa-question-circle"
              data-tip
              data-for={`view-info-hierarchy-${param.id}`}
              onClick={() => {
                linker.UniversalHelp(`Tooltip.Enrollment.Enroll.Hierarchy.${param.field}`, 'popup', null);
              }}
            ></i>
          </label>
          <div className="input-container">
            <Select
              id={`${param.id}`}
              className={viewOnlyMode ? 'disabled-dropdown account-select' : 'account-select'}
              options={this.state.hierarchySelectOptions[param.id]}
              value={this.state.hierarchyData[param.id]}
              placeholder=""
              onChange={(e) => {
                this.setState((prevState) => {
                  let temp = { ...prevState.hierarchyData };
                  temp[param.id] = e;
                  return { hierarchyData: temp, isFormDirty: true };
                });
              }}
              isClearable={true}
              isDisabled={viewOnlyMode}
            />
            {!viewOnlyMode && (
              <button
                disabled={viewOnlyMode}
                className={viewOnlyMode ? 'disabled-label search-button hierarchy-plus-button' : 'search-button hierarchy-plus-button'}
                onClick={() => {
                  this.setState({ hierarchyOption: param.label, addHierarchyOptionModalOpen: true });
                }}
              >
                <FontAwesome className="fa fas fa-plus" id={`plus-${param.id}-new`} name="fa-plus" />
              </button>
            )}
          </div>
        </div>
      );
    });

    return <div className="ga-hierarchy">{tmp}</div>;
  };

  onHierarchyItemSave = () => {
    const option = hierarchyParams.find((param) => param.label === this.state.hierarchyOption);
    this.setState((prevState) => {
      let tempOptions = { ...prevState.hierarchySelectOptions };
      const newOption = {
        label: this.state.hierarchyItem,
        value: this.state.hierarchyItem,
      };
      tempOptions[option.id].push(newOption);
      let tempData = { ...prevState.hierarchyData };
      tempData[option.id] = newOption;
      return {
        hierarchySelectOptions: tempOptions,
        hierarchyData: tempData,
        addHierarchyOptionModalOpen: false,
        hierarchyItem: '',
      };
    });
  };

  renderFundInfo = (viewOnlyMode, disabled) => {
    let { showRequired, nullFields, fundData } = this.state;
    let { accountData } = this.props;
    let managerType = this.toTitleCase(accountData.ManagerType);

    let fundFilter = `Manager_UID eq ${accountData && accountData.CustodianID} and Status ne 'INACTIVE'`;

    // Below excludes the selected Fund_UID from the other Fund drop downs to prevent form selecting the same fund.
    let excludeList = [];
    if (fundData.length > 0) {
      for (let fund of fundData) {
        fund.Fund_UID && excludeList.push(`'${fund.Fund_UID}'`);
      }
      excludeList.join();
    }
    if (excludeList && excludeList.length > 0) {
      fundFilter = `${fundFilter} and (Fund_UID in (${excludeList}) eq false)`;
    }

    let funds = fundData ? fundData : [];
    let tmp = [];
    funds.map((fund, index) => {
      //Existing Funds
      tmp.push(
        <div className="signer-info-item">
          <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`view-info-fund-${index}`} place="top" type="info" effect="solid" className="tooltip_custom">
            {this.toolTipFundText(index)}
          </ReactTooltip>

          <label className={disabled ? 'disabled-label' : 'label'} for={`fund${index}`}>
            {index === 0 && getRequiredAsterisk(true, '', '', viewOnlyMode)}Fund {index + 1}
            <i
              className="fa fas fa-question-circle"
              data-tip
              data-for={`view-info-fund-${index}`}
              onClick={() => {
                linker.UniversalHelp(`Tooltip.Enrollment.Enroll.${managerType}.Fund`, 'popup', null);
              }}
            ></i>
          </label>
          <div className="input-container">
            <ServerSideSelect
              key={fundFilter}
              disabled={disabled}
              id={`fund${index}`}
              labelField="Name"
              labelDelimiter=" "
              additionalFields={`and ${fundFilter}`}
              wrapperClassName={`search-input`}
              fetchProperties={this.state.FundFetchProperties}
              selectClassName={index === 0 && showRequired && (nullFields.indexOf('Fund0') !== -1 || nullFields.indexOf('Fund') !== -1) ? 'account-select req' : 'account-select'} // specifically check Fund0 for required field check
              placeholder={null}
              isClearable={true}
              onClick={() => {
                this.setState({ showRequired: false });
              }}
              onChange={(e) => {
                this.handleFundSelect(e, index);
              }}
              ref={(input) => {
                this[`fund${index}`] = input;
              }}
              value={
                fundData && fundData[index]
                  ? fundData[index].Fund_UID && {
                      label: fundData[index].Name,
                      value: fundData[index].Fund_UID,
                    }
                  : null
              }
            />

            {!viewOnlyMode && (
              <button
                disabled={disabled}
                className="search-button"
                id="add-Fund-button"
                onClick={() => {
                  this.onAddClick('fund');
                }}
              >
                <FontAwesome className="fa fas fa-plus request-button" name="fas fa-plus" />
              </button>
            )}
            {!viewOnlyMode && funds.length > 1 && (
              <button
                title="Remove Fund"
                disabled={disabled}
                className={disabled ? 'disabled-label search-button remove-signer' : 'search-button remove-signer'}
                onClick={() => {
                  this.confirmRemoveFund(index);
                }}
              >
                <FontAwesome className="fa fas fa-trash" id={`search-signer-button-${index}`} name="xbutton" />
              </button>
            )}
          </div>
        </div>
      );
    });
    //Field in place to add a fund if they want
    if ((fundData && !this.props.edit) || (fundData.length === funds.length && this.props.edit)) {
      let lastIndex = fundData ? fundData.length : 0;
      fundCount = lastIndex + 1;
      if (fundCount <= 5) {
        tmp.push(
          <div className="signer-info-item">
            <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-fund-last" place="top" type="info" effect="solid" className="tooltip_custom">
              {this.toolTipFundText(lastIndex)}
            </ReactTooltip>

            <label className={disabled ? 'disabled-label' : 'label'} for="fund">
              {lastIndex === 0 && getRequiredAsterisk(true, '', '', viewOnlyMode)}Fund {lastIndex + 1}
              <i
                className="fa fas fa-question-circle"
                data-tip
                data-for={`view-info-fund-last`}
                onClick={() => {
                  linker.UniversalHelp(`Tooltip.Enrollment.Enroll.${managerType}.Fund`, 'popup', null);
                }}
              ></i>
            </label>
            <div className="input-container">
              <ServerSideSelect
                key={fundFilter}
                disabled={disabled}
                id={`fund${lastIndex}`}
                labelField="Name"
                labelDelimiter=" "
                additionalFields={`and ${fundFilter}`}
                wrapperClassName={`search-input`}
                fetchProperties={this.state.FundFetchProperties}
                selectClassName={lastIndex === 0 && showRequired && (nullFields.indexOf('Fund0') !== -1 || nullFields.indexOf('Fund') !== -1) ? 'account-select req' : 'account-select'} // specifically check Fund0 for required field check
                placeholder=""
                isClearable={true}
                onChange={(e) => {
                  this.handleFundSelect(e, lastIndex);
                }}
                ref={(input) => {
                  this[`fund${lastIndex}`] = input;
                }}
                value={
                  fundData && fundData[lastIndex]
                    ? fundData[lastIndex].Fund_UID && {
                        label: fundData[lastIndex].Name,
                        value: fundData[lastIndex].Fund_UID,
                      }
                    : null
                }
              />

              {viewOnlyMode ? null : (
                <button
                  disabled={disabled}
                  className="search-button"
                  id="add-Fund-button"
                  onClick={() => {
                    this.onAddClick('fund');
                  }}
                >
                  <FontAwesome className="fa fas fa-plus request-button" name="fas fa-plus" />
                </button>
              )}
            </div>
          </div>
        );
      }
    }
    return <div className="ga-funds">{tmp}</div>;
  };

  async getCurrentLOA() {
    let { user, services, accountData } = this.props;

    let id = accountData.LOAID || this.state.generatedLOAID;
    this.setState({ loadSpinner: true });
    let result = await AccountModels.getLoaDocument({
      user,
      services,
      loaid: id,
    });
    if (result.value.length > 0) {
      var base = atob(result.value[0].FileData);
      var input = str2ab(base);
      var blob = new Blob([input], { type: 'application/pdf' });
      this.setState({ loaStatus: result.value[0].LOA.Status, loaFileBlob: blob });
      if (blob) {
        var ie = navigator.userAgent.match(/.NET/g);
        var edge = navigator.userAgent.match(/Edge/g);
        var fileURL = window.URL.createObjectURL(blob);
        this.setState({
          existingLoaFileData: fileURL,
          existingFileContent: result.value[0].FileData,
          viewExistingModalOpen: true,
        });
      }
    } else {
      this.setState({
        loaFileError: "We're sorry, there was an error getting this PDF.",
        previewModalOpen: true,
      });
    }
    this.setState({ loadSpinner: false });
  }

  async cancelLOA(status) {
    let { user, services } = this.props;
    this.setState({
      loadSpinner: true,
      previewModalOpen: false,
      isTokenized: true,
    });

    let result = await AccountModels.cancelLoas({
      user,
      services,
      loaids: [this.state.temporaryLOAID],
    });

    this.setState({
      isFormDirty: false,
    });
    if (status === 'NEW') {
      this.saveAccount('preview').then(() => {
        this.props.fetchAccountData();
      });
    } else {
      this.props.fetchAccountData();
    }
  }

  addCustodianContactClick = () => {
    this.props.setCurrentScreen('custodian');
  };

  onAddClick = (requestFor) => {
    this.setState({ requestCustodianModalOpen: true });
    this.props.setRequestFor(requestFor, 'requestcustodian');
  };

  setRequestModalOpen = (bool) => {
    this.setState({ requestCustodianModalOpen: bool });
  };

  closeModal = () => {
    this.checkHasRequired();
    this.setState({
      searchCustodianModalOpen: false,
      searchCustodianRelated: false,
      searchTaxEntityModalOpen: false,
      searchSignerModalOpen: false,
      addHierarchyOptionModalOpen: false,
      previewModalOpen: false,
      accountDetailsModalOpen: false,
      viewExistingModalOpen: false,
      requestCustodianModalOpen: false,
      multipleAccountsModalOpen: false,
    });
  };

  handleManagerTypeChange = (selectedManagerType, fromEnroll) => {
    const { enrollment_anonymized } = this.state;
    if (enrollment_anonymized) {
      BROKERAGERequiredFields = BROKERAGERequiredFields_Anonymized;
      ALTERNATIVERequiredFields = ALTERNATIVERequiredFields_Anonymized;
    }
    this.setState(
      {
        ...this.state,
        tokenFilter: this.state.tokenFilter, //Don't want to reset this
        enableButton: false,
        accountDataLoaded: false,
        fundData: [],
      },
      () => {
        if (this.props.accountData && this.props.accountData.AccountID) {
          this.props.setIsEdit(true);
          var temp = JSON.parse(JSON.stringify(this.props.accountData));
          temp.ManagerType = selectedManagerType;
          temp.AccountTypeID = temp.AccountTypeID.value;
          this.props.setAccountData(temp);
        } else {
          let accountData = {};
          // accountData.ACCOUNT_SIGNER_LINK = this.props.accountData.ACCOUNT_SIGNER_LINK;
          accountData.ACCOUNT_SIGNER_LINK = [];

          // accountData.Status = this.props.accountData.Status;
          accountData.StatusName = this.props.accountData.StatusName;
          accountData.ManagerType = selectedManagerType;
          this.props.setIsEdit(false);
          this.props.setAccountData(accountData); //Reset All fields, Keep signers, those are fine
          // this.setState({selectedManagerType})
        }
        if (selectedManagerType != null) {
          this.setState({ accountTypePrompt: false, fromEnroll });
        }
      }
    );
  };

  getRequestSubtitle = () => {
    let { requestFor } = this.props;
    let subtitle;
    if (requestFor === 'custodian') {
      subtitle =
        'If you were unable to identify a Custodian, Institution or Manager in our known reporting sources you can request a new source here by providing as much information as you have and continue with your account enrollment.   Our team will research and confirm the information to complete your enrollment.';
    } else if (requestFor === 'manager') {
      subtitle =
        'If you were unable to identify a Custodian, Institution or Manager in our known reporting sources you can request a new source here by providing as much information as you have and continue with your account enrollment.   Our team will research and confirm the information to complete your enrollment.';
    } else if (requestFor === 'institution') {
      subtitle =
        'If you were unable to identify a Custodian, Institution or Manager in our known reporting sources you can request a new source here by providing as much information as you have and continue with your account enrollment.   Our team will research and confirm the information to complete your enrollment.';
    } else if (requestFor === 'fund') {
      subtitle =
        'If you were unable to identify the fund, you can request we investigate.   Provide the name of the fund and any other information that may be of help and then continue with your enrollment.';
    }
    return subtitle;
  };

  getRequestTitle = () => {
    let { requestFor } = this.props;
    let title;
    if (requestFor === 'custodian') {
      title = 'Request New Custodian';
    } else if (requestFor === 'manager') {
      title = 'Request New Manager';
    } else if (requestFor === 'institution') {
      title = 'Request New Institution';
    } else if (requestFor === 'fund') {
      title = 'Request New Fund';
    }
    return title;
  };

  addContact = (label) => {
    this.setState({ contactModalOpen: true, contactModalType: 'add', contactModalLabel: label, setCreateCustodianName: true });
  };

  handleOverrideMaskClick = () => {
    let { multipleAccountsSet } = this.state;
    if (this.state.overrideMask) {
      this.setState({ overrideMask: false, multipleAccountsSet: false }, () => {
        this.updateAccountData('AccountName', '');
        // this.state.multipleAccountsSet = false;
      });
    } else {
      confirmAlert({
        title: <div className="unsaved-changes-header">Warning</div>,
        childrenElement: () => {
          return (
            <div className="unsaved-changes-div">
              <div>Check statement for correct account number pattern. Use only if account number provided by client does not match pattern.</div>
            </div>
          );
        },
        buttons: [
          { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
          {
            label: <div id="continue-confirm-button">Override</div>,
            id: 'continue-confirm-button',
            onClick: () => {
              multipleAccountsSet === true && this.updateAccountData('AccountName', '');
              this.setState({ overrideMask: true, multipleAccountsSet: false, multipleAccountsString: '' });
            },
          },
        ],
      });
    }
  };

  handleClearMultipleAccounts = () => {
    this.updateAccountData('AccountName', '').then(() => {
      this.updateAccountData('AccountTitle', '').then(() => {
        this.updateAccountData('InvestorName', '').then(() => {
          this.updateAccountData('Fund', '').then(() => {
            this.setState({
              multipleAccountsSet: false,
              multipleAccountsModalOpen: false,
              multipleAccountsError: false,
              erroredAccountNames: [],
              multipleAccountsData: null,
            });
          });
        });
      });
    });
  };
  accountValidator() {
    // multipleAccountsError
    let { multipleAccountsString } = this.state;
    let { accountData } = this.props;
    let accountStringReplace = multipleAccountsString.trim().replace(/\s+/g, '\\n\n') + '\\n';
    let accountsArray = accountStringReplace.split(/\r?\n/);
    let erroredAccountNames = [];
    let uniqueAccountNames = [];
    // let regex = RegExp('[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9][0-9]-[0-9]'); //Set to custodian regex
    let regex = accountData.CustodianData.AccountMask ? (!this.state.overrideMask ? RegExp(`^${accountData.CustodianData.AccountMask}$`) : { test: () => true }) : { test: () => true };
    for (let i in accountsArray) {
      let name = accountsArray[i].trim().replace(/\\n/g, '');
      if (name === '') {
        continue;
      }
      //has length, non-unique or doesntmatch regex
      if (accountData.CustodianData.AccountMask && !regex.test(name)) {
        erroredAccountNames.push(name);
      } else if (uniqueAccountNames.includes(name)) {
        erroredAccountNames.push(name);
      }
      uniqueAccountNames.push(name);
    }
    if (uniqueAccountNames.length > accountData.CustodianData.AccountLimit) {
      erroredAccountNames.push(`Account Limit of ${accountData.CustodianData.AccountLimit} exceeded.`);
    }
    if (erroredAccountNames.length === 0) {
      this.setState({ erroredAccountNames, multipleAccountsError: false });
      return true;
    }
    this.setState({ erroredAccountNames, multipleAccountsError: true });
    return false;
  }

  handleMultipleAccountInput = (e) => {
    this.setState({ multipleAccountsString: e.target.value }, () => {
      this.validateAccounts();
    });
  };

  validateAccounts = debounce(() => {
    this.accountValidator();
  }, 250);

  onMultipleAccountsSaveClick = (accountNumbers, accountNames, data) => {
    this.updateAccountData('AccountName', accountNumbers).then(() => {
      this.updateAccountData('AccountTitle', accountNames).then(() => {
        this.setState({
          multipleAccountsSet: true,
          multipleAccountsModalOpen: false,
          multipleAccountsError: false,
          erroredAccountNames: [],
          multipleAccountsData: data,
        });
      });
    });
  };

  onMultipleAccountsAltSaveClick = (funds, investorNames, data) => {
    this.updateAccountData('Funds', funds).then(() => {
      this.updateAccountData('InvestorName', investorNames).then(() => {
        this.setState({
          multipleAccountsSet: true,
          multipleAccountsModalOpen: false,
          multipleAccountsError: false,
          erroredAccountNames: [],
          multipleAccountsData: data,
        });
      });
    });
  };
  isAnyModalOpen = () => {
    const {
      searchCustodianModalOpen,
      searchSignerModalOpen,
      addHierarchyOptionModalOpen,
      previewModalOpen,
      viewExistingModalOpen,
      requestCustodianModalOpen,
      contactModalOpen,
      multipleAccountsModalOpen,
    } = this.state;
    return (
      searchCustodianModalOpen ||
      searchSignerModalOpen ||
      addHierarchyOptionModalOpen ||
      previewModalOpen ||
      viewExistingModalOpen ||
      requestCustodianModalOpen ||
      contactModalOpen ||
      multipleAccountsModalOpen
    );
  };

  showCopyConfirmation = () => {
    let message = `Clicking continue will start a new account creation.`;
    if (!this.state.isFormDirty) {
      message = `Clicking continue will start a new account creation and cause all unsaved changes to this account to be lost.`;
    }
    confirmAlert({
      title: `Create Copy?`,
      childrenElement: () => {
        return (
          <div className="delete-error-div">
            <div>{message}</div>
            <br />
          </div>
        );
      },
      buttons: [
        {
          label: <div id="cancel-confirm-button">Cancel</div>,
          onClick: () => {},
        },
        {
          label: <div id="continue-confirm-button">Continue</div>,
          onClick: () => {
            this.onCopyClick();
          },
        },
      ],
    });
  };

  onCopyClick = async () => {
    const { user, services, accountData } = this.props;
    let { fundData, funds } = this.state;
    this.setState({ loadSpinner: true });
    this.setState({ toEnroll: true });
    let response = await AccountModels.getAccountData({
      user,
      services,
      accountId: accountData.AccountID,
      showData: true,
    });
    let incomingData = response[0];
    if (incomingData) {
      let newAccountData = {
        ACCOUNT_SIGNER_LINK: incomingData.ACCOUNT_SIGNER_LINK,
        AccountTitle: '',
        AccountName: '',
        AccountTypeID: incomingData.AccountTypeID,
        AdvisorID: incomingData.AdvisorID,
        CONTACT: incomingData.CONTACT,
        ContactDetailID: accountData.ContactDetailID,
        ContactID: accountData.ContactID,
        CustodianContact: accountData.CustodianContact,
        CustodianData: accountData.CustodianData,
        CustodianID: accountData.CustodianID,
        CUSTODIAN: incomingData.CUSTODIAN,
        Fund_UID: incomingData.Fund_UID,
        InvestorID: incomingData.InvestorID,
        InvestorName: incomingData.InvestorName, // Use incoming for detokenized
        ManagerType: accountData.ManagerType,
        RelationshipID: incomingData.RelationshipID, // Use incoming for detokenized
        Status: 'NEW',
        Account_PartnerID: incomingData.Account_PartnerID,
        Account_TeamID: incomingData.Account_TeamID,
        Account_ClientID: incomingData.Account_ClientID,
        Account_InvestmentID: incomingData.Account_InvestmentID,
        Account_Custom1: incomingData.Account_Custom1,
        Account_Custom2: incomingData.Account_Custom2,
        Account_Custom3: incomingData.Account_Custom3,
        Account_Custom4: incomingData.Account_Custom4,
      };
      // Only time we will have a fund coming over is during an Alternative copy.
      if (accountData.ManagerType === 'ALTERNATIVE') {
        let fund = funds.find((Element) => Element.value === incomingData.Fund_UID);
        fundData = fund ? [{ Fund_UID: fund.value, Name: fund.label }] : [];
      } else {
        fundData = [];
      }

      this.setState({ ...this.state, fundData }, async () => {
        this.props.setIsEdit(false);
        await this.props.setSelectedAccount(null);
        await this.props.setAccountData(newAccountData);
        await this.getTypes();
        this.setState({ loadSpinner: false }, () => {
          //Need to wait until spinner is gone because we reference the document element in remove signer function
          //And the dom element isnt there whe spinner is spinning
          for (let i in accountData.ACCOUNT_SIGNER_LINK) {
            this.clearSignerFromDOM(i);
          }
        });
      });
    } else {
      // Error, go back
      this.setState({ loadSpinner: false });
      this.onCancelClick();
    }
  };

  showNewConfirmation = () => {
    const { accountData } = this.props;
    let message = `Clicking continue will start a new account creation.`;
    if (!this.state.isFormDirty) {
      message = `Clicking continue will start a new account creation and cause all unsaved changes to this account to be lost.`;
    }
    let title = 'Create New Account?';
    switch (accountData.ManagerType) {
      case 'BROKERAGE':
        title = 'Create New Brokerage Account?';
        break;
      case 'ALTERNATIVE':
        title = 'Create New Alternative Account?';
        break;
    }
    confirmAlert({
      title,
      childrenElement: () => {
        return (
          <div className="delete-error-div">
            <div>{message}</div>
            <br />
          </div>
        );
      },
      buttons: [
        {
          label: <div id="cancel-confirm-button">Cancel</div>,
          onClick: () => {},
        },
        {
          label: <div id="continue-confirm-button">Continue</div>,
          onClick: () => {
            this.onNewClick();
          },
        },
      ],
    });
  };

  onNewClick = () => {
    const { accountData } = this.props;
    this.setState({ loadSpinner: true });
    this.setState({ toEnroll: true });
    let newAccountData = {
      AccountTitle: '',
      AccountName: '',
      AdvisorID: null,
      Status: 'NEW',
      ManagerType: accountData.ManagerType,
      InvestorID: null,
      InvestorName: null,
      RelationshipID: null,
      Account_PartnerID: null,
      Account_TeamID: null,
      Account_ClientID: null,
      Account_InvestmentID: null,
      Account_Custom1: null,
      Account_Custom2: null,
      Account_Custom3: null,
      Account_Custom4: null,
    };
    this.setState({ ...this.state }, async () => {
      this.props.setIsEdit(false);
      await this.props.setSelectedAccount(null);
      await this.props.setAccountData(newAccountData);
      this.setState({ loadSpinner: false }, () => {
        //Need to wait until spinner is gone because we reference the document element in remove signer function
        //And the dom element isnt there whe spinner is spinning
        for (let i in accountData.ACCOUNT_SIGNER_LINK) {
          this.clearSignerFromDOM(i);
        }
      });
      // Any time we come into this onNewClick, we should never come in with an accountid enroll/accountid - So we are making sure of that here
      if (this.props.history.location.pathname.indexOf('/enroll/') !== -1) {
        this.props.history.push('/enroll');
      }
    });
  };

  serverSelectCustodianHandle = (e) => {
    if (e == null) {
      this.child.search('null');
      this.updateAccountData('CustodianID', null, 'CustodianName', null);
      this.setState({ selectedCustodianId: null }, () => {
        this.updateAccountData('CustodianData', null).then(() => {
          this.setState({ validCustodian: false });
        });
      });
    } else {
      this.checkHasRequired();
      this.updateAccountData('CustodianID', e.value.CustodianID, 'CustodianName', e.value.Name);
      this.setState({ selectedCustodianId: e.value.CustodianID }, () => {
        this.updateAccountData('CustodianData', e.value).then(() => {
          this.setState({ validCustodian: true });
        });
      });
    }
  };

  serverSelectCustodianRelatedHandle = (e) => {
    if (e == null) {
      this.child.search('null');
      this.updateAccountData('Account_Related_Entity_UID', null, 'CustodianRelatedName', '');
      this.setState({ selectedCustodianRelatedId: null }, () => {});
    } else {
      this.checkHasRequired();
      this.updateAccountData('Account_Related_Entity_UID', e.value.CustodianID, 'CustodianRelatedName', e.value.Name);
      this.setState({ selectedCustodianRelatedId: e.value.CustodianID }, () => {});
    }
  };

  serverSelectTaxEntityHandle = (e) => {
    let val = [];
    val['TaxIdNmb'] = '';
    val['HierarchyPath'] = '';
    if (e) {
      if (e.value) {
        val = e.value;
      }
    }
    this.updateAccountData('TaxEntityID', val.TaxIdNmb, 'TaxEntityName', val.HierarchyPath).then(() => {
      this.updateAccountData('RelationshipID', val.TaxIdNmb);
    });
  };

  searchCustodianModal(e) {
    if (e.key === 'Enter') {
      this.setState({ searchCustodianModalOpen: true });
    }
  }

  checkBatchPermissions = () => {
    switch (window.location.hostname.toLowerCase()) {
      case 'enrollment.totalwealthops.com':
        return false;
      case 'cfgenrollment.totalwealthviews.net':
        return false;
      default:
        return true;
    }
  };

  filterOption = (option, inputValue) => {
    if (option.label.toUpperCase().startsWith(inputValue.toUpperCase())) {
      return true;
    } else {
      return false;
    }
  };

  toggle() {
    this.setState((prevState) => ({
      showAuthStatusDropdown: !prevState.showAuthStatusDropdown,
    }));
  }

  onMouseLeave() {
    this.setState({ showAuthStatusDropdown: false });
  }

  onMouseOver() {
    this.setState({ showAuthStatusDropdown: true });
  }

  handleAuthStatusDropDownClick = (value) => {
    this.setState({ showAuthStatusDropdown: false });
    let e = { value: value };
    // this.confirmLOAStatusChange(e);
    this.confirmCancelLOA(value);
  };

  authStatusDropDownMenu = () => {
    let buttons = [];
    let { showAuthStatusDropdown } = this.state;

    buttons.push(
      <div>
        {showAuthStatusDropdown !== true ? (
          <div tabIndex="0" className="icon-down-arrow">
            <FontAwesome name="fa-bars" style={{ cursor: 'pointer' }} className="fa fa-bars fa-pencil-edit-row" id="fa-grid-icon" />
          </div>
        ) : (
          <div tabIndex="0" className="icon-down-arrow">
            <Dropdown key={1111111} className="d-inline-block" isOpen={true}>
              <FontAwesome name="fa-bars" style={{ cursor: 'pointer' }} className="fa fa-bars fa-pencil-edit-row" id="fa-grid-icon" />
              <DropdownMenu className="auth-status-dropdown">
                <DropdownItem
                  onClick={() => {
                    this.handleAuthStatusDropDownClick('NEW');
                  }}
                >
                  NEW
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.handleAuthStatusDropDownClick('CANCEL');
                  }}
                >
                  CANCEL
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>
    );
    return (
      <div
        onMouseLeave={this.onMouseLeave}
        onMouseOver={this.onMouseOver}
        onClick={() => {
          this.setState({ showAuthStatusDropdown: !showAuthStatusDropdown });
        }}
        className="col-edit-grid pointer"
      >
        {' '}
        {buttons}{' '}
      </div>
    );
  };

  getTileWidth(brokerage, alternative, other) {
    let cnt = 0;
    brokerage && cnt++;
    alternative && cnt++;
    other && cnt++;

    return cnt;
  }

  getEnrollType(tileCnt, enrollBrokeragePolicy, enrollAlternativePolicy, enrollOtherPolicy, enrollDisclaimer) {
    let type = '';

    if (tileCnt === 1 && enrollBrokeragePolicy) {
      type = 'BROKERAGE';
    }
    if (tileCnt === 1 && enrollAlternativePolicy) {
      type = 'ALTERNATIVE';
    }
    if (tileCnt === 1 && enrollOtherPolicy) {
      type = 'OTHER';
    }
    if (type !== '' && !enrollDisclaimer) {
      type = `REDIRECT_${type}`;
    }
    return type;
  }

  handleRedirect = (enrollType) => {
    if (enrollType.indexOf('REDIRECT_') === -1 && enrollType !== '' && enrollType) {
      this.handleManagerTypeChange(enrollType, true);
    }
    if (enrollType.indexOf('REDIRECT_') !== -1 && enrollType !== '' && enrollType) {
      enrollPrompt = false;
      this.handleManagerTypeChange(enrollType.substring(9, enrollType.length));
    }
  };

  handleAlreadyCreated = (response, Account, action = 'NEXT') => {
    let { enhancedNavigation } = this.state;
    confirmAlert({
      title: <div className="unsaved-changes-header">Warning</div>,
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div> {response} </div>
            <div> {`${Account} previously enrolled, would you like to duplicate ${Account}?`} </div>
          </div>
        );
      },
      buttons: [
        { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
        {
          label: <div id="continue-confirm-button">Continue</div>,
          onClick: () => {
            // Continue button should behave differently based on enhancedNavigation
            if (enhancedNavigation) {
              this.saveAccount('', action); // Enhanced Navigation opens LOA Preview when clicking Next button
            } else {
              this.saveAccount(); // Just save the account (No redirect)
            }
          },
        },
      ],
    });
  };

  checkAccountsFirst = async (from, action) => {
    let { dirtyFields, fromStatus, multipleAccountsSet, otherTypeId, removedSigners, fundData } = this.state;
    const { user, services, accountData, edit, validSigners, crmData } = this.props;
    if (!this.hasRequiredFields() || !this.isAccountMaskCorrect()) {
      return;
    }

    this.setState({ loadSpinner: true, showRequired: false });
    this.setState({ accountTypePrompt: false });

    let response = await AccountModels.checkAccounts({
      user,
      services,
      accountData,
      dirtyFields,
      otherTypeId,
      validSigners,
      multipleAccountsSet,
      crmData,
      fundData,
    });
    if (response !== '') {
      this.setState({ loadSpinner: false });
      this.createAccountString(response, action);
    } else {
      this.saveAccount(from, action);
    }
  };

  createAccountString = (response, action) => {
    let accountString = '';
    var arrayLength = response.length;
    var Title = arrayLength > 1 ? 'Accounts' : 'Account';

    for (var i = 0; i < arrayLength; i++) {
      accountString = accountString + response[i].AccountName + ', ';
    }

    accountString = accountString.substring(0, accountString.length - 2);

    this.handleAlreadyCreated(accountString, Title, action);
  };

  checkUpdatedAccount = (from, action) => {
    let { accountData } = this.props;
    let { comparedAccountInfo } = this.state;
    switch (accountData.ManagerType) {
      case 'BROKERAGE':
        if (accountData.CustodianID !== comparedAccountInfo.ID || accountData.AccountName !== comparedAccountInfo.Name) {
          this.checkAccountsFirst(from, action);
        } else {
          this.saveAccount(from, action);
        }
        break;
      case 'ALTERNATIVE':
        if (accountData.Fund_UID !== comparedAccountInfo.ID || accountData.InvestorName !== comparedAccountInfo.Name) {
          this.checkAccountsFirst(from, action);
        } else {
          this.saveAccount(from, action);
        }
        break;
      case 'OTHER':
        this.saveAccount(from, action);
        break;
      default:
        break;
    }
  };

  closeContactModal = () => {
    this.checkHasRequired();
    this.setState({ contactModalOpen: false, setCreateCustodianName: false });
  };

  getCustomClassName = (disabled) => {
    return disabled ? 'account-select disabled-dropdown' : 'account-select';
  };

  showOtherDetails = () => {
    const {
      enrollAdvisorIDShow,
      enrollRelationshipIDShow,
      enrollTeamIDShow,
      enrollPartnerIDShow,
      enrollClientIDShow,
      enrollInvestmentIDShow,
      enrollAccountCustom1Show,
      enrollAccountCustom2Show,
      enrollAccountCustom3Show,
      enrollAccountCustom4Show,
    } = this.state;

    return (
      enrollAdvisorIDShow ||
      enrollRelationshipIDShow ||
      enrollTeamIDShow ||
      enrollPartnerIDShow ||
      enrollClientIDShow ||
      enrollInvestmentIDShow ||
      enrollAccountCustom1Show ||
      enrollAccountCustom2Show ||
      enrollAccountCustom3Show ||
      enrollAccountCustom4Show
    );
  };
  render() {
    let { accountData, statusOptions, validCustodian, viewOnlyMode, user, services } = this.props;
    let {
      loadSpinner,
      showRequired,
      nullFields,
      isTokenized,
      tokenFilter,
      accountTypePrompt,
      isFormDirty,
      enrollLabel,
      enrollPrefixLabel,
      helpInformation,
      fromEnroll,
      pendingGrid,
      enrollTitle,
      enhancedNavigation,
      enrollBrokeragePanel,
      enrollAlternativePanel,
      enrollOtherPanel,
      enrollBrokerageTitle,
      enrollAlternativeTitle,
      enrollOtherTitle,
      enrollNextButtonLabel,
      previewModalOpen,
      enrollPartnerIDShow,
      enrollPartnerIDEdit,
      enrollPartnerIDLabel,
      enrollTeamIDShow,
      enrollTeamIDEdit,
      enrollTeamIDLabel,
      enrollClientIDShow,
      enrollClientIDEdit,
      enrollClientIDLabel,
      enrollInvestmentIDShow,
      enrollInvestmentIDEdit,
      enrollInvestmentIDLabel,
      enrollAccountCustom1Show,
      enrollAccountCustom1Edit,
      enrollAccountCustom1Label,
      enrollAccountCustom2Show,
      enrollAccountCustom2Edit,
      enrollAccountCustom2Label,
      enrollAccountCustom3Show,
      enrollAccountCustom3Edit,
      enrollAccountCustom3Label,
      enrollAccountCustom4Show,
      enrollAccountCustom4Edit,
      enrollAccountCustom4Label,
      enrollAccountTaxEntityShow,
      enrollAccountTaxEntityEdit,
      enrollAccountTaxEntityLabel,
      enrollAlternativeCustodianShow,
      enrollAlternativeCustodianEdit,
      enrollAlternativeCustodianLabel,
      enrollHierarchyShow,
      enrollAdvisorIDShow,
      enrollRelationshipIDShow,
      allRequiredFields,
      searchCustodianRelated,
      enableButton,
      validAccountMask,
      overrideMask,
      fieldArray,
      fieldArrayLists,
      enrollCounterpartyIntelligence,
      enrollRelationshipIDSplit,
      enrollment_anonymized,
    } = this.state;
    var ie = navigator.userAgent.match(/.NET/g);
    var edge = navigator.userAgent.match(/Edge/g);
    let requestTitle = this.getRequestTitle();
    let path = getCookie('path');
    let mode = 'add';

    let disabled = loadSpinner || viewOnlyMode || !globals.REMOTE_INSTANCE ? !accountData.ManagerType : null;
    let authorizationStatusDisabled = false;
    disabled = this.state.edit && this.state.multipleAccountsSet ? true : disabled;
    disabled = viewOnlyMode ? true : disabled;
    disabled = this.isAnyModalOpen() ? true : disabled;
    let disabledAlwaysEditable = disabled; // Use this field to disable account name / investor id skipping accountData.LOA check
    if (!disabled) {
      disabled = !disabled && accountData.LOA;
    }

    // Fields that should be editable after LOA is generated.
    let disabledEditable = this.isAnyModalOpen() || viewOnlyMode ? true : false;

    let showContent = !enhancedNavigation ? true : enhancedNavigation && previewModalOpen ? false : true;

    //Disable loa status select for automated loas and enable it for print.
    if (accountData.LOA && accountData.LOA.AuthorizeID) {
      authorizationStatusDisabled = true;
    } else {
      authorizationStatusDisabled = false;
    }

    let title = !globals.REMOTE_INSTANCE ? (!accountData.ManagerType && enrollTileCount > 1 ? 'Please Select an Account Type to Begin' : null) : null;
    let mainTitle = enrollLabel;

    mainTitle = enrollTitle ? enrollTitle : mainTitle; // Enhanced Navigation - Use UI.Enrollment.Enroll.Title if we have it

    let newButtonTitle = 'Create New Account';
    switch (accountData.ManagerType) {
      case 'BROKERAGE':
        mainTitle = `${enrollPrefixLabel} a Brokerage Account`;
        mainTitle = enrollBrokerageTitle ? enrollBrokerageTitle : mainTitle; // Enhanced Navigation - UI.Enrollment.Enroll.Brokerage.Title
        newButtonTitle = 'Create New Brokerage Account';
        break;
      case 'ALTERNATIVE':
        mainTitle = `${enrollPrefixLabel} an Alternative Account`;
        mainTitle = enrollAlternativeTitle ? enrollAlternativeTitle : mainTitle; // Enhanced Navigation - UI.Enrollment.Enroll.Alternative.Title
        newButtonTitle = 'Create New Alternative Account';
        break;
      case 'OTHER':
        mainTitle = enrollOtherTitle ? enrollOtherTitle : mainTitle; // Enhanced Navigation - UI.Enrollment.Enroll.Other.Title
        break;
      default:
        break;
    }

    if (this.props.match.params.accountid) {
      mainTitle = 'Edit Account';
      mode = 'edit';
    } else if (viewOnlyMode) {
      mode = 'view';
    }

    let selectClassName = disabled ? 'account-select disabled-dropdown' : 'account-select';
    let selectAuthClassName = disabled && authorizationStatusDisabled ? 'account-select auth-status-style disabled-dropdown' : 'account-select auth-status-style';
    let inputAuthClassName = disabled && authorizationStatusDisabled ? 'account-input auth-status-style disabled-dropdown' : 'account-input auth-status-style';
    let custodianClassName = showRequired && nullFields.indexOf('CustodianName') !== -1 ? 'account-input search-input req' : 'account-input search-input';
    custodianClassName = this.props.viewOnlyMode || validCustodian ? custodianClassName : `${custodianClassName} italic`;
    let invalidMask = !validAccountMask && !this.props.edit && accountData.CustodianData && !viewOnlyMode && accountData.AccountName && !overrideMask;
    let batchPermission = this.checkBatchPermissions();

    return (
      <div className="account-div">
        {enrollType ? (enrollType.indexOf('REDIRECT_') !== -1 && enrollPrompt === true ? this.handleRedirect(enrollType) : null) : null}
        {this.state.toEnroll ? <Redirect push to={`/enroll`} /> : null}
        {this.state.toMyEnrollments ? <Redirect push to="/myenrollments" /> : null}
        {this.state.toRedirect ? <Redirect push to={`${this.state.redirect}`} /> : null}
        {/* {this.state.toBatchJobs ? <Redirect push to={`/batchjobs`} /> : null} */}
        {this.state.toAuthorizations ? <Redirect to={{ pathname: '/authorizations', state: { pendingGrid } }} /> : null}

        {!viewOnlyMode ? (
          <div className="header-accounts">
            {this.props.edit ? (
              <div className="account-title">
                {/* Hide the home button if in the enroll workflow */}
                {accountData && accountData.Status !== 'CREATING' ? <i className="fa fas fa-home" onClick={this.onCancelClick.bind(this, { home: true })}></i> : null}
                {accountData && accountData.Status !== 'CREATING' && enhancedNavigation && <span className="generic-divider">|</span>}
                {accountTypePrompt ? enrollLabel : mainTitle}
                {tokenFilter && isTokenized && !this.state.multipleAccountsSet && (
                  <button disabled={loadSpinner} onClick={this.getPII.bind(this)} className="search-button" id="account-number-eyeball-button">
                    <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="eyeball-info" place="top" type="info" effect="solid" className="tooltip_custom">
                      <span>Detokenize Fields</span>
                    </ReactTooltip>
                    <FontAwesome name="far fa-eye account-eye" style={{ cursor: 'pointer' }} className="far fa-eye" data-for="eyeball-info" data-tip />
                  </button>
                )}
              </div>
            ) : (
              <div className="account-title">
                {/* Hide the home button if in the enroll worklow */}
                {accountData && accountData.Status !== 'CREATING' ? <i className="fa fas fa-home" onClick={this.onCancelClick.bind(this, { home: true })}></i> : null}
                {accountData && accountData.Status !== 'CREATING' && enhancedNavigation && <span className="generic-divider">|</span>}
                {mainTitle}
              </div>
            )}
            {!loadSpinner && !this.props.fetchingData ? (
              <div>
                {path !== 'enroll' && path !== 'external' && !this.props.accountData.ManagerType ? (
                  <div className="button-div">
                    <div
                      className="generic-button-secondary generic-button-auto"
                      id="view-accounts-button"
                      onClick={() => {
                        if (!loadSpinner) {
                          this.onCancelClick({ toAccounts: true });
                        }
                      }}
                    >
                      {accountsButtonLabel ? accountsButtonLabel : 'Accounts'}
                    </div>
                    {!enrollment_anonymized && (
                      <div
                        className={enhancedNavigation ? 'generic-button-secondary generic-button-margin-right' : 'generic-button-primary generic-button-margin-right'}
                        id="view-authorizations-button"
                        onClick={() => {
                          this.setState({ toAuthorizations: true });
                        }}
                      >
                        {authorizationsButtonLabel ? authorizationsButtonLabel : 'Authorizations'}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {/* @@@@@@@@@@@ ENHANCED NAVIGATION BUTTONS @@@@@@@@@@@@@@@ */}
                    {/* When enhanced navigation policy is set to true, fall into different button handlers */}

                    {enhancedNavigation && this.props.accountData.ManagerType ? (
                      <div className="button-div">
                        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`enroll-button-cancel`} place="top" type="info" effect="solid" className="tooltip_custom">
                          {this.getTooltip('Tooltip.Enrollment.Enroll.Button.Cancel')}
                        </ReactTooltip>
                        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`enroll-button-cancel-account`} place="top" type="info" effect="solid" className="tooltip_custom">
                          {this.getTooltip('Tooltip.Enrollment.Enroll.Button.Cancel.Account')}
                        </ReactTooltip>
                        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`enroll-button-copy`} place="top" type="info" effect="solid" className="tooltip_custom">
                          {this.getTooltip('Tooltip.Enrollment.Enroll.Button.Copy')}
                        </ReactTooltip>
                        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`enroll-button-saveexit`} place="top" type="info" effect="solid" className="tooltip_custom">
                          {this.getTooltip('Tooltip.Enrollment.Enroll.Button.SaveExit')}
                        </ReactTooltip>
                        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`enroll-button-savenew`} place="top" type="info" effect="solid" className="tooltip_custom">
                          {this.getTooltip('Tooltip.Enrollment.Enroll.Button.SaveNew')}
                        </ReactTooltip>
                        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`enroll-button-next`} place="top" type="info" effect="solid" className="tooltip_custom">
                          {this.getTooltip('Tooltip.Enrollment.Enroll.Button.Next')}
                        </ReactTooltip>
                        <div
                          className="generic-button-secondary"
                          id="cancel-enroll-button"
                          // data-tip
                          // data-for="enroll-button-cancel"
                          onClick={() => {
                            if (!loadSpinner) {
                              this.onCancelClick({ toEnroll: true });
                            }
                          }}
                        >
                          {cancelButtonLabel ? cancelButtonLabel : 'Back'}
                        </div>
                        {accountData.Status === 'NEW' && this.props.edit && (
                          <div
                            className={'generic-button-secondary'}
                            id="cancel-account-button"
                            data-tip
                            data-for="enroll-button-cancel-account"
                            onClick={() => {
                              this.onConfirmCancelAccount();
                            }}
                          >
                            {cancelAccountButtonLabel ? cancelAccountButtonLabel : 'Cancel Account'}
                          </div>
                        )}
                        {accountData.Status === 'CREATING' || disabled ? null : !this.props.edit ? null : !enrollCopyPolicy ? null : (
                          <div
                            className={'generic-button-secondary'}
                            id="copy-accounts-button"
                            data-tip
                            data-for="enroll-button-copy"
                            onClick={() => {
                              if (!disabled) {
                                this.showCopyConfirmation();
                              }
                            }}
                          >
                            {copyButtonLabel ? copyButtonLabel : 'Copy'}
                          </div>
                        )}
                        {(this.state.multipleAccountsSet && this.props.edit) || accountTypePrompt || disabled ? null : (
                          <div
                            className={enableButton ? 'generic-button-secondary' : 'generic-button-secondary generic-button-enhanced-secondary-disabled'}
                            id="save-accounts-exit-button"
                            data-tip
                            data-for="enroll-button-saveexit"
                            disabled={!enableButton}
                            onClick={() => {
                              if (!disabled && enableButton && !this.props.edit && accountData.ManagerType !== 'OTHER') {
                                this.checkAccountsFirst('', 'SAVE_AND_EXIT');
                              } else if (!disabled && enableButton && this.props.edit) {
                                this.checkUpdatedAccount('', 'SAVE_AND_EXIT');
                              } else if (!disabled && enableButton) {
                                this.saveAccount('', 'SAVE_AND_EXIT');
                              }
                            }}
                          >
                            {'Save and Exit'}
                          </div>
                        )}
                        {(this.state.multipleAccountsSet && this.props.edit) || accountTypePrompt || disabled ? null : (
                          <div
                            className={`${enableButton ? 'generic-button-secondary' : 'generic-button-secondary generic-button-enhanced-secondary-disabled'}${
                              enrollment_anonymized ? ' margin-right-25' : ''
                            }`}
                            id="save-accounts-new-button"
                            data-tip
                            data-for="enroll-button-savenew"
                            disabled={!enableButton}
                            onClick={() => {
                              if (!disabled && enableButton && !this.props.edit && accountData.ManagerType !== 'OTHER') {
                                this.checkAccountsFirst('', 'SAVE_AND_NEW');
                              } else if (!disabled && enableButton && this.props.edit) {
                                this.checkUpdatedAccount('', 'SAVE_AND_NEW');
                              } else if (!disabled && enableButton) {
                                this.saveAccount('', 'SAVE_AND_NEW');
                              }
                            }}
                          >
                            {'Save and New'}
                          </div>
                        )}
                        {(this.state.multipleAccountsSet && this.props.edit) || accountTypePrompt || disabled || enrollment_anonymized ? null : (
                          <div
                            className={
                              enableButton ? 'generic-button-primary generic-button-margin-right' : 'generic-button-primary generic-button-margin-right generic-button-enhanced-disabled'
                            }
                            id="save-accounts-next-button"
                            data-tip
                            data-for="enroll-button-next"
                            disabled={!enableButton}
                            onClick={() => {
                              if (!disabled && enableButton && !this.props.edit && accountData.ManagerType !== 'OTHER') {
                                this.checkAccountsFirst('', 'NEXT');
                              } else if (!disabled && enableButton && this.props.edit) {
                                this.checkUpdatedAccount('', 'NEXT');
                              } else if (!disabled && enableButton) {
                                this.saveAccount('', 'NEXT');
                              }
                            }}
                          >
                            {enrollNextButtonLabel ? enrollNextButtonLabel : 'Next'}
                          </div>
                        )}
                        {((this.state.multipleAccountsSet && this.props.edit) || accountTypePrompt || disabled) && !disabledEditable && (
                          <div
                            className="generic-button-primary generic-button-margin-right"
                            id="save-accounts-button"
                            onClick={() => {
                              if (!disabledEditable && !this.props.edit && accountData.ManagerType !== 'OTHER') {
                                this.checkAccountsFirst();
                              } else if (!disabledEditable && this.props.edit) {
                                this.checkUpdatedAccount();
                              } else if (!disabledEditable) {
                                this.saveAccount();
                              }
                            }}
                          >
                            {saveButtonLabel ? saveButtonLabel : 'Save'}
                          </div>
                        )}
                      </div>
                    ) : (
                      this.props.accountData.ManagerType && (
                        <div className="button-div">
                          {/* Hide the copy account button if the account is in creating status */}
                          {accountData.Status === 'CREATING' || disabled ? null : !this.props.edit ? null : !enrollCopyPolicy ? null : (
                            <div
                              className={enhancedNavigation ? 'generic-button-secondary' : 'generic-button-primary'}
                              id="copy-accounts-button"
                              title="Create a copy of this account"
                              onClick={() => {
                                if (!disabled) {
                                  this.showCopyConfirmation();
                                }
                              }}
                            >
                              {copyButtonLabel ? copyButtonLabel : 'Copy'}
                            </div>
                          )}

                          {/* Hide the NEW button if in the enroll worklfow */}
                          {path === 'enroll' || path === 'view' || path === 'external' || disabled ? null : !this.props.edit ? null : (
                            <div
                              className={enhancedNavigation ? 'generic-button-secondary' : 'generic-button-primary'}
                              id="new-accounts-button"
                              title={newButtonTitle}
                              onClick={() => {
                                if (!disabled) {
                                  this.showNewConfirmation();
                                }
                              }}
                            >
                              {newButtonLabel ? newButtonLabel : 'New'}
                            </div>
                          )}

                          {this.props.edit &&
                            accountData.StatusObject &&
                            accountData.StatusObject.value !== 'CREATING' &&
                            typeof this.state.fromStatus === 'undefined' &&
                            !accountData.LOA &&
                            !isFormDirty && (
                              <div
                                title={!this.props.edit ? 'Please Save Your Changes First' : ''}
                                className={enhancedNavigation ? 'generic-button-secondary' : 'generic-button-primary'}
                                id="preview-accounts-button"
                                onClick={() => {
                                  if (!disabled) {
                                    this.saveAccount('preview').then(() => {
                                      this.generateLOA('PREVIEW');
                                    });
                                  }
                                }}
                              >
                                {previewButtonLabel ? previewButtonLabel : 'Preview'}
                              </div>
                            )}
                          {accountData.Status === 'NEW' && this.props.edit && (
                            <div
                              className={enhancedNavigation ? 'generic-button-secondary' : 'generic-button-primary'}
                              id="cancel-account-button"
                              data-tip
                              data-for="enroll-button-cancel-account"
                              cancel="Cancel Account"
                              onClick={() => {
                                this.onConfirmCancelAccount();
                              }}
                            >
                              {cancelAccountButtonLabel ? cancelAccountButtonLabel : 'Cancel Account'}
                            </div>
                          )}
                          {(this.state.multipleAccountsSet && this.props.edit) || accountTypePrompt || path === 'view' ? null : (
                            <div
                              className="generic-button-secondary "
                              id="cancel-secondary-accounts-button"
                              onClick={() => {
                                if (!loadSpinner) {
                                  // If we are coming from edit, cancel redirects to home
                                  // If we have a tilecount of less than 2 and no disclaimer, we are now redirecting to Enroll Account screen automatically
                                  // So in the case we are going directly to a create screen, the cancel should go back home to myenrollments
                                  this.onCancelClick({ home: (enrollTileCount < 2 && enrollDisclaimerPolicy === false && !this.props.edit) || this.props.edit ? true : false });
                                }
                              }}
                            >
                              {cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
                            </div>
                          )}

                          {/* {(this.state.multipleAccountsSet && this.props.edit) || accountTypePrompt || disabled ? null : ( */}
                          {!disabledEditable && (
                            <div
                              className="generic-button-primary generic-button-margin-right"
                              id="save-accounts-button"
                              onClick={() => {
                                if (!disabledEditable && !this.props.edit && accountData.ManagerType !== 'OTHER') {
                                  this.checkAccountsFirst();
                                } else if (!disabledEditable && this.props.edit) {
                                  this.checkUpdatedAccount();
                                } else if (!disabledEditable) {
                                  this.saveAccount();
                                }
                              }}
                            >
                              {saveButtonLabel ? saveButtonLabel : 'Save'}
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="header-accounts">
            <div className="account-title"></div>
            <div className="button-div"></div>
          </div>
        )}

        {(this.state.loadSpinner || this.props.fetchingData) && (
          <Modal isOpen={this.state.loadSpinner || this.props.fetchingData} style={spinnerModalStyle}>
            <center>
              <Spinner id="view-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
            </center>
          </Modal>
        )}

        <div title={title} className="body-accounts body-accounts-main">
          <div>
            {!viewOnlyMode && accountTypePrompt && (
              <div>
                <div className="manager-type-prompt-text">{enrollTileCount > 1 && 'Select the type of account(s) to enroll:'}</div>
                <div className={`account-info-item span-all-col manager-type-wrapper-${enrollTileCount}`}>
                  {enrollBrokeragePolicy && (
                    <div
                      id="manager-type-brokerage"
                      className="manager-type-div panel"
                      onClick={() => {
                        this.handleManagerTypeChange('BROKERAGE');
                      }}
                    >
                      <div className="manager-type-header panel">Brokerage / Bank Accounts</div>
                      <div className="manager-type-body">
                        <div className="manager-type-text-wrapper">Assets held with custodians and banks such as Schwab, Fidelity, Morgan Stanley, and J.P. Morgan.</div>
                        <div className="manager-type-buttons">
                          {enhancedNavigation && (
                            <p>
                              <button className="generic-button-primary manager-type-button" id="add-manager-type-brokerage">
                                {enrollBrokeragePanel ? enrollBrokeragePanel : 'Enroll a Brokerage / Bank Account'}
                              </button>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {enrollAlternativePolicy && (
                    <div
                      id="manager-type-alternative"
                      className="manager-type-div"
                      onClick={() => {
                        this.handleManagerTypeChange('ALTERNATIVE');
                      }}
                    >
                      <div className="manager-type-header">Alternative Investments</div>
                      <div className="manager-type-body">
                        <div className="manager-type-text-wrapper">
                          Assets held in hedge funds, private equity, real estate, venture capital, limited partnerships, and business interests.
                        </div>
                        <div className="manager-type-buttons">
                          {enhancedNavigation && (
                            <p>
                              <button className="generic-button-primary manager-type-button" id="add-manager-type-brokerage">
                                {enrollAlternativePanel ? enrollAlternativePanel : 'Enroll an Alternative Investment'}
                              </button>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {enrollOtherPolicy && (
                    <div
                      id="manager-type-other"
                      className="manager-type-div"
                      onClick={() => {
                        this.handleManagerTypeChange('OTHER');
                      }}
                    >
                      <div className="manager-type-header">Other Financial</div>
                      <div className="manager-type-body">
                        <div className="manager-type-text-wrapper">Financial accounts such as banking, retirement and credit.</div>
                        <div className="manager-type-buttons">
                          {enhancedNavigation && (
                            <p>
                              <button className="generic-button-primary manager-type-button" id="add-manager-type-brokerage">
                                {enrollOtherPanel ? enrollOtherPanel : 'Enroll an External Account'}
                              </button>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div id='manager-type-personal' className='manager-type-div'>
                  <div className='manager-type-header'>
                    Personal Use Assets
                  </div>
                    <div className='manager-type-body'>
                      <div className='manager-type-text-wrapper'>
                        Personal Assets such as Homes, Art, Jewelry, Vehicles, Collectables, Insurance, etc
                      </div>
                    </div>
                </div> */}
                </div>

                {/* <div className='manager-type-div'>
                <input id='manager-type-brokerage' disabled={disableRadioButtons} id='manager-type-brokerage' type='radio' name='managerType' checked={accountData.ManagerType==='BROKERAGE'} onChange={() => {this.handleManagerTypeChange('BROKERAGE')}} />
                <label>Brokerage</label>
              </div>
              <div className='manager-type-div'>
                <input id='manager-type-alternative' disabled={disableRadioButtons} id='manager-type-alternative' type='radio' name='managerType' checked={accountData.ManagerType==='ALTERNATIVE'} onChange={() => {this.handleManagerTypeChange('ALTERNATIVE')}} />
                <label>Alternative</label>
              </div>
              <div className='manager-type-div'>
                <input id='manager-type-other' disabled={disableRadioButtons} id='manager-type-other' type='radio' name='managerType' checked={accountData.ManagerType==='OTHER'} onChange={() => {this.handleManagerTypeChange('OTHER')}} />
                <label>Other</label>
              </div> */}
                {enrollDisclaimerPolicy && (
                  <div>
                    <div className="manager-type-text">
                      By enrolling an account in this system, you are initiating an automated process that will capture account/owner information. A request to approve will be transmitted,
                      resulting in the sharing of account information with our approved aggregator.
                    </div>
                    <div className="enroll-disclaimer-button-wrapper">
                      {enrollTileCount === 0 ||
                        (enrollTileCount === 1 && (
                          <button
                            onClick={() => {
                              this.handleRedirect(enrollType, true);
                            }}
                            className="generic-button-primary"
                          >
                            Accept
                          </button>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* -------------------------------------Brokerage Account Fields -----------------------------------------------------------*/}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {(this.state.multipleAccountsSet && this.props.edit) || !accountData.ManagerType || viewOnlyMode || accountTypePrompt ? null : (
              <div className="required-field-message-wrapper">
                <div className="required-field-message" id="required-field-message">
                  {getRequiredAsterisk(true, '', '', viewOnlyMode)} Indicates required field
                </div>
              </div>
            )}
            {/* { accountData.ManagerType === 'BROKERAGE' || globals.REMOTE_INSTANCE && */}
            {accountData.ManagerType === 'BROKERAGE' && (viewOnlyMode || !accountTypePrompt) && (
              <div className={viewOnlyMode ? 'account-info account-form-header no-border' : 'account-info account-form-header'}>
                <input type="hidden" value="something" />
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-cust" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Brokerage.CustodianName'] ? helpInformation['Tooltip.Enrollment.Enroll.Brokerage.CustodianName'] : <span>Custodian Field</span>}
                </ReactTooltip>
                <div className="account-info-item col-1 ga-1-1 account-info-item-counterparty">
                  <div className={!viewOnlyMode ? 'account-learn-more-wrapper account-learn-more-counterparty' : 'account-learn-more-wrapper'}>
                    <div>
                      <label className={disabled ? 'disabled-label' : 'label'} for="custodian">
                        {getRequiredAsterisk(false, BROKERAGERequiredFields, 'CustodianName', viewOnlyMode)}Custodian
                        {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-cust'></i> */}
                        <i
                          className="fa fas fa-question-circle"
                          data-tip
                          data-for="view-info-cust"
                          onClick={() => {
                            linker.UniversalHelp('Tooltip.Enrollment.Enroll.Brokerage.CustodianName', 'popup', null);
                          }}
                        ></i>
                      </label>
                    </div>
                    {accountData.CustodianName && enrollCounterpartyIntelligence && (
                      <CounterpartyIntelligence key={accountData.CustodianName} user={user} services={services} accountData={accountData} />
                    )}
                  </div>
                  <div className="input-container">
                    <ServerSideSelect
                      disabled={disabled}
                      id="custodian"
                      type="fuzzy"
                      labelField="Name"
                      additionalFields={"and CUSTODIAN_TYPE/NAME EQ 'CUSTODIAN' and CUSTODIAN_STATUS/Status NE 'Inactive' and Enrollment eq true and ChangeType ne 'D'"}
                      wrapperClassName={`search-input`}
                      fetchProperties={this.state.SourceFetchProperties}
                      // selectClassName={`search-input`}
                      selectClassName={showRequired && nullFields.indexOf('CustodianName') !== -1 ? 'account-select req' : 'account-select'}
                      placeholder={accountData.CustodianName}
                      isClearable={true}
                      onChange={(e) => {
                        this.serverSelectCustodianHandle(e);
                      }}
                      ref={(instance) => {
                        this.child = instance;
                      }}
                      value={accountData.CustodianName}
                    />
                    {viewOnlyMode ? null : (
                      <button
                        disabled={disabled}
                        className="search-button"
                        onClick={() => {
                          this.setState({ searchCustodianModalOpen: true });
                        }}
                      >
                        <FontAwesome className="fa fas fa-search" id="search-custodian-button" name="fa-search" />
                      </button>
                    )}
                  </div>
                </div>

                {accountData.CustodianData && accountData.CustodianData.EnrollmentExplain && (
                  <div className="account-custodian-description-item col-2 ga-2-1">
                    <div></div>
                    <div className={accountData.CustodianData.EnrollmentExplain.length > 80 ? 'custodian-description-text' : 'custodian-description-text-2'}>
                      {accountData.CustodianData.EnrollmentExplain}
                    </div>
                  </div>
                )}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-accountnumber" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Brokerage.AccountNumber'] ? (
                    helpInformation['Tooltip.Enrollment.Enroll.Brokerage.AccountNumber']
                  ) : (
                    <span>Account Number Field</span>
                  )}
                </ReactTooltip>
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-overridemask" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Brokerage.OverrideMask'] ? (
                    helpInformation['Tooltip.Enrollment.Enroll.Brokerage.OverrideMask']
                  ) : (
                    <span>Override Mask Field</span>
                  )}
                </ReactTooltip>
                <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? `account-info-item col-2 ga-2-2` : `account-info-item col-2 row-1 ga-2-1`}>
                  <div className="account-number-label-wrapper">
                    <label id="account-number-label" className={disabled ? 'disabled-label' : 'label'} for="accountnumber">
                      {getRequiredAsterisk(false, BROKERAGERequiredFields, 'AccountName', viewOnlyMode)}Account Number
                      {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-accountnumber'></i> */}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-accountnumber"
                        onClick={() => {
                          linker.UniversalHelp('Tooltip.Enrollment.Enroll.Brokerage.AccountNumber', 'popup', null);
                        }}
                      ></i>
                    </label>
                    {accountData.CustodianData && accountData.CustodianData.AccountMaskExplain && !this.state.overrideMask && !viewOnlyMode && (
                      <label className={disabled ? 'disabled-label' : 'label'}>{`Sample: ${accountData.CustodianData.AccountMaskExplain}`}</label>
                    )}
                    {accountData.CustodianData && accountData.CustodianData.AccountMask && !viewOnlyMode && !this.state.multipleAccountsSet && (
                      <div className="override-mask-div">
                        <label className={disabled ? 'disabled-label' : 'label'}>Override Mask</label>
                        <i
                          className="fa fas fa-question-circle"
                          data-tip
                          data-for="view-info-overridemask"
                          onClick={() => {
                            linker.UniversalHelp('Tooltip.Enrollment.Enroll.Brokerage.OverrideMask', 'popup', null);
                          }}
                        ></i>
                        <input
                          disabled={disabled}
                          id="override-mask-input"
                          className="override-mask-input"
                          type="checkbox"
                          checked={this.state.overrideMask}
                          onClick={this.handleOverrideMaskClick}
                        />
                      </div>
                    )}

                    {!this.props.edit && accountData.CustodianData && accountData.CustodianData.AccountLimit > 1 && !viewOnlyMode && (
                      // handleClearMultipleAccounts
                      <>
                        <div>
                          {this.state.multipleAccountsSet && (
                            <div
                              className="generic-button-secondary add-multiple-accounts-button"
                              id="add-multiple-accounts-button"
                              data-tip
                              data-for="add-multiple-accounts-button"
                              onClick={this.handleClearMultipleAccounts}
                            >
                              Clear All
                            </div>
                          )}
                          <div
                            className="generic-button-secondary add-multiple-accounts-button"
                            id="add-multiple-accounts-button"
                            data-tip
                            data-for="add-multiple-accounts-button"
                            onClick={() => {
                              this.setState({ multipleAccountsModalOpen: true });
                            }}
                          >
                            {this.state.multipleAccountsSet ? 'Edit Multiple' : 'Add Multiple'}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={
                      this.props.edit
                        ? enhancedNavigation
                          ? 'add-multiple-accounts-wrapper'
                          : 'eyeball-wrapper'
                        : enhancedNavigation
                        ? 'add-multiple-accounts-wrapper'
                        : 'add-multiple-accounts-wrapper'
                    }
                  >
                    <input
                      autoComplete="off"
                      disabled={disabled || this.state.multipleAccountsSet}
                      id="accountnumber"
                      className={(showRequired && nullFields.indexOf('AccountName') !== -1) || invalidMask ? 'account-input req' : 'account-input'}
                      placeholder=""
                      value={accountData.AccountName || ''}
                      onChange={(e) => {
                        this.updateAccountData('AccountName', e.target.value);
                      }}
                    />
                    {!this.props.edit && accountData.CustodianData && !viewOnlyMode && accountData.AccountName && !overrideMask && (
                      <div>
                        {validAccountMask ? (
                          <FontAwesome className="fa fas fa-check-circle validate-account-icon-ok" id="search-custodian-button" name="fas fa-check-circle" />
                        ) : (
                          <FontAwesome
                            className="fa fas fa-exclamation-circle validate-account-icon-warning"
                            id="search-custodian-button"
                            name="fas fa-exclamation-circle"
                            title={accountData.CustodianData.AccountMaskExplain ? `Account Number must be in the following format: ${accountData.CustodianData.AccountMaskExplain}` : ''}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-custcontacts" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Brokerage.CustodianContacts'] ? (
                    helpInformation['Tooltip.Enrollment.Enroll.Brokerage.CustodianContacts']
                  ) : (
                    <span>Custodian Contacts Field</span>
                  )}
                </ReactTooltip>
                {accountData.CustodianID && !enrollment_anonymized && (
                  <div className="account-info-item col-1 ga-1-2">
                    <label className={disabled ? 'disabled-label' : 'label'} for="custodian-contact">
                      {getRequiredAsterisk(false, BROKERAGERequiredFields, 'CustodianContact', viewOnlyMode)}Custodian Contacts
                      {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-custcontacts'></i> */}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-custcontacts"
                        onClick={() => {
                          linker.UniversalHelp('Tooltip.Enrollment.Enroll.Brokerage.CustodianContacts', 'popup', null);
                        }}
                      ></i>
                    </label>
                    {viewOnlyMode ? (
                      <input
                        autoComplete="off"
                        disabled={disabled}
                        id="custodian-contacts"
                        className="account-input"
                        placeholder=""
                        value={accountData.CustodianContact ? accountData.CustodianContact.label : null}
                      />
                    ) : (
                      <div className="select-button-wrapper">
                        <Select
                          className={showRequired && nullFields.indexOf('CustodianContact') !== -1 ? `${selectClassName} req` : selectClassName}
                          id="custodian-contacts"
                          filterOption={this.filterOption}
                          value={accountData.CustodianContact}
                          onChange={(e) => {
                            let val = e ? e.value : null;
                            this.updateAccountData('CustodianContact', e);
                          }}
                          onClick={() => {
                            this.setState({ showRequired: false });
                          }}
                          options={this.state.custodiancontacts}
                          isDisabled={disabled}
                        />
                        <button
                          disabled={disabled}
                          className="search-button"
                          id="add-custodian-contact-button"
                          onClick={() => {
                            this.addContact('Custodian Contact');
                          }}
                        >
                          <FontAwesome className="fa fas fa-plus request-button" name="fas fa-plus" />
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-accountname" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Brokerage.AccountName'] ? helpInformation['Tooltip.Enrollment.Enroll.Brokerage.AccountName'] : <span>Account Name Field</span>}
                </ReactTooltip>
                <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? `account-info-item col-2 ga-2-3` : `account-info-item col-2 row-2 ga-2-2`}>
                  <label className={disabledAlwaysEditable ? 'disabled-label' : 'label'} for="accounttitle">
                    {getRequiredAsterisk(false, BROKERAGERequiredFields, 'AccountTitle', viewOnlyMode)}Account Name
                    {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-accountname'></i> */}
                    <i
                      className="fa fas fa-question-circle"
                      data-tip
                      data-for="view-info-accountname"
                      onClick={() => {
                        linker.UniversalHelp('Tooltip.Enrollment.Enroll.Brokerage.AccountName', 'popup', null);
                      }}
                    ></i>
                  </label>
                  <div className={this.props.edit ? 'eyeball-wrapper' : ''}>
                    <input
                      autoComplete="off"
                      disabled={disabledAlwaysEditable || this.state.multipleAccountsSet}
                      id="accounttitle"
                      className={showRequired && nullFields.indexOf('AccountTitle') !== -1 ? 'account-input req' : 'account-input'}
                      placeholder=""
                      value={accountData.AccountTitle}
                      onChange={(e) => {
                        this.updateAccountData('AccountTitle', e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* Tax Entity */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-brokerage-taxentity" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountTaxEntityLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountTaxEntityLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountTaxEntityLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountTaxEntityShow && (
                  <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? `account-info-item col-2 ga-2-4` : `account-info-item col-2 ga-2-3`}>
                    <label className={disabledEditable || !enrollAccountTaxEntityEdit ? 'disabled-label' : 'label'} for="taxentity">
                      {getRequiredAsterisk(false, BROKERAGERequiredFields, 'TaxEntityID', viewOnlyMode)}
                      {enrollAccountTaxEntityLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-brokerage-taxentity"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountTaxEntityLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    <div className="input-container">
                      <ServerSideSelect
                        disabled={disabledEditable || !enrollAccountTaxEntityEdit}
                        id="taxentity"
                        labelField="HierarchyPath"
                        additionalFields={`and (TaxName ne null${accountData.TaxEntityID && ' or TaxIdNmb eq ' + accountData.TaxEntityID})`}
                        wrapperClassName={`search-input`}
                        fetchProperties={this.state.TaxEntityFetchProperties}
                        // selectClassName={`search-input`}
                        selectClassName={showRequired && nullFields.indexOf('TaxEntityID') !== -1 ? 'account-select req' : 'account-select'}
                        placeholder={accountData.TaxEntityName}
                        isClearable={true}
                        onChange={(e) => {
                          this.serverSelectTaxEntityHandle(e);
                        }}
                        ref={(instance) => {
                          this.child = instance;
                        }}
                        value={accountData.TaxEntityID}
                      />
                      {viewOnlyMode || !enrollAccountTaxEntityEdit ? null : (
                        <button
                          title="Search / Manage Entities"
                          className="ellipsis-button"
                          onClick={() => {
                            this.setState({ searchTaxEntityModalOpen: true });
                          }}
                        >
                          <FontAwesome className="fa fas ellipsis-h" id="search-taxentity-button" name="ellipsis-h" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {!enrollment_anonymized && (
                  <>
                    <div className="subheader span-all-col ga-subheader-2">Account Signers</div>
                    {this.renderSignerInfo(viewOnlyMode, disabled)}
                  </>
                )}
                {enrollHierarchyShow && accountData.Status !== 'CREATING' && <div className="subheader span-all-col ga-subheader-4">Hierarchy</div>}
                {enrollHierarchyShow && accountData.Status !== 'CREATING' && this.renderHierarchy(viewOnlyMode)}
                {this.showOtherDetails() && <div className="subheader span-all-col ga-subheader-3">Other Details</div>}

                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-firmadvisor" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value} Field</span>
                  )}
                  {/* {<span>{this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value}</span>} */}
                </ReactTooltip>
                {enrollAdvisorIDShow && (
                  <div className="account-info-item ga-1-bottom">
                    <label className={disabledEditable || !enrollAdvisorIdPolicy ? 'disabled-label' : 'label'} for="advisor">
                      {getRequiredAsterisk(false, allRequiredFields, 'AdvisorID', viewOnlyMode)}
                      {this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value}
                      {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-firmadvisor'></i> */}

                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-firmadvisor"
                        onClick={() => {
                          linker.UniversalHelp(
                            `TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`,
                            'popup',
                            null
                          );
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('AdvisorID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('AdvisorID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAdvisorIdPolicy)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAdvisorIdPolicy)
                        }
                        id="firm-advisor"
                        filterOption={this.filterOption}
                        value={{ label: accountData.AdvisorID, value: accountData.AdvisorID }}
                        onChange={(e) => {
                          this.updateAccountData('AdvisorID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['AdvisorID'].choices}
                        isDisabled={disabledEditable || !enrollAdvisorIdPolicy}
                        isClearable={!(disabledEditable || !enrollAdvisorIdPolicy)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAdvisorIdPolicy}
                        id="firm-advisor"
                        className={showRequired && nullFields.indexOf('AdvisorID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.AdvisorID ? accountData.AdvisorID : ''}
                        onChange={(e) => {
                          this.updateAccountData('AdvisorID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-rel" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[
                    `TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`
                  ] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value} Field</span>
                  )}
                </ReactTooltip>
                {enrollRelationshipIDShow && (
                  <div className="account-info-item ga-2-bottom">
                    <label className={disabledEditable || !enrollRelationshipIdPolicy ? 'disabled-label' : 'label'} for="advisor">
                      {getRequiredAsterisk(false, allRequiredFields, 'RelationshipID', viewOnlyMode)}
                      {this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value}
                      {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-rel'></i> */}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-rel"
                        onClick={() => {
                          linker.UniversalHelp(
                            `TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`,
                            'popup',
                            null
                          );
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('RelationshipID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('RelationshipID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollRelationshipIdPolicy)} req`
                            : this.getCustomClassName(disabledEditable || !enrollRelationshipIdPolicy)
                        }
                        id="firm-relationship"
                        filterOption={this.filterOption}
                        value={{ label: accountData.RelationshipID, value: accountData.RelationshipID }}
                        onChange={(e) => {
                          this.updateAccountData('RelationshipID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['RelationshipID'].choices}
                        isDisabled={disabledEditable || !enrollRelationshipIdPolicy}
                        isClearable={!(disabledEditable || !enrollRelationshipIdPolicy)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollRelationshipIdPolicy}
                        id="firm-relationship"
                        className={showRequired && nullFields.indexOf('RelationshipID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.RelationshipID ? accountData.RelationshipID : ''}
                        onChange={(e) => {
                          this.updateAccountData('RelationshipID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* ADDITIONAL ACCOUNT FIELDS - BROKERAGE     */}
                {/* TeamID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-teamid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollTeamIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollTeamIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollTeamIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollTeamIDShow && (
                  <div className="account-info-item ga-3-bottom">
                    <label className={disabledEditable || !enrollTeamIDEdit ? 'disabled-label' : 'label'} for="teamid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_TeamID', viewOnlyMode)}
                      {enrollTeamIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-teamid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollTeamIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>

                    {fieldArrayLists.includes('TeamID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_TeamID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollTeamIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollTeamIDEdit)
                        }
                        id="teamid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_TeamID, value: accountData.Account_TeamID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_TeamID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['TeamID'].choices}
                        isDisabled={disabledEditable || !enrollTeamIDEdit}
                        isClearable={!(disabledEditable || !enrollTeamIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollTeamIDEdit}
                        id="teamid"
                        className={showRequired && nullFields.indexOf('Account_TeamID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_TeamID ? accountData.Account_TeamID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_TeamID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* PartnerID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-partnerid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollPartnerIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollPartnerIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollPartnerIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {/* New Account Fields */}
                {enrollPartnerIDShow && (
                  <div className="account-info-item ga-4-bottom">
                    <label className={disabledEditable || !enrollPartnerIDEdit ? 'disabled-label' : 'label'} for="partnerid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_PartnerID', viewOnlyMode)}
                      {enrollPartnerIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-partnerid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollPartnerIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>

                    {fieldArrayLists.includes('PartnerID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_PartnerID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollPartnerIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollPartnerIDEdit)
                        }
                        id="partnerid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_PartnerID, value: accountData.Account_PartnerID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_PartnerID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['PartnerID'].choices}
                        isDisabled={disabledEditable || !enrollPartnerIDEdit}
                        isClearable={!(disabledEditable || !enrollPartnerIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollPartnerIDEdit}
                        id="partnerid"
                        className={showRequired && nullFields.indexOf('Account_PartnerID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_PartnerID ? accountData.Account_PartnerID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_PartnerID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* ClientID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-clientid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollClientIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollClientIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollClientIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollClientIDShow && (
                  <div className="account-info-item ga-5-bottom">
                    <label className={disabledEditable || !enrollClientIDEdit ? 'disabled-label' : 'label'} for="clientid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_ClientID', viewOnlyMode)}
                      {enrollClientIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-clientid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollClientIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('ClientID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_ClientID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollClientIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollClientIDEdit)
                        }
                        id="clientid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_ClientID, value: accountData.Account_ClientID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_ClientID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['ClientID'].choices}
                        isDisabled={disabledEditable || !enrollClientIDEdit}
                        isClearable={!(disabledEditable || !enrollClientIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollClientIDEdit}
                        id="clientid"
                        className={showRequired && nullFields.indexOf('Account_ClientID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_ClientID ? accountData.Account_ClientID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_ClientID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}

                {/* InvestmentID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-investmentid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollInvestmentIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollInvestmentIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollInvestmentIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollInvestmentIDShow && (
                  <div className="account-info-item ga-6-bottom">
                    <label className={disabledEditable || !enrollInvestmentIDEdit ? 'disabled-label' : 'label'} for="investmentid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_InvestmentID', viewOnlyMode)}
                      {enrollInvestmentIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-investmentid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollInvestmentIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('InvestmentID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_InvestmentID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollInvestmentIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollInvestmentIDEdit)
                        }
                        id="investmentid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_InvestmentID, value: accountData.Account_ClientID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_InvestmentID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['InvestmentID'].choices}
                        isDisabled={disabledEditable || !enrollInvestmentIDEdit}
                        isClearable={!(disabledEditable || !enrollInvestmentIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollInvestmentIDEdit}
                        id="investmentid"
                        className={showRequired && nullFields.indexOf('Account_InvestmentID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_InvestmentID ? accountData.Account_InvestmentID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_InvestmentID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}

                {/* Account_Custom1 AccountCustom1*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom1" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom1Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom1Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom1Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom1Show && (
                  <div className={`account-info-item ${enrollRelationshipIDSplit ? 'ga-4-bottom' : 'ga-7-bottom'}`}>
                    <label className={disabledEditable || !enrollAccountCustom1Edit ? 'disabled-label' : 'label'} for="account-custom1">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom1', viewOnlyMode)}
                      {enrollAccountCustom1Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom1"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom1Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom1') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom1') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom1Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom1Edit)
                        }
                        id="account-custom1"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom1, value: accountData.Account_Custom1 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom1', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom1'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom1Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom1Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom1Edit}
                        id="account-custom1"
                        className={showRequired && nullFields.indexOf('Account_Custom1') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom1 ? accountData.Account_Custom1 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom1', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom2 AccountCustom2*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom2" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom2Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom2Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom2Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom2Show && (
                  <div className="account-info-item ga-8-bottom">
                    <label className={disabledEditable || !enrollAccountCustom2Edit ? 'disabled-label' : 'label'} for="account-custom2">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom2', viewOnlyMode)}
                      {enrollAccountCustom2Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom2"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom2Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom2') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom2') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom2Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom2Edit)
                        }
                        id="account-custom2"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom2, value: accountData.Account_Custom2 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom2', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom2'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom2Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom2Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom2Edit}
                        id="account-custom2"
                        className={showRequired && nullFields.indexOf('Account_Custom2') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom2 ? accountData.Account_Custom2 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom2', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom3 AccountCustom3*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom3" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom3Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom3Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom3Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom3Show && (
                  <div className="account-info-item ga-9-bottom">
                    <label className={disabledEditable || !enrollAccountCustom3Edit ? 'disabled-label' : 'label'} for="account-custom3">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom3', viewOnlyMode)}
                      {enrollAccountCustom3Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom3"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom3Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom3') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom3') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom3Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom3Edit)
                        }
                        id="account-custom3"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom3, value: accountData.Account_Custom2 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom3', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom3'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom3Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom3Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom3Edit}
                        id="account-custom3"
                        className={showRequired && nullFields.indexOf('Account_Custom3') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom3 ? accountData.Account_Custom3 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom3', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom4 AccountCustom4*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom4" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom4Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom4Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom4Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom4Show && (
                  <div className="account-info-item ga-10-bottom">
                    <label className={disabledEditable || !enrollAccountCustom4Edit ? 'disabled-label' : 'label'} for="account-custom4">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom4', viewOnlyMode)}
                      {enrollAccountCustom4Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom4"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.BROKERAGE.${enrollAccountCustom4Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom4') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom4') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom4Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom4Edit)
                        }
                        id="account-custom4"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom4, value: accountData.Account_Custom2 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom4', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom4'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom4Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom4Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom4Edit}
                        id="account-custom4"
                        className={showRequired && nullFields.indexOf('Account_Custom4') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom4 ? accountData.Account_Custom4 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom4', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            )}

            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/*----------------------------------------------- Alternative Account Fields -----------------------------------------------*/}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}

            {accountData.ManagerType === 'ALTERNATIVE' && (viewOnlyMode || !accountTypePrompt) && (
              <div className="account-info">
                <input type="hidden" value="something" />
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-manager" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Alternative.Manager'] ? (
                    helpInformation['Tooltip.Enrollment.Enroll.Alternative.Manager']
                  ) : (
                    <span>Select from 38,000 existing managers using the telesense field or click Magnifying Glass to begin adding a new Manager.</span>
                  )}
                </ReactTooltip>
                <div className="account-info-item col-1 account-info-item-counterparty">
                  <div className={!viewOnlyMode ? 'account-learn-more-wrapper account-learn-more-counterparty' : 'account-learn-more-wrapper'}>
                    <div>
                      <label className={disabled ? 'disabled-label' : 'label'} for="custodian">
                        {getRequiredAsterisk(false, ALTERNATIVERequiredFields, 'CustodianName', viewOnlyMode)}Manager
                        <i
                          className="fa fas fa-question-circle"
                          data-tip
                          data-for="view-info-manager"
                          onClick={() => {
                            linker.UniversalHelp('Tooltip.Enrollment.Enroll.Alternative.Manager', 'popup', null);
                          }}
                        ></i>
                      </label>
                    </div>
                    {accountData.CustodianName && enrollCounterpartyIntelligence && (
                      <CounterpartyIntelligence key={accountData.CustodianName} user={user} services={services} accountData={accountData} />
                    )}
                  </div>
                  <div className="input-container">
                    <ServerSideSelect
                      disabled={disabled}
                      id="manager-input"
                      type="fuzzy"
                      labelField="Name"
                      additionalFields={"and CUSTODIAN_TYPE/NAME EQ 'Manager' and CUSTODIAN_STATUS/Status NE 'Inactive' and Enrollment eq true"}
                      wrapperClassName={`search-input`}
                      fetchProperties={this.state.SourceFetchProperties}
                      // selectClassName={`search-input`}
                      selectClassName={showRequired && nullFields.indexOf('CustodianName') !== -1 ? 'account-select req' : 'account-select'}
                      placeholder={accountData.CustodianName}
                      isClearable={true}
                      onChange={(e) => {
                        this.serverSelectCustodianHandle(e);
                      }}
                      ref={(instance) => {
                        this.child = instance;
                      }}
                      value={accountData.CustodianName}
                    />
                    {viewOnlyMode ? null : (
                      <button
                        disabled={disabled}
                        className="search-button"
                        onClick={() => {
                          this.setState({ searchCustodianModalOpen: true });
                        }}
                      >
                        <FontAwesome className="fa fas fa-search" id="search-manager-button" name="fa-search" />
                      </button>
                    )}
                  </div>
                </div>
                {accountData.CustodianData && accountData.CustodianData.EnrollmentExplain && (
                  <div className="account-custodian-description-item col-2 ga-2-1">
                    <div></div>
                    <div className="custodian-description-text">{accountData.CustodianData.EnrollmentExplain}</div>
                  </div>
                )}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-investorid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Alternative.InvestorID'] ? (
                    helpInformation['Tooltip.Enrollment.Enroll.Alternative.InvestorID']
                  ) : (
                    <span>Optional field - Unique Investor ID found on investor statement.</span>
                  )}
                </ReactTooltip>
                <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? 'account-info-item col-2 ga-2-2' : 'account-info-item col-2 row-1 ga-2-1'}>
                  <div className={!viewOnlyMode ? 'account-learn-more-wrapper account-learn-more-counterparty no-margin' : 'account-learn-more-wrapper no-margin'}>
                    <div>
                      <label className={disabledAlwaysEditable ? 'disabled-label' : 'label'} for="investorid">
                        {getRequiredAsterisk(false, ALTERNATIVERequiredFields, 'InvestorID', viewOnlyMode)}
                        {investorIDLabel}
                        <i
                          className="fa fas fa-question-circle"
                          data-tip
                          data-for="view-info-investorid"
                          onClick={() => {
                            linker.UniversalHelp('Tooltip.Enrollment.Enroll.Alternative.InvestorID', 'popup', null);
                          }}
                        ></i>
                      </label>
                    </div>
                    {!this.props.edit && accountData.CustodianData && accountData.CustodianData.AccountLimit > 1 && !viewOnlyMode && (
                      // handleClearMultipleAccounts
                      <>
                        <div>
                          {this.state.multipleAccountsSet && (
                            <div
                              className="generic-button-secondary add-multiple-accounts-button"
                              id="add-multiple-accounts-button"
                              data-tip
                              data-for="add-multiple-accounts-button"
                              onClick={this.handleClearMultipleAccounts}
                            >
                              Clear All
                            </div>
                          )}
                          <div
                            className="generic-button-secondary add-multiple-accounts-button"
                            id="add-multiple-accounts-button"
                            data-tip
                            data-for="add-multiple-accounts-button"
                            onClick={() => {
                              this.setState({ multipleAccountsModalOpen: true });
                            }}
                          >
                            {this.state.multipleAccountsSet ? 'Edit Multiple' : 'Add Multiple'}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <input
                      autoComplete="off"
                      disabled={disabledAlwaysEditable}
                      id="investorid"
                      className={showRequired && nullFields.indexOf('InvestorID') !== -1 ? 'account-input req' : 'account-input'}
                      placeholder=""
                      value={accountData.InvestorID}
                      onChange={(e) => {
                        this.updateAccountData('InvestorID', e.target.value);
                      }}
                    />
                  </div>
                </div>
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="fund-info" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Alternative.Fund'] ? (
                    helpInformation['Tooltip.Enrollment.Enroll.Alternative.Fund']
                  ) : (
                    <span>Select from existing funds using the telesense field or click [+] to begin adding a new Fund.</span>
                  )}
                </ReactTooltip>
                {accountData.CustodianID &&
                (this.props.edit || viewOnlyMode) && ( //Only show when selected custodian
                    <div className="account-info-item col-1 ga-1-2">
                      <label className={disabled ? 'disabled-label' : 'label'} for="fund">
                        {getRequiredAsterisk(false, ALTERNATIVERequiredFields, 'Fund', viewOnlyMode)}Fund
                        <i
                          className="fa fas fa-question-circle"
                          data-tip
                          data-for="fund-info"
                          onClick={() => {
                            linker.UniversalHelp('Tooltip.Enrollment.Enroll.Alternative.Fund', 'popup', null);
                          }}
                        ></i>
                      </label>
                      {viewOnlyMode ? (
                        <input autoComplete="off" disabled={disabled} id="fund" className="account-input" placeholder="" value={accountData.Fund ? accountData.Fund.label : null} />
                      ) : (
                        <div className="select-button-wrapper">
                          <Select
                            className={showRequired && nullFields.indexOf('Fund') !== -1 ? `${selectClassName} req` : selectClassName}
                            id="fund"
                            filterOption={this.filterOption}
                            value={accountData.Fund}
                            onChange={(e) => {
                              let val = e ? e.value : null;
                              this.updateAccountData('Fund', e);
                            }}
                            onClick={() => {
                              this.setState({ showRequired: false });
                            }}
                            options={this.state.funds}
                            isDisabled={disabled}
                          />
                          <button
                            disabled={disabled}
                            className="search-button"
                            id="add-Fund-button"
                            onClick={() => {
                              this.onAddClick('fund');
                            }}
                          >
                            <FontAwesome className="fa fas fa-plus request-button" name="fas fa-plus" />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                {/* {(!accountData.CustodianID) &&
              <div>
                empty
              </div>
            } */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-investorname" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Alternative.InvestorName'] ? (
                    helpInformation['Tooltip.Enrollment.Enroll.Alternative.InvestorName']
                  ) : (
                    <span>Registered legal entity / investor name found on investor statement</span>
                  )}
                </ReactTooltip>
                <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? 'account-info-item col-2 ga-1-3' : 'account-info-item col-2 row-2 ga-2-2'}>
                  <label className={disabled ? 'disabled-label' : 'label'} for="investor-name">
                    {getRequiredAsterisk(false, ALTERNATIVERequiredFields, 'InvestorName', viewOnlyMode)}Investor Name
                    <i
                      className="fa fas fa-question-circle"
                      data-tip
                      data-for="view-info-investorname"
                      onClick={() => {
                        linker.UniversalHelp('Tooltip.Enrollment.Enroll.Alternative.InvestorName', 'popup', null);
                      }}
                    ></i>
                  </label>
                  <div className={this.props.edit ? 'eyeball-wrapper' : ''}>
                    <input
                      autoComplete="off"
                      disabled={disabled || this.state.multipleAccountsSet}
                      id="investor"
                      className={showRequired && nullFields.indexOf('InvestorName') !== -1 ? 'account-input search-input req' : 'account-input'}
                      placeholder=""
                      value={accountData.InvestorName}
                      onChange={(e) => {
                        this.updateAccountData('InvestorName', e.target.value);
                      }}
                    />
                  </div>
                </div>
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-managercontacts" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Alternative.ManagerContact'] ? (
                    helpInformation['Tooltip.Enrollment.Enroll.Alternative.ManagerContact']
                  ) : (
                    <span>This is the person who will be receiving the LOA. Select from existing contacts using dropdown menu or click [+] to add a new Manager Contact.</span>
                  )}
                </ReactTooltip>
                {accountData.CustodianID && !enrollment_anonymized && (
                  <div
                    className={
                      // Needed some style logic here because the Custodian Contact Field is displayed based on Policy
                      mode === 'add' && !enrollAlternativeCustodianShow
                        ? 'account-info-item col-1 ga-1-2'
                        : mode === 'add' && enrollAlternativeCustodianShow
                        ? 'account-info-item col-1 ga-1-2'
                        : mode === 'view' || mode === 'edit'
                        ? 'account-info-item col-1 ga-1-3'
                        : 'account-info-item col-1 ga-1-2'
                    }
                  >
                    <label className={disabled ? 'disabled-label' : 'label'} for="custodian-contact">
                      {getRequiredAsterisk(false, ALTERNATIVERequiredFields, 'CustodianContact', viewOnlyMode)}Manager Contact
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-managercontacts"
                        onClick={() => {
                          linker.UniversalHelp('Tooltip.Enrollment.Enroll.Alternative.ManagerContact', 'popup', null);
                        }}
                      ></i>
                    </label>
                    {viewOnlyMode ? (
                      <input
                        autoComplete="off"
                        disabled={disabled}
                        id="manager-contacts"
                        className="account-input"
                        placeholder=""
                        value={accountData.CustodianContact ? accountData.CustodianContact.label : null}
                      />
                    ) : (
                      <div className="select-button-wrapper">
                        <Select
                          className={showRequired && nullFields.indexOf('CustodianContact') !== -1 ? `${selectClassName} req` : selectClassName}
                          filterOption={this.filterOption}
                          id="manager-contacts"
                          value={accountData.CustodianContact}
                          onChange={(e) => {
                            let val = e ? e.value : null;
                            this.updateAccountData('CustodianContact', e);
                          }}
                          onClick={() => {
                            this.setState({ showRequired: false });
                          }}
                          options={this.state.custodiancontacts}
                          isDisabled={disabled}
                        />
                        <button
                          disabled={disabled}
                          className="search-button"
                          id="add-Managercontact-button"
                          onClick={() => {
                            this.addContact('Manager Contact');
                          }}
                        >
                          <FontAwesome className="fa fas fa-plus request-button" name="fas fa-plus" />
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-cust-related" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Alternative.Custodian'] ? helpInformation['Tooltip.Enrollment.Enroll.Alternative.Custodian'] : <span>Custodian Field</span>}
                </ReactTooltip>
                {accountData.CustodianID && enrollAlternativeCustodianShow && (
                  <div className="account-info-item col-1 ">
                    <label className={disabled ? 'disabled-label' : 'label'} for="custodian">
                      {getRequiredAsterisk(false, ALTERNATIVERequiredFields, 'Account_Related_Entity_UID', viewOnlyMode)}
                      {enrollAlternativeCustodianLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-cust-related"
                        onClick={() => {
                          linker.UniversalHelp('Tooltip.Enrollment.Enroll.Alternative.Custodian', 'popup', null);
                        }}
                      ></i>
                    </label>
                    <div className="input-container">
                      <ServerSideSelect
                        disabled={disabled || disabledEditable || !enrollAlternativeCustodianEdit}
                        id="account_related_entity"
                        labelField="Name"
                        additionalFields={`and CUSTODIAN_TYPE/NAME EQ 'CUSTODIAN' and CUSTODIAN_STATUS/Status NE 'Inactive' and Enrollment eq true`}
                        wrapperClassName={`search-input`}
                        fetchProperties={this.state.SourceFetchProperties}
                        selectClassName={showRequired && nullFields.indexOf('Account_Related_Entity_UID') !== -1 ? 'account-select req' : 'account-select'}
                        onChange={(e) => {
                          this.serverSelectCustodianRelatedHandle(e);
                        }}
                        isClearable={true}
                        ref={(instance) => {
                          this.child = instance;
                        }}
                        placeholder={accountData.CustodianRelatedName ? accountData.CustodianRelatedName : ''}
                        value={accountData.CustodianRelatedName ? accountData.CustodianRelatedName : ''}
                      />
                      {viewOnlyMode ? null : (
                        <button
                          disabled={disabled || disabledEditable || !enrollAlternativeCustodianEdit}
                          className="search-button"
                          onClick={() => {
                            !disabledEditable && enrollAlternativeCustodianEdit && this.setState({ searchCustodianModalOpen: true, searchCustodianRelated: true });
                          }}
                        >
                          <FontAwesome className="fa fas fa-search" id="search-custodian-button" name="fa-search" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {/* Alternative - Tax Entity */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-alternative-taxentity" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountTaxEntityLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountTaxEntityLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountTaxEntityLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountTaxEntityShow ? (
                  <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? `account-info-item col-2 ga-1-4` : `account-info-item col-2 ga-2-3`}>
                    <label className={disabledEditable || !enrollAccountTaxEntityEdit ? 'disabled-label' : 'label'} for="taxentity">
                      {getRequiredAsterisk(false, ALTERNATIVERequiredFields, 'TaxEntityID', viewOnlyMode)}
                      {enrollAccountTaxEntityLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-alternative-taxentity"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountTaxEntityLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    <div className="input-container">
                      <ServerSideSelect
                        disabled={disabledEditable || !enrollAccountTaxEntityEdit}
                        id="taxentity"
                        labelField="HierarchyPath"
                        additionalFields={`and (TaxName ne null${accountData.TaxEntityID && ' or TaxIdNmb eq ' + accountData.TaxEntityID})`}
                        wrapperClassName={`search-input`}
                        fetchProperties={this.state.TaxEntityFetchProperties}
                        selectClassName={showRequired && nullFields.indexOf('TaxEntityID') !== -1 ? 'account-select req' : 'account-select'}
                        placeholder={accountData.TaxEntityName}
                        isClearable={true}
                        onChange={(e) => {
                          this.serverSelectTaxEntityHandle(e);
                        }}
                        ref={(instance) => {
                          this.child = instance;
                        }}
                        value={accountData.TaxEntityID}
                      />
                      {viewOnlyMode || !enrollAccountTaxEntityEdit ? null : (
                        <button
                          title="Search / Manage Entities"
                          className="ellipsis-button"
                          onClick={() => {
                            this.setState({ searchTaxEntityModalOpen: true });
                          }}
                        >
                          <FontAwesome className="fa fas ellipsis-h" id="search-taxentity-button" name="ellipsis-h" />
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={`account-info-item col-2 ga-1-3`}></div>
                )}
                {/* Support for Multiple Fund Values */}
                {accountData.CustodianID && !this.props.edit && !viewOnlyMode && !this.state.multipleAccountsSet && (
                  <div className="ga-1-5">
                    <div className="subheader span-all-col ga-subheader-1">Funds</div>
                    {this.renderFundInfo(viewOnlyMode, disabled)}
                  </div>
                )}
                {accountData.CustodianID && !this.props.edit && !viewOnlyMode && this.state.multipleAccountsSet && (
                  <div className="ga-1-5">
                    <div className="subheader span-all-col ga-subheader-1">Funds</div>

                    <div className={'account-info-item ga-1-bottom'}>
                      <input
                        title={this.state.multipleAccountsData && this.state.multipleAccountsData.FundNames}
                        autoComplete="off"
                        disabled={disabled || this.state.multipleAccountsSet}
                        id="investor"
                        className={'account-input'}
                        placeholder=""
                        value={this.state.multipleAccountsData && this.state.multipleAccountsData.FundNames}
                      />
                    </div>
                  </div>
                )}
                {!enrollment_anonymized && (
                  <>
                    <div className="subheader span-all-col ga-subheader-2">Account Signers</div>
                    {this.renderSignerInfo(viewOnlyMode, disabled)}
                  </>
                )}
                {enrollHierarchyShow && accountData.Status !== 'CREATING' && <div className="subheader span-all-col ga-subheader-4">Hierarchy</div>}
                {enrollHierarchyShow && accountData.Status !== 'CREATING' && this.renderHierarchy(viewOnlyMode)}
                {this.showOtherDetails() && <div className="subheader span-all-col ga-subheader-3">Other Details</div>}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-firmadvisor" place="top" type="info" effect="solid" className="tooltip_custom">
                  {/* {helpInformation["Tooltip.Enrollment.Enroll.Alternative.FirmAdvisorID"] ? (
                    helpInformation["Tooltip.Enrollment.Enroll.Alternative.FirmAdvisorID"]
                  ) : (
                    <span>Optional field to append your own proprietary identifier to help group and sort records</span>
                    )} */}
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>Optional field to append your own proprietary identifier to help group and sort records</span>
                  )}
                </ReactTooltip>
                {enrollAdvisorIDShow && (
                  <div className="account-info-item ga-1-bottom">
                    <label className={disabledEditable || !enrollAdvisorIdPolicy ? 'disabled-label' : 'label'} for="advisor">
                      {getRequiredAsterisk(false, allRequiredFields, 'AdvisorID', viewOnlyMode)}
                      {this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value}
                      {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-firmadvisor'></i> */}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-firmadvisor"
                        onClick={() => {
                          //  linker.UniversalHelp("Tooltip.Enrollment.Enroll.Alternative.FirmAdvisorID", "popup", null);
                          linker.UniversalHelp(
                            `TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`,
                            'popup',
                            null
                          );
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('AdvisorID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('AdvisorID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAdvisorIdPolicy)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAdvisorIdPolicy)
                        }
                        id="firm-advisor"
                        filterOption={this.filterOption}
                        value={{ label: accountData.AdvisorID, value: accountData.AdvisorID }}
                        onChange={(e) => {
                          this.updateAccountData('AdvisorID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['AdvisorID'].choices}
                        isDisabled={disabledEditable || !enrollAdvisorIdPolicy}
                        isClearable={!(disabledEditable || !enrollAdvisorIdPolicy)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAdvisorIdPolicy}
                        id="firm-advisor"
                        className={showRequired && nullFields.indexOf('AdvisorID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.AdvisorID ? accountData.AdvisorID : ''}
                        onChange={(e) => {
                          this.updateAccountData('AdvisorID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-rel" place="top" type="info" effect="solid" className="tooltip_custom">
                  {/* {helpInformation["Tooltip.Enrollment.Enroll.Alternative.FirmRelationshipID"] ? (
                    helpInformation["Tooltip.Enrollment.Enroll.Alternative.FirmRelationshipID"]
                  ) : (
                    <span>Optional field to append your own proprietary identifier to help group and sort records</span>
                    )} */}
                  {helpInformation[
                    `TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`
                  ] ? (
                    helpInformation[
                      `TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`
                    ]
                  ) : (
                    <span>Optional field to append your own proprietary identifier to help group and sort records</span>
                  )}
                </ReactTooltip>
                {enrollRelationshipIDShow && (
                  <div className="account-info-item ga-2-bottom">
                    <label className={disabledEditable || !enrollRelationshipIdPolicy ? 'disabled-label' : 'label'} for="advisor">
                      {getRequiredAsterisk(false, allRequiredFields, 'RelationshipID', viewOnlyMode)}
                      {this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value}
                      {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-rel'></i> */}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-rel"
                        onClick={() => {
                          // linker.UniversalHelp("Tooltip.Enrollment.Enroll.Alternative.FirmRelationshipID", "popup", null);
                          linker.UniversalHelp(
                            `TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`,
                            'popup',
                            null
                          );
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('RelationshipID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('RelationshipID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollRelationshipIdPolicy)} req`
                            : this.getCustomClassName(disabledEditable || !enrollRelationshipIdPolicy)
                        }
                        id="firm-relationship"
                        filterOption={this.filterOption}
                        value={{ label: accountData.RelationshipID, value: accountData.RelationshipID }}
                        onChange={(e) => {
                          this.updateAccountData('RelationshipID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['RelationshipID'].choices}
                        isDisabled={disabledEditable || !enrollRelationshipIdPolicy}
                        isClearable={!(disabledEditable || !enrollRelationshipIdPolicy)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollRelationshipIdPolicy}
                        id="firm-relationship"
                        className={showRequired && nullFields.indexOf('RelationshipID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.RelationshipID ? accountData.RelationshipID : ''}
                        onChange={(e) => {
                          this.updateAccountData('RelationshipID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* ADDITIONAL ACCOUNT FIELDS - ALTERNATIVE     */}
                {/* TeamID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-teamid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollTeamIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollTeamIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollTeamIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollTeamIDShow && (
                  <div className="account-info-item ga-3-bottom">
                    <label className={disabledEditable || !enrollTeamIDEdit ? 'disabled-label' : 'label'} for="teamid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_TeamID', viewOnlyMode)}
                      {enrollTeamIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-teamid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollTeamIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('TeamID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_TeamID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollTeamIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollTeamIDEdit)
                        }
                        id="teamid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_TeamID, value: accountData.Account_TeamID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_TeamID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['TeamID'].choices}
                        isDisabled={disabledEditable || !enrollTeamIDEdit}
                        isClearable={!(disabledEditable || !enrollTeamIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollTeamIDEdit}
                        id="teamid"
                        className={showRequired && nullFields.indexOf('Account_TeamID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_TeamID ? accountData.Account_TeamID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_TeamID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* PartnerID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-partnerid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollPartnerIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollPartnerIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollPartnerIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {/* New Account Fields */}
                {enrollPartnerIDShow && (
                  <div className="account-info-item ga-4-bottom">
                    <label className={disabledEditable || !enrollPartnerIDEdit ? 'disabled-label' : 'label'} for="partnerid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_PartnerID', viewOnlyMode)}
                      {enrollPartnerIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-partnerid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollPartnerIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('PartnerID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_PartnerID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollPartnerIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollPartnerIDEdit)
                        }
                        id="partnerid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_PartnerID, value: accountData.Account_PartnerID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_PartnerID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['PartnerID'].choices}
                        isDisabled={disabledEditable || !enrollPartnerIDEdit}
                        isClearable={!(disabledEditable || !enrollPartnerIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollPartnerIDEdit}
                        id="partnerid"
                        className={showRequired && nullFields.indexOf('Account_PartnerID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_PartnerID ? accountData.Account_PartnerID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_PartnerID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* ClientID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-clientid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollClientIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollClientIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollClientIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollClientIDShow && (
                  <div className="account-info-item ga-5-bottom">
                    <label className={disabledEditable || !enrollClientIDEdit ? 'disabled-label' : 'label'} for="clientid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_ClientID', viewOnlyMode)}
                      {enrollClientIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-clientid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollClientIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('ClientID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_ClientID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollClientIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollClientIDEdit)
                        }
                        id="clientid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_ClientID, value: accountData.Account_ClientID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_ClientID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['ClientID'].choices}
                        isDisabled={disabledEditable || !enrollClientIDEdit}
                        isClearable={!(disabledEditable || !enrollClientIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollClientIDEdit}
                        id="clientid"
                        className={showRequired && nullFields.indexOf('Account_ClientID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_ClientID ? accountData.Account_ClientID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_ClientID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* InvestmentID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-investmentid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollInvestmentIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollInvestmentIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollInvestmentIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollInvestmentIDShow && (
                  <div className="account-info-item ga-6-bottom">
                    <label className={disabledEditable || !enrollInvestmentIDEdit ? 'disabled-label' : 'label'} for="investmentid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_InvestmentID', viewOnlyMode)}
                      {enrollInvestmentIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-investmentid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollInvestmentIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('InvestmentID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_InvestmentID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollInvestmentIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollInvestmentIDEdit)
                        }
                        id="investmentid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_InvestmentID, value: accountData.Account_InvestmentID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_InvestmentID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['InvestmentID'].choices}
                        isDisabled={disabledEditable || !enrollInvestmentIDEdit}
                        isClearable={!(disabledEditable || !enrollInvestmentIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollInvestmentIDEdit}
                        id="investmentid"
                        className={showRequired && nullFields.indexOf('Account_InvestmentID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_InvestmentID ? accountData.Account_InvestmentID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_InvestmentID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom1 AccountCustom1*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom1" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom1Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom1Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom1Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom1Show && (
                  <div className={`account-info-item ${enrollRelationshipIDSplit ? 'ga-4-bottom' : 'ga-7-bottom'}`}>
                    <label className={disabledEditable || !enrollAccountCustom1Edit ? 'disabled-label' : 'label'} for="account-custom1">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom1', viewOnlyMode)}
                      {enrollAccountCustom1Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom1"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom1Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom1') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom1') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom1Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom1Edit)
                        }
                        id="account-custom1"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom1, value: accountData.Account_Custom1 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom1', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom1'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom1Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom1Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom1Edit}
                        id="account-custom1"
                        className={showRequired && nullFields.indexOf('Account_Custom1') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom1 ? accountData.Account_Custom1 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom1', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom2 AccountCustom2*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom2" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom2Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom2Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom2Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom2Show && (
                  <div className="account-info-item ga-8-bottom">
                    <label className={disabledEditable || !enrollAccountCustom2Edit ? 'disabled-label' : 'label'} for="account-custom2">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom2', viewOnlyMode)}
                      {enrollAccountCustom2Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom2"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom2Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom2') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom2') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom2Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom2Edit)
                        }
                        id="account-custom2"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom2, value: accountData.Account_Custom2 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom2', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom2'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom2Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom2Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom2Edit}
                        id="account-custom2"
                        className={showRequired && nullFields.indexOf('Account_Custom2') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom2 ? accountData.Account_Custom2 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom2', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom3 AccountCustom3*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom3" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom3Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom3Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom3Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom3Show && (
                  <div className="account-info-item ga-9-bottom">
                    <label className={disabledEditable || !enrollAccountCustom3Edit ? 'disabled-label' : 'label'} for="account-custom3">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom3', viewOnlyMode)}
                      {enrollAccountCustom3Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom3"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom3Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom3') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom3') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom3Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom3Edit)
                        }
                        id="account-custom3"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom3, value: accountData.Account_Custom3 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom3', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom3'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom3Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom3Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom3Edit}
                        id="account-custom3"
                        className={showRequired && nullFields.indexOf('Account_Custom3') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom3 ? accountData.Account_Custom3 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom3', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom4 AccountCustom4*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom4" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom4Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom4Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom4Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom4Show && (
                  <div className="account-info-item ga-10-bottom">
                    <label className={disabledEditable || !enrollAccountCustom4Edit ? 'disabled-label' : 'label'} for="account-custom4">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom4', viewOnlyMode)}
                      {enrollAccountCustom4Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom4"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.ALTERNATIVE.${enrollAccountCustom4Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom4') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom4') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom4Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom4Edit)
                        }
                        id="account-custom4"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom4, value: accountData.Account_Custom4 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom4', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom4'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom4Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom4Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom4Edit}
                        id="account-custom4"
                        className={showRequired && nullFields.indexOf('Account_Custom4') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom4 ? accountData.Account_Custom4 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom4', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            )}

            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* -------------------------------------------------- Other Account Fields -------------------------------------------------*/}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------------------------------------------------------------------------ */}

            {accountData.ManagerType === 'OTHER' && (viewOnlyMode || !accountTypePrompt) && (
              <div className="account-info">
                <input type="hidden" value="something" />
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-institution" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Other.Institution'] ? helpInformation['Tooltip.Enrollment.Enroll.Other.Institution'] : <span>Institution Field</span>}
                </ReactTooltip>
                <div className="account-info-item col-1 ga-1-1">
                  <label className={disabled ? 'disabled-label' : 'label'} for="custodian">
                    {getRequiredAsterisk(false, OTHERRequiredFields, 'CustodianName', viewOnlyMode)}Institution
                    {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-institution'></i> */}
                    <i
                      className="fa fas fa-question-circle"
                      data-tip
                      data-for="view-info-institution"
                      onClick={() => {
                        linker.UniversalHelp('Tooltip.Enrollment.Enroll.Other.Institution', 'popup', null);
                      }}
                    ></i>
                  </label>
                  <div className="input-container">
                    <ServerSideSelect
                      disabled={disabled}
                      id="institution-input"
                      labelField="Name"
                      additionalFields={"and CUSTODIAN_TYPE/NAME ne 'Custodian' and CUSTODIAN_TYPE/NAME ne 'Manager' and Enrollment eq true"}
                      wrapperClassName={`search-input`}
                      fetchProperties={this.state.SourceFetchProperties}
                      // selectClassName={`search-input`}
                      selectClassName={showRequired && nullFields.indexOf('CustodianName') !== -1 ? 'account-select req' : 'account-select'}
                      placeholder={accountData.CustodianName}
                      isClearable={true}
                      onChange={(e) => {
                        this.serverSelectCustodianHandle(e);
                      }}
                      ref={(instance) => {
                        this.child = instance;
                      }}
                      value={accountData.CustodianName}
                    />
                    {viewOnlyMode ? null : (
                      <button
                        disabled={disabled}
                        className="search-button"
                        onClick={() => {
                          this.setState({ searchCustodianModalOpen: true });
                        }}
                      >
                        <FontAwesome className="fa fas fa-search" id="search-institution-button" name="fa-search" />
                      </button>
                    )}
                  </div>
                </div>

                {accountData.CustodianData && accountData.CustodianData.EnrollmentExplain && (
                  <div className="account-custodian-description-item ga-2-1">
                    <div></div>
                    <div className="custodian-description-text">{accountData.CustodianData.EnrollmentExplain}</div>
                  </div>
                )}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-accountname" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Other.AccountName'] ? helpInformation['Tooltip.Enrollment.Enroll.Other.AccountName'] : <span>Account Name Field</span>}
                </ReactTooltip>
                <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? 'account-info-item col-2 ga-2-2' : 'account-info-item col-2 row-1 ga-2-1'}>
                  <label className={disabledAlwaysEditable ? 'disabled-label' : 'label'} for="accounttitle">
                    {getRequiredAsterisk(false, OTHERRequiredFields, 'AccountTitle', viewOnlyMode)}Account Name
                    {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-accountname'></i> */}
                    <i
                      className="fa fas fa-question-circle"
                      data-tip
                      data-for="view-info-accountname"
                      onClick={() => {
                        linker.UniversalHelp('Tooltip.Enrollment.Enroll.Other.AccountName', 'popup', null);
                      }}
                    ></i>
                  </label>
                  <input
                    className={showRequired && nullFields.indexOf('AccountTitle') !== -1 ? 'account-input req' : 'account-input'}
                    placeholder=""
                    autoComplete="off"
                    disabled={disabledAlwaysEditable}
                    id="accounttitle"
                    value={accountData.AccountTitle}
                    onChange={(e) => {
                      this.updateAccountData('AccountTitle', e.target.value);
                    }}
                  />
                </div>

                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-custcontacts" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Other.CustodianContacts'] ? (
                    helpInformation['Tooltip.Enrollment.Enroll.Other.CustodianContacts']
                  ) : (
                    <span>Custodian Contacts Field</span>
                  )}
                </ReactTooltip>
                {accountData.CustodianID && !enrollment_anonymized && (
                  <div className="account-info-item col-1 ga-1-2">
                    <label className={disabled ? 'disabled-label' : 'label'} for="custodian-contact">
                      {getRequiredAsterisk(false, OTHERRequiredFields, 'AccountTitle', viewOnlyMode)}Custodian Contacts
                      {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-custcontacts'></i> */}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-custcontacts"
                        onClick={() => {
                          linker.UniversalHelp('Tooltip.Enrollment.Enroll.Other.CustodianContacts', 'popup', null);
                        }}
                      ></i>
                    </label>
                    {viewOnlyMode ? (
                      <input
                        autoComplete="off"
                        disabled={disabled}
                        id="custodian-contacts"
                        className="account-input"
                        placeholder=""
                        value={accountData.CustodianContact ? accountData.CustodianContact.label : null}
                      />
                    ) : (
                      <div className="select-button-wrapper">
                        <Select
                          className={showRequired && nullFields.indexOf('CustodianContact') !== -1 ? `${selectClassName} req` : selectClassName}
                          id="custodian-contacts"
                          filterOption={this.filterOption}
                          value={accountData.CustodianContact}
                          onChange={(e) => {
                            let val = e ? e.value : null;
                            this.updateAccountData('CustodianContact', e);
                          }}
                          onClick={() => {
                            this.setState({ showRequired: false });
                          }}
                          options={this.state.custodiancontacts}
                          isDisabled={disabled}
                        />
                        <button
                          disabled={disabled}
                          className="search-button"
                          id="add-datasource-button"
                          onClick={() => {
                            this.addContact('Custodian Contact');
                          }}
                        >
                          <FontAwesome className="fa fas fa-plus request-button" name="fas fa-plus" />
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-accountnumber" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Other.AccountNumber'] ? helpInformation['Tooltip.Enrollment.Enroll.Other.AccountNumber'] : <span>Account Number Field</span>}
                </ReactTooltip>

                <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? 'account-info-item col-2 ga-2-3' : 'account-info-item col-2 row-2 ga-2-2'}>
                  <label className={disabled ? 'disabled-label' : 'label'} for="accountnumber">
                    {getRequiredAsterisk(false, OTHERRequiredFields, 'AccountName', viewOnlyMode)}Account Number
                    {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-accountnumber'></i> */}
                    <i
                      className="fa fas fa-question-circle"
                      data-tip
                      data-for="view-info-accountnumber"
                      onClick={() => {
                        linker.UniversalHelp('Tooltip.Enrollment.Enroll.Other.AccountNumber', 'popup', null);
                      }}
                    ></i>
                  </label>
                  <div className={this.props.edit ? 'eyeball-wrapper' : ''}>
                    <input
                      autoComplete="off"
                      disabled={disabled}
                      id="accountnumber"
                      className={showRequired && nullFields.indexOf('AccountName') !== -1 ? 'account-input req' : 'account-input'}
                      placeholder=""
                      value={accountData.AccountName}
                      onChange={(e) => {
                        this.updateAccountData('AccountName', e.target.value);
                      }}
                    />
                  </div>
                </div>

                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-accounttype" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation['Tooltip.Enrollment.Enroll.Other.AccountType'] ? helpInformation['Tooltip.Enrollment.Enroll.Other.AccountType'] : <span>Account Type Field</span>}
                </ReactTooltip>
                <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? 'account-info-item col-2 ga-2-4' : 'account-info-item col-2 row-3 ga-2-3'}>
                  <label className={disabled ? 'disabled-label' : 'label'} for="accounttype">
                    {getRequiredAsterisk(false, OTHERRequiredFields, 'AccountTypeID', viewOnlyMode)}Account Type
                    {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-accounttype'></i> */}
                    <i
                      className="fa fas fa-question-circle"
                      data-tip
                      data-for="view-info-accounttype"
                      onClick={() => {
                        linker.UniversalHelp('Tooltip.Enrollment.Enroll.Other.AccountType', 'popup', null);
                      }}
                    ></i>
                  </label>
                  {viewOnlyMode ? (
                    <input
                      autoComplete="off"
                      disabled={disabled}
                      id="accounttype"
                      className="account-input"
                      placeholder=""
                      value={accountData.AccountTypeID ? accountData.AccountTypeID.label : null}
                    />
                  ) : (
                    <Select
                      className={showRequired && nullFields.indexOf('AccountTypeID') !== -1 ? `${selectClassName} req` : selectClassName}
                      filterOption={this.filterOption}
                      id="accounttype"
                      value={accountData.AccountTypeID}
                      onChange={(e) => {
                        let val = e ? e.value : null;
                        this.updateAccountData('AccountTypeID', e);
                      }}
                      onClick={() => {
                        this.setState({ showRequired: false });
                      }}
                      options={this.state.accountTypeOptions}
                      isDisabled={disabled}
                    />
                  )}
                </div>
                {/* Other - Tax Entity */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-other-taxentity" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountTaxEntityLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountTaxEntityLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountTaxEntityLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountTaxEntityShow && (
                  <div className={accountData.CustodianData && accountData.CustodianData.EnrollmentExplain ? `account-info-item col-2 ga-1-5` : `account-info-item col-2 row-4 ga-2-4`}>
                    <label className={disabledEditable || !enrollAccountTaxEntityEdit ? 'disabled-label' : 'label'} for="taxentity">
                      {getRequiredAsterisk(false, OTHERRequiredFields, 'TaxEntityID', viewOnlyMode)}
                      {enrollAccountTaxEntityLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-other-taxentity"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountTaxEntityLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    <div className="input-container">
                      <ServerSideSelect
                        disabled={disabledEditable || !enrollAccountTaxEntityEdit}
                        id="taxentity"
                        labelField="HierarchyPath"
                        additionalFields={`and (TaxName ne null${accountData.TaxEntityID && ' or TaxIdNmb eq ' + accountData.TaxEntityID})`}
                        wrapperClassName={`search-input`}
                        fetchProperties={this.state.TaxEntityFetchProperties}
                        selectClassName={showRequired && nullFields.indexOf('TaxEntityID') !== -1 ? 'account-select req' : 'account-select'}
                        placeholder={accountData.TaxEntityName}
                        isClearable={true}
                        onChange={(e) => {
                          this.serverSelectTaxEntityHandle(e);
                        }}
                        ref={(instance) => {
                          this.child = instance;
                        }}
                        value={accountData.TaxEntityID}
                      />
                      {viewOnlyMode || !enrollAccountTaxEntityEdit ? null : (
                        <button
                          title="Search / Manage Entities"
                          className="ellipsis-button"
                          onClick={() => {
                            this.setState({ searchTaxEntityModalOpen: true });
                          }}
                        >
                          <FontAwesome className="fa fas ellipsis-h" id="search-taxentity-button" name="ellipsis-h" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {!enrollment_anonymized && (
                  <>
                    <div className="subheader span-all-col ga-subheader-2">Account Signers</div>
                    {this.renderSignerInfo(viewOnlyMode, disabled)}
                  </>
                )}

                {enrollHierarchyShow && accountData.Status !== 'CREATING' && <div className="subheader span-all-col ga-subheader-4">Hierarchy</div>}
                {enrollHierarchyShow && accountData.Status !== 'CREATING' && this.renderHierarchy(viewOnlyMode)}
                {this.showOtherDetails() && <div className="subheader span-all-col ga-subheader-3">Other Details</div>}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-firmadvisor" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value}</span>
                  )}
                </ReactTooltip>
                {enrollAdvisorIDShow && (
                  <div className="account-info-item ga-1-bottom">
                    <label className={disabledEditable || !enrollAdvisorIdPolicy ? 'disabled-label' : 'label'} for="advisor">
                      {getRequiredAsterisk(false, allRequiredFields, 'AdvisorID', viewOnlyMode)}
                      {this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value}
                      {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-firmadvisor'></i> */}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-firmadvisor"
                        onClick={() => {
                          linker.UniversalHelp(
                            `TOOLTIP.ENROLLMENT.ENROLL.OTHER.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_ADVISORID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`,
                            'popup',
                            null
                          );
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('AdvisorID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('AdvisorID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAdvisorIdPolicy)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAdvisorIdPolicy)
                        }
                        id="firm-advisor"
                        filterOption={this.filterOption}
                        value={{ label: accountData.AdvisorID, value: accountData.AdvisorID }}
                        onChange={(e) => {
                          this.updateAccountData('AdvisorID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['AdvisorID'].choices}
                        isDisabled={disabledEditable || !enrollAdvisorIdPolicy}
                        isClearable={!(disabledEditable || !enrollAdvisorIdPolicy)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAdvisorIdPolicy}
                        id="firm-advisor"
                        className={showRequired && nullFields.indexOf('AdvisorID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.AdvisorID ? accountData.AdvisorID : ''}
                        onChange={(e) => {
                          this.updateAccountData('AdvisorID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-rel" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value} Field</span>
                  )}
                </ReactTooltip>
                {enrollRelationshipIDShow && (
                  <div className="account-info-item ga-2-bottom">
                    <label className={disabledEditable || !enrollRelationshipIdPolicy ? 'disabled-label' : 'label'} for="advisor">
                      {getRequiredAsterisk(false, allRequiredFields, 'RelationshipID', viewOnlyMode)}
                      {this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value}
                      {/* <i className='fa fas fa-question-circle' data-tip data-for='view-info-rel'></i> */}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-rel"
                        onClick={() => {
                          linker.UniversalHelp(
                            `TOOLTIP.ENROLLMENT.ENROLL.OTHER.${this.props.user.firmPolicies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL].Value.replace(/\s/g, '').toUpperCase()}`,
                            'popup',
                            null
                          );
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('RelationshipID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('RelationshipID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollRelationshipIdPolicy)} req`
                            : this.getCustomClassName(disabledEditable || !enrollRelationshipIdPolicy)
                        }
                        id="firm-relationship"
                        filterOption={this.filterOption}
                        value={{ label: accountData.RelationshipID, value: accountData.RelationshipID }}
                        onChange={(e) => {
                          this.updateAccountData('RelationshipID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['RelationshipID'].choices}
                        isDisabled={disabledEditable || !enrollRelationshipIdPolicy}
                        isClearable={!(disabledEditable || !enrollRelationshipIdPolicy)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollRelationshipIdPolicy}
                        id="firm-relationship"
                        className={showRequired && nullFields.indexOf('RelationshipID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.RelationshipID ? accountData.RelationshipID : ''}
                        onChange={(e) => {
                          this.updateAccountData('RelationshipID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* ADDITIONAL ACCOUNT FIELDS - OTHER     */}
                {/* TeamID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-teamid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollTeamIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollTeamIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollTeamIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollTeamIDShow && (
                  <div className="account-info-item ga-3-bottom">
                    <label className={disabledEditable || !enrollTeamIDEdit ? 'disabled-label' : 'label'} for="teamid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_TeamID', viewOnlyMode)}
                      {enrollTeamIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-teamid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollTeamIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('TeamID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_TeamID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollTeamIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollTeamIDEdit)
                        }
                        id="teamid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_TeamID, value: accountData.Account_TeamID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_TeamID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['TeamID'].choices}
                        isDisabled={disabledEditable || !enrollTeamIDEdit}
                        isClearable={!(disabledEditable || !enrollTeamIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollTeamIDEdit}
                        id="teamid"
                        className={showRequired && nullFields.indexOf('Account_TeamID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_TeamID ? accountData.Account_TeamID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_TeamID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* PartnerID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-partnerid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollPartnerIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollPartnerIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollPartnerIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {/* New Account Fields */}
                {enrollPartnerIDShow && (
                  <div className="account-info-item ga-4-bottom">
                    <label className={disabledEditable || !enrollPartnerIDEdit ? 'disabled-label' : 'label'} for="partnerid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_PartnerID', viewOnlyMode)}
                      {enrollPartnerIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-partnerid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollPartnerIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('PartnerID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_PartnerID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollPartnerIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollPartnerIDEdit)
                        }
                        id="partnerid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_PartnerID, value: accountData.Account_PartnerID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_PartnerID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['PartnerID'].choices}
                        isDisabled={disabledEditable || !enrollPartnerIDEdit}
                        isClearable={!(disabledEditable || !enrollPartnerIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollPartnerIDEdit}
                        id="partnerid"
                        className={showRequired && nullFields.indexOf('Account_PartnerID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_PartnerID ? accountData.Account_PartnerID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_PartnerID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* ClientID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-clientid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollClientIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollClientIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollClientIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollClientIDShow && (
                  <div className="account-info-item ga-5-bottom">
                    <label className={disabledEditable || !enrollClientIDEdit ? 'disabled-label' : 'label'} for="clientid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_ClientID', viewOnlyMode)}
                      {enrollClientIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-clientid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollClientIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('ClientID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_ClientID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollClientIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollClientIDEdit)
                        }
                        id="clientid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_ClientID, value: accountData.Account_ClientID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_ClientID', e.value);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['ClientID'].choices}
                        isDisabled={disabledEditable || !enrollClientIDEdit}
                        isClearable={!(disabledEditable || !enrollClientIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollClientIDEdit}
                        id="clientid"
                        className={showRequired && nullFields.indexOf('Account_ClientID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_ClientID ? accountData.Account_ClientID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_ClientID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}

                {/* InvestmentID */}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-investmentid" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollInvestmentIDLabel.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollInvestmentIDLabel.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollInvestmentIDLabel} Field</span>
                  )}
                </ReactTooltip>
                {enrollInvestmentIDShow && (
                  <div className="account-info-item ga-6-bottom">
                    <label className={disabledEditable || !enrollInvestmentIDEdit ? 'disabled-label' : 'label'} for="investmentid">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_InvestmentID', viewOnlyMode)}
                      {enrollInvestmentIDLabel}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-investmentid"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollInvestmentIDLabel.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('InvestmentID') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_InvestmentID') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollInvestmentIDEdit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollInvestmentIDEdit)
                        }
                        id="investmentid"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_InvestmentID, value: accountData.Account_InvestmentID }}
                        onChange={(e) => {
                          this.updateAccountData('Account_InvestmentID', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['InvestmentID'].choices}
                        isDisabled={disabledEditable || !enrollInvestmentIDEdit}
                        isClearable={!(disabledEditable || !enrollInvestmentIDEdit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollInvestmentIDEdit}
                        id="investmentid"
                        className={showRequired && nullFields.indexOf('Account_InvestmentID') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_InvestmentID ? accountData.Account_InvestmentID : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_InvestmentID', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}

                {/* Account_Custom1 AccountCustom1*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom1" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom1Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom1Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom1Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom1Show && (
                  <div className={`account-info-item ${enrollRelationshipIDSplit ? 'ga-4-bottom' : 'ga-7-bottom'}`}>
                    <label className={disabledEditable || !enrollAccountCustom1Edit ? 'disabled-label' : 'label'} for="account-custom1">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom1', viewOnlyMode)}
                      {enrollAccountCustom1Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom1"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom1Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom1') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom1') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom1Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom1Edit)
                        }
                        id="account-custom1"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom1, value: accountData.Account_Custom1 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom1', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom1'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom1Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom1Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom1Edit}
                        id="account-custom1"
                        className={showRequired && nullFields.indexOf('Account_Custom1') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom1 ? accountData.Account_Custom1 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom1', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom2 AccountCustom2*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom2" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom2Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom2Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom2Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom2Show && (
                  <div className="account-info-item ga-8-bottom">
                    <label className={disabledEditable || !enrollAccountCustom2Edit ? 'disabled-label' : 'label'} for="account-custom2">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom2', viewOnlyMode)}
                      {enrollAccountCustom2Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom2"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom2Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom2') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom2') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom2Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom2Edit)
                        }
                        id="account-custom2"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom2, value: accountData.Account_Custom2 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom2', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom2'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom2Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom2Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom2Edit}
                        id="account-custom2"
                        className={showRequired && nullFields.indexOf('Account_Custom2') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom2 ? accountData.Account_Custom2 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom2', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom3 AccountCustom3*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom3" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom3Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom3Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom3Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom3Show && (
                  <div className="account-info-item ga-9-bottom">
                    <label className={disabledEditable || !enrollAccountCustom3Edit ? 'disabled-label' : 'label'} for="account-custom3">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom3', viewOnlyMode)}
                      {enrollAccountCustom3Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom3"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom3Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom3') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom3') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom3Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom3Edit)
                        }
                        id="account-custom3"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom3, value: accountData.Account_Custom3 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom3', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom3'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom3Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom3Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom3Edit}
                        id="account-custom3"
                        className={showRequired && nullFields.indexOf('Account_Custom3') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom3 ? accountData.Account_Custom3 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom3', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
                {/* Account_Custom4 AccountCustom4*/}
                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="view-info-account-custom4" place="top" type="info" effect="solid" className="tooltip_custom">
                  {helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom4Label.replace(/\s/g, '').toUpperCase()}`] ? (
                    helpInformation[`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom4Label.replace(/\s/g, '').toUpperCase()}`]
                  ) : (
                    <span>{enrollAccountCustom4Label} Field</span>
                  )}
                </ReactTooltip>
                {enrollAccountCustom4Show && (
                  <div className="account-info-item ga-9-bottom">
                    <label className={disabledEditable || !enrollAccountCustom4Edit ? 'disabled-label' : 'label'} for="account-custom4">
                      {getRequiredAsterisk(false, allRequiredFields, 'Account_Custom4', viewOnlyMode)}
                      {enrollAccountCustom4Label}
                      <i
                        className="fa fas fa-question-circle"
                        data-tip
                        data-for="view-info-account-custom4"
                        onClick={() => {
                          linker.UniversalHelp(`TOOLTIP.ENROLLMENT.ENROLL.OTHER.${enrollAccountCustom4Label.replace(/\s/g, '').toUpperCase()}`, 'popup', null);
                        }}
                      ></i>
                    </label>
                    {fieldArrayLists.includes('Account_Custom4') ? (
                      <Select
                        className={
                          showRequired && nullFields.indexOf('Account_Custom4') !== -1
                            ? `${this.getCustomClassName(disabledEditable || !enrollAccountCustom4Edit)} req`
                            : this.getCustomClassName(disabledEditable || !enrollAccountCustom4Edit)
                        }
                        id="account-custom4"
                        filterOption={this.filterOption}
                        value={{ label: accountData.Account_Custom4, value: accountData.Account_Custom4 }}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom4', e ? e.value : null);
                        }}
                        onClick={() => {
                          this.setState({ showRequired: false });
                        }}
                        options={fieldArray && fieldArray['Account_Custom4'].choices}
                        isDisabled={disabledEditable || !enrollAccountCustom4Edit}
                        isClearable={!(disabledEditable || !enrollAccountCustom4Edit)}
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        disabled={disabledEditable || !enrollAccountCustom4Edit}
                        id="account-custom4"
                        className={showRequired && nullFields.indexOf('Account_Custom4') !== -1 ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={accountData.Account_Custom4 ? accountData.Account_Custom4 : ''}
                        onChange={(e) => {
                          this.updateAccountData('Account_Custom4', e.target.value);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <Modal isOpen={this.state.searchCustodianModalOpen} style={styleOverridesWide}>
          <Draggable handle=".handle">
            <div className="">
              <SearchCustodian
                user={this.props.user}
                services={this.props.services}
                accountData={accountData}
                updateAccountData={this.updateAccountData}
                closeModal={this.closeModal}
                setCurrentScreen={this.props.setCurrentScreen}
                setValidCustodian={this.props.setValidCustodian}
                managerType={accountData.ManagerType}
                searchCustodianRelated={searchCustodianRelated}
                setRequestFor={this.props.setRequestFor}
                setRequestModalOpen={this.setRequestModalOpen}
                setForceUpdate={this.props.setForceUpdate}
                crmData={this.props.crmData}
                handleFundSelect={this.handleFundSelect}
                fundData={this.state.fundData}
              />
            </div>
          </Draggable>
        </Modal>

        <Modal isOpen={this.state.searchTaxEntityModalOpen} style={styleOverridesWide}>
          <Draggable handle=".handle">
            <div className="">
              <SearchTaxEntity
                user={this.props.user}
                services={this.props.services}
                accountData={accountData}
                updateAccountData={this.updateAccountData}
                closeModal={this.closeModal}
                setCurrentScreen={this.props.setCurrentScreen}
                setValidCustodian={this.props.setValidCustodian}
                managerType={accountData.ManagerType}
                setRequestFor={this.props.setRequestFor}
                setRequestModalOpen={this.setRequestModalOpen}
                setForceUpdate={this.props.setForceUpdate}
                crmData={this.props.crmData}
              />
            </div>
          </Draggable>
        </Modal>

        <Modal isOpen={this.state.searchSignerModalOpen} style={styleOverrides}>
          <Draggable handle=".handle">
            <div className="">
              <SearchContact
                user={this.props.user}
                services={this.props.services}
                accountData={accountData}
                updateAccountData={this.props.updateAccountData}
                closeModal={this.closeModal}
                setCurrentScreen={this.props.setCurrentScreen}
                signerIndex={this.props.signerIndex}
                setValidSigner={this.setValidSigner}
                setValidSigners={this.props.setValidSigners}
                setForceUpdate={this.props.setForceUpdate}
                capacities={this.state.capacities}
                selectedSigners={accountData.ACCOUNT_SIGNER_LINK}
                validSigners={this.props.validSigners}
                isNewSigner={this.state.isNewSigner}
                crmData={this.props.crmData}
              />
            </div>
          </Draggable>
        </Modal>

        {this.state.hierarchyOption && (
          <Modal isOpen={this.state.addHierarchyOptionModalOpen} style={hierarchyModalStyle}>
            <Draggable handle=".handle">
              <div className="">
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">New {this.state.hierarchyOption}</div>
                  </div>
                  <div
                    className="sidemodal_addnew_x"
                    onClick={() => {
                      this.setState({ addHierarchyOptionModalOpen: false, hierarchyItem: '' });
                    }}
                  >
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <div className="hierarchy-item-modal-container">
                  <div className="header-accounts">
                    <div className="account-title"></div>
                    <div className="button-div">
                      <div
                        id="hierarchy-new-item-save"
                        className="generic-button-primary"
                        onClick={() => {
                          this.onHierarchyItemSave();
                        }}
                      >
                        Save
                      </div>
                    </div>
                  </div>
                  <div className="body-hierarchy">
                    <input
                      id="hierarchy-item-input"
                      className="hierarchy-item-value"
                      onChange={(e) => {
                        this.setState({ hierarchyItem: e.target.value });
                      }}
                      value={this.state.hierarchyItem}
                    />
                  </div>
                </div>
              </div>
            </Draggable>
          </Modal>
        )}

        {this.state.previewModalOpen && (
          <Modal isOpen={this.state.previewModalOpen} style={ie || edge ? smallModalStyle : styleOverridesWideLOA}>
            <Draggable handle=".handle">
              <div className="">
                <div>
                  <div className="fullmodal handle">
                    <div className="fullmodal_title">
                      <div className="fullmodal_title_add">Preview LOA</div>
                    </div>
                    <div
                      id="modal-x-button"
                      className="sidemodal_addnew_x"
                      onClick={() => {
                        this.setState({ previewModalOpen: false });
                      }}
                    >
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>
                  {this.renderPreviewLOA()}
                </div>
              </div>
            </Draggable>
          </Modal>
        )}

        {this.state.viewExistingModalOpen && (
          <Modal isOpen={this.state.viewExistingModalOpen} style={modalStyle}>
            <div>
              <div className="fullmodal handle">
                <div className="fullmodal_title">
                  <div className="fullmodal_title_add">View LOA</div>
                </div>

                <div id="modal-x-button" className="sidemodal_addnew_x" onClick={this.closeModal}>
                  <FontAwesome name="xbutton" className="fa-times" />
                </div>
              </div>
              <div className="modalBody">{this.renderExistingLOA()}</div>
            </div>
          </Modal>
        )}

        {this.state.requestCustodianModalOpen && (
          <Modal isOpen={this.state.requestCustodianModalOpen} style={styleOverrides}>
            <Draggable handle=".handle">
              <div>
                <div>
                  <div className="fullmodal handle">
                    <div className="fullmodal_title">
                      <div className="fullmodal_title_add">{requestTitle}</div>
                    </div>
                    <div
                      className="sidemodal_addnew_x"
                      onClick={() => {
                        this.setState({ requestCustodianModalOpen: false });
                      }}
                    >
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>
                </div>
                <RequestCustodian
                  user={this.props.user}
                  services={this.props.services}
                  closeModal={() => {
                    this.setState({ requestCustodianModalOpen: false });
                  }}
                  accountData={this.props.accountData}
                  setCurrentScreen={this.props.setCurrentScreen}
                  updateAccountData={this.props.updateAccountData}
                  requestFor={this.props.requestFor}
                  setValidCustodian={this.props.setValidCustodian}
                  setForceUpdate={this.props.setForceUpdate}
                />
              </div>
            </Draggable>
          </Modal>
        )}

        {this.state.contactModalOpen && (
          <Modal isOpen={this.state.contactModalOpen} style={styleOverrides}>
            <Draggable handle=".handle">
              <div>
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">{this.state.contactModalType === 'add' ? 'Add ' + this.state.contactModalLabel : 'Edit ' + this.state.contactModalLabel}</div>
                  </div>

                  <div className="sidemodal_addnew_x" onClick={this.closeContactModal}>
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <div className="search-modal-container">
                  <Contact
                    user={this.props.user}
                    services={this.props.services}
                    contactFor={'custodian'}
                    custodianData={this.props.accountData ? this.props.accountData.CustodianData : null}
                    type={'add'}
                    closeDetailModal={this.closeContactModal}
                    custodianId={this.props.accountData.CustodianID}
                    updateAccountData={this.updateAccountData}
                    setCurrentScreen={this.props.setCurrentScreen}
                    standalone={false}
                    accountData={this.props.accountData}
                    setForceUpdate={this.props.setForceUpdate}
                    isModal={true}
                    closeViewModal={this.closeContactModal}
                    crmData={this.props.crmData}
                    setCreateCustodianName={this.state.setCreateCustodianName}
                    helpInformation={this.state.helpInformation}
                    contactModalLabel={this.state.contactModalLabel}
                  />
                </div>
              </div>
            </Draggable>
          </Modal>
        )}
        {this.state.multipleAccountsModalOpen && (
          <AccountMultiple
            onMultipleAccountsSaveClick={this.onMultipleAccountsSaveClick}
            onMultipleAccountsAltSaveClick={this.onMultipleAccountsAltSaveClick}
            multipleAccountsData={this.state.multipleAccountsData}
            accountData={this.props.accountData}
            funds={this.state.funds}
            closeModal={this.closeModal}
          />
        )}
        {/* {this.state.multipleAccountsModalOpen && (
          <Modal isOpen={this.state.multipleAccountsModalOpen} style={multipleAccountsModalStyle}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                <div>
                  <div className="fullmodal handle">
                    <div className="fullmodal_title">
                      <div className="fullmodal_title_add">Add Multiple Accounts</div>
                    </div>

                    <div
                      className="sidemodal_addnew_x"
                      onClick={() => {
                        this.setState({
                          multipleAccountsModalOpen: false,
                          multipleAccountsString: '',
                          multipleAccountsError: false,
                          erroredAccountNames: [],
                        });
                      }}
                    >
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>
                  <div className="search-modal-container">
                    <div className="multiple-accounts-header">
                      <div className="button-div">
                        <div
                          id="save-accounts-button"
                          className={
                            !this.state.multipleAccountsError && this.state.multipleAccountsString.length
                              ? 'generic-button-primary generic-button-margin-right'
                              : 'generic-button-primary generic-button-margin-right generic-button-disabled'
                          }
                          onClick={() => {
                            if (!this.state.multipleAccountsError && this.state.multipleAccountsString.length) {
                              this.onMultipleAccountsSaveClick();
                            }
                          }}
                        >
                          Save
                        </div>
                      </div>
                    </div>

                    <div className="multiple-accounts-body">
                      <div className="multiple-accounts-text">
                        {this.state.multipleAccountsError ? (
                          <div>
                            The following account numbers did not pass the validation for this data source or were not unique. Please correct or remove from list.
                            <ul className="multiple-accounts-errored-accounts">
                              {this.state.erroredAccountNames.map((account) => {
                                return <li>{account}</li>;
                              })}
                            </ul>
                          </div>
                        ) : (
                          'Cut and paste a list of accounts associated with this Data Source and Signers.   It is important that the accounts are for the same account owners or the LOA will be rejected.'
                        )}
                      </div>
                      <div className="multiple-accounts-input-wrapper">
                        <textarea
                          id="multiple-accounts-textarea"
                          className="multiple-accounts-textarea"
                          value={this.state.multipleAccountsString}
                          onChange={(e) => {
                            this.handleMultipleAccountInput(e);
                          }}
                        />
                        <div>Note: Each individual account should be separated by a space.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Draggable>
          </Modal>
        )} */}
      </div>
    );
  }
}

export default withRouter(Account);
