import React, { Component } from 'react';
import { withRouter } from 'react-router';
import * as TaxEntityModels from './TaxEntityModels';
import { showRequired, hasRequired, getRequiredAsterisk } from '../Utils/RequiredFields';
import ServerSideSelect from '../Utils/ServerSideSelect';
import 'react-confirm-alert/src/react-confirm-alert.css';
const Spinner = require('react-spinkit');

function getINITIAL_STATE(that, props) {
  let policies = that.props.user.firmPolicies;
  return {
    countries: [],
    states: [],
    nullFields: [],
    loadSpinner: false,
    showAdditionalInformation: true,
    advisorName: that.props.rowData && that.props.rowData.advisorName ? that.props.rowData.advisorName : '',
    superhouseName: that.props.rowData && that.props.rowData.superhouseName,
    householdName: that.props.rowData && that.props.rowData.householdName,
    taxEntityName: that.props.rowData && that.props.rowData.taxEntityName,
    AdvisorFetchProperties: {
      BaseUrl: `${that.props.services.Enrollment.URL}/EnrollmentCoreHierarchy`,
      Mode: 'cors',
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': that.props.services.Enrollment.subscription_key,
        'x-universal-firm': that.props.user.FirmId,
      },
    },
    SuperhouseFetchProperties: {
      BaseUrl: `${that.props.services.Enrollment.URL}/SUPER_HOUSE_ETY`,
      Mode: 'cors',
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': that.props.services.Enrollment.subscription_key,
        'x-universal-firm': that.props.user.FirmId,
      },
    },
    HouseholdFetchProperties: {
      BaseUrl: `${that.props.services.Enrollment.URL}/HOUSE_ETY`,
      Mode: 'cors',
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': that.props.services.Enrollment.subscription_key,
        'x-universal-firm': that.props.user.FirmId,
      },
    },
    TaxEntityFetchProperties: {
      BaseUrl: `${that.props.services.Enrollment.URL}/TAX_ETY`,
      Mode: 'cors',
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': that.props.services.Enrollment.subscription_key,
        'x-universal-firm': that.props.user.FirmId,
      },
    },
  };
}

class TaxEntity extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this, props);
  }

  async componentDidMount() {
    this.setState({
      type: this.props.type,
      standalone: this.props.standalone || this.props.match.params.id,
    });
    if (this.props.type === 'view' || this.props.type === 'edit') {
      this.getRecordDetail(this.props.modalID, this.props.modalTitle);
    } else {
      this.setState({ type: 'add', advisorName: '', superhouseName: '', householdName: '', taxEntityName: '' });
    }
  }

  async getRecordDetail(modalID, modalTitle) {
    let { user, services } = this.props;
    let response = [];
    let endPoint = 'TAX_ETY';
    switch (modalTitle) {
      case 'Superhouse':
        endPoint = 'SUPER_HOUSE_ETY';
        response = await TaxEntityModels.getRecordDetail({
          user,
          services,
          modalID,
          endPoint,
        });
        break;
      case 'Household':
        endPoint = 'HOUSE_ETY';
        response = await TaxEntityModels.getRecordDetail({
          user,
          services,
          modalID,
          endPoint,
        });
        break;
      //Tax Entity TAX_ETY
      default:
        response = await TaxEntityModels.getRecordDetail({
          user,
          services,
          modalID,
          endPoint,
        });
        break;
    }

    if (!response.error) {
      let body = response.body ? response.body : null;
      if (body) {
        let state = Object.assign({}, this.state);
        body = body.value[0];

        state.recordID = body.IdNmb;
        state.recordName = body.NameTxt;

        switch (modalTitle) {
          case 'Superhouse':
            break;
          case 'Household':
            break;
          //Tax Entity TAX_ETY
          default:
            break;
        }

        this.setState(state, () => {
          this.setState({ loadSpinner: false });
        });
      }
    }
  }

  onSaveClick = async () => {
    let { modalTitle, modalID, user, services } = this.props;
    let requiredFields = [];
    let endPoint = '';
    let body = {};
    // advisorName: '',
    // superhouseName: that.props.rowData && that.props.rowData.superhouseName,
    // householdName: that.props.rowData && that.props.rowData.householdName,
    // taxEntityName: that.props.rowData && that.props.rowData.taxEntityName,
    switch (modalTitle) {
      case 'Superhouse':
        requiredFields = ['advisorName', 'superhouseName'];
        body = {
          AdvIdNmb: this.state.advisorID,
          NameTxt: this.state.superhouseName,
        };
        endPoint = 'SUPER_HOUSE_ETY';
        break;
      case 'Household':
        requiredFields = ['superhouseName', 'householdName'];
        body = {
          SuperHouseIdNmb: this.state.superhouseID,
          NameTxt: this.state.householdName,
        };
        endPoint = 'HOUSE_ETY';
        break;
      //Tax Entity TAX_ETY
      default:
        requiredFields = ['householdName', 'taxEntityName'];
        body = {
          HouseIdNmb: this.state.householdID,
          NameTxt: this.state.taxEntityName,
        };
        endPoint = 'TAX_ETY';
        break;
    }

    if (hasRequired(this.state, requiredFields, true)) {
      this.setState({ loadSpinner: true });
      //Do Save

      let response = await TaxEntityModels.createRecord({
        user,
        services,
        endPoint,
        body,
      });

      if (!response.error) {
        if (response.body) {
          modalID = response.body.IdNmb;
          this.props.updateModalID(modalID);
        }
      }

      this.setState({ loadSpinner: false, modalID });
      if (!response.error) {
        this.setState({ type: 'view', modalType: 'View' });
        if (this.props.refreshGrid) {
          this.props.refreshGrid();
        }
        if (this.props.setForceUpdate) {
          this.props.setForceUpdate(true);
        }
      } else {
      }
    } else {
      this.setState({ checkRequired: true });
    }
  };
  async onPatchClick() {
    let { user, services, modalTitle, modalID } = this.props;

    let endPoint = '';
    let requiredFields = [];
    // advisorName: '',
    // superhouseName: that.props.rowData && that.props.rowData.superhouseName,
    // householdName: that.props.rowData && that.props.rowData.householdName,
    // taxEntityName: that.props.rowData && that.props.rowData.taxEntityName,
    let body = {};
    switch (modalTitle) {
      case 'Superhouse':
        requiredFields = ['advisorName', 'superhouseName'];
        body = {
          NameTxt: this.state.superhouseName,
        };
        endPoint = 'SUPER_HOUSE_ETY';
        break;
      case 'Household':
        requiredFields = ['superhouseName', 'householdName'];
        body = {
          NameTxt: this.state.householdName,
        };
        endPoint = 'HOUSE_ETY';
        break;
      //Tax Entity TAX_ETY
      default:
        requiredFields = ['householdName', 'taxEntityName'];
        body = {
          NameTxt: this.state.taxEntityName,
        };
        endPoint = 'TAX_ETY';
        break;
    }

    if (hasRequired(this.state, requiredFields, true)) {
      this.setState({ loadSpinner: true });

      let response = await TaxEntityModels.updateRecord({
        user,
        services,
        modalID: modalID,
        endPoint,
        body,
      });

      this.setState({ loadSpinner: false });
      if (!response.error) {
        this.setState({ type: 'view' });
        if (this.props.refreshGrid) {
          this.props.refreshGrid();
        }
      } else {
      }
    } else {
      this.setState({ checkRequired: true });
    }
  }

  onCancelClick = () => {
    if (this.props.closeViewModal) {
      this.props.closeViewModal();
    } else {
      this.props.setCurrentScreen('account');
    }
  };

  setEdit = () => {
    this.setState({ type: 'edit' });
    if (this.props.setPageTitle) {
      this.props.setPageTitle('Edit');
    }
  };

  getTooltip = (helpId, defaultText = '') => {
    const { helpInformation } = this.props;
    if (helpInformation[helpId]) {
      return helpInformation[helpId];
    } else {
      return defaultText;
    }
  };

  render() {
    let { checkRequired, superhouseName, householdName, advisorName, type } = this.state;
    let { modalTitle, type: modalType } = this.props;
    let title = '';
    let disabled = this.state.loadSpinner || this.state.type === 'view';

    let superhouseDisabled = modalTitle === 'Household' || modalTitle === 'Tax Entity';
    let householdDisabled = modalTitle === 'Tax Entity';

    return (
      <div>
        {this.state.loadSpinner ? (
          <center style={{ height: '500px' }}>
            <Spinner id="view-spinner" className="signer-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
          </center>
        ) : (
          <div className="contact-wrapper">
            <div className="header-accounts">
              <div className="account-title">{this.props.isModal ? null : title}</div>

              {this.state.type === 'add' && (
                <div className="button-div">
                  <div
                    className="generic-button-secondary"
                    id={`taxentity-cancel-button`}
                    onClick={() => {
                      this.props.closeDetailModal ? this.props.closeDetailModal() : window.history.back();
                    }}
                  >
                    Cancel
                  </div>

                  <div
                    className="generic-button-primary"
                    id={`taxentity-save-button`}
                    onClick={() => {
                      this.onSaveClick();
                    }}
                  >
                    Save
                  </div>
                </div>
              )}
              {this.state.type === 'view' && (
                <div className="button-div">
                  <div
                    className="generic-button-secondary"
                    id={`taxentity-cancel-button`}
                    onClick={() => {
                      this.props.closeDetailModal ? this.props.closeDetailModal() : window.history.back();
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    className="generic-button-primary"
                    id={`taxentity-edit-button`}
                    onClick={() => {
                      this.setEdit();
                    }}
                  >
                    Edit
                  </div>
                </div>
              )}
              {this.state.type === 'edit' && (
                <div className="button-div">
                  <div
                    className="generic-button-secondary"
                    id={`taxentity-cancel-button`}
                    onClick={() => {
                      this.props.closeDetailModal ? this.props.closeDetailModal() : window.history.back();
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    className="generic-button-primary"
                    id={`taxentity-save-button`}
                    onClick={() => {
                      this.onPatchClick();
                    }}
                  >
                    Save
                  </div>
                </div>
              )}
            </div>
            {/* Legend for required fields  */}
            {!disabled && (
              <div className="required-field-message-wrapper">
                {this.props.type === 'add' && (
                  <div className="contact-message-wrapper">
                    <div className="contact-message-icon" />
                    <div className="contact-message-text"></div>
                  </div>
                )}
                <div className="required-field-message required-field-message-contact no-wrap" id="required-field-message">
                  {getRequiredAsterisk(true, '', '', disabled)} Indicates required field
                </div>
              </div>
            )}

            <div className={'body-contacts'}>
              <div className="one-col-grid-area">
                {modalTitle === 'Superhouse' && (
                  <div className="account-info-item tax-entity-info-item ga-1-1">
                    <label className="label" for="household">
                      {modalTitle === 'Superhouse' && modalType === 'add' && type !== 'edit' && getRequiredAsterisk(true, '', '', disabled)}
                      Advisor
                    </label>
                    {modalType === 'add' && type !== 'edit' ? (
                      <ServerSideSelect
                        disabled={disabled}
                        id="advisor"
                        labelField="AdvisorName"
                        apply={'groupby((AdvisorName, AdvisorIdNmb))'}
                        additionalFields={''}
                        wrapperClassName={`search-input`}
                        fetchProperties={this.state.AdvisorFetchProperties}
                        // selectClassName={`search-input`}
                        selectClassName={showRequired(checkRequired, this.state, 'advisorName') ? 'account-select req' : 'account-select'}
                        placeholder={advisorName}
                        isClearable={true}
                        onChange={(e) => {
                          if (e) {
                            this.setState({ advisorName: e.value.AdvisorName, advisorID: e.value.AdvisorIdNmb });
                          }
                        }}
                        ref={(instance) => {
                          this.child = instance;
                        }}
                        value={advisorName}
                      />
                    ) : (
                      <input
                        disabled={true}
                        id={`advisor`}
                        className={showRequired(checkRequired, this.state, 'advisorName') ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={this.state.advisorName}
                        onChange={(e) => {
                          this.setState({ advisorName: e.target.value });
                        }}
                      />
                    )}
                  </div>
                )}
                {(modalTitle === 'Superhouse' || modalTitle === 'Household') && (
                  <div className="account-info-item tax-entity-info-item ga-1-2">
                    <label className="label" for="household">
                      {(modalTitle === 'Superhouse' || (modalTitle === 'Household' && modalType === 'add' && type !== 'edit')) && getRequiredAsterisk(true, '', '', disabled)}
                      Superhouse
                    </label>

                    {modalTitle === 'Household' && modalType === 'add' && type !== 'edit' ? (
                      <ServerSideSelect
                        disabled={disabled}
                        id="superhouse"
                        labelField="NameTxt"
                        additionalFields={'and NameTxt ne null'}
                        wrapperClassName={`search-input`}
                        fetchProperties={this.state.SuperhouseFetchProperties}
                        // selectClassName={`search-input`}
                        selectClassName={showRequired(checkRequired, this.state, 'superhouseName') ? 'account-select req' : 'account-select'}
                        placeholder={superhouseName}
                        isClearable={true}
                        onChange={(e) => {
                          this.setState({ superhouseName: e.value.NameTxt, superhouseID: e.value.IdNmb });
                        }}
                        ref={(instance) => {
                          this.child = instance;
                        }}
                        value={superhouseName}
                      />
                    ) : (
                      <input
                        disabled={disabled || superhouseDisabled}
                        id={`superhouse`}
                        className={showRequired(checkRequired, this.state, 'superhouseName') ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={this.state.superhouseName}
                        onChange={(e) => {
                          this.setState({ superhouseName: e.target.value });
                        }}
                      />
                    )}
                  </div>
                )}
                {(modalTitle === 'Household' || modalTitle === 'Tax Entity') && (
                  <div className="account-info-item tax-entity-info-item ga-1-3">
                    <label className="label" for="household">
                      {(modalTitle === 'Household' || (modalTitle === 'Tax Entity' && modalType === 'add' && type !== 'edit')) && getRequiredAsterisk(true, '', '', disabled)}Household
                    </label>

                    {modalTitle === 'Tax Entity' && modalType === 'add' && type !== 'edit' ? (
                      <ServerSideSelect
                        disabled={disabled}
                        id="household"
                        labelField="NameTxt"
                        additionalFields={'and NameTxt ne null'}
                        wrapperClassName={`search-input`}
                        fetchProperties={this.state.HouseholdFetchProperties}
                        // selectClassName={`search-input`}
                        selectClassName={showRequired(checkRequired, this.state, 'householdName') ? 'account-select req' : 'account-select'}
                        placeholder={householdName}
                        isClearable={true}
                        onChange={(e) => {
                          this.setState({ householdName: e.value.NameTxt, householdID: e.value.IdNmb });
                        }}
                        ref={(instance) => {
                          this.child = instance;
                        }}
                        value={householdName}
                      />
                    ) : (
                      <input
                        disabled={disabled || householdDisabled}
                        id={`household`}
                        className={showRequired(checkRequired, this.state, 'householdName') ? 'account-input req' : 'account-input'}
                        placeholder=""
                        value={this.state.householdName}
                        onChange={(e) => {
                          this.setState({ householdName: e.target.value });
                        }}
                      />
                    )}
                  </div>
                )}
                {modalTitle === 'Tax Entity' && (
                  <div className="account-info-item tax-entity-info-item ga-1-4">
                    <label className="label" for="firstname">
                      {getRequiredAsterisk(true, '', '', disabled)}Tax Entity
                    </label>
                    <input
                      disabled={disabled}
                      id={`taxentity`}
                      className={showRequired(checkRequired, this.state, 'taxEntityName') ? 'account-input req' : 'account-input'}
                      placeholder=""
                      value={this.state.taxEntityName}
                      onChange={(e) => {
                        this.setState({ taxEntityName: e.target.value });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(TaxEntity);
