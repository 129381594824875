import React, { Component } from 'react';
import Draggable from 'react-draggable';
import Modal from 'react-modal';
import ReactTable from 'react-table';
import TaxEntity from './TaxEntity';
import * as DropdownModels from './DropdownModels';
import * as globals from '../../Globals/Variables';
import { checkPolicy } from '../Utils/HelperFunctions';

var debounce = require('lodash/debounce');
const FontAwesome = require('react-fontawesome');
const modalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1111',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    top: '8%',
    left: '12%',
    height: '80%',
    width: '75%',
    overflow: 'hidden',
  },
};
const styleOverrides = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    bottom: 'unset',
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    background: 'none',
    width: '60%',
    height: '75%',
    top: '2%',
    left: '20%',
  },
};
function getINITIAL_STATE(that) {
  return {
    searchText: '',
    manageModalOpen: false,
    selectedContactId: null,
    selectedContactData: null,
    modalOperation: 'View',
    data: [],
    pagesize: 10,
    page: 0,
    rowCount: 0,
    loading: true,
    orderby: '&$orderby=HierarchyPath',
    rowData: [],
    columns: [
      {
        Header: () => {
          return <div className="select-div">Select</div>;
        },
        accessor: 'Checkboxes',
        Cell: that.checkboxCell,
        width: 75,
        sortable: false,
      },
      {
        accessor: 'superhouseName',
        Header: 'Superhouse',
        width: 250,
        Cell: that.textCell,
      },
      {
        accessor: 'householdName',
        Header: 'Household',
        width: 250,
        Cell: that.textCell,
      },
      {
        accessor: 'taxEntityName',
        Header: 'Tax Entity',
        width: 250,
        Cell: that.textCell,
      },
    ],
  };
}
export default class SearchTaxEntity extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
  }

  componentDidMount() {
    let search = this.props.accountData.TaxEntityName ? this.props.accountData.TaxEntityName : '';
    this.setState({ searchText: search });
    this.searchTaxEntity(search);
  }

  searchTaxEntity = debounce(async (searchName) => {
    let { user, services } = this.props;
    let { orderby, expand, page, pagesize } = this.state;
    this.setState({ loading: true });
    if (searchName.indexOf('&') !== -1) {
      searchName = searchName.replace(/&/g, '%26');
    }
    if (searchName.indexOf("'") !== -1) {
      searchName = searchName.replace(/'/g, "''");
    }
    let filter = `&$filter=(contains(HierarchyPath,'${searchName}') and SuperHouseName ne null)`;

    let { values, count } = await DropdownModels.getTaxEntityGrid({
      user,
      services,
      filter,
      orderby,
      expand,
      page,
      pagesize,
    });

    let temp = [];
    this.setState({ data: temp });
    let prevSuperhouse = '';
    let prevHousehold = '';

    for (let x in values) {
      let taxEntity = values[x];
      // Possible Tree for future implementation
      // Superhouse
      // if (prevSuperhouse !== taxEntity.SuperHouseIdNmb) {
      //   temp = [
      //     ...temp,
      //     {
      //       level: 'Superhouse',
      //       advisorName: taxEntity.AdvisorName,
      //       advisorID: taxEntity.AdvisorID,
      //       superhouseName: taxEntity.SuperHouseName,
      //       superhouseID: taxEntity.SuperHouseIdNmb,
      //       householdName: '',
      //       householdID: '',
      //       taxEntityName: '',
      //       taxEntityID: '',
      //       path: '',
      //       data: '',
      //     },
      //   ];

      //   prevSuperhouse = taxEntity.SuperHouseIdNmb;
      // }
      // Household
      // if (prevHousehold !== taxEntity.HouseIdNmb && taxEntity.HouseName) {
      //   temp = [
      //     ...temp,
      //     {
      //       level: 'Household',
      //       advisorName: taxEntity.AdvisorName,
      //       advisorID: taxEntity.AdvisorID,
      //       superhouseName: taxEntity.SuperHouseName,
      //       superhouseID: taxEntity.SuperHouseIdNmb,
      //       householdName: taxEntity.HouseName,
      //       householdID: taxEntity.HouseIdNmb,
      //       taxEntityName: '',
      //       taxEntityID: '',
      //       path: '',
      //       data: '',
      //     },
      //   ];
      //   prevHousehold = taxEntity.HouseIdNmb;
      // }
      // if (taxEntity.TaxName) {
      // Tax Entity
      temp = [
        ...temp,
        {
          level: 'TaxEntity',
          advisorName: taxEntity.AdvisorName,
          advisorID: taxEntity.AdvisorID,
          superhouseName: taxEntity.SuperHouseName,
          superhouseID: taxEntity.SuperHouseIdNmb,
          householdName: taxEntity.HouseName,
          householdID: taxEntity.HouseIdNmb,
          taxEntityName: taxEntity.TaxName,
          taxEntityID: taxEntity.TaxIdNmb,
          path: taxEntity.HierarchyPath,
          data: taxEntity,
        },
      ];
      // }
    }
    this.setState({ data: temp, loading: false, rowCount: count });
  }, 1000);

  onSortedChange = (obj) => {
    let tempBaseOrderby = '';
    let desc = !obj[0].desc ? ' desc' : '';

    switch (obj[0].id) {
      case 'superhouseName':
        tempBaseOrderby = `&$orderby=SuperHouseName${desc}`;
        break;
      case 'householdName':
        tempBaseOrderby = `&$orderby=HouseName${desc}`;
        break;
      case 'taxEntityName':
        tempBaseOrderby = `&$orderby=TaxName${desc}`;
        break;
      default:
        tempBaseOrderby = this.state.orderby;
        break;
    }
    this.setState({ orderby: tempBaseOrderby }, () => {
      this.searchTaxEntity(this.state.searchText);
    });
  };

  textCell = (cellInfo) => {
    let { data } = this.state;

    return (
      <div
        title={`Manage ${this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}`}
        className={this.state.hoveredRow === cellInfo.index ? `clickable-row` : ''}
        onMouseEnter={() => {
          this.setState({ hoveredRow: cellInfo.index });
        }}
        onMouseLeave={() => {
          this.setState({ hoveredRow: null });
        }}
        onClick={() => {
          switch (cellInfo.column.id) {
            case 'superhouseName':
              // id,operation,title
              this.onManageClick(this.state.data[cellInfo.index].superhouseID, 'View', 'Superhouse', this.state.data[cellInfo.index]);
              break;
            case 'householdName':
              this.onManageClick(this.state.data[cellInfo.index].householdID, 'View', 'Household', this.state.data[cellInfo.index]);
              break;
            default:
              this.onManageClick(this.state.data[cellInfo.index].taxEntityID, 'View', 'Tax Entity', this.state.data[cellInfo.index]);
              break;
          }
        }}
      >
        <input
          id={cellInfo.index + cellInfo.column.id}
          className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          key={cellInfo.index + cellInfo.column.id}
          readOnly
          value={this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
        />
      </div>
    );
  };

  checkboxCell = (cellInfo) => {
    return this.state.data[cellInfo.index]['level'] && this.state.data[cellInfo.index]['level'] === 'TaxEntity' && this.state.data[cellInfo.index]['taxEntityName'] ? (
      <div className="grid-radio">
        <input
          id={cellInfo.index + cellInfo.column.id}
          type="radio"
          name="signer"
          className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          key={cellInfo.index + cellInfo.column.id}
          disabled={false}
          onChange={(e) => {
            this.handleTaxEntitySelect(this.state.data[cellInfo.index].taxEntityID, this.state.data[cellInfo.index].data);
          }}
        />
      </div>
    ) : null;
  };

  handleClearClick = () => {
    if (this.state.searchText !== '') {
      this.setState({ searchText: '' });
      this.searchTaxEntity('');
    }
  };

  handleTaxEntitySelect = (pk, val) => {
    this.props.updateAccountData('TaxEntityID', pk, 'TaxEntityName', val.HierarchyPath).then(() => {
      this.props.updateAccountData('RelationshipID', pk);
    });
    this.props.closeModal();
  };

  onSearchChange = (e) => {
    this.setState({ searchText: e.target.value, selectedCustodianId: null, selectedCustodianData: null, page: 0 }, () => {
      this.searchTaxEntity(this.state.searchText);
    });
  };

  onManageClick = (id, operation, title, rowData) => {
    this.setState({
      manageModalOpen: true,
      modalID: id,
      modalType: operation.toLowerCase(),
      modalOperation: operation,
      modalTitle: title,
      rowData,
    });
  };

  onNewClick = (title) => {
    this.setState({
      manageModalOpen: true,
      modalType: 'add',
      modalOperation: 'Add',
      modalTitle: title,
    });
  };
  onPageSizeChange = (size) => {
    this.setState({ pagesize: size, page: 0, loading: true }, () => {
      this.searchTaxEntity(this.state.searchText);
    });
  };

  refreshGrid = () => {
    this.searchTaxEntity(this.state.searchText);
  };

  onPageChange = (index) => {
    this.setState({ page: index, loading: true }, () => {
      this.searchTaxEntity(this.state.searchText);
    });
  };

  setModalOperation = (operation) => {
    this.setState({ modalOperation: operation });
  };

  closeModalDetail = () => {
    this.setState({
      manageModalOpen: false,
    });
  };

  isAnyModalOpen = () => {
    const { manageModalOpen } = this.state;
    return manageModalOpen;
  };

  handlePages = (pages) => {
    return pages === 0 ? 1 : pages; // DEV-876 Dont let pages = 0, default to 1 or issues with jumpto occurs
  };
  updateModalID = (id) => {
    this.setState({ modalID: id });
  };

  render() {
    const { modalType } = this.state;
    const disabled = this.isAnyModalOpen();
    return (
      <div>
        <div className="fullmodal handle">
          <div className="fullmodal_title">
            <div className="fullmodal_title_add">Search / Manage Entities</div>
          </div>

          <div className="sidemodal_addnew_x" onClick={this.props.closeModal}>
            <FontAwesome name="xbutton" className="fa-times" />
          </div>
        </div>
        <div className="search-modal-container">
          <div className="header-myenrollments">
            <div className="taxentity-modal-search">
              <input
                disabled={disabled}
                id="search-contact-input"
                className="custodian-search"
                placeholder="Search..."
                onChange={(e) => {
                  this.onSearchChange(e);
                }}
                value={this.state.searchText}
              />
              <FontAwesome
                name="xbutton"
                className="fa-times fa-times-clear clear-search-popup"
                onClick={() => {
                  this.handleClearClick();
                }}
              />
            </div>
            {checkPolicy(this.props.user.firmPolicies[globals.UI_ENROLLMENT_TAXENTITY_ADD]) && (
              <div className="generic-button-wrapper-te">
                <div
                  id="search-taxentity-new-taxentity"
                  className="generic-button-primary no-margin myenrollments-button"
                  onClick={() => {
                    this.onNewClick('Tax Entity');
                  }}
                >
                  Add Tax Entity
                </div>
                <div
                  id="search-taxentity-new-household"
                  className="generic-button-primary myenrollments-button"
                  onClick={() => {
                    this.onNewClick('Household');
                  }}
                >
                  Add Household
                </div>
                <div
                  id="search-taxentity-new-superhouse"
                  className="generic-button-primary myenrollments-button"
                  onClick={() => {
                    this.onNewClick('Superhouse');
                  }}
                >
                  Add Superhouse
                </div>
              </div>
            )}
          </div>
          <div className="table-container">
            <ReactTable
              data={this.state.data}
              columns={this.state.columns}
              page={this.state.page || 0}
              pages={this.handlePages(Math.ceil(this.state.rowCount / this.state.pagesize))}
              pageSize={this.state.pagesize}
              className="-striped -highlight grid"
              onSortedChange={this.onSortedChange} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
              loading={this.state.loading}
              manual={true}
              getTrProps={this.getTrProps}
            />
          </div>
        </div>
        {this.state.manageModalOpen && (
          <Modal isOpen={this.state.manageModalOpen} style={styleOverrides}>
            <Draggable handle=".handle">
              <div>
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">
                      {this.state.modalOperation} {this.state.modalTitle}
                    </div>
                  </div>

                  <div
                    className="sidemodal_addnew_x"
                    onClick={() => {
                      this.setState({ manageModalOpen: false });
                    }}
                  >
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <div className="search-modal-container">
                  <TaxEntity
                    user={this.props.user}
                    services={this.props.services}
                    type={modalType}
                    closeSearchModal={() => {
                      this.setState({ manageModalOpen: false });
                    }}
                    closeViewModal={this.props.closeModal}
                    closeDetailModal={this.closeModalDetail}
                    modalID={this.state.modalID}
                    modalTitle={this.state.modalTitle}
                    updateAccountData={this.props.updateAccountData}
                    updateModalID={this.updateModalID}
                    setCurrentScreen={this.props.setCurrentScreen}
                    accountData={this.props.accountData}
                    refreshGrid={this.refreshGrid}
                    setmodalOperation={this.setModalOperation}
                    isModal={true}
                    rowData={this.state.rowData}
                  />
                </div>
              </div>
            </Draggable>
          </Modal>
        )}
      </div>
    );
  }
}
