import React, { Component } from 'react';
import { getCookie } from '../Utils/HelperFunctions';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
import Draggable from 'react-draggable';

import { CircularProgress } from '@material-ui/core';
import ServerSideSelect from '../Utils/ServerSideSelectMaterial';
import { confirmAlert } from 'react-confirm-alert';
import * as AccountModels from './AccountModels';
import * as globals from '../../Globals/Variables';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const queryString = require('query-string');
const FontAwesome = require('react-fontawesome');
const multipleAccountsModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    height: '70%',

    width: '70%',
    top: 0,
  },
};

const newRow = {
  Status: '',
  OverrideMask: '',
  Action: '',
  AccountNumber: '',
  AccountName: '',
  AccountNumberValid: true,
  AccountNumberMessage: '',
  AccountNameValid: true,
  AccountNameMessage: '',
};

const header = (header, required, align = 'left') => {
  return (
    <div className={`select-div-${align}`}>
      {required && <i className="fa fas fa-asterisk required-field-header"></i>}
      {header}
    </div>
  );
};

function getINITIAL_STATE(that, props) {
  let title = 'Custodian';
  if (props.managerType === 'ALTERNATIVE' && !props.searchCustodianRelated) {
    title = 'Manager';
  }
  if (props.managerType === 'OTHER') {
    title = 'Institution';
  }
  return {
    multipleAccountsError: false,
    multipleAccountsString: '',
    data: [],
    key: new Date().getTime(),
    keyAuth: 'custodian-contacts',
    accountId: props.accountId,
    direction: 'ascending',
    loading: true,
    loaMethod: 'print',
    tableLoading: false,
    linkedAccounts: null,
    tokenFilter: props.tokenFilter,
    accountRowData: props.rowData ? props.rowData : { Authorization: '', AuthorizationInput: '' },
    accountLimit: 0,
    pendingLOAFetchProperties: {
      BaseUrl: `${that.props.services.Enrollment.URL}/PENDING_LOAS`,
      Mode: 'cors',
      Method: 'GET',
      SuccessMessage: 'Request Successful',
      FailureMessage: 'Request Failed',
      SuppressMessageOverride: false,
      HeaderVals: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': that.props.services.Enrollment.subscription_key,
        'x-universal-firm': that.props.user.FirmId,
      },
    },
    columns: [
      {
        accessor: 'Action',
        Header: props.selectedGrid === 'Pending' ? header('Action', false, 'center') : header('Linked', false, 'center'),
        Cell: that.actionCell,
        width: 100,
      },
      {
        accessor: 'Account_SID',
        Header: header('Account SID'),
        width: 100,
        Cell: that.textCell,
      },
      {
        accessor: 'AccountName',
        Header: header('Account Number'),
        width: 350,
        Cell: that.textCell,
      },
      {
        accessor: 'AccountTitle',
        Header: header('Account Name'),
        width: 350,
        Cell: that.textCell,
      },
    ],
  };
}

export default class AccountLinking extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this, props);
  }

  async componentDidMount() {
    const { selectedGrid, accountId, rowData } = this.props;
    if (accountId) {
      if (queryString.parse(window.location.search).navigationMode && queryString.parse(window.location.search).navigationMode === 'limited') {
        document.cookie = `accounts_tokenFilter=false;path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `path=view;path=/;${globals.SAMESITECOOKIE}`;
        this.setState({ navigationMode: 'limited', tokenFilter: false });
      }
      let tokenFilter = this.state.tokenFilter;
      if (getCookie('accounts_tokenFilter') !== '') {
        tokenFilter = JSON.parse(getCookie('accounts_tokenFilter'));
      }
      await this.getData();
    } else {
      this.setState({ loading: false });
    }
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.refresh !== this.props.refresh && this.props.refresh === true && prevProps.refresh === false) {
      let { accountId, accountRowData } = this.state;
      this.getData(accountId, accountRowData).then(() => {
        this.setState({ keyAuth: new Date().getTime() }, () => {
          this.props.setParentRefresh();
        });
      });
    }
  }
  async getData(passedAccountId = '', passedRowData = '', fromToken = false) {
    this.setState({ tableLoading: true });
    let { accountId, accountSID, rowData } = this.props;
    let { accountList: accountListOriginal, accountSIDList: accountSIDListOriginal } = this.state;

    if (passedAccountId) {
      accountId = passedAccountId;
    }

    if (passedRowData) {
      rowData = passedRowData;
    }

    let showData = this.state.tokenFilter === false ? true : false;
    let { user, services } = this.props;

    let accounts = await AccountModels.getAccountData({
      user,
      services,
      accountId,
      showData,
    });
    this.setAccountData(accounts[0]).then(async (res) => {
      if (rowData) {
        let accountList = rowData.AccountIds.split(',');

        if (fromToken) {
          accountList = accountListOriginal;
        }

        let accountIds = rowData.AccountIds.split(',').join(',');

        // Get accounts from other Authorizations that have the same Counterparty ,Contact, Signers, FirmId, InvestorName
        let otherAccountIds = await AccountModels.getPendingLoas({
          user,
          services,
          key: rowData.AuthorizationKey,
          authorization: rowData.Authorization,
        });

        if (otherAccountIds) {
          if (accountIds) {
            accountIds += ',' + otherAccountIds;
          } else {
            accountIds += otherAccountIds;
          }
        }

        let allAccounts = await AccountModels.getAccountsData({
          user,
          services,
          accountIds: accountIds,
          showData,
        });

        allAccounts = this.handleAction(accountList, allAccounts);

        let defaultAuthorization = '';

        if (rowData) {
          let signers = '';
          if (rowData.PrimarySigner) {
            signers += rowData.PrimarySigner;
          }
          if (rowData.Signer2) {
            signers += ', ' + rowData.Signer2;
          }
          if (rowData.Signer3) {
            signers += ', ' + rowData.Signer3;
          }
          if (rowData.Signer4) {
            signers += ', ' + rowData.Signer4;
          }
          defaultAuthorization = { value: rowData.Authorization, label: `${signers} | (${rowData.Accounts}) Account(s) Pending Authorization` };
        }

        this.setState(
          {
            accountData: res,
            accountRowData: rowData,
            data: allAccounts,
            accountList,
            accountId,
            accountSID,
            loading: false,
            tableLoading: false,
            accountLimit: rowData.AccountLimit,
            defaultAuthorization,
          },
          () => {}
        );
      }
    });
  }

  textCell = (cellInfo) => {
    let data = this.state.data;
    let value = this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null;
    var even_odd_css = '';
    cellInfo.viewIndex % 2 === 0 ? (even_odd_css = `custom-grid-input even-row`) : (even_odd_css = `custom-grid-input odd-row`);
    let isValid = true;
    let message = '';
    if (cellInfo.column.id === 'AccountNumber') {
      if (!data[cellInfo.index]['AccountNumberValid']) {
        isValid = false;
      }
      if (data[cellInfo.index]['AccountNumberMessage'] !== '') {
        message = data[cellInfo.index]['AccountNumberMessage'];
      }
    }

    if (cellInfo.column.id === 'AccountName') {
      if (!data[cellInfo.index]['AccountNameValid']) {
        isValid = false;
      }
      if (data[cellInfo.index]['AccountNameMessage'] !== '') {
        message = data[cellInfo.index]['AccountNameMessage'];
      }
    }
    return (
      <div
        title={message ? message : value}
        className={this.state.hoveredRowCol === data + cellInfo.index ? '' : ''}
        onMouseEnter={() => {
          this.setState({ hoveredRowCol: data + cellInfo.index });
        }}
        onMouseLeave={() => {
          this.setState({ hoveredRowCol: null });
        }}
      >
        {cellInfo.column.id === 'Account_SID' || cellInfo.column.id === 'AccountName' || cellInfo.column.id === 'AccountTitle' ? (
          <div
            className={`${even_odd_css} row-input hover-underline`}
            onClick={() => {
              this.props.fetchAccountData(data[cellInfo.index]['AccountID']);
            }}
          >
            {this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
          </div>
        ) : (
          <input
            id={cellInfo.index + cellInfo.column.id}
            className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
            key={cellInfo.index + cellInfo.column.id}
            onChange={(e) => {
              let data = this.state.data;
              data[cellInfo.index][cellInfo.column.id] = e.target.value;
              this.setState({ data });
            }}
            value={this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
          />
        )}
      </div>
    );
  };
  handleAction = (accountList = [], data = []) => {
    let allAccounts = [...data];
    return allAccounts.map((x) => {
      let id = x.AccountID ? x.AccountID.toUpperCase() : '';
      if (accountList.includes(id)) {
        x['Action'] = 1;
      } else {
        x['Action'] = 0;
      }
      return x;
    });
  };
  actionCell = (cellInfo) => {
    let { data, accountList, accountRowData, accountLimit } = this.state;
    let { selectedGrid } = this.props;

    let icon = 'unlink';
    let acctId = data && data[cellInfo.index] && data[cellInfo.index]['AccountID'].toUpperCase();
    if (accountList) {
      if (!accountList.includes(acctId)) {
        icon = 'plus';
      }
    }

    let linked = accountList.length;
    let max = accountLimit;

    return (
      <div className="grid-action" value={cellInfo.index}>
        {selectedGrid !== 'Pending' ? (
          <FontAwesome
            name="fas fa-link"
            title="Account linked to LOA"
            className="fa fas fa-link fa-pencil-edit-row unlink-icon"
            id="fa-grid-icon-link"
            onClick={(e) => {
              accountList = accountList.filter((element) => element !== acctId);
              data = this.handleAction(accountList, data);
              accountRowData['Accounts'] = accountList.length;
              this.setState({ accountList, data, accountRowData });
            }}
          />
        ) : icon === 'unlink' ? (
          <FontAwesome
            name="fas fa-unlink"
            style={{ cursor: 'pointer' }}
            title="Unlink account from this authorization"
            className={`fa fas fa-unlink fa-pencil-edit-row unlink-icon`}
            id="fa-grid-icon-link"
            onClick={(e) => {
              accountList = accountList.filter((element) => element !== acctId);
              data = this.handleAction(accountList, data);
              accountRowData['Accounts'] = accountList.length;
              this.setState({ accountList, data, accountRowData });
            }}
          />
        ) : (
          <FontAwesome
            name="fas fa-plus"
            style={{ cursor: 'pointer' }}
            title={`${linked >= max ? `The maximum number of accounts (${max}) that can be added to this authorization has been reached.` : 'Add account to this authorization'}`}
            className={`fa fas fa-plus fa-pencil-edit-row unlink-icon ${linked >= max ? 'disabled-icon' : ''}`}
            id="fa-grid-icon-link"
            onClick={(e) => {
              if (linked < max) {
                accountList.push(acctId);
                data = this.handleAction(accountList, data);
                accountRowData['Accounts'] = accountList.length;
                if (accountList.length === max) {
                  confirmAlert({
                    closeOnClickOutside: false,
                    title: `Maximum Account Limit Reached`,
                    childrenElement: () => <div>A maximum of ({max}) accounts can be added to this authorization.</div>,
                    buttons: [{ label: <div id="continue-confirm-button">OK</div>, onClick: () => {} }],
                  });
                }
                this.setState({ accountList, data, accountRowData });
              }
            }}
          />
        )}
      </div>
    );
  };

  async setAccountData(data) {
    let { user, services } = this.props;
    let temp = {};
    temp.AccountID = data.AccountID;
    temp.AccountName = data.AccountName;
    temp['AccountName-token'] = data['AccountName-token'];
    temp.AccountTitle = data.AccountTitle;
    temp.AccountTypeID = { label: '', value: data.AccountTypeID };
    temp.ChangeDate = data.ChangeDate;
    temp.CustodianName = data.CUSTODIAN.Name;
    temp.CustodianID = data.CustodianID;
    temp.CustodianData = data.CUSTODIAN;
    temp.CustodianContact = data.CONTACT
      ? {
          label: data.CONTACT.NameAlias,
          value: data.CONTACT.ContactID,
          DetailID: data.CONTACT.CONTACT_DETAIL[0] ? data.CONTACT.CONTACT_DETAIL[0].DetailID : null,
          data: data.CONTACT.CONTACT_DETAIL[0] ? data.CONTACT.CONTACT_DETAIL[0] : null,
        }
      : null;
    temp.ContactID = data.ContactID;
    temp.ContactDetailID = data.ContactDetailID;
    temp.Currency1 = data.Currency1;
    temp.Fund = { label: '', value: data.Fund_UID };
    temp.Fund_UID = data.Fund_UID;
    temp.InvestorName = data.InvestorName;
    temp.InvestorID = data.InvestorID;
    temp.Status = data.Status;
    temp.StatusName = data.ACCOUNT_LOA_STATUS.Name;
    temp.AdvisorID = data.AdvisorID;
    temp.RelationshipID = data.RelationshipID;
    temp.ManagerType = data.ManagerType;
    temp.LOA = data.LOA;
    temp.AdvisorName = data.AdvisorName;
    temp.ClientName = data.ClientName;
    temp.Account_PartnerID = data.Account_PartnerID;
    temp.Account_TeamID = data.Account_TeamID;
    temp.Account_ClientID = data.Account_ClientID;
    temp.Account_InvestmentID = data.Account_InvestmentID;
    temp.Account_Custom1 = data.Account_Custom1;
    temp.Account_Custom2 = data.Account_Custom2;
    temp.Account_Custom3 = data.Account_Custom3;
    temp.Account_Custom4 = data.Account_Custom4;
    temp.Account_Related_Entity_UID = data.Account_Related_Entity_UID ? data.Account_Related_Entity_UID : '';

    // REMOVE SOFT DELETES FROM SIGNERS
    let signers = [];
    let validSigners = [];

    for (let i in data.ACCOUNT_SIGNER_LINK) {
      if (data.ACCOUNT_SIGNER_LINK[i].ChangeType !== 'D') {
        //Push to Signers
        signers.push(data.ACCOUNT_SIGNER_LINK[i]);
      }
    }
    for (let i in signers) {
      if (signers[i].CONTACT_DETAIL) {
        //Create Full Name
        signers[i].CONTACT_DETAIL.FullName = signers[i].CONTACT_DETAIL.FirstName + ' ' + signers[i].CONTACT_DETAIL.LastName;
      }
      validSigners.push(parseInt(i));
    }

    this.setValidSigners(validSigners);
    temp.ACCOUNT_SIGNER_LINK = signers;

    let custodianRelated = {};
    if (temp.Account_Related_Entity_UID) {
      custodianRelated = await AccountModels.getCustodianRelated({
        user,
        services,
        id: temp.Account_Related_Entity_UID,
      });
      if (custodianRelated[0]) {
        temp.CustodianRelatedName = custodianRelated[0].Name;
      }
    }

    this.setState({
      previewAccountDetailsData: temp,
      previewLoading: false,
    });
    return temp;
  }
  setValidSigners = (arr) => {
    this.setState({ validSigners: arr });
  };

  isSaveDisabled = () => {
    const { accountList } = this.state;
    if (accountList) {
      if (accountList.length > 0) {
        return false;
      }
    }
    return true;
  };

  getInfo = () => {
    const { accountData, accountRowData } = this.state;
    const { selectedGrid } = this.props;

    if (accountData) {
      let custodianName = accountData.CustodianName;
      let custodianContact = accountData.CustodianContact && accountData.CustodianContact.label ? accountData.CustodianContact.label : '';
      let signerArray = accountData.ACCOUNT_SIGNER_LINK;
      let signerInfo = [];

      for (let signer of signerArray) {
        signerInfo.push({ name: signer.CONTACT_DETAIL.FullName, id: signer.CONTACT_DETAIL.DetailID });
      }

      return (
        <>
          {accountRowData && (
            <>
              <div>
                Authorization: <b>{accountRowData.Authorization}</b>
              </div>
            </>
          )}
          {custodianName && (
            <div>
              Counterparty: <b>{custodianName}</b>
            </div>
          )}

          {custodianContact && (
            <div>
              Custodian Contact: <b>{custodianContact}</b>
            </div>
          )}
          {accountRowData && (
            <>
              {selectedGrid !== 'Pending' ? (
                <div>
                  Method: <b>{accountRowData.EnrollMethod}</b>
                </div>
              ) : (
                <div>
                  E-Signature Eligible: <b>{accountRowData.EsignatureFlag === true ? 'Yes' : 'No'}</b>
                </div>
              )}
              <div>
                Linked Accounts: <b>{`${accountRowData.Accounts} ${accountRowData.AccountLimit ? ` out of ${accountRowData.AccountLimit}` : ''}`}</b>
              </div>
            </>
          )}

          {signerInfo && (
            <div>
              Signer(s):{' '}
              <b>
                {signerInfo.map((signer, index) => {
                  return (
                    <>
                      {index !== 0 && ', '}
                      <span
                        title={`Click to edit '${signer.name}'`}
                        className="account-linking-signer-edit"
                        onClick={() => {
                          this.props.handleSignerEdit(signer.id);
                        }}
                      >
                        {signer.name}
                      </span>
                    </>
                  );
                })}
              </b>
            </div>
          )}
        </>
      );
    }
  };

  handleRowData = (field, e) => {
    let { accountRowData } = this.state;
    if (e) {
      accountRowData[field] = e.target.value;
      this.setState({ accountRowData });
    }
  };

  handleLoaMethod = (method) => {
    this.setState({ loaMethod: method.target.value });
  };

  handleGenerateLOA = (accountIds) => {
    const { user, services } = this.props;
    const { loaMethod } = this.state;
    loaMethod === 'print'
      ? this.props.generateAndDownload(accountIds, user, services).then(() => {
          this.setState({ loading: false });
        })
      : this.props.generateAndSend(accountIds, user, services).then(() => {
          this.setState({ loading: false });
        });
  };

  setTokenFilter = () => {
    let currentValue = this.state.tokenFilter;
    let { accountId, accountRowData } = this.state;
    this.setState({ tokenFilter: !currentValue }, () => {
      document.cookie = `accounts_tokenFilter=${JSON.stringify(!currentValue)};path=/;${globals.SAMESITECOOKIE}`;
      this.getData(accountId, accountRowData, true);
    });
  };

  render() {
    const { accountRowData, accountList, defaultAuthorization, tokenFilter, keyAuth, loaMethod, loading } = this.state;
    const { selectedGrid } = this.props;
    const isSaveDisabled = this.isSaveDisabled();

    return (
      <>
        <Modal isOpen={true} style={multipleAccountsModalStyle}>
          <Draggable handle=".handle">
            <div className="draggable-wrapper">
              <div>
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">{selectedGrid === 'Pending' ? 'Link Accounts' : 'Linked Accounts'}</div>
                  </div>

                  <div
                    className="sidemodal_addnew_x"
                    onClick={() => {
                      this.props.closeModal();
                    }}
                  >
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <div className="search-modal-container account-linking-modal-container">
                  {loading ? (
                    <div className="spinner-div-wrapper">
                      <div className="spinner-div spinner-div-full">
                        <div className="spinner-circle">
                          <CircularProgress />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="multiple-accounts-header">
                        <div className="multiple-accounts-info-text">{this.getInfo()}</div>
                        <div className="button-div">
                          <div
                            id="upload-batch-accounts-button"
                            className={'generic-button-secondary'}
                            onClick={() => {
                              this.props.closeModal();
                            }}
                          >
                            Cancel
                          </div>
                          {selectedGrid === 'Pending' && (
                            <>
                              <div
                                id="upload-batch-accounts-button"
                                className={!isSaveDisabled ? 'generic-button-secondary' : 'generic-button-secondary generic-button-enhanced-secondary-disabled'}
                                onClick={() => {
                                  if (!isSaveDisabled) {
                                    let temp = { ...accountRowData };
                                    temp['AccountIds'] = String(accountList);
                                    this.props.onPreviewClick(temp);
                                  }
                                }}
                              >
                                Preview LOA
                              </div>
                              <div
                                id="save-accounts-button"
                                className={!isSaveDisabled ? 'generic-button-primary no-margin' : 'generic-button-primary no-margin generic-button-disabled'}
                                onClick={() => {
                                  this.setState({ loading: true });
                                  if (!isSaveDisabled) {
                                    let accountIds = String(accountList);
                                    this.handleGenerateLOA(accountIds);
                                  }
                                }}
                              >
                                {loaMethod === 'print' ? 'Print LOA' : 'Send LOA'}
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="multiple-accounts-body">
                        {selectedGrid === 'Pending' && (
                          <div className="account-linking-grid-container padding-top-10">
                            <div className="account-linking-item1">
                              <label className="account-linking-label" for="addressType">
                                Pending Authorizations
                              </label>
                              <ServerSideSelect
                                disabled={false}
                                placeholder=""
                                wrapperClassName="account-linking-search-wrapper"
                                selectClassName={'std-powergrid-full-select'}
                                fetchProperties={this.state.pendingLOAFetchProperties}
                                groupField="EntityName"
                                groupValue="EntityName"
                                labelField="EntityName"
                                labelField2="PrimarySigner"
                                labelField3="Signer2"
                                labelField4="Signer3"
                                labelField5="Signer4"
                                labelMask="{PrimarySigner}, {Signer2}, {Signer3}, {Signer4} | ({Accounts}) Account(s) Pending Authorization"
                                orderBy={'EntityName, PrimarySigner, Signer2, Signer3, Signer4 asc'}
                                valueField="Authorization"
                                startsWith={false}
                                // initialDropdown={true}
                                id="custodian-contacts"
                                key={keyAuth}
                                value={accountRowData.AuthorizationInput}
                                defaultValue={defaultAuthorization}
                                onChange={async (event, newInputValue, reason) => {
                                  this.handleRowData('AuthorizationInput', event);
                                  if (reason === 'select-option') {
                                    this.setState({ loaMethod: 'print' }, async () => {
                                      let rowData = newInputValue.data;
                                      rowData['AuthorizationInput'] = newInputValue.data.Authorization;
                                      await this.getData(newInputValue.data.AccountID, rowData);
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="account-linking-item2">
                              <label className="account-linking-label" for="addressType">
                                LOA Method
                              </label>

                              <RadioGroup
                                row
                                value={loaMethod}
                                defaultValue="print"
                                onChange={this.handleLoaMethod}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                              >
                                <FormControlLabel value="print" control={<Radio />} label="Print" />
                                <FormControlLabel
                                  value="electronic"
                                  disabled={!accountRowData.EsignatureFlag}
                                  title={!accountRowData.EsignatureFlag ? 'Not E-Signature Eligible' : 'Send LOA to Signers Electronically'}
                                  control={<Radio />}
                                  label="Electronic"
                                />
                              </RadioGroup>
                            </div>
                            <div className="account-linking-item3">
                              <button
                                onClick={() => {
                                  this.setTokenFilter();
                                }}
                                className="search-button"
                                id="account-number-eyeball-button"
                              >
                                <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="eyeball-info" place="top" type="info" effect="solid" className="tooltip_custom">
                                  <p>{tokenFilter ? 'Show Information' : 'Hide Information'}</p>
                                </ReactTooltip>
                                <FontAwesome
                                  name={tokenFilter ? 'far fa-eye ' : 'far fa-eye-slash '}
                                  style={{ cursor: 'pointer' }}
                                  className={tokenFilter ? 'far fa-eye' : 'far fa-eye-slash'}
                                  data-for="eyeball-info"
                                  data-tip
                                />
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="table-container">
                          <ReactTable
                            key={this.state.key}
                            data={this.state.data}
                            columns={this.state.columns}
                            defaultSorted={[
                              {
                                id: 'Action',
                                desc: true,
                              },
                            ]}
                            showPagination={false}
                            pageSize={this.state.data.length > 10 ? this.state.data.length : 10}
                            className="-striped -highlight grid"
                            loading={this.state.tableLoading}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Draggable>
        </Modal>
      </>
    );
  }
}
