import React from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router';
import Modal from 'react-modal';
import Spinner from 'react-spinkit';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as LOAModels from './LOAModels';
import * as AuthorizationModels from './AuthorizationModels';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Draggable from 'react-draggable';
import * as DropdownModels from './DropdownModels';
import * as Models from '../Utils/AddTicketModels';
import { showRequired, hasRequired } from '../Utils/RequiredFields';
import { checkPolicy } from '../Utils/HelperFunctions';
import * as validationManager from '../Utils/ValidationManager';
import * as globals from '../../Globals/Variables';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import str2ab from 'string-to-arraybuffer';
import ReactTooltip from 'react-tooltip';
const FontAwesome = require('react-fontawesome');
const getINITIAL_STATE = (that) => {
  let policies = that.props.user.firmPolicies;
  return {
    loading: false,
    accountList: [],
    showError: false,
    certificateShow: checkPolicy(policies[globals.UI_ENROLLMENT_LOA_CERTIFICATEOFCOMPLETION_BTN_SHOW]),
    certificateLabel: checkPolicy(policies[globals.UI_ENROLLMENT_LOA_CERTIFICATEOFCOMPLETION_BTN_LBL]),
    certificateModalOpen: false,
  };
};
let AllRequiredFields = ['FileName', 'FileData', 'AccountLimit', 'SignerLimit', 'Type'];
let spinnerModalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1111',
    background: 'rgba(255, 255, 255, 0.5)',
  },
  content: {
    top: '25%',
    left: '12%',
    height: '75%',
    width: '75%',
    border: 'none',
    background: 'transparent',
  },
};

const modalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    bottom: 'unset',
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    width: '60%',
    height: '75%',
    top: '2%',
    left: '20%',
    // pointerEvents: "none"
  },
};

let showUpload = 'true';

class LOADocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
    this.toggle = this.toggle.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
  }

  componentDidMount() {
    let { loaID, status, directive } = this.props;
    if (loaID) {
      this.getLoa(loaID, directive);
    }
    if (status) {
      this.setState({ status: { value: status, label: status } });
      this.getLoaStatusOptions(status);
    }

    showUpload = this.props.user.firmPolicies[globals.UI_ENROLLMENT_LOA_MANUAL_CHOOSEFILE_SHOW]
      ? this.props.user.firmPolicies[globals.UI_ENROLLMENT_LOA_MANUAL_CHOOSEFILE_SHOW].Value
      : 'true';
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  async getLoa(loaId, directive) {
    let { user, services } = this.props;
    let showData = directive ? true : false;
    this.setState({ loading: true });
    let [response, links] = await Promise.all([
      LOAModels.getLoaById({
        user,
        services,
        loaId,
      }),
      LOAModels.getLoaAccountLinks({
        user,
        services,
        loaId,
        showData,
      }),
    ]);

    if (!response.error) {
      let body = response.body ? response.body.Value : null;

      if (body && body[0]) {
        this.setState({ DocumentID: body[0].DocumentID, LOAType: body[0].LOA.AuthorizeID ? 'DOCUSIGN' : 'PRINTED' });

        if (body[0].FileData) {
          this.handleFileChange(body[0].FileData, true);
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, showError: true });
      }
    }

    let accountList = links.map((item) => {
      return item.ACCOUNT;
    });
    this.setState({ accountList });
  }

  async getLoaStatusOptions(currentStatus) {
    let { user, services } = this.props;
    let statusArray = await LOAModels.getLoaStatusOptions({
      user,
      services,
      currentStatus,
    });
    let options = statusArray.map((obj) => {
      return { label: obj.NextStatus, value: obj.NextStatus };
    });

    options = options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    this.setState({ statusOptions: options });
    return;
  }

  async onSaveLoaClick() {
    this.setState({ loading: true });
    if (this.props.type === 'add') {
      await this.createLoa();
    } else {
      await this.patchLoa();
    }
    this.setState({ loading: false });
  }

  async createLoa() {
    let { user, services } = this.props;
    let { custodianId, FileData, FileName, Type, AccountLimit, SignerLimit, templateId, templateModalType } = this.state;
    let templateBody = {
      FirmID: this.props.user.FirmId,
      FileData,
      FileName,
      CustodianID: custodianId,
      AccountLimit: parseInt(AccountLimit),
      SignerLimit: parseInt(SignerLimit),
      Type: Type ? Type.value : null,
      CreateDate: new Date().toISOString().slice(0, 19).concat('Z'),
      ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
      ChangeType: 'I',
    };

    let response = LOAModels.createLoa({
      user,
      services,
      body: templateBody,
    });
    if (!response.error) {
      if (this.props.refreshGrid) {
        this.props.refreshGrid();
      }
      if (this.props.closeModal) {
        this.props.closeModal();
      }
    }
    return;
  }
  async patchLoa() {
    let { user, services } = this.props;
    let { DocumentID, FileData } = this.state;
    let templateBody = {
      FileData,
      ChangeDate: new Date().toISOString().slice(0, 19).concat('Z'),
      ChangeType: 'U',
    };
    let response = await LOAModels.patchLoa({
      user,
      services,
      body: templateBody,
      documentId: DocumentID,
    });
    return;
  }

  handleFileChange = (data, initialLoad) => {
    var base = atob(data);
    var input = str2ab(base);
    var blob = new Blob([input], { type: 'application/pdf' });
    if (blob) {
      var ie = navigator.userAgent.match(/.NET/g);
      var edge = navigator.userAgent.match(/Edge/g);
      var fileURL = window.URL.createObjectURL(blob);
    }
    this.setState(
      {
        FileData: data,
        FileDataPreview: fileURL,
        FileBlob: blob,
      },
      () => {
        if (!initialLoad) {
          this.onSaveLoaClick();
        }
      }
    );
  };

  handleCancel = () => {
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };

  certificateConversion = (data) => {
    var base = atob(data);
    var input = str2ab(base);
    var blob = new Blob([input], { type: 'application/pdf' });
    if (blob) {
      var fileURL = window.URL.createObjectURL(blob);
    }
    this.setState({
      CertificateDataPreview: fileURL,
      certificateModalOpen: true,
    });
  };

  async postCertificate() {
    let { user, services, loaID } = this.props;
    let body = { LoaID: loaID };

    return await LOAModels.postCertificate({
      user,
      services,
      body,
    }); // Returns response with certificate PDF
  }

  handleCertificate = () => {
    this.postCertificate().then((data) => {
      if (data.body) {
        if (data.body.Code === 200) {
          this.certificateConversion(data.body.Value);
        } else {
          validationManager.toastMessage(false, data.body.UserMessage, data.body.Message);
        }
      }
    });
  };
  handleViewPDF = (url) => {
    if (url) {
      window.open(url + '#toolbar=1&navpanes=0&scrollbar=0&view=fitH,100');
    }
  };
  checkStatusChange = (e) => {
    if (e.value.toUpperCase() === 'SIGNED') {
      this.changePendingDataConfirm(e);
    } else {
      this.changeStatusConfirm(e);
    }
  };

  changePendingDataConfirm = (e) => {
    confirmAlert({
      title: <div className="unsaved-changes-header">Change Status to {e.value}?</div>,
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div>
              {this.props.user.firmPolicies[globals.UI_ENROLLMENT_LOA_PENDINGDATA_ALERT]
                ? this.props.user.firmPolicies[globals.UI_ENROLLMENT_LOA_PENDINGDATA_ALERT].Value
                : 'If this is an E-Signed LOA, uploading a Printed file will require everyone to sign again manually'}
            </div>
          </div>
        );
      },
      buttons: [
        { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
        {
          label: <div id="continue-confirm-button">Change Status</div>,
          id: 'continue-confirm-button',
          onClick: () => {
            this.handleStatusChange(e);
          },
        },
      ],
    });
  };

  changeStatusConfirm = (e) => {
    confirmAlert({
      title: <div className="unsaved-changes-header">Warning</div>,
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div>Update LOA Status to {e.value}?</div>
          </div>
        );
      },
      buttons: [
        { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
        {
          label: <div id="continue-confirm-button">Continue</div>,
          id: 'continue-confirm-button',
          onClick: () => {
            this.handleStatusChange(e);
          },
        },
      ],
    });
  };

  async handleStatusChange(e) {
    let { user, services } = this.props;
    let { DocumentID, FileData, status } = this.state;
    let response;

    if (e.value === 'CANCELLED') {
      response = await AuthorizationModels.cancelLoas({
        user,
        services,
        loaIds: [this.props.loaID],
      });
    } else {
      response = await LOAModels.updateLoaStatus({
        user,
        services,
        fromStatus: status.value,
        toStatus: e.value,
        loaIds: this.props.loaID,
      });
    }

    if (!response.error) {
      this.setState({ status: e });
      this.getLoaStatusOptions(e.value);
      this.props.refreshGrid();
    }
    return;
  }

  openLOAInNewTab = () => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(this.state.FileBlob, 'LOAPreview');
    }
  };

  toolTipAuthStatusText = (index) => {
    return (
      <p className="align-left">
        NEW: LOA saved but not sent OR printed.
        <br />
        SENT: LOA sent for e-signature through DocuSign.
        <br />
        PRINTED: LOA is downloaded OR printed.
        <br />
        DECLINED: LOA declined by one OR more signer(s).
        <br />
        PARTIALLY SIGNED: LOA signed by one of multiple signers available.
        <br />
        DELIVERED: LOA signed by all parties and PCR is waiting for data.
        <br />
        CANCELLED: LOA cancelled due to error OR change.
        <br />
        ACKNOWLEDGED: LOA accepted by Custodian OR Manager.
        <br />
        REJECTED: LOA declined by Custodian OR Manager.
        <br />
        EXPIRED: LOA voided after no activity for 6 months.
        <br />
        ACTIVE: PCR is receiving data for account(s) covered in LOA.
        <br />
        REVOKED: LOA is revoked after being Active (all accounts).
      </p>
    );
  };

  toggle() {
    this.setState({ anchorEl: null });
  }
  onMouseLeave() {
    this.setState({ showAuthStatusDropdown: false });
  }
  onMouseOver() {
    this.setState({ showAuthStatusDropdown: true });
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  authStatusDropDownMenu = () => {
    let buttons = [];
    let { showAuthStatusDropdown, status } = this.state;
    status = status ? status.value : '';
    buttons.push(
      <div>
        <Button
          className="actions-internal-div actions-internal-text"
          id="basic-button"
          aria-controls={showAuthStatusDropdown ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={showAuthStatusDropdown ? 'true' : undefined}
          onClick={this.handleClick}
        >
          Action
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={this.state.anchorEl}
          open={showAuthStatusDropdown}
          // onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {status === 'PRINTED' && (
            <MenuItem
              key={4}
              onClick={() => {
                this.checkStatusChange({ label: 'SIGNED', value: 'SIGNED' });
              }}
            >
              LOA Signed
            </MenuItem>
          )}
          <MenuItem
            key={5}
            onClick={() => {
              this.checkStatusChange({ label: 'CANCELLED', value: 'CANCELLED' });
            }}
          >
            Cancel LOA
          </MenuItem>
        </Menu>
      </div>
      // <div>
      //   {showAuthStatusDropdown !== true ? (
      //     <div tabIndex="0" className="icon-down-arrow">
      //       <FontAwesome name="fa-caret-down" style={{ cursor: 'pointer' }} className="fas fa-caret-down actions-dropdown-icon" id="fa-grid-icon-down" />
      //     </div>
      //   ) : (
      //     <div tabIndex="0" className="icon-down-arrow">
      //       <Dropdown key={1} className="d-inline-block" isOpen={true} toggle={this.toggle}>
      //         <FontAwesome name="fa-caret-down" style={{ cursor: 'pointer' }} className="fas fa-caret-up actions-dropdown-icon actions-dropdown-icon-up" id="fa-grid-icon-down" />
      //         <DropdownMenu key={2} onMouseLeave={this.onMouseLeave} className="auth-status-dropdown">
      //           {status === 'PRINTED' && (
      //             <DropdownItem
      //               key={4}
      //               onClick={() => {
      //                 this.checkStatusChange({ label: 'SIGNED', value: 'SIGNED' });
      //               }}
      //             >
      //               LOA Signed
      //             </DropdownItem>
      //           )}
      //           <DropdownItem
      //             key={5}
      //             onClick={() => {
      //               this.checkStatusChange({ label: 'CANCELLED', value: 'CANCELLED' });
      //             }}
      //           >
      //             Cancel LOA
      //           </DropdownItem>
      //           {/* )} */}
      //         </DropdownMenu>
      //       </Dropdown>
      //     </div>
      //   )}
      // </div>
    );
    return (
      <div className="col-edit-grid pointer">
        {/* <div className="actions-internal-div actions-internal-text">Action</div> */}
        <div className="actions-internal-div">{buttons}</div>
      </div>
    );
  };
  render() {
    const { fileName, certificateShow, certificateLabel, FileDataPreview } = this.state;
    let file = null;
    let toolTip = null;
    let type = this.state.LOAType === 'DOCUSIGN' || this.state.LOAType === 'PRINTED' ? (this.state.LOAType === 'PRINTED' ? 'Manual' : 'E-signature') : '';
    file = fileName ? <span>File Selected - {fileName}</span> : <span>{this.props.type === 'edit' ? 'Upload only one PDF file' : ''}</span>;

    toolTip = fileName ? fileName : 'No File Chosen';

    let { checkRequired, status, statusOptions, accountList, showAuthStatusDropdown } = this.state;
    let { primarySigner } = this.props;
    var ie = navigator.userAgent.match(/.NET/g);
    var edge = navigator.userAgent.match(/Edge/g);
    let disableStatus = true;
    disableStatus =
      this.state.LOAType === 'DOCUSIGN' &&
      this.state.status &&
      (this.state.status.value.toUpperCase() === 'SENT' ||
        this.state.status.value.toUpperCase() === 'PARTIALLY SIGNED' ||
        this.state.status.value.toUpperCase() === 'SIGNED' ||
        this.state.status.value.toUpperCase() === 'ACKNOWLEDGED' ||
        this.state.status.value.toUpperCase() === 'ACTIVE');

    return (
      <div>
        <div className="header-view-loa">
          <div>{/* Intentionally blank */}</div>
        </div>

        <div className={this.state.loading ? 'preview no-events' : 'preview'}>
          {this.state.showError || this.state.loading ? (
            <div>
              {this.state.showError ? (
                <div className="error-previewing-loa">Error Previewing LOA Document</div>
              ) : (
                <center>
                  <Spinner id="preview-spinner" style={spinnerModalStyle} name="line-scale-pulse-out-rapid" color="#315B7B" />
                </center>
              )}
            </div>
          ) : (
            <div className="preview-wrapper no-top">
              {this.state.FileDataPreview && !ie && !edge ? (
                <object className="loa-preview-object" data={`${this.state.FileDataPreview}#toolbar=1&navpanes=0&scrollbar=0&view=fitH,100`} type="application/pdf">
                  <embed src={`${this.state.FileDataPreview}`} type="application/pdf" />
                </object>
              ) : (
                <div className="loa-preview-object"></div>
              )}
              <div className="loa-preview-summary-wrapper-compact">
                {/* If certificate show policy is set to true and the Type = E-signature, display the button */}

                <div className="button-div no-margin width-onehundred inline-block">
                  <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="loa-newwindow-info" place="left" type="info" effect="solid" className="tooltip_custom">
                    Open PDF in new browser tab
                  </ReactTooltip>
                  <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="loa-coc-info" place="bottom" type="info" effect="solid" className="tooltip_custom">
                    View the Certificate of Completion PDF
                  </ReactTooltip>
                  <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="loa-action-info" place="bottom" type="info" effect="solid" className="tooltip_custom">
                    Available LOA Actions
                  </ReactTooltip>
                  {FileDataPreview && (
                    <div
                      className={'fa-external-link-loa-wrapper margin-right'}
                      id="view-newwindow-button"
                      data-tip
                      data-for="loa-newwindow-info"
                      onClick={() => {
                        this.handleViewPDF(FileDataPreview);
                      }}
                    >
                      <FontAwesome name="fas fa-external-link" className="fa fas fa-external-link fa-external-link-loa" id="fa-external-link-icon" />
                    </div>
                  )}

                  {status && status.value !== 'CANCELLED' && (
                    // don't show dropdown if status.value === 'CANCELLED'
                    <div
                      className={'actions-dropdown'}
                      id="view-action-button"
                      onClick={() => {
                        this.setState({ showAuthStatusDropdown: !showAuthStatusDropdown });
                      }}
                    >
                      {this.authStatusDropDownMenu()}
                    </div>
                  )}
                  {certificateShow && this.state.LOAType === 'DOCUSIGN' && (
                    <div data-tip data-for="loa-coc-info" className={'generic-button-long-primary'} id="view-completion-button" onClick={this.handleCertificate}>
                      <FontAwesome name="fas fa-certificate" className="fa fas fa-certificate margin-right" id="fa-certificate-icon" />
                      {'CoC'}
                    </div>
                  )}
                </div>

                <div>
                  <div className="loa-preview-summary-headers">Accounts</div>
                  <ul className="loa-preview-ul">
                    {accountList.map((account, idx) => {
                      return <li id={`loa-account-name-${idx}`}>{account.AccountName.includes('|') ? account.AccountName.split('|')[0] : account.AccountName}</li>;
                    })}
                  </ul>
                </div>
                <div>
                  <div className="loa-preview-summary-headers">Primary Signer</div>
                  <ul className="loa-preview-ul">
                    <li id="loa-primary-signer">{primarySigner}</li>
                  </ul>
                </div>
                <div>
                  <div className="loa-preview-summary-headers">Type</div>
                  <ul className="loa-preview-ul">
                    {this.state.LOAType === 'DOCUSIGN' || this.state.LOAType === 'PRINTED' ? (
                      this.state.LOAType === 'PRINTED' ? (
                        <li id="loa-type">Manual</li>
                      ) : (
                        <li id="loa-type">E-signature</li>
                      )
                    ) : null}
                  </ul>
                </div>
                <div>
                  <div className="loa-preview-summary-headers">Authorization Status</div>
                  <ul className="loa-preview-ul">
                    <li id="loa-status">{status ? status.label : null}</li>
                  </ul>
                </div>
                <div>
                  {ie || edge ? (
                    <div>
                      <div className="loa-preview-summary-headers">LOA</div>
                      <ul className="view-loa-link loa-preview-ul" title="Open LOA Document in a New Tab" onClick={this.state.loading ? null : this.openLOAInNewTab}>
                        <li id="loa-edge">Click to View Current LOA Document</li>
                      </ul>
                    </div>
                  ) : null}

                  {/* UI.Enrollment.LOA.Manual.ChooseFile.Show */}

                  <div className="loa-preview-summary-headers">
                    {this.state.LOAType === 'PRINTED' ? 'File' : null}
                    {this.state.LOAType === 'PRINTED' ? (
                      /* Status */

                      <div className="account-info-item">
                        <label className="label" for="status">
                          {/* {this.props.type === "edit" ? "Choose New File" : "File"} */}

                          {this.props.type === 'edit' ? 'After uploading a fully signed LOA change status to "Signed"' : 'File'}
                        </label>
                        {showUpload === 'false' && type === 'Manual' ? (
                          ''
                        ) : (
                          <div>
                            <input
                              disabled={this.state.loading}
                              id={`template-file`}
                              className={'template-file-input'}
                              type="file"
                              placeholder="Upload only one PDF file"
                              title={toolTip}
                              onChange={(e) => {
                                let files = e.target.files || [];
                                let blob = files[0];

                                this.setState({ fileName: blob.name });

                                var reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onloadend = () => {
                                  let base64data = reader.result;
                                  base64data = base64data.replace(`data:image/png;base64,`, '');
                                  base64data = base64data.replace(`data:application/pdf;base64,`, '');
                                  this.handleFileChange(base64data);
                                };
                              }}
                            />
                            <label className="label">{file}</label>
                          </div>
                        )}
                        {/* <label className='label'>{this.props.type === "edit" ? "Upload only one PDF file" : ""}</label> */}
                      </div>
                    ) : (
                      <div className="hide-loa-fileupload"> </div>
                    )}
                  </div>

                  <div>{/* loa tracking table? */}</div>
                </div>
                <div className="loa-preview-footer"></div>
              </div>
            </div>
          )}
        </div>

        {/* Certificate of Completion Modal - Displays a modal with the Certificate of Completion PDF in an Object */}
        {this.state.certificateModalOpen && (
          <Modal isOpen={this.state.certificateModalOpen} style={modalStyle}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                <div>
                  <div className="fullmodal handle">
                    <div className="fullmodal_title">
                      <div className="fullmodal_title_add">Certificate of Completion</div>
                    </div>

                    <div
                      id="close-modal"
                      className="sidemodal_addnew_x"
                      onClick={() => {
                        this.setState({ certificateModalOpen: false });
                      }}
                    >
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>
                  <div className="modalBody-certificate">
                    {this.state.CertificateDataPreview && !ie && !edge ? (
                      <object className="loa-preview-object-certificate" data={`${this.state.CertificateDataPreview}#toolbar=1&navpanes=0&scrollbar=0&view=fitH,100`} type="application/pdf">
                        <embed src={`${this.state.CertificateDataPreview}`} type="application/pdf" />
                      </object>
                    ) : (
                      <div className="loa-preview-object-certificate"></div>
                    )}
                  </div>
                </div>
              </div>
            </Draggable>
          </Modal>
        )}
      </div>
    );
  }
}
export default withRouter(LOADocument);
