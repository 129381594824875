import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import Draggable from 'react-draggable';
import Modal from 'react-modal';
import * as validationManager from '../Utils/ValidationManager';
import moment from 'moment';
import Guid from 'guid';
import str2ab from 'string-to-arraybuffer';
import 'react-datepicker/dist/react-datepicker.css';
import converter from 'json-2-csv';
import FileSaver from 'file-saver';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LOADocument from './LOADocument';
import Account from './Account';
import AccountLinking from './AccountLinking';
import * as globals from '../../Globals/Variables';
import * as PermissionManager from '../Utils/PermissionManager';
import * as AuthorizationModels from './AuthorizationModels';
import * as AccountModels from './AccountModels';
import Contact from './Contact';
import { getCookie, getCurrentTimeString, checkPolicy, checkIfHome, handleEnhancedNav } from '../Utils/HelperFunctions';
import * as LOAModels from './LOAModels';
import * as DropdownModels from './DropdownModels';
//Material UI imports
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Tooltip from '@mui/material/Tooltip';
//Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';

const queryString = require('query-string');
const FontAwesome = require('react-fontawesome');
const Spinner = require('react-spinkit');
var debounce = require('lodash/debounce');
const dateQuickfilterModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    top: '8%',
    left: '30%',
    height: '50%',
    width: '40%',
  },
};
const quickfilterModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    top: '5%',
    left: '13%',
    height: '80%',
    width: '75%',
  },
};
const largeQuickfilterModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    top: '3%',
    left: '20%',
    background: 'none',
    height: '80%',
    width: '60%',
    verticalAlign: 'middle',
  },
};
const generateModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    zIndex: 9,
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    height: '65%',
    width: '50%',
  },
};
const styleOverrides = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    bottom: 'unset',
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    width: '60%',
    height: '75%',
    top: '2%',
    left: '20%',
    // pointerEvents: "none"
  },
};
const previewModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    zIndex: 99,
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    height: '90%',
    width: '95%',
  },
};
const modalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    zIndex: 9,
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    width: '95%',
  },
};
const header = (header, required, align = 'left') => {
  return (
    <div className={`select-div-${align}`}>
      {required && <i className="fa fas fa-asterisk required-field-header"></i>}
      {header}
    </div>
  );
};
function getINITIAL_STATE(that) {
  let policies = that.props.user.firmPolicies;
  return {
    refresh: false,
    accountLinkingModalOpen: false,
    Active_customNoData: false,
    Pending_customNoData: false,
    tokenFilter: true,
    selectedGrid: 'Active',
    Active_LoaSearch: '',
    Active_pagesize: 10,
    Active_page: 0,
    Active_rowCount: 0,
    Active_filter: ``,
    Active_orderby: '&$orderby=LastChangeDate desc',
    Active_expand: '',
    Active_selectedIndex: 0,
    Active_selected: null,
    Active_selectedRows: [],
    Active_selectedRowsValues: {},
    Active_selectAll: false,
    Active_data: [],
    Active_columns: [
      {
        Header: () => {
          return (
            <div
              className="select-all-div"
              onClick={(e) => {
                that.selectAll(e);
              }}
            >
              <div className="grid-checkbox-center">Select All</div>
              <div className="grid-checkbox-center">
                <input
                  className="custom-grid-input"
                  type="checkbox"
                  checked={that.state.Active_selectAll}
                  onChange={(e) => {
                    that.selectAll(e);
                  }}
                />
              </div>
            </div>
          );
        },
        accessor: 'Checkboxes',
        Cell: that.checkboxCell,
        sortable: false,
        filterable: false,
        width: 80,
      },
      {
        accessor: 'LoaSID',
        Header: 'LOA SID',
        Cell: that.textCell,
        width: 100,
      },
      {
        accessor: 'EntityName',
        Header: 'Custodian/Manager',
        Cell: that.textCell,
        width: 400,
      },
      {
        accessor: 'PrimarySigner',
        Header: 'Primary Signer',
        Cell: that.textCell,
        width: 250,
      },
      {
        accessor: 'EnrollMethod',
        Header: 'Method',
        Cell: that.textCell,
        width: 75,
      },
      {
        accessor: 'AccountSIDs',
        Header: header('Account ID(s)', false, 'center'),
        Cell: that.textCell,
        width: 150,
      },
      {
        accessor: 'Status',
        Header: 'Status',
        Cell: that.textCell,
        width: 150,
      },

      {
        accessor: 'LastChangeDate',
        Header: 'Last Change Date',
        Cell: that.textCell,
        width: 175,
      },
    ],
    Active_loading: true,
    includeInAction: [],
    actionFunction: null,
    actionActionText: '',

    Pending_LoaSearch: '',
    Pending_pagesize: 10,
    Pending_page: 0,
    Pending_rowCount: 0,
    Pending_filter: '',
    Pending_orderby: '&$orderby=Authorization',
    Pending_expand: '',
    Pending_selectedIndex: 0,
    Pending_selected: null,
    Pending_selectedRows: [],
    Pending_selectedRowsValues: {},
    Pending_selectAll: false,
    Pending_data: [],
    Pending_columns: [
      {
        Header: () => {
          return (
            <div
              className="select-all-div"
              onClick={(e) => {
                that.selectAll(e);
              }}
            >
              <div className="grid-checkbox-center">Select All</div>
              <div className="grid-checkbox-center">
                <input
                  className="custom-grid-input"
                  type="checkbox"
                  checked={that.state.Pending_selectAll}
                  onChange={(e) => {
                    that.selectAll(e);
                  }}
                />
              </div>
            </div>
          );
        },
        accessor: 'Checkboxes',
        Cell: that.checkboxCell,
        sortable: false,
        filterable: false,
        width: 80,
        resizable: false,
      },
      {
        accessor: 'Authorization',
        Header: 'Authorization',
        Cell: that.textCell,
        width: 450,
      },
      {
        accessor: 'LinkedAccounts',
        Header: 'Linked Accounts',
        Cell: that.textCell,
        width: 150,
      },
      {
        accessor: 'EntityName',
        Header: 'Custodian/Manager',
        Cell: that.textCell,
        width: 350,
      },
      {
        accessor: 'PrimarySigner',
        Header: 'Primary Signer',
        Cell: that.textCell,
        width: 300,
      },
      {
        accessor: 'AccountSIDs',
        Header: header('Account ID(s)', false, 'center'),
        Cell: that.textCell,
        width: 150,
      },
      {
        accessor: 'EsignatureFlag',
        Header: 'E-Signature',
        Cell: that.eSignatureCell,
        width: 100,
      },
    ],
    Pending_loading: true,
    includeInGenerate: [],
    generateFunction: null,
    generateButtonText: '',
    helpInformation: [],
    enrollPrefixLabel: that.props.user.firmPolicies[globals.UI_ENROLLMENT_ENROLLPREFIXLABEL].Value,
    accountsButtonLabel: that.props.user.firmPolicies[globals.UI_ENROLLMENT_ACCOUNTS_BTN_LBL].Value,
    // Enhanced Navigation and Styles
    enhancedNavigation: checkPolicy(policies[globals.UI_ENROLLMENT_ENHANCED_NAVIGATION]),
    enrollAccountsNewButton: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_ACCOUNTS_NEW_BUTTON_LABEL]),
    enrollHomeSection: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_HOME_REDIRECT_SECTION]),
    relationshipId_show: checkPolicy(policies[globals.UI_ENROLLMENT_RELATIONSHIPID_SHOW]),
    relationshipId_label: checkPolicy(policies[globals.UI_ENROLLMENT_RELATIONSHIPID_LABEL]),
    advisorId_show: checkPolicy(policies[globals.UI_ENROLLMENT_ADVISORID_SHOW]),
    advisorId_label: checkPolicy(policies[globals.UI_ENROLLMENT_ADVISORID_LABEL]),
    enrollSignersEdit: checkPolicy(policies[globals.UI_ENROLLMENT_SIGNER_EDIT]),
    signerQuickfilterSearch: [],
    Active_quickfilters: [],
    Active_quickfilter: '',

    Active_auth_filter_selected: [],

    Active_auth_quickfilter: [],
    Active_auth_filter: [],
    Pending_quickfilters: [],
    Pending_quickfilter: '',
    Pending_auth_filter_selected: [],
    Pending_auth_quickfilter: [],
    Pending_auth_filter: [],
    Pending_filter: [],
    Active_filter: [],
    Active_quickfilterValues: {
      Status: [],
      EnrolledDate: [],
      Custodian: [],
      Signer: [],
      RelationshipId: [],
      AdvisorId: [],
      AccountSID: [],
    },
    Active_quickfilterModalValues: {
      Status: [],
      EnrolledDate: [moment(Date.now()), moment(Date.now())],
      Custodian: [],
      Signer: [],
      RelationshipId: [],
      AdvisorId: [],
      AccountSID: [],
    },
    Pending_quickfilters: [],
    Pending_quickfilterValues: {
      Status: [],
      EnrolledDate: [],
      Custodian: [],
      Signer: [],
      RelationshipId: [],
      AdvisorId: [],
      AccountSID: [],
    },
    Pending_quickfilterModalValues: {
      Status: [],
      EnrolledDate: [moment(Date.now()), moment(Date.now())],
      Custodian: [],
      Signer: [],
      RelationshipId: [],
      AdvisorId: [],
      AccountSID: [],
    },
    quickfilterOptions: {
      Status: [],
      EnrolledDate: [],
      Custodian: [],
      Signer: [],
      RelationshipId: [],
      AdvisorId: [],
      AccountSID: [],
    },
  };
}

let helpArray = [];

export default class Authorizations extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
    // OTHER Fields that shall be displayed in the grid if <field>.Show policy = true
    let Active_columns = this.state.Active_columns;
    Active_columns.push(...this.getPolicyBasedColumns());

    let Pending_columns = this.state.Pending_columns;
    Pending_columns.push(...this.getPolicyBasedColumns());

    this.setState({ Active_columns, Pending_columns });
  }

  componentDidMount = () => {
    if (queryString.parse(window.location.search).navigationMode && queryString.parse(window.location.search).navigationMode === 'limited') {
      document.cookie = `accounts_tokenFilter=false;path=/;${globals.SAMESITECOOKIE}`;
      document.cookie = `path=view;path=/;${globals.SAMESITECOOKIE}`;
      this.setState({ navigationMode: 'limited', tokenFilter: false });
    }
    let tokenFilter = this.state.tokenFilter;
    if (getCookie('accounts_tokenFilter') !== '') {
      tokenFilter = JSON.parse(getCookie('accounts_tokenFilter'));
    }
    // PageSize
    let pagesize = 10;
    if (getCookie('table_pagesize') !== '' && JSON.parse(getCookie('table_pagesize'))) {
      pagesize = JSON.parse(getCookie('table_pagesize'));
    }

    // Active_searchText

    let Active_filter = this.state.Active_filter;
    if (getCookie('Active_filter') !== '' && JSON.parse(getCookie('Active_filter'))) {
      Active_filter = JSON.parse(getCookie('Active_filter'));
    }

    // Pending_searchText
    let Pending_filter = this.state.Pending_filter;
    if (getCookie('Pending_filter') !== '' && JSON.parse(getCookie('Pending_filter'))) {
      Pending_filter = JSON.parse(getCookie('Pending_filter'));
    }

    let filterObj_Active = this.state.Active_quickfilterModalValues;
    if (getCookie('Active_auth_filter') !== '' && JSON.parse(getCookie('Active_auth_filter'))) {
      filterObj_Active = JSON.parse(getCookie('Active_auth_filter'));
    }
    let filterObj_Pending = this.state.Pending_quickfilterModalValues;
    if (getCookie('Pending_auth_filter') !== '' && JSON.parse(getCookie('Pending_auth_filter'))) {
      filterObj_Pending = JSON.parse(getCookie('Pending_auth_filter'));
    }

    let Active_quickfilter = this.state.Active_quickfilter;
    if (getCookie('Active_auth_quickfilter') !== '' && JSON.parse(getCookie('Active_auth_quickfilter'))) {
      Active_quickfilter = JSON.parse(getCookie('Active_auth_quickfilter'));
    }
    let Pending_quickfilter = this.state.Pending_quickfilter;
    if (getCookie('Pending_auth_quickfilter') !== '' && JSON.parse(getCookie('Pending_auth_quickfilter'))) {
      Pending_quickfilter = JSON.parse(getCookie('Pending_auth_quickfilter'));
    }

    // All filters selected (buttons green or not)
    let filterSelectedObj_Active = this.state.Active_quickfilters;
    if (getCookie('Active_auth_filter_selected') !== '' && JSON.parse(getCookie('Active_auth_filter_selected'))) {
      filterSelectedObj_Active = JSON.parse(getCookie('Active_auth_filter_selected'));
      filterSelectedObj_Active = Object.keys(filterSelectedObj_Active).map((key) => filterSelectedObj_Active[key]);
    }
    // All filters selected (buttons green or not)
    let filterSelectedObj_Pending = this.state.Pending_quickfilters;
    if (getCookie('Pending_auth_filter_selected') !== '' && JSON.parse(getCookie('Pending_auth_filter_selected'))) {
      filterSelectedObj_Pending = JSON.parse(getCookie('Pending_auth_filter_selected'));
      filterSelectedObj_Pending = Object.keys(filterSelectedObj_Pending).map((key) => filterSelectedObj_Pending[key]);
    }

    this.setState(
      {
        tokenFilter,
        Pending_pagesize: pagesize,
        Active_pagesize: pagesize,
        Active_filter,
        Pending_filter,

        Active_quickfilterModalValues: filterObj_Active,
        Pending_quickfilterModalValues: filterObj_Pending,
        Active_quickfilters: filterSelectedObj_Active,
        Pending_quickfilters: filterSelectedObj_Pending,
        Active_quickfilter,
        Pending_quickfilter,
      },
      () => {
        this.getTooltips(); // Read tooltips
        this.searchActiveLOAs();
        //Get the Pending filter before searchPendingLOAs
        this.handleSaveQuickfilterClick(false, 'Pending').then(() => {
          this.searchPendingLOAs();
        });

        this.AuthorizationsGrid(this.props);
        this.getStatusOptions();
      }
    );
  };

  getPolicyBasedColumns = () => {
    let columns = [];
    let policies = this.props.user.firmPolicies;
    let checkPolicies = [
      { show: 'UI_ENROLLMENT_RELATIONSHIPID_SHOW', label: 'UI_ENROLLMENT_RELATIONSHIPID_LABEL', field: 'RelationshipID' },
      { show: 'UI_ENROLLMENT_ADVISORID_SHOW', label: 'UI_ENROLLMENT_ADVISORID_LABEL', field: 'AdvisorID' },
      { show: 'UI_ENROLLMENT_PARTNERID_SHOW', label: 'UI_ENROLLMENT_PARTNERID_LABEL', field: 'PartnerID' },
      { show: 'UI_ENROLLMENT_TEAMID_SHOW', label: 'UI_ENROLLMENT_TEAMID_LABEL', field: 'TeamID' },
      { show: 'UI_ENROLLMENT_CLIENTID_SHOW', label: 'UI_ENROLLMENT_CLIENTID_LABEL', field: 'ClientID' },
      { show: 'UI_ENROLLMENT_INVESTMENTID_SHOW', label: 'UI_ENROLLMENT_INVESTMENTID_LABEL', field: 'InvestmentID' },
      { show: 'UI_ENROLLMENT_ACCOUNTCUSTOM1_SHOW', label: 'UI_ENROLLMENT_ACCOUNTCUSTOM1_LABEL', field: 'Custom1' },
      { show: 'UI_ENROLLMENT_ACCOUNTCUSTOM2_SHOW', label: 'UI_ENROLLMENT_ACCOUNTCUSTOM2_LABEL', field: 'Custom2' },
      { show: 'UI_ENROLLMENT_ACCOUNTCUSTOM3_SHOW', label: 'UI_ENROLLMENT_ACCOUNTCUSTOM3_LABEL', field: 'Custom3' },
      { show: 'UI_ENROLLMENT_ACCOUNTCUSTOM4_SHOW', label: 'UI_ENROLLMENT_ACCOUNTCUSTOM4_LABEL', field: 'Custom4' },
    ];
    for (let x in checkPolicies) {
      if (checkPolicy(policies[globals[checkPolicies[x].show]])) {
        columns.push({
          accessor: checkPolicies[x].field,
          Header: policies[globals[checkPolicies[x].label]].Value,
          Cell: this.textCell,
          sortable: false,
          width: 150,
        });
      }
    }

    return columns;
  };
  getCustodianOptions = debounce(async () => {
    let { user, services } = this.props;
    let { custodianQuickfilterSearch, quickfilterOptions } = this.state;
    if (custodianQuickfilterSearch.length < 3) {
      return;
    } else {
      this.setState({ custodianSearchLoading: true });
      let options = await DropdownModels.getCustodians({
        user,
        services,
        search: encodeURIComponent(custodianQuickfilterSearch),
      });
      let quickfilterModalValues = Object.assign({}, quickfilterModalValues);
      options.map((opt) => {
        opt.name = opt.Name;
        return opt;
      });
      this.setState({ quickfilterOptions: { ...quickfilterOptions, Custodian: options }, custodianSearchLoading: false });
    }
  }, 500);

  getSignerOptions = debounce(async () => {
    let { user, services, crmData } = this.props;
    let { selectedGrid, selectedQuickfilter } = this.state;
    let { [`${selectedGrid}_${selectedQuickfilter}`]: signerQuickfilterSearch, quickfilterOptions } = this.state;
    if (signerQuickfilterSearch.length < 3) {
      return;
    } else {
      this.setState({ signerSearchLoading: true });
      let options = await DropdownModels.getSigners({
        user,
        services,
        search: encodeURIComponent(signerQuickfilterSearch),
        crmData,
      });
      let quickfilterModalValues = Object.assign({}, quickfilterModalValues);
      options.map((opt) => {
        opt.name = opt.NameAlias;
        return opt;
      });
      this.setState({ quickfilterOptions: { ...quickfilterOptions, Signer: options }, signerSearchLoading: false });
    }
  }, 500);
  async getStatusOptions() {
    let { user, services } = this.props;
    let { selectedGrid } = this.state;
    let statusOptions = await AuthorizationModels.getStatusOptions({
      user,
      services,
    });

    //Remove LOA Statuses to just display Account statuses.
    for (let i in statusOptions) {
      statusOptions[i]['Name'] = statusOptions[i]['Status'];
    }

    let quickfilterModalValues = Object.assign({}, this.state[`${selectedGrid}_quickfilterModalValues`]);
    let setByCookie = quickfilterModalValues.Status && quickfilterModalValues.Status.length !== 0;
    let quickfilterValues = Object.assign({}, this.state[`${selectedGrid}_quickfilterValues`]);
    if (!setByCookie && !quickfilterModalValues.AccountID) {
      //No cookie, default these guys
      quickfilterModalValues.Status = [];
      quickfilterValues.Status = [];
    }
    statusOptions.map((opt) => {
      opt.name = opt.Name;
      return opt;
    });
    this.setState(
      {
        selectedQuickfilter: 'Status',
        quickfilterOptions: { ...this.state.quickfilterOptions, Status: statusOptions },
        quickfilterModalValues,
      },
      () => {
        this.handleSaveQuickfilterClick();
      }
    );
  }

  getTooltips = () => {
    return new Promise((resolve, reject) => {
      let { services, user } = this.props;
      AccountModels.getHelpTooltips({ services, user })
        .then((response) => {
          if (!response.error) {
            let helpInformation = response.body;
            if (helpInformation.value) {
              helpInformation.value.map((item) => {
                helpArray[item.HelpID] = item.Text;
                helpArray[item.HelpID.toUpperCase()] = item.Text;
                return {
                  HelpID: item['HelpID'],
                };
              });
              this.setState(
                {
                  helpInformation: helpArray,
                },
                () => {
                  resolve();
                }
              );
            } else {
              this.setState(
                {
                  helpInformation: null,
                },
                () => {
                  resolve();
                }
              );
            }
          }
        })
        .catch((err) => {
          // Silently do nothing if something happens with Help read
        });
    });
  };
  getTooltip = (helpId, defaultText = '') => {
    const { helpInformation } = this.state;
    if (helpInformation) {
      if (helpInformation[helpId]) {
        return helpInformation[helpId];
      }
    }

    return defaultText;
  };

  AuthorizationsGrid = (x) => {
    let location = x.routeProps.location;
    if (location) {
      if (location.state) {
        if (location.state.pendingGrid) {
          this.handleChangeGrid('Pending');
        }
      }
    }
  };

  refreshGrid = () => {
    this.getSearch();
  };

  async openAccountLinking(accountId = null, accountSID = null, rowData = { Authorization: '' }) {
    rowData['AuthorizationInput'] = rowData['Authorization'];
    this.setState({ accountId, accountSID, accountRowData: rowData }, () => {
      this.setState({ accountLinkingModalOpen: true });
    });
  }

  async fetchAccountData(accountId) {
    let showData = this.state.tokenFilter === false ? true : false;
    let { user, services } = this.props;
    this.setState({ viewAccountDetailsModalOpen: true, previewLoading: true });
    let accounts = await AccountModels.getAccountData({
      user,
      services,
      accountId,
      showData,
    });
    this.setAccountData(accounts[0]);
  }

  async setAccountData(data) {
    let { user, services } = this.props;
    let temp = {};
    temp.AccountID = data.AccountID;
    temp.AccountName = data.AccountName;
    temp['AccountName-token'] = data['AccountName-token'];
    temp.AccountTitle = data.AccountTitle;
    temp.AccountTypeID = { label: '', value: data.AccountTypeID };
    temp.ChangeDate = data.ChangeDate;
    temp.CustodianName = data.CUSTODIAN.Name;
    temp.CustodianID = data.CustodianID;
    temp.CustodianData = data.CUSTODIAN;
    temp.CustodianContact = data.CONTACT
      ? {
          label: data.CONTACT.NameAlias,
          value: data.CONTACT.ContactID,
          DetailID: data.CONTACT.CONTACT_DETAIL[0] ? data.CONTACT.CONTACT_DETAIL[0].DetailID : null,
          data: data.CONTACT.CONTACT_DETAIL[0] ? data.CONTACT.CONTACT_DETAIL[0] : null,
        }
      : null;
    temp.ContactID = data.ContactID;
    temp.ContactDetailID = data.ContactDetailID;
    temp.Currency1 = data.Currency1;
    temp.Fund = { label: '', value: data.Fund_UID };
    temp.Fund_UID = data.Fund_UID;
    temp.InvestorName = data.InvestorName;
    temp.InvestorID = data.InvestorID;
    temp.Status = data.Status;
    temp.StatusName = data.ACCOUNT_LOA_STATUS.Name;
    temp.AdvisorID = data.AdvisorID;
    temp.RelationshipID = data.RelationshipID;
    temp.ManagerType = data.ManagerType;
    temp.LOA = data.LOA;
    temp.AdvisorName = data.AdvisorName;
    temp.ClientName = data.ClientName;
    temp.Account_PartnerID = data.Account_PartnerID;
    temp.Account_TeamID = data.Account_TeamID;
    temp.Account_ClientID = data.Account_ClientID;
    temp.Account_InvestmentID = data.Account_InvestmentID;
    temp.Account_Custom1 = data.Account_Custom1;
    temp.Account_Custom2 = data.Account_Custom2;
    temp.Account_Custom3 = data.Account_Custom3;
    temp.Account_Custom4 = data.Account_Custom4;
    temp.Account_Related_Entity_UID = data.Account_Related_Entity_UID ? data.Account_Related_Entity_UID : '';

    // REMOVE SOFT DELETES FROM SIGNERS
    let signers = [];
    let validSigners = [];

    for (let i in data.ACCOUNT_SIGNER_LINK) {
      if (data.ACCOUNT_SIGNER_LINK[i].ChangeType !== 'D') {
        //Push to Signers
        signers.push(data.ACCOUNT_SIGNER_LINK[i]);
      }
    }
    for (let i in signers) {
      if (signers[i].CONTACT_DETAIL) {
        //Create Full Name
        signers[i].CONTACT_DETAIL.FullName = signers[i].CONTACT_DETAIL.FirstName + ' ' + signers[i].CONTACT_DETAIL.LastName;
      }
      validSigners.push(parseInt(i));
    }

    this.setValidSigners(validSigners);
    temp.ACCOUNT_SIGNER_LINK = signers;

    let custodianRelated = {};
    if (temp.Account_Related_Entity_UID) {
      custodianRelated = await AccountModels.getCustodianRelated({
        user,
        services,
        id: temp.Account_Related_Entity_UID,
      });
      if (custodianRelated[0]) {
        temp.CustodianRelatedName = custodianRelated[0].Name;
      }
    }

    this.setState({
      previewAccountDetailsData: temp,
      previewLoading: false,
    });
    return temp;
  }
  setValidSigners = (arr) => {
    this.setState({ validSigners: arr });
  };
  textCell = (cellInfo) => {
    let data = this.state[`${this.state.selectedGrid}_data`];
    var even_odd_css = '';
    cellInfo.viewIndex % 2 === 0 ? (even_odd_css = `custom-grid-input even-row`) : (even_odd_css = `custom-grid-input odd-row`);
    let title = data[cellInfo.index] ? data[cellInfo.index][cellInfo.column.id] : '';
    title = cellInfo.column.id === 'Accounts' ? 'Click to View Account(s)' : title;
    title = cellInfo.column.id === 'PrimarySigner' ? 'Click to View Signer(s)' : title;
    title = cellInfo.column.id === 'AccountSIDs' ? 'Click to View Account(s)' : title;
    let onClick = () => {
      if (cellInfo.column.id === 'Accounts') {
        let accountIds = data[cellInfo.index].AccountIds.replace(/\s/g, '');
        accountIds = data[cellInfo.index].AccountIds.split(',');
        let quickfilterModalValues = {
          Status: [],
          EnrolledDate: [moment(new Date()).format().substring(0, 10)],
          Custodian: [],
          Signer: [],
          AccountID: accountIds,
        };
        document.cookie = `${this.state.selectedGrid}_auth_filter=${JSON.stringify(quickfilterModalValues)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchText=${JSON.stringify('')};path=/;${globals.SAMESITECOOKIE}`;
        this.setState({ toMyEnrollments: true });
      } else if (cellInfo.column.id === 'AccountSIDs') {
        //Don't add onclick on Account SIDS col
      } else if (cellInfo.column.id === 'PrimarySigner') {
        this.setState({ editContactDetailId: data[cellInfo.index].DetailID }, () => {
          this.setState({ viewContactModalOpen: true });
        });
      } else if (this.state.selectedGrid === 'Active') {
        this.handlePreviewClick(data[cellInfo.index]);
      }
    };

    let limit = 10; // Controls the number of Account SIDs we will display before displaying an ellipsis
    let accountSIDs = [];
    let accountSIDsLimited = [];
    let accountSIDs_accountIDs = [];
    if (cellInfo.column.id === 'AccountSIDs') {
      if (data[cellInfo.index].AccountSIDs) {
        accountSIDs = data[cellInfo.index].AccountSIDs.split(',');
        accountSIDsLimited = accountSIDs.slice(0, limit).map((x) => x);
        accountSIDs_accountIDs = data[cellInfo.index].AccountIds.split(',');
      }
    }

    return (
      <div
        title={title}
        onClick={onClick}
        className={
          this.state.hoveredRowCol === cellInfo.index + cellInfo.column.id && (cellInfo.column.id === 'Accounts' || cellInfo.column.id === 'PrimarySigner')
            ? `clickable-row row-input`
            : 'row-input'
        }
        onMouseEnter={() => {
          this.setState({ hoveredRowCol: cellInfo.index + cellInfo.column.id });
        }}
        onMouseLeave={() => {
          this.setState({ hoveredRowCol: null });
        }}
      >
        {cellInfo.column.id === 'AccountSIDs' ? (
          <div className="new-line">
            {accountSIDs_accountIDs ? (
              accountSIDs_accountIDs.length <= 1 ? (
                accountSIDsLimited.map((sid, index) => {
                  let accountId = accountSIDs_accountIDs[index];
                  return (
                    <span>
                      <div
                        className={`${even_odd_css} row-input hover-underline`}
                        onClick={() => {
                          this.fetchAccountData(accountId);
                        }}
                      >
                        {sid}
                      </div>
                      {/* If the total number of accountSIDs > limit, then display ellipsis with title hover */}
                      {accountSIDsLimited.length === index + 1 && accountSIDs.length > limit && (
                        <div title={`Total of (${accountSIDs.length}) Account IDs.  Displaying the first ${limit}.`} className="authorizations-ellipsis">
                          <FontAwesome name="account-ids-ellipsis" className="fas fa-ellipsis-h authorizations-ellipsis-icon" />
                        </div>
                      )}
                    </span>
                  );
                })
              ) : (
                // Greater than 1 display Linked Accounts
                <div
                  className={`${even_odd_css} row-input hover-underline`}
                  onClick={() => {
                    let accountId = accountSIDs_accountIDs[0];
                    let accountSID = accountSIDs[0];
                    this.openAccountLinking(accountId, accountSID, data[cellInfo.index]);
                  }}
                >
                  Linked Accounts
                </div>
              )
            ) : null}
          </div>
        ) : cellInfo.column.id === 'LinkedAccounts' ? (
          <input
            id={cellInfo.index + cellInfo.column.id}
            className={even_odd_css}
            key={cellInfo.index + cellInfo.column.id}
            readOnly
            value={data[cellInfo.index] ? data[cellInfo.index]['Accounts'] + ' of ' + data[cellInfo.index]['AccountLimit'] : null}
          />
        ) : (
          <input
            id={cellInfo.index + cellInfo.column.id}
            className={even_odd_css}
            key={cellInfo.index + cellInfo.column.id}
            readOnly
            value={data[cellInfo.index] ? data[cellInfo.index][cellInfo.column.id] : null}
          />
        )}
      </div>
    );
  };
  checkboxCell = (cellInfo) => {
    let data = this.state[`${this.state.selectedGrid}_data`];
    let value = this.state.selectedGrid === 'Active' ? 'LoaID' : 'Authorization';
    return (
      <div className="grid-checkbox">
        <input
          id={cellInfo.index + cellInfo.column.id}
          type="checkbox"
          name="account"
          className={cellInfo.viewIndex % 2 === 0 ? 'custom-grid-input even-row' : 'custom-grid-input odd-row'}
          key={cellInfo.index + cellInfo.column.id}
          disabled={false}
          onChange={(e) => {
            this.handleRowSelect(data[cellInfo.index][value], data[cellInfo.index]);
          }}
          checked={this.state[`${this.state.selectedGrid}_selectedRows`].includes(data[cellInfo.index][value])}
        />
      </div>
    );
  };
  eSignatureCell = ({ value }) => {
    let text = value ? 'Yes' : 'No';

    return <div>{text}</div>;
  };
  handleRowSelect = (pk, val) => {
    let { selectedGrid } = this.state;
    let temp = this.state[`${selectedGrid}_selectedRows`];
    let tempVals = this.state[`${selectedGrid}_selectedRowsValues`];

    temp.includes(pk) ? temp.splice(temp.indexOf(pk), 1) : temp.push(pk);
    tempVals[pk] ? delete tempVals[pk] : (tempVals[pk] = val);
    this.setState({ [`${selectedGrid}_selectedRows`]: temp, [`${selectedGrid}_selectedRowsValues`]: tempVals });
  };

  selectAll = (e) => {
    let { selectedGrid } = this.state;
    let value = selectedGrid === 'Active' ? 'LoaID' : 'Authorization';
    let data = this.state[`${selectedGrid}_data`];
    let rows = this.state[`${selectedGrid}_selectedRows`];
    let rowsValues = this.state[`${selectedGrid}_selectedRowsValues`];
    for (let i in data) {
      if (!this.state[`${selectedGrid}_selectAll`]) {
        //Want to select all on screen
        if (!rows.includes(data[i][value])) {
          rows.push(data[i][value]);
          rowsValues[data[i][value]] = data[i];
        }
      } else {
        //Want to remove all on screen
        if (rows.includes(data[i][value])) {
          rows.splice(rows.indexOf(data[i][value]), 1);
          delete rowsValues[data[i][value]];
        }
      }
    }
    this.setState({ [`${selectedGrid}_selectAll`]: !this.state[`${selectedGrid}_selectAll`], [`${selectedGrid}_selectedRows`]: rows, [`${selectedGrid}_selectedRowsValues`]: rowsValues });
  };

  handlePreviewClick = (rowData) => {
    this.setState({
      viewLoaModalOpen: true,
      selectedRow: rowData,
    });
  };

  onSearchChange = (e) => {
    document.cookie = `${this.state.selectedGrid}_filter=${JSON.stringify(e.target.value)};path=/;${globals.SAMESITECOOKIE}`;
    this.setState({ [`${this.state.selectedGrid}_LoaSearch`]: e.target.value, [`${this.state.selectedGrid}_filter`]: e.target.value, [`${this.state.selectedGrid}_page`]: 0 }, () => {
      this.getSearch();
    });
  };
  checkSelectAll = (modalValues) => {
    const { quickfilterOptions } = this.state;
    let temp = Object.assign([], modalValues);
    let hasSelectAll = false;
    for (let i in temp) {
      if (temp[i] === 'SelectAll') {
        hasSelectAll++;
      }
    }

    if (quickfilterOptions.Status.length === temp.length && !hasSelectAll) {
      temp.push('SelectAll');
    } else if (quickfilterOptions.Status.length === temp.length && hasSelectAll) {
      for (let i in temp) {
        if (temp[i] === 'SelectAll') {
          temp.splice(i, 1);
        }
      }
    }
    return temp;
  };
  handleQuickfilterCheckboxSelect = (name, value) => {
    let { quickfilterOptions, selectedGrid } = this.state;
    let temp = Object.assign([], this.state[`${selectedGrid}_quickfilterModalValues`][this.state.selectedQuickfilter]);
    if (name === 'Account') {
      if (temp.includes(name) && value === 'clear') {
        temp.splice(temp.indexOf(name), 1);
        this.setState({ [`${selectedGrid}_quickfilterModalValues`]: { ...this.state[`${selectedGrid}_quickfilterModalValues`], [this.state.selectedQuickfilter]: temp } }, () => {
          this.handleSaveQuickfilterClick();
        });
      } else if (value !== 'clear') {
        temp.push(name);
      }
    } else {
      if (temp.includes(name)) {
        temp.splice(temp.indexOf(name), 1);
      } else {
        temp.push(name);
      }
    }
    if (name === 'SelectAll' && temp.includes(name)) {
      for (let i in this.state.quickfilterOptions[this.state.selectedQuickfilter]) {
        if (!temp.includes(this.state.quickfilterOptions[this.state.selectedQuickfilter][i].name)) {
          temp.push(this.state.quickfilterOptions[this.state.selectedQuickfilter][i].name);
        }
      }
    } else if (name === 'SelectAll' && !temp.includes(name)) {
      temp = [];
    } else if (name !== 'SelectAll' && this.state.selectedQuickfilter === 'Status') {
      temp = this.checkSelectAll(temp);
    }
    if (name === 'ClearAll') {
      temp = [];
      this.setState({ quickfilterOptions: { ...quickfilterOptions, Custodian: temp }, custodianSearchLoading: false, custodianQuickfilterSearch: '' });
    }

    this.setState({ [`${selectedGrid}_quickfilterModalValues`]: { ...this.state[`${selectedGrid}_quickfilterModalValues`], [this.state.selectedQuickfilter]: temp } });
  };

  handleQuickFilter = (name) => {
    let { selectedGrid, quickfilterOptions, selectedQuickfilter } = this.state;
    if (name === 'clear') {
      this.setState(
        {
          [`${selectedGrid}_quickfilters`]: [],
          [`${selectedGrid}_quickfilterValues`]: {
            Status: [],
            EnrolledDate: [],
            Custodian: [],
            Signer: [],
            RelationshipId: [],
            AdvisorId: [],
          },
          [`${selectedGrid}_quickfilterModalValues`]: {
            Status: [],
            EnrolledDate: [moment(Date.now()), moment(Date.now())],
            Custodian: [],
            Signer: [],
            RelationshipId: [],
            AdvisorId: [],
          },
          quickfilterOptions: {
            ...this.state.quickfilterOptions,
            Custodian: [],
            Signer: [],
            RelationshipId: [],
            AdvisorId: [],
          },

          custodianQuickfilterSearch: '',
          selectedQuickfilter: null,
          accountFilter: '',
        },
        () => {
          this.handleSaveQuickfilterClick();
        }
      );
    } else {
      this.setState({ selectedQuickfilter: name, quickFilterModalOpen: true, [`${selectedGrid}_${selectedQuickfilter}`]: '', quickfilterOptions: { ...quickfilterOptions, Signer: [] } });
    }
  };
  handleSaveQuickfilterClick = async (isDate, passGrid = '') => {
    let { searchTokens, relationshipIdTokens, advisorIdTokens, selectedGrid } = this.state;

    // If we pass Active or Pending in, override the selectedGrid state
    if (passGrid) {
      selectedGrid = passGrid;
    }

    if (isDate) {
      //If save qfmodal, set values to modalvalues
      this.setState(
        {
          dateSaved: true,
        },
        () => {
          let { dateSaved } = this.state;
        }
      );
    }

    //If save qfmodal, set values to modalvalues
    this.setState(
      {
        [`${selectedGrid}_quickfilterValues`]: this.state[`${selectedGrid}_quickfilterModalValues`],
        showSearchLengthNote: false,
      },
      () => {
        let { [`${selectedGrid}_quickfilters`]: quickfilters, [`${selectedGrid}_quickfilterValues`]: quickfilterValues, dateFromSelected, dateToSelected } = this.state;
        //Handles Green vs Grey

        for (let i in quickfilterValues) {
          if (i !== 'EnrolledDate') {
            if (quickfilterValues[i] && quickfilterValues[i].length > 0 && quickfilters.indexOf(i) === -1) {
              quickfilters.push(i);
            } else if (quickfilters.indexOf(i) !== -1 && quickfilterValues[i] && quickfilterValues[i].length === 0) {
              quickfilters.splice(quickfilters.indexOf(i), 1);
            } else if (quickfilters.indexOf(i) !== -1 && quickfilterValues[i] === '') {
              quickfilters.splice(quickfilters.indexOf(i), 1);
            }
          } else {
            dateFromSelected = true;
            dateToSelected = true;
            if (dateFromSelected && dateToSelected && !quickfilters.includes(i) && this.state.dateSaved) {
              quickfilters.push(i);
            } else if ((!dateFromSelected || !dateToSelected) && quickfilters.includes(i)) {
              quickfilters.splice(quickfilters.indexOf(i), 1);
            }
          }
        }

        this.setState({ quickfilters });

        let wholeString = ``;
        let filter = '';
        let val, condition;

        for (let i in quickfilterValues) {
          let localString = '';
          let list = '';
          for (let j in quickfilterValues[i]) {
            let query;
            let searchBy = i;

            if ((quickfilters.includes(i) || i === 'AccountID') && i !== 'RelationshipId' && i !== 'AdvisorId') {
              if (i === 'AccountID') {
                this.setState({ quickfilters: [] });
              }

              isDate = i === 'EnrolledDate';
              let isValid = moment(quickfilterValues[i][j]).isValid();
              val = !Guid.isGuid(quickfilterValues[i][j]) ? `'${quickfilterValues[i][j]}'` : quickfilterValues[i][j];
              val = isDate ? moment(quickfilterValues[i][j]).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z' : val;
              val = val.replace(',', '%2C');
              val = val.replace("'", '%27');
              val = val.replace('&', '%26');
              condition = isDate ? 'le' : 'eq';

              if (dateFromSelected && dateToSelected && i === 'EnrolledDate' && isValid) {
                let dateTo = quickfilterValues[i][parseInt(j) + 1] ? quickfilterValues[i][parseInt(j) + 1] : Date.now();
                dateTo = !Guid.isGuid(quickfilterValues[i][parseInt(j) + 1]) ? `'${quickfilterValues[i][parseInt(j) + 1]}'` : quickfilterValues[i][parseInt(j) + 1];
                dateTo =
                  moment(quickfilterValues[i][parseInt(j) + 1])
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                query = `AccountCreateDate ge ${val} and AccountCreateDate le ${dateTo}`;
              }

              if (i === 'Custodian') {
                searchBy = 'CustodianName';
              }

              if (i === 'Account') {
                for (let k in this.state.accountTokenSearch) {
                  if (list.length === 0) {
                    list = `'${this.state.accountTokenSearch[k].Token}'`;
                  } else {
                    list = list + `, '${this.state.accountTokenSearch[k].Token}'`;
                  }
                }
                localString = `AccountName in (${list})`;
                break;
              } else if (i === 'Signer') {
                localString = (localString ? `${localString} or ` : '') + `Contains(PrimarySigner, ${val})`;
              } else if (i === 'AccountSID') {
                localString = (localString ? `${localString} or ` : '') + `Contains(AccountSIDs, ${val})`;
              } else {
                if (list.length === 0) {
                  if (i === 'AccountID') {
                    list = `'${val}'`;
                  } else {
                    list = `${val}`;
                  }
                } else {
                  if (i === 'AccountID') {
                    list = list + `, '${val}'`;
                  } else {
                    list = list + `, ${val}`;
                  }
                }

                if (query && (!localString || localString.length === 0)) {
                  localString = query;
                } else if (query) {
                  localString = localString + ` or ${query}`;
                } else {
                  searchBy = searchBy === 'Status' ? 'Status' : searchBy;

                  localString = `${searchBy} in (${list})`;
                }
                if (i === 'EnrolledDate') {
                  break; //Already handled the enrolled date
                }
              }
            }
          }
          localString = `(${localString})`;
          if (localString.length > 2) {
            if (filter.length === 0) {
              filter = localString;
            } else {
              filter = filter + ` and ${localString}`;
            }
          }
        }

        if (this.props.crmData && this.props.crmData.firmRelationshipIDToken && this.props.crmData.firmRelationshipIDToken.length > 0) {
          let tokenList = '';
          for (let i in this.props.crmData.firmRelationshipIDToken) {
            if (tokenList.length === 0) {
              tokenList = `'${this.props.crmData.firmRelationshipIDToken[i]}'`;
            } else {
              tokenList = tokenList + `, '${this.props.crmData.firmRelationshipIDToken[i]}'`;
            }
          }

          if (filter.length === 0) {
            filter = filter + `RelationshipID in (${tokenList})`;
          } else {
            filter = filter + ` and RelationshipID in (${tokenList})`;
          }
        }

        if (this.props.crmData && this.props.crmData.teamIDToken && this.props.crmData.teamIDToken.length > 0) {
          let tokenList = '';
          for (let i in this.props.crmData.teamIDToken) {
            if (tokenList.length === 0) {
              tokenList = `'${this.props.crmData.teamIDToken[i]}'`;
            } else {
              tokenList = tokenList + `, '${this.props.crmData.teamIDToken[i]}'`;
            }
          }

          if (filter.length === 0) {
            filter = filter + `TeamID in (${tokenList})`;
          } else {
            filter = filter + ` and TeamID in (${tokenList})`;
          }
        }

        this.setState({ quickFilterModalOpen: false, [`${selectedGrid}_quickfilter`]: filter, loading: true, page: 0 }, () => {
          this[`search${this.state.selectedGrid}LOAs`]();
        });

        let tmpQuickfilters = Object.assign({}, quickfilters);
        let tmpQuickfilterValues = Object.assign({}, quickfilterValues);
        delete tmpQuickfilterValues.AccountID;
        this.setState({ [`${selectedGrid}_quickfilterModalValues`]: tmpQuickfilterValues, [`${selectedGrid}_quickfilterValues`]: tmpQuickfilterValues });

        document.cookie = `${selectedGrid}_auth_quickfilter=${JSON.stringify(filter)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `${selectedGrid}_auth_filter=${JSON.stringify(tmpQuickfilterValues)};path=/;${globals.SAMESITECOOKIE}`;

        document.cookie = `${selectedGrid}_auth_filter_selected=${JSON.stringify(tmpQuickfilters)};path=/;${globals.SAMESITECOOKIE}`;
      }
    );
  };
  searchActiveLOAs = debounce(async () => {
    let { user, services } = this.props;
    let { selectedGrid } = this.state;
    let {
      tokenFilter,
      [`Active_page`]: page,
      [`Active_pagesize`]: pagesize,
      [`Active_orderby`]: orderby,
      [`Active_quickfilters`]: oquickfilter, //Pending_quickfilters is a list of filters selected.  We use this to determine if we display customNoData message
      [`Active_filter`]: ofilter,
      Active_customNoData,
    } = this.state;
    let filter = this.state[`Active_filter`];
    let quickFilter = this.state[`Active_quickfilter`];
    if (filter) {
      filter = `&$filter=(contains(Authorization,'${encodeURIComponent(filter)}'))`;
    }
    if (quickFilter && filter) {
      filter = `${filter} and ${quickFilter}`;
    } else if (quickFilter) {
      filter = `&$filter=${quickFilter}`;
    }

    let relationshipID = null;
    let advisorID = null;
    if (this.props.crmData) {
      if (this.props.crmData.firmRelationshipIDToken) {
        relationshipID = this.props.crmData.firmRelationshipIDToken.join(',');
      }
      if (this.props.crmData.teamIDToken) {
        advisorID = this.props.crmData.teamIDToken.join(',');
      }
    }
    let body = {
      RelationshipID: relationshipID,
      AdvisorID: advisorID,
    };

    this.setState({ [`Active_loading`]: true });
    let { values, count } = await AuthorizationModels.getActiveLoas({
      user,
      services,
      filter,
      body,
      tokenFilter,
      page,
      pagesize,
      orderby,
    });

    values.map((loa) => {
      loa.LastChangeDate = loa.LastChangeDate ? getCurrentTimeString(new Date(loa.LastChangeDate)) : null;
    });

    Active_customNoData = oquickfilter.length === 0 && ofilter.length === 0 && !count ? true : false;

    this.setState({
      Active_customNoData,
      [`Active_data`]: values,
      [`Active_loading`]: false,
      [`Active_rowCount`]: count,
      [`Active_selectAll`]: false,
      loading: false,
    });
  }, 1000);

  searchPendingLOAs = debounce(async () => {
    let { user, services } = this.props;

    let {
      tokenFilter,
      [`Pending_page`]: page,
      [`Pending_pagesize`]: pagesize,
      [`Pending_orderby`]: orderby,
      [`Pending_quickfilters`]: oquickfilter, //Pending_quickfilters is a list of filters selected.  We use this to determine if we display customNoData message
      [`Pending_filter`]: ofilter,
    } = this.state;
    let filter = this.state[`Pending_filter`];
    let quickFilter = this.state[`Pending_quickfilter`];
    if (filter) {
      filter = `&$filter=(contains(Authorization,'${encodeURIComponent(filter)}'))`;
    }

    if (quickFilter && filter) {
      filter = `${filter} and ${quickFilter}`;
    } else if (quickFilter) {
      filter = `&$filter=${quickFilter}`;
    }
    let relationshipID = null;
    let advisorID = null;
    if (this.props.crmData) {
      if (this.props.crmData.firmRelationshipIDToken) {
        relationshipID = this.props.crmData.firmRelationshipIDToken.join(',');
      }
      if (this.props.crmData.teamIDToken) {
        advisorID = this.props.crmData.teamIDToken.join(',');
      }
    }

    let body = {
      RelationshipID: relationshipID,
      AdvisorID: advisorID,
    };

    this.setState({ [`Pending_loading`]: true });
    let { values, count } = await AuthorizationModels.getPendingLoas({
      user,
      services,
      filter,
      body,
      tokenFilter,
      page,
      pagesize,
      orderby,
    });
    let Pending_customNoData = oquickfilter.length === 0 && ofilter.length === 0 && !count ? true : false;
    this.setState({
      Pending_customNoData,
      [`Pending_data`]: values,
      [`Pending_loading`]: false,
      [`Pending_rowCount`]: count,
      [`Pending_selectAll`]: false,
      loading: false,
    });
  }, 1000);

  onSortedChange = (obj) => {
    let temp = this.state[`${this.state.selectedGrid}_expand`];
    let tempBaseOrderby = this.state[`${this.state.selectedGrid}_orderby`];
    let desc = obj[0].desc ? ' desc' : '';
    switch (obj[0].id) {
      case 'custodian':
        temp = `&$expand=LOA_ACCOUNT_LINK($expand=LOA),ACCOUNT_LOA_STATUS,ACCOUNT_SIGNER_LINK($expand=CONTACT_DETAIL,SIGNER_CAPACITY),CUSTODIAN&$orderby=CUSTODIAN/Name${desc}`;
        break;
      case 'LinkedAccounts':
        tempBaseOrderby = `&$orderby=Accounts${desc}`;
        break;
      case 'AccountSIDs':
        tempBaseOrderby = `&$orderby=Accounts${desc},AccountSIDs${desc}`;
        break;
      default:
        tempBaseOrderby = `&$orderby=${obj[0].id}${desc}`;
        break;
    }
    this.setState(
      {
        [`${this.state.selectedGrid}_expand`]: temp,
        [`${this.state.selectedGrid}_orderby`]: tempBaseOrderby,
      },
      () => {
        this.getSearch();
      }
    );
  };

  onViewAuthorizationsClick = () => {
    this.setState({ toAuthorizations: true });
  };

  onBatchJobsClick = () => {
    this.setState({ toBatchJobs: true });
  };

  onViewAccountsClick = () => {
    this.setState({ toMyEnrollments: true });
  };
  onEnrollClick = () => {
    this.props.setSelectedAccount(null, null);
    this.setState({ toEnroll: true });
  };

  onPageSizeChange = (size) => {
    let { selectedGrid } = this.state;
    document.cookie = `table_pagesize=${JSON.stringify(size)};path=/;${globals.SAMESITECOOKIE}`;
    this.setState(
      {
        [`Active_pagesize`]: size,
        [`Active_page`]: 0,
        [`Pending_pagesize`]: size,
        [`Pending_page`]: 0,
        loading: true,
      },
      () => {
        this.getSearch('reload');
      }
    );
  };

  onPageChange = (index) => {
    let { selectedGrid } = this.state;

    this.setState(
      {
        [`${selectedGrid}_page`]: index,
        loading: true,
      },
      () => {
        this.getSearch();
      }
    );
  };

  getSearch = (type) => {
    if (type === 'reload') {
      this.searchActiveLOAs();
      this.searchPendingLOAs();
    } else {
      this[`search${this.state.selectedGrid}LOAs`]();
    }
  };

  handleChangeGrid = (selectedGrid) => {
    this.setState({ selectedGrid });
  };

  handleBulkUpdateClick = (button) => {
    this.checkForSelected(button);
  };

  checkForSelected = (button) => {
    let { selectedGrid } = this.state;
    let rows = this.state[`${selectedGrid}_selectedRows`];
    if (rows.length === 0) {
      confirmAlert({
        title: `Sorry`,
        childrenElement: () => <div>Please select at least one item first.</div>,
        buttons: [{ label: <div id="continue-confirm-button">Continue</div>, onClick: () => {} }],
      });
    } else {
      switch (button) {
        case 'generate-send-button':
        case 'generate-download-button':
          this.showGenerateModal(button);
          break;
        case 'download-loa-button':
        case 'cancel-loa-button':
          this.showActionModal(button);
        case 'send-loa-button':
          this.showActionModal(button);
          break;
      }
    }
  };

  showGenerateModal = (button) => {
    let includeInGenerate = Object.assign([], this.state.Pending_selectedRows);
    let generateButtonText = '';
    let generateFunction;
    if (button === 'generate-send-button') {
      generateFunction = this.generateAndSend.bind(this);
      generateButtonText = 'Generate and Send';
    } else if (button === 'generate-download-button') {
      generateFunction = this.generateAndDownload.bind(this);
      generateButtonText = 'Generate and Download';
    }
    this.setState({ generateModalOpen: true, includeInGenerate, generateButtonText, generateFunction });
  };

  showActionModal = (button) => {
    let includeInAction = Object.assign([], this.state.Active_selectedRows);
    let actionButtonText = '';
    let actionFunction;
    if (button === 'download-loa-button') {
      actionFunction = this.downloadLOA.bind(this);
      actionButtonText = 'Download';
    } else if (button === 'cancel-loa-button') {
      actionFunction = this.cancelActiveLOAClick.bind(this);
      actionButtonText = 'Cancel Authorizations';
    } else if (button === 'send-loa-button') {
      actionFunction = this.sendActiveLOAClick.bind(this);
      actionButtonText = 'Send Authorizations';
    }
    this.setState({ actionModalOpen: true, includeInAction, actionButtonText, actionFunction });
  };

  async cancelLOA(loaIds) {
    let { user, services } = this.props;
    await AuthorizationModels.cancelLoa({
      user,
      services,
      loaIds,
    });
    return;
  }

  closePreviewAndCancelLoas = () => {
    this.setState({ previewLoaModalOpen: false });
  };

  closeGenerate = () => {
    this.searchPendingLOAs();
    this.searchActiveLOAs();
    this.setState({ generateModalOpen: false, includeInGenerate: [], Pending_selectAll: false, Pending_selectedRows: [], Pending_selectedRowsValues: {} });
  };

  updateIncludeInGenerate = (auth) => {
    let { includeInGenerate } = this.state;
    if (includeInGenerate.includes(auth)) {
      includeInGenerate.splice(includeInGenerate.indexOf(auth), 1);
    } else {
      includeInGenerate.push(auth);
    }
    this.setState({ includeInGenerate });
  };

  onPreviewClick = (obj) => {
    //on generate we should clear previewed loas array
    var ie = navigator.userAgent.match(/.NET/g);
    var edge = navigator.userAgent.match(/Edge/g);
    if (!ie && !edge) {
      this.setState({ previewLoaModalOpen: true, previewLoaLoading: true });
      this.loadPreview(obj.AccountIds.replace(/\s/g, '')).then(() => {
        this.setState({ previewLoaLoading: false });
      });
    } else {
      this.setState({ generateLoading: true });
      this.loadPreview(obj.AccountIds.replace(/\s/g, '')).then(() => {
        this.setState({ generateLoading: false });
      });
    }
  };

  async loadPreview(accountIds) {
    let { user, services } = this.props;
    let result = await AuthorizationModels.generateLoas({
      user,
      services,
      accountIds,
      mode: 'PREVIEW',
      showMessage: 'ON_FAIL',
    });
    if (result && !result.error) {
      var responseBody = JSON.parse(result.body);
      if (responseBody[0] && responseBody[0].content) {
        let content = responseBody[0].content;
        let id = responseBody[0].LOAID;
        var base = atob(content);
        var input = str2ab(base);
        var blob = new Blob([input], { type: 'application/pdf' });
        if (blob) {
          var ie = navigator.userAgent.match(/.NET/g);
          var edge = navigator.userAgent.match(/Edge/g);
          var fileURL = window.URL.createObjectURL(blob);
          if (ie || edge) {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, 'LOAPreview');
            }
          } else {
            this.setState({ loaFileData: fileURL, loaFileContent: content });
            return id;
          }
        } else {
          this.setState({ loaFileData: 'Error Previewing LOA', loadSpinner: false });
          validationManager.toastMessage(false, 'Failed to Load!');
          return id;
        }
      }
    } else {
      this.setState({ loaFileData: 'Error Previewing LOA', loadSpinner: false });
      validationManager.toastMessage(false, 'Failed to Load!');
      return null;
    }
  }
  renderPreviewLOA = () => {
    return (
      <div>
        <div className="account-title"></div>
        <div className="preview-wrapper">
          {this.state.loaFileData ? (
            <object className="loa-preview-object" data={this.state.loaFileData} type="application/pdf">
              <embed src={this.state.loaFileData} type="application/pdf" />
            </object>
          ) : (
            <div className="faux-loa"></div>
          )}
        </div>
      </div>
    );
  };
  async generateAndSend(passedAccountIds = null) {
    let { user, services } = this.props;
    this.setState({ generateLoading: true });
    let { includeInGenerate, Pending_selectedRowsValues } = this.state;
    let accountIds = '';
    for (let i in includeInGenerate) {
      accountIds = accountIds === '' ? Pending_selectedRowsValues[includeInGenerate[i]].AccountIds : accountIds + ',' + Pending_selectedRowsValues[includeInGenerate[i]].AccountIds;
    }
    if (passedAccountIds) {
      accountIds = passedAccountIds;
    }
    let result = await AuthorizationModels.generateLoas({
      user,
      services,
      accountIds,
      mode: 'ELECTRONIC',
      showMessage: 'ALWAYS',
      successMessage: `Successfully sent ${includeInGenerate && includeInGenerate.length > 1 ? 'LOAs' : 'LOA'}`,
      failMessage: `Failed to send ${includeInGenerate && includeInGenerate.length > 1 ? 'LOAs' : 'LOA'}`,
    });
    this.getSearch();
    if (result && !result.error) {
      this.setState(
        {
          Pending_selectAll: false,
          Pending_selected: [],
          Pending_selectedRows: [],
          Pending_selectedRowsValues: {},
          includeInGenerate: [],
          generateLoading: false,
        },
        () => {
          this.closeGenerate();
          this.closeModal();
        }
      );
    } else {
      this.setState({
        generateLoading: false,
      });
    }
  }

  async generateAndDownload(passedAccountIds = null) {
    let { user, services } = this.props;
    this.setState({ generateLoading: true });
    let { includeInGenerate, Pending_selectedRowsValues } = this.state;

    let accountIds = '';
    for (let i in includeInGenerate) {
      accountIds = accountIds === '' ? Pending_selectedRowsValues[includeInGenerate[i]].AccountIds : accountIds + ',' + Pending_selectedRowsValues[includeInGenerate[i]].AccountIds;
    }

    if (passedAccountIds) {
      accountIds = passedAccountIds;
    }
    let result = await AuthorizationModels.generateLoas({
      user,
      services,
      accountIds,
      mode: 'PRINT',
      showMessage: 'ALWAYS',
      successMessage: `Successfully printed ${includeInGenerate && includeInGenerate.length > 1 ? 'LOAs' : 'LOA'}`,
      failMessage: `Failed to print ${includeInGenerate && includeInGenerate.length > 1 ? 'LOAs' : 'LOA'}`,
    });
    if (result && !result.error) {
      var responseBody = JSON.parse(result.body);
      this.getSearch();
      for (let i in responseBody) {
        let content = responseBody[i].content;
        let loaId = responseBody[i].LOAID;
        var base = atob(content);
        var input = str2ab(base);
        var blob = new Blob([input], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `LOA_${parseInt(i) + 1}.pdf`);
        this.setState(
          {
            Pending_selectAll: false,
            Pending_selected: [],
            Pending_selectedRows: [],
            Pending_selectedRowsValues: {},
            includeInGenerate: [],
            generateLoading: false,
          },
          () => {
            this.closeGenerate();
            this.closeModal();
          }
        );
      }
    } else {
      this.setState({
        generateLoading: false,
      });
    }
  }

  async downloadLOA() {
    let { user, services } = this.props;
    let { includeInAction } = this.state;
    this.setState({ actionLoading: true });
    let result = await AuthorizationModels.getLoaDocument({
      user,
      services,
      loaIds: includeInAction,
    });
    if (result.body.value.length > 0) {
      let loas = result.body.value;
      for (let i in loas) {
        let base = atob(loas[i].FileData);
        let input = str2ab(base);
        let blob = new Blob([input], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `${loas[i].FileName}.pdf`);
      }
      this.setState({ actionLoading: false });
      this.closeActionModal();
    } else {
      this.setState({ actionLoading: false, actionError: 'Sorry, we could not find the LOA Document' });
    }
  }

  async cancelActiveLOAClick() {
    let loaIds = this.state.includeInAction;
    let { user, services } = this.props;
    let defaultMessageBeg = 'Are you sure you would like to cancel the selected';
    let defaultMessage = loaIds.length === 1 ? `${defaultMessageBeg} LOA?` : `${defaultMessageBeg} (${loaIds.length}) LOAs?`;
    let message = this.getTooltip('Enrollment.Authorizations.Cancel.Confirm', defaultMessage);
    //warning
    confirmAlert({
      childrenElement: () => {
        return (
          <div className="unsaved-changes-div">
            <div>{message}</div>
          </div>
        );
      },
      buttons: [
        { label: <div id="cancel-confirm-button">Cancel</div>, onClick: () => {} },
        {
          label: <div id="continue-confirm-button">Yes, I'm Sure</div>,
          id: 'continue-confirm-button',
          onClick: async () => {
            this.setState({ actionLoading: true });
            await AuthorizationModels.cancelLoas({
              user,
              services,
              loaIds,
            });
            this.setState({ actionLoading: false });
            this.closeActionModal();
          },
        },
      ],
    });
  }

  async sendActiveLOAClick() {
    this.setState({ actionLoading: true });
    let loaIds = this.state.Active_selectedRows;

    let { user, services } = this.props;
    await AccountModels.sendLoas({
      user,
      services,
      loaids: loaIds,
    });
    this.setState({ actionLoading: false });
    this.closeActionModal();
  }

  updateIncludeInAction = (loaId, remove) => {
    let { includeInAction } = this.state;
    if (includeInAction.includes(loaId)) {
      includeInAction.splice(includeInAction.indexOf(loaId), 1);
    } else {
      includeInAction.push(loaId);
    }
    this.setState({ includeInAction });
  };
  closeActionModal = () => {
    this.searchPendingLOAs();
    this.searchActiveLOAs();
    this.setState({ actionModalOpen: false, actionError: false, includeInAction: [], Active_selectAll: false, Active_selectedRows: [], Active_selectedRowsValues: {} });
  };

  closeModal = () => {
    this.setState({
      viewLoaModalOpen: false,
      generateModalOpen: false,
      actionModalOpen: false,
      previewLoaModalOpen: false,
      viewContactModalOpen: false,
      Pending_selectAll: false,
      Pending_selected: [],
      Pending_selectedRows: [],
      Pending_selectedRowsValues: {},
      includeInGenerate: [],
      generateLoading: false,
      quickFilterModalOpen: false,
      accountLinkingModalOpen: false,
      accountData: null,
      accountId: null,
      accountSID: null,
      accountRowData: null,
    });
  };

  handlePages = (pages) => {
    return pages === 0 ? 1 : pages; // DEV-876 Dont let pages = 0, default to 1 or issues with jumpto occurs
  };
  setTokenFilter = () => {
    let currentValue = this.state.tokenFilter;
    this.setState({ tokenFilter: !currentValue }, () => {
      document.cookie = `accounts_tokenFilter=${JSON.stringify(!currentValue)};path=/;${globals.SAMESITECOOKIE}`;
      this.getSearch();
    });
  };
  getModalStyle = () => {
    let { selectedQuickfilter } = this.state;
    switch (selectedQuickfilter) {
      case 'Custodian':
        return largeQuickfilterModalStyle;
      case 'AccountSID':
        return dateQuickfilterModalStyle;

      default:
        return quickfilterModalStyle;
    }
  };
  getQuickfilterTitle = () => {
    const { selectedQuickfilter } = this.state;
    switch (selectedQuickfilter) {
      case 'Status':
        return 'Status';
      case 'Signer':
        return 'Primary Signer';
      case 'AccountSID':
        return 'Account ID';
      default:
        return selectedQuickfilter;
    }
  };

  isValidEsignature = () => {
    let selectedRows = this.state[`${this.state.selectedGrid}_selectedRows`];
    let data = this.state[`${this.state.selectedGrid}_data`];
    let selectedData = data.filter((item) => selectedRows.includes(item['Authorization']));
    let hasEsignatureFlag = selectedData.filter((selected) => !selected['EsignatureFlag']).length > 0;

    return selectedRows.length ? !hasEsignatureFlag : true;
  };

  handleSignerEdit = (detailId) => {
    this.setState({ editContactDetailId: detailId }, () => {
      this.setState({ viewContactModalOpen: true });
    });
  };

  setParentRefresh = () => {
    this.setState({ refresh: false });
  };
  setAnchorEl = (target) => {
    this.setState({ anchorEl: target, open: Boolean(target) });
  };
  handleClick = (event) => {
    this.setAnchorEl(event.currentTarget);
  };
  handleClose = () => {
    this.setAnchorEl(null);
  };
  handleActionClick = (e) => {
    this.handleBulkUpdateClick(e.target.id);
    this.handleClose(e);
  };
  render() {
    let {
      [`${this.state.selectedGrid}_quickfilters`]: quickfilters,
      [`${this.state.selectedGrid}_quickfilterModalValues`]: quickfilterModalValues,
      selectedGrid,
      enrollPrefixLabel,
      accountsButtonLabel,
      enrollAccountsNewButton,
      enrollHomeSection,
      enhancedNavigation,
      actionButtonText,
      tokenFilter,
      enrollSignersEdit,
      accountLinkingModalOpen,
      accountData,
      accountId,
      accountSID,
      accountRowData,
      refresh,
      anchorEl,
      open,
      [`${this.state.selectedGrid}_customNoData`]: customNoData,
      selectedQuickfilter,
    } = this.state;
    const { services, user } = this.props;
    let path = getCookie('path');
    let isHome = checkIfHome(enrollHomeSection); // We made the home icon and redirect after account creation a policy.  This tells us if we are on the home screen.

    let enrollNewButton = enrollPrefixLabel ? `${enrollPrefixLabel} Account` : 'Account';
    enrollNewButton = enrollAccountsNewButton ? enrollAccountsNewButton : enrollNewButton; // Enhanced Navigation - UI.Enrollment.Enroll.Accounts.New.Button.Label

    return (
      <div className="myenrollments-wrapper">
        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`download-loas`} place="top" type="info" effect="solid" className="tooltip_custom">
          <p>{this.getTooltip('Enrollment.Authorizations.Download', 'Download selected LOA(s)')}</p>
        </ReactTooltip>
        <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id={`cancel-pending-loas`} place="top" type="info" effect="solid" className="tooltip_custom">
          <p>{this.getTooltip('Enrollment.Authorizations.Cancel', 'Cancel Selected Pending LOA(s)')}</p>
        </ReactTooltip>
        {accountLinkingModalOpen && (
          <AccountLinking
            accountId={accountId}
            accountSID={accountSID}
            rowData={accountRowData}
            selectedGrid={selectedGrid}
            tokenFilter={tokenFilter}
            refresh={refresh}
            setParentRefresh={this.setParentRefresh.bind(this)}
            onPreviewClick={this.onPreviewClick}
            generateAndDownload={this.generateAndDownload.bind(this)}
            generateAndSend={this.generateAndSend.bind(this)}
            handleSignerEdit={this.handleSignerEdit.bind(this)}
            fetchAccountData={this.fetchAccountData.bind(this)}
            closeModal={this.closeModal}
            services={services}
            user={user}
          />
        )}
        {this.state.toMyEnrollments ? <Redirect push to={`/myenrollments`} /> : null}
        {this.state.toEnroll ? <Redirect push to={`/enroll`} /> : null}
        {this.state.toAuthorizations ? <Redirect push to={`/authorizations`} /> : null}

        <div className="header-myenrollments header-authorizations">
          <div className="myenrollments-title">
            <i
              className={isHome && enhancedNavigation ? 'fa fas fa-home generic-enhanced-disabled' : 'fa fas fa-home'} // Disable home icon when on home
              onClick={() => {
                enrollHomeSection == 'Accounts' ? this.onViewAccountsClick() : this.onViewAuthorizationsClick();
              }}
            ></i>
            {enhancedNavigation && <span className="generic-divider">|</span>}
            Authorizations
          </div>
          <div className="generic-button-wrapper">
            {path === 'regular' && (
              <div
                className={enrollAccountsNewButton ? 'generic-button-primary generic-button-margin-right generic-button-long' : 'generic-button-primary generic-button-margin-right'}
                id="enroll-account-button"
                onClick={() => {
                  this.onEnrollClick(null, null);
                }}
              >
                {/* {enrollPrefixLabel ? enrollPrefixLabel + ' ' : ''}Account */}
                {enrollNewButton}
              </div>
            )}
            <div
              id="view-accounts-button"
              className="generic-button-secondary generic-button-auto"
              onClick={() => {
                this.onViewAccountsClick();
              }}
            >
              {accountsButtonLabel ? accountsButtonLabel : 'Accounts'}
            </div>
          </div>
        </div>

        <div className="subheader-myenrollments">
          <div className="account-search-wrapper">
            <input
              className="account-search"
              id="account-search-text-input"
              placeholder="Search..."
              value={this.state[`${selectedGrid}_filter`]}
              onChange={(e) => {
                this.onSearchChange(e);
              }}
            />
            <FontAwesome
              name="xbutton"
              className="fa-times fa-times-clear clear-search"
              onClick={() => {
                this.onSearchChange({ target: { value: '' } });
              }}
            />

            <div className="quickfilter-div justify-content-left">
              <div className="quickfilter-buttons-div">
                {enhancedNavigation && <div className="quickfilter-add-filters">Add filters:</div>}

                <div
                  id="active-quickfilter-button"
                  className={handleEnhancedNav(this.state.selectedGrid !== 'Active', enhancedNavigation)}
                  onClick={() => {
                    this.handleChangeGrid('Active');
                  }}
                >
                  Active
                </div>
                <div
                  id="pending-quickfilter-button"
                  className={handleEnhancedNav(this.state.selectedGrid !== 'Pending', enhancedNavigation)}
                  onClick={() => {
                    this.handleChangeGrid('Pending');
                  }}
                >
                  Pending
                </div>
              </div>
              <div className="quickfilter-divider">|</div>
              {selectedGrid !== 'Pending' && (
                <div
                  id="status-quickfilter"
                  className={handleEnhancedNav(quickfilters.indexOf('Status') === -1, enhancedNavigation)}
                  onClick={() => {
                    this.handleQuickFilter('Status');
                  }}
                >
                  Status
                </div>
              )}
              <div
                id="status-quickfilter"
                className={handleEnhancedNav(quickfilters.indexOf('Signer') === -1, enhancedNavigation)}
                onClick={() => {
                  this.handleQuickFilter('Signer');
                }}
              >
                Primary Signer
              </div>
              <div
                id="relationshipid-quickfilter"
                className={handleEnhancedNav(quickfilters.indexOf('AccountSID') === -1, enhancedNavigation)}
                onClick={() => {
                  this.handleQuickFilter('AccountSID');
                }}
              >
                Account ID
              </div>
              <div
                id="clear-quickfilter"
                className={enhancedNavigation ? (quickfilters.length > 0 ? 'quickfilter-button' : 'quickfilter-button-disabled') : 'clear-quickfilter-button'}
                onClick={() => {
                  if (enhancedNavigation) {
                    quickfilters.length > 0 && this.handleQuickFilter('clear');
                  } else {
                    this.handleQuickFilter('clear');
                  }
                }}
              >
                Clear Search
              </div>
            </div>
            {selectedGrid === 'Pending' && (
              <div
                id="clear-quickfilter"
                className={'generic-button-primary generic-button-margin-right generic-button-long quickfilter-button'}
                onClick={() => {
                  this.openAccountLinking();
                }}
              >
                Link Accounts
              </div>
            )}
          </div>
          {selectedGrid === 'Pending' && (
            <div className="select-functions-inner-div">
              <div>
                <Button
                  id="actions-button"
                  aria-controls={open ? 'customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  sx={{ ml: 6, textTransform: 'capitalize' }}
                  className="actions-button"
                  onClick={this.handleClick}
                  startIcon={<MenuIcon />}
                  endIcon={<ArrowDropDownIcon />}
                >
                  Actions
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={this.handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    disabled={!this.isValidEsignature()}
                    onClick={(e) => {
                      this.isValidEsignature() && this.handleActionClick(e);
                    }}
                  >
                    <Tooltip placement="right" title={<h2>Generate and send selected LOA(s)</h2>}>
                      <span id="generate-send-button">Generate and Send</span>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem onClick={this.handleActionClick}>
                    <Tooltip placement="right" title={<h2>Generate and download selected LOA(s)</h2>}>
                      <span id="generate-download-button">Generate and Download</span>
                    </Tooltip>
                  </MenuItem>
                </Menu>
              </div>
              <div id="detokenize-current-view" className="authorizations-eye-div">
                <button
                  onClick={() => {
                    this.setTokenFilter();
                  }}
                  className="search-button"
                  id="account-number-eyeball-button"
                >
                  <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="eyeball-info" place="top" type="info" effect="solid" className="tooltip_custom">
                    <p>{tokenFilter ? 'Show Information' : 'Hide Information'}</p>
                  </ReactTooltip>
                  <FontAwesome
                    name={tokenFilter ? 'far fa-eye ' : 'far fa-eye-slash '}
                    style={{ cursor: 'pointer' }}
                    className={tokenFilter ? 'far fa-eye' : 'far fa-eye-slash'}
                    data-for="eyeball-info"
                    data-tip
                  />
                </button>
              </div>
            </div>
          )}
          {selectedGrid === 'Active' && (
            <div className="select-functions-inner-div">
              <div>
                <Button
                  id="actions-button"
                  aria-controls={open ? 'customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  sx={{ ml: 6, textTransform: 'capitalize' }}
                  className="actions-button"
                  onClick={this.handleClick}
                  startIcon={<MenuIcon />}
                  endIcon={<ArrowDropDownIcon />}
                >
                  Actions
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={this.handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={this.handleActionClick}>
                    <Tooltip placement="right" title={<h2>Download selected LOA(s)</h2>}>
                      <span id="download-loa-button">Download LOA(s)</span>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem onClick={this.handleActionClick}>
                    <Tooltip placement="right" title={<h2>Cancel selected LOA(s)</h2>}>
                      <span id="cancel-loa-button">Cancel LOA(s)</span>
                    </Tooltip>
                  </MenuItem>
                </Menu>
              </div>
              <div id="detokenize-current-view" className="authorizations-eye-div">
                <button
                  onClick={() => {
                    this.setTokenFilter();
                  }}
                  className="search-button"
                  id="account-number-eyeball-button"
                >
                  <ReactTooltip delayShow={globals.TOOLTIP_DELAY} id="eyeball-info" place="top" type="info" effect="solid" className="tooltip_custom">
                    <p>{tokenFilter ? 'Show Information' : 'Hide Information'}</p>
                  </ReactTooltip>
                  <FontAwesome
                    name={tokenFilter ? 'far fa-eye ' : 'far fa-eye-slash '}
                    style={{ cursor: 'pointer' }}
                    className={tokenFilter ? 'far fa-eye' : 'far fa-eye-slash'}
                    data-for="eyeball-info"
                    data-tip
                  />
                </button>
              </div>
            </div>
          )}

          <div className="table-container">
            <ReactTable
              data={this.state[`${selectedGrid}_data`]}
              columns={this.state[`${selectedGrid}_columns`]}
              page={this.state[`${selectedGrid}_page`] || 0}
              pages={this.handlePages(Math.ceil(this.state[`${selectedGrid}_rowCount`] / this.state[`${selectedGrid}_pagesize`]))}
              pageSize={this.state[`${selectedGrid}_pagesize`]}
              className="-striped -highlight grid"
              onSortedChange={this.onSortedChange} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
              loading={this.state[`${selectedGrid}_loading`]}
              manual={true}
              showPagination={true}
              NoDataComponent={() => {
                return (
                  !this.state[`${selectedGrid}_loading`] &&
                  (customNoData ? (
                    <div className="rt-noData-custom">
                      It looks like there aren't any accounts.
                      {(path === 'regular' || path === 'enroll' || path === 'external') && (
                        <div>
                          <div
                            className="rt-calltoaction"
                            onClick={() => {
                              this.onEnrollClick(null, null);
                            }}
                          >
                            Enroll a new account
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="rt-noData-custom">No rows found</div>
                  ))
                );
              }}
              // getTrProps={this.getTrProps}
            />
          </div>
        </div>

        {/* Generate Modal (Generate and Send / Generate and Download) */}
        {this.state.generateModalOpen && (
          <Modal isOpen={this.state.generateModalOpen} style={generateModalStyle}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                <div>
                  <div className="fullmodal handle">
                    <div className="fullmodal_title">
                      <div className="fullmodal_title_add">Generate Authorization</div>
                    </div>
                    <div
                      className="sidemodal_addnew_x"
                      onClick={() => {
                        this.closeModal();
                      }}
                    >
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>

                  {this.state.generateLoading ? (
                    <center className="generate-spinner">
                      <Spinner id="preview-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
                    </center>
                  ) : (
                    <div className="detail-modal-container">
                      <div className="generate-button-wrapper">
                        <button
                          disabled={this.state.includeInGenerate.length === 0}
                          className="generate-button"
                          id="generate-button"
                          onClick={() => {
                            this.state.generateFunction();
                          }}
                        >
                          {this.state.generateButtonText}
                        </button>
                      </div>
                      <div>
                        <div className="generate-modal-list-item-header">
                          <div className="generate-modal-list-header">Account</div>
                          <div className="generate-modal-list-header center">Preview</div>
                          <div className="generate-modal-list-header center">Include</div>
                        </div>
                        {this.state.Pending_selectedRows.map((auth) => {
                          return (
                            <div className="generate-modal-list-item">
                              <div className="generate-modal-list-name">{auth}</div>
                              <div className="generate-modal-list-name center">
                                <FontAwesome
                                  className="fa fas fa-search fa-pencil-edit-row"
                                  style={{ cursor: 'pointer' }}
                                  name="fa-search"
                                  onClick={() => {
                                    this.onPreviewClick(this.state.Pending_selectedRowsValues[auth]);
                                  }}
                                />
                              </div>
                              <div className="generate-modal-list-input center">
                                <input
                                  type="checkbox"
                                  checked={this.state.includeInGenerate.includes(auth)}
                                  onChange={() => {
                                    this.updateIncludeInGenerate(auth);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Draggable>
          </Modal>
        )}

        {this.state.previewLoaModalOpen && (
          <Modal isOpen={this.state.previewLoaModalOpen} style={previewModalStyle}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                <div>
                  <div className="fullmodal handle">
                    <div className="fullmodal_title">
                      <div className="fullmodal_title_add">Preview LOA</div>
                    </div>

                    <div
                      className="sidemodal_addnew_x"
                      onClick={() => {
                        this.closePreviewAndCancelLoas();
                      }}
                    >
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>
                  {this.state.previewLoaLoading ? (
                    <center className="generate-spinner modal-spinner">
                      <Spinner id="preview-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
                    </center>
                  ) : (
                    <div className="modalBodyPreview">{this.renderPreviewLOA()}</div>
                  )}
                </div>
              </div>
            </Draggable>
          </Modal>
        )}

        {this.state.viewLoaModalOpen && (
          <Modal isOpen={this.state.viewLoaModalOpen} style={modalStyle}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                <div>
                  <div className="fullmodal fullmodal-loa-adjust handle">
                    <div className="fullmodal_title">
                      <div className="fullmodal_title_add">View LOA</div>
                    </div>

                    <div id="close-modal" className="sidemodal_addnew_x" onClick={this.closeModal}>
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>
                  <div className="modalBodyLOA">
                    <LOADocument
                      user={this.props.user}
                      services={this.props.services}
                      loaID={this.state.selectedRow.LoaID}
                      primarySigner={this.state.selectedRow.PrimarySigner}
                      status={this.state.selectedRow.Status}
                      type="edit"
                      refreshGrid={this.refreshGrid}
                    />
                    {/* {this.renderPDF()} */}
                  </div>
                </div>
              </div>
            </Draggable>
          </Modal>
        )}

        {/* Action Modal (Download / Cancel) */}
        {this.state.actionModalOpen && (
          <Modal isOpen={this.state.actionModalOpen} style={generateModalStyle}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                {/* If %STATUSLIST% is provided in the help content, we will replace it with the list of acceptable Statuses */}
                <div className="preview-modal-wrapper">
                  <div className="fullmodal handle">
                    <div className="fullmodal_title">
                      <div className="fullmodal_title_add">{this.state.actionButtonText}</div>
                    </div>
                    <div
                      className="sidemodal_addnew_x"
                      onClick={() => {
                        this.closeModal();
                      }}
                    >
                      <FontAwesome name="xbutton" className="fa-times" />
                    </div>
                  </div>

                  {this.state.actionLoading ? (
                    <center className="generate-spinner">
                      <Spinner id="preview-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
                    </center>
                  ) : (
                    <div className="detail-modal-container">
                      {this.state.actionError ? (
                        <div className="action-error">{this.state.actionError}</div>
                      ) : (
                        <div>
                          <div className="generate-button-wrapper">
                            <button
                              disabled={this.state.includeInAction.length === 0}
                              className={this.state.includeInAction.length === 0 ? 'generate-button generic-button-disabled' : 'generate-button'}
                              title="Please make a selection to continue"
                              id="action-button"
                              onClick={() => {
                                this.state.actionFunction();
                              }}
                            >
                              {this.state.actionButtonText}
                            </button>
                          </div>
                          <div>
                            <div className="generate-modal-list-item-header">
                              <div className="generate-modal-list-header">LOA</div>
                              <div className="generate-modal-list-header center">Include</div>
                            </div>
                            {this.state.Active_selectedRows.map((loa) => {
                              let name = this.state.Active_selectedRowsValues[loa].Authorization;
                              let status = this.state.Active_selectedRowsValues[loa].Status;
                              return (
                                <div className="generate-modal-list-item">
                                  <div className="generate-modal-list-name">{name}</div>
                                  <div className="generate-modal-list-input">
                                    <input
                                      type="checkbox"
                                      checked={this.state.includeInAction.includes(loa)}
                                      onChange={() => {
                                        this.updateIncludeInAction(loa);
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Draggable>
          </Modal>
        )}

        {this.state.viewContactModalOpen && (
          <Modal isOpen={this.state.viewContactModalOpen} style={styleOverrides}>
            <Draggable handle=".handle">
              <div>
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">Edit Signer</div>
                  </div>

                  <div
                    className="sidemodal_addnew_x"
                    onClick={() => {
                      this.setState({ viewContactModalOpen: false, refresh: true });
                    }}
                  >
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <div className="search-modal-container">
                  <Contact
                    user={this.props.user}
                    services={this.props.services}
                    contactFor={'signer'}
                    type={'view'}
                    closeSearchModal={() => {
                      this.setState({ viewContactModalOpen: false, refresh: true });
                    }}
                    closeViewModal={() => {
                      this.setState({ viewContactModalOpen: false, refresh: true });
                    }}
                    closeDetailModal={() => {
                      this.setState({ viewContactModalOpen: false, refresh: true });
                    }}
                    handleContactSelect={null}
                    contactDetailId={this.state.editContactDetailId}
                    updateAccountData={this.props.updateAccountData}
                    setCurrentScreen={this.props.setCurrentScreen}
                    isModal={true}
                    crmData={this.props.crmData}
                    hideSignerAddEdit={!enrollSignersEdit}
                  />
                </div>
                {/* {this.renderAccountDetails()} */}
              </div>
            </Draggable>
          </Modal>
        )}
        {this.state.quickFilterModalOpen && (
          <Modal isOpen={this.state.quickFilterModalOpen} style={this.getModalStyle()}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">{this.getQuickfilterTitle()}</div>
                  </div>
                  <div className="sidemodal_addnew_x" onClick={this.closeModal}>
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                <div className="large-quickfilter-modal">
                  <div className="large-quickfilter-top-div top-margin">
                    <div className="top-div-buttons">
                      <div
                        className="generic-button-secondary"
                        id="cancel-button"
                        onClick={() => {
                          this.closeModal();
                        }}
                      >
                        Cancel
                      </div>

                      {selectedQuickfilter === 'Account' && (
                        <div
                          title="Clear Account Search Criteria"
                          className="generic-button-secondary"
                          id="clear-button"
                          onClick={() => {
                            this.clearAccountQuickFilter();
                          }}
                        >
                          Clear
                        </div>
                      )}

                      <div
                        className={
                          this.state.dateWarning === false || this.state.selectedQuickfilter != 'EnrolledDate' ? 'generic-button-primary' : 'generic-button-primary generic-button-disabled'
                        }
                        id="save-button"
                        onClick={() => {
                          if (this.state.selectedQuickfilter === 'Account') {
                            this.getAccountToken();
                          } else {
                            this.handleSaveQuickfilterClick(this.state.selectedQuickfilter === 'EnrolledDate');
                          }
                        }}
                      >
                        Save
                      </div>
                    </div>
                  </div>

                  {selectedQuickfilter && selectedQuickfilter === 'Custodian' ? (
                    <div className="modal-wrapper">
                      <input
                        autoFocus
                        placeholder="Search..."
                        className="search-custodian"
                        value={this.state.custodianQuickfilterSearch}
                        onChange={(e) => {
                          this.setState({ custodianQuickfilterSearch: e.target.value }, () => {
                            this.getCustodianOptions();
                          });
                        }}
                      />
                    </div>
                  ) : null}

                  {selectedQuickfilter && selectedQuickfilter === 'Signer' ? (
                    <div className="modal-wrapper">
                      <div className="search-signer-wrapper">
                        <input
                          autoFocus
                          id="search-signer"
                          placeholder="Search..."
                          className="search-custodian"
                          // value={this.state.signerQuickfilterSearch}
                          value={this.state[`${selectedGrid}_${selectedQuickfilter}`]}
                          onChange={(e) => {
                            this.setState({ [`${selectedGrid}_${selectedQuickfilter}`]: e.target.value }, () => {
                              this.getSignerOptions();
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null}

                  {this.state.selectedQuickfilter &&
                  this.state.selectedQuickfilter !== 'Account' &&
                  this.state.selectedQuickfilter !== 'EnrolledDate' &&
                  selectedQuickfilter !== 'Custodian' &&
                  selectedQuickfilter !== 'Signer' &&
                  selectedQuickfilter !== 'RelationshipId' &&
                  selectedQuickfilter !== 'AdvisorId' &&
                  selectedQuickfilter !== 'AccountSID' ? (
                    <div
                      className="quickfilter-select-all"
                      onClick={() => {
                        this.handleQuickfilterCheckboxSelect('SelectAll');
                      }}
                    >
                      <input
                        type="checkbox"
                        className="quickfilter-input select-all-filter "
                        checked={quickfilterModalValues[this.state.selectedQuickfilter] ? quickfilterModalValues[this.state.selectedQuickfilter].includes('SelectAll') : false}
                      />
                      Select All
                    </div>
                  ) : null}
                  {/* <div className={this.state.selectedQuickfilter === 'Custodian' ? 'quickfilter-modal-body custodian ': 'quickfilter-modal-body'}> */}

                  <div className={'quickfilter-modal-body quickfilter-modal-body-' + this.state.selectedQuickfilter}>
                    {selectedQuickfilter && selectedQuickfilter === 'Custodian' ? (
                      <div>
                        <div>
                          {this.state.custodianQuickfilterSearch.length >= 3 && !this.state.custodianSearchLoading && this.state.quickfilterOptions[selectedQuickfilter].length === 0 ? (
                            <div className="large-quickfilter-text">No Results Found</div>
                          ) : null}

                          {!this.state.custodianSearchLoading ? (
                            this.state.quickfilterOptions[selectedQuickfilter].map((opt) => {
                              return (
                                <div
                                  className="large-quickfilter-checkbox-field"
                                  onClick={() => {
                                    this.handleQuickfilterCheckboxSelect(opt.name);
                                  }}
                                >
                                  <input type="checkbox" className="quickfilter-input" checked={quickfilterModalValues[selectedQuickfilter].includes(opt.name)} />
                                  {opt.name}
                                </div>
                              );
                            })
                          ) : (
                            <div className="large-quickfilter-text">Searching...</div>
                          )}
                        </div>
                        {/* <div>
                      <div className='selected-custodians-text'>Selected Custodians:</div>
                      <ul> 
                        {this.state.quickfilterModalValues.Custodian.map((custodian) => {
                          return (
                            <li>{custodian}</li>
                          )
                        })}
                      </ul>
                    </div> */}
                      </div>
                    ) : null}
                    {selectedQuickfilter && selectedQuickfilter === 'Signer' ? (
                      <div>
                        <div>
                          {this.state.signerQuickfilterSearch.length >= 3 && !this.state.signerSearchLoading && this.state.quickfilterOptions[selectedQuickfilter].length === 0 ? (
                            <div className="large-quickfilter-text">No Results Found</div>
                          ) : null}

                          {!this.state.signerSearchLoading ? (
                            this.state.quickfilterOptions[selectedQuickfilter].map((opt) => {
                              return (
                                <div
                                  className="large-quickfilter-checkbox-field"
                                  onClick={() => {
                                    this.handleQuickfilterCheckboxSelect(opt.name);
                                  }}
                                >
                                  <input type="checkbox" className="quickfilter-input" checked={quickfilterModalValues[selectedQuickfilter].includes(opt.name)} />
                                  {opt.name}
                                </div>
                              );
                            })
                          ) : (
                            <div className="large-quickfilter-text">Searching...</div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {this.state.selectedQuickfilter && this.state.selectedQuickfilter === 'AccountSID' ? (
                      <div className="search-account-wrapper">
                        <div className="search-account-bottom-div">
                          <div className="enrollment-account-search-modal">
                            <div className="enrollment-account-search filter-exact-id">
                              <div className="modal-label">
                                <label className="enrollment-account-search-name-label label" id="account-enrollment-seach">
                                  Filter by Account ID:
                                </label>
                              </div>

                              <div className="account-modal-search filter-exact-id">
                                <input
                                  className="search-account"
                                  id="search-account-sid"
                                  type="number"
                                  min={0}
                                  max={Number.MAX_SAFE_INTEGER + 1}
                                  value={this.state[`${selectedGrid}_quickfilterModalValues`][selectedQuickfilter]}
                                  placeholder=""
                                  onChange={(e) => {
                                    if (e && e.target.value < Number.MAX_SAFE_INTEGER + 1 && !e.target.value.includes('-')) {
                                      let temp = [];
                                      let value = e.target.value.replace(/^0+/, '');
                                      if (value) temp.push(value);
                                      this.setState({
                                        searchAccountSIDText: value,
                                        [`${selectedGrid}_quickfilterModalValues`]: { ...this.state[`${selectedGrid}_quickfilterModalValues`], [this.state.selectedQuickfilter]: temp },
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {selectedQuickfilter && this.state.selectedQuickfilter !== 'Account' && selectedQuickfilter !== 'Custodian' && selectedQuickfilter !== 'Signer'
                      ? this.state.quickfilterOptions[selectedQuickfilter].map((opt) => {
                          return (
                            <div
                              className="quickfilter-checkbox-field"
                              onClick={() => {
                                this.handleQuickfilterCheckboxSelect(opt.name);
                              }}
                            >
                              <input
                                type="checkbox"
                                className="quickfilter-input"
                                checked={quickfilterModalValues[selectedQuickfilter] ? quickfilterModalValues[selectedQuickfilter].includes(opt.name) : false}
                              />
                              {opt.name}
                            </div>
                          );
                        })
                      : null}
                  </div>
                  {selectedQuickfilter && selectedQuickfilter === 'Custodian' && (
                    <div>
                      {quickfilterModalValues.Custodian && quickfilterModalValues.Custodian.length > 0 && (
                        <div className="selected-custodians-wrapper">
                          <div className="selected-custodians-text">Selected Custodians:</div>
                          <div
                            className="clear-quickfilter-button clear-selected-custodians"
                            onClick={() => {
                              this.handleQuickfilterCheckboxSelect('ClearAll');
                            }}
                          >
                            Clear All Selected
                          </div>
                        </div>
                      )}
                      <ul className="custodian-quickfilter-ul">
                        {quickfilterModalValues.Custodian.map((custodian) => {
                          return <li>{custodian}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                  {selectedQuickfilter && selectedQuickfilter === 'Signer' && (
                    <div>
                      {quickfilterModalValues.Signer && quickfilterModalValues.Signer.length > 0 && (
                        <div className="selected-custodians-wrapper">
                          <div className="selected-custodians-text">Selected Signers:</div>
                          <div
                            className="clear-quickfilter-button clear-selected-custodians"
                            onClick={() => {
                              this.handleQuickfilterCheckboxSelect('ClearAll');
                            }}
                          >
                            Clear All Selected
                          </div>
                        </div>
                      )}
                      <ul className="custodian-quickfilter-ul">
                        {quickfilterModalValues.Signer.map((signer) => {
                          return <li>{signer}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </Draggable>
          </Modal>
        )}
        {this.state.viewAccountDetailsModalOpen && (
          <Modal isOpen={this.state.viewAccountDetailsModalOpen} style={styleOverrides}>
            <Draggable handle=".handle">
              <div className="draggable-wrapper">
                <div className="fullmodal handle">
                  <div className="fullmodal_title">
                    <div className="fullmodal_title_add">Account Details</div>
                  </div>

                  <div
                    id="close-modal"
                    className="sidemodal_addnew_x"
                    onClick={() => {
                      this.setState({ viewAccountDetailsModalOpen: false });
                    }}
                  >
                    <FontAwesome name="xbutton" className="fa-times" />
                  </div>
                </div>
                {this.state.previewLoading ? (
                  <center className="generate-spinner modal-spinner">
                    <Spinner id="preview-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
                  </center>
                ) : (
                  <div className="account-details-wrapper">
                    <Account
                      user={this.props.user}
                      setMyAccountsLoaded={this.props.setMyAccountsLoaded}
                      services={this.props.services}
                      setIsEdit={this.setIsEdit}
                      edit={this.state.edit || this.props.selectedAccountId ? true : false}
                      accountData={this.state.previewAccountDetailsData}
                      updateAccountData={(obj) => {
                        return new Promise((resolve, reject) => {
                          this.setState({ previewAccountDetailsData: obj });
                          resolve();
                        });
                      }}
                      setCurrentScreen={this.setCurrentScreen}
                      setValidCustodian={this.setValidCustodian}
                      setSignerIndex={this.setSignerIndex}
                      signerIndex={this.state.signerIndex}
                      setValidSigners={this.setValidSigners || []}
                      validSigners={this.state.validSigners || []}
                      forceUpdate={this.state.forceUpdate}
                      setForceUpdate={this.setForceUpdate}
                      viewOnlyMode={true}
                    />
                  </div>
                )}
              </div>
            </Draggable>
          </Modal>
        )}
      </div>
    );
  }
}
